import React, { Component } from 'react';
import logo from '../../assets/coffeepass_web_logo_interior.png';
import './App.css';
import { NavLink } from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import windowSize from 'react-window-size';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        }
        this.links = []
        if(props.page == '/') {
            this.links.push({ link: '/shopLanding', title: 'Shop Owners', style: 'navButton' })
        } else if(props.page == '/shopLanding') {
            this.links.push({ link: '/', title: 'Coffee Lovers', style: 'navButton' })
        } else {
            this.links.push({ link: '/', title: 'Coffee Lovers', style: 'navButton' })
            this.links.push({ link: '/shopLanding', title: 'Shop Owners', style: 'navButton' })
        }

        var otherLinks = [
            { link: 'http://blog.coffeepass.io', title: 'Blog', style: 'navButton' },
            { link: '/contactUs', title: 'Contact Us', style: 'navButton' },
            { link: 'app/overview', title: 'Shop Dashboard', style: 'navButton' }
        ]
        this.links = this.links.concat(otherLinks)
    }

    toggleDrawer = (open) => () => {
        this.setState({
            isOpen: open,
        })
    }

    render() {
        const isDrawer = this.props.windowWidth < 1200

        var linksView = this.links.map((link, index) => {
            if (isDrawer) {
                link.style = 'hamburgerMenuLinks'
            } else {
                link.style = 'navButton'
            }
            return (
                (link.link.indexOf('http') != -1)?
                    <a key={index} href={link.link} style={{ textDecoration: 'none' }}>
                        <h2 className={link.style}>
                            {link.title}
                        </h2>
                        {(isDrawer) ? <Divider /> : null}
                    </a>
                    :
                    <NavLink key={index} to={link.link} style={{ textDecoration: 'none' }}>
                        <h2 className={link.style}>
                            {link.title}
                        </h2>
                        {(isDrawer) ? <Divider /> : null}
                    </NavLink>
                )
        })

        return (
            <div className="headerBlock">
                <NavLink to='/' style={{ textDecoration: 'none' }}>
                    {
                        this.props.page != '/' &&
                        <img src={logo} className="logoInte" alt="logo" />
                    }

                </NavLink>
                {(!isDrawer) ?
                    <div className='navBar'>
                        {linksView}
                    </div>
                    :
                    <div className="navBar">
                        <span className="hamburgerMenu">
                            <IconButton style={styles.iconButton} variant="raised" onClick={this.toggleDrawer(true)}>
                                <MenuIcon style={styles.menuIcon} />
                            </IconButton>
                            <SwipeableDrawer
                                anchor="right"
                                open={this.state.isOpen}
                                onClose={this.toggleDrawer(false)}
                                onOpen={this.toggleDrawer(true)}>
                                <div
                                    tabIndex={0}
                                    role="button"
                                    onClick={this.toggleDrawer(false)}
                                    onKeyDown={this.toggleDrawer(false)}>
                                    <div style={styles.menuList}>
                                        {linksView}
                                    </div>
                                </div>
                            </SwipeableDrawer>
                        </span>
                    </div>
                }
            </div>
        );
    }
}

const styles = {
    header: {
        fontSize: 60,
        color: 'white',
        padding: '10%',
        fontFamily: "Palatino"
    },
    iconButton: {
        justifyContent: 'flex-end'
    },
    menuIcon: {
        width: 50,
        color: 'white'
    },
    menuList: {
        width: 250
    }
}

export default windowSize(Header);
