import React, { Component } from 'react';
import NoDataCard from './NoDataCard';

class NoDataYearSales extends Component {
    render() {
        return (
            <div className="col-xl-12 col-md-12 col-12">
                <div className="row">
                    <div className="col-12">
                        <div className="jr-card">
                            <div className="jr-card-header">{this.props.title}</div>
                            <NoDataCard type={this.props.type} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NoDataYearSales