import mixpanel from 'mixpanel-browser';

mixpanel.init("91d12b8fae3f0573a208ee9380e68cd0");

export const MixpanelTrack = (message) => {
    mixpanel.track('Dashboard: ' + message)
}

export const MixpanelIdentify = (userId) => {
    mixpanel.identify(userId)
}

export const MixpanelEmail = (email) => {
    mixpanel.people.set({
        '$email': email
    })
}