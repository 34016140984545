
import { NOT_IN_STORE, types } from '../constants/ActionTypes';
import { baseUrl } from '../constants/baseUrl';

export const isShopChainAction = (shopId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: shopId,
    meta: {
        type: 'api',
        url: baseUrl + '/is_shop_chain',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const getChainShopsAction = (shopId, onSuccess, onFailure) => ({
    type: types.GET_CHAIN_SHOPS,
    payload: shopId,
    meta: {
        type: 'apijson',
        url: baseUrl + '/get_chain_shops',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'GET'
        }
    }
})

export const copyChainInfoAction = (shopId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: shopId,
    meta: {
        type: 'api',
        url: baseUrl + '/copy_chain_info',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'PUT'
        }
    }
})

export const copyChainMenuAction = (shopId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: shopId,
    meta: {
        type: 'api',
        url: baseUrl + '/copy_chain_menu',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: shopId
        },
        options: {
            method: 'PUT'
        }
    }
})