import React, { Component } from 'react';
import { NotificationContainer } from 'react-notifications';
import Footer from '../home/Footer';
import HeaderBlock from '../home/HeaderBlock';
import '../home/App.css';
import './TermsOfService.css'
import { MixpanelTrack } from '../../util/MixpanelHelper';
import ToSItem from './ToSItem'

export default class TermsOfService extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        MixpanelTrack("Visited Privacy Policy")
    }

    render() {
        return (
            <div className='app'>
                <NotificationContainer />
                <div className="navBarBackground">
                    <HeaderBlock />
                </div>
                <div className="row">
                    <h1 className="tosTitle">Privacy Policy</h1>
                </div>

                <div className="row tosWindow">
                    <ToSItem
                        header=""
                        content={`CoffeePass (“we”, “us” or “CoffeePass”) respects your right to privacy. As such, we value your ability to make informed choices about the use of your information. This Privacy Policy explains our policy regarding the collection, use, disclosure and protection of Personal Information we receive when you register and use the Service (“the App”). If not otherwise defined in this Privacy Policy, capitalized terms have the meaning given to them in our Terms of Service, which you may read at http://coffeepass.io/terms (the “Terms”).
                        `}
                    />
                    <ToSItem
                        header="Information We Collect"
                        content={`CoffeePass requires users to register a CoffeePass account in order to use the features in the App.  Some of the information you provide to register a CoffeePass account is Personal Information; “Personal Information” means information that can be directly associated with a specific person. 
 
                        CoffeePass may collect and store some or all the following information:
                         
                        • Your first and last name
                        • Your login email address
                        • Your phone number
                        • Your physical location and that of the devices you use to access our Services
                          
                        In addition, when you use the App, certain information may be automatically gathered about your mobile device, browser, operating system, location, or IP address.
                         
                        We also collect Personal Information when you contact us for any reason. It is your voluntary decision whether to provide us with any of the above referenced Personal Information, but if you refuse to provide such information your use of the Services may be limited.
                        
                        While CoffeePass does store some Personal Information, it does NOT store the credit card or bank account information of its users or Partners, and instead relies on a reputable third-party (e.g. Stripe, Inc, “Stripe”) to do so.
                        `}
                    />
                    <ToSItem
                        header="How We Use Information"
                        content={`Except as specifically set forth in these Terms, we will not share your Personal Information with third parties without your explicit permission, except when required by law, regulation, subpoena or court order, to protect our rights or to cooperate with an investigation by a law enforcement body.
 
                        We may use Personal Information internally – for example, to personalize CoffeePass’s content for you. We may deliver targeted marketing, service updates, and promotional offers.
                         
                        We will use the information you provide in order to provide our service.
                         
                        We may share your Personal Information with:
                         
                        • third-party vendors or service providers (e.g, Partners), including purchase history and demographic information. We require third-party vendors and service providers to follow practices that protect your privacy rights on a level at least as restrictive as the practices described in this Privacy Policy.
                        • payment processors that enable the transfer of your payment submitted through the App to the Business with which you are conducting a transaction (e.g. Stripe, Inc, “Stripe”). Such payment processors may provide your Personal Information to credit card companies to enable billing. Additionally, such payment processors may provide the last four digits of your method of payment to the Business or third-party vendor to allow transaction identification. Such payment processors may also use your Personal Information for internal purposes; it is recommended that you review Stripe’s Privacy Policy to understand their standard use of your Personal Information (www.stripe.com/privacy). Please note that we do not control the privacy practices of these third-party vendors or service providers. If you wish to no longer allow that third-party to use your information, please contact that third-party directly.
                        • referring websites; if you were referred to the Services from a web site (for example, through a link), we may share some information about you with that referring website. We encourage you to review the privacy policies of any website that referred you to the Services.
                         
                        We may share anonymized information (not personally identifiable information) about your demographics and your purchase history with Partners. Such information will not be linked to your Personal Information and will only be used for purposes of tracking transactions and data collection.
                         
                        By analyzing all information we receive, including all information concerning users, we may compile statistical information across a variety of users (“Statistical Information”). Statistical Information helps us understand trends and user needs so that new products and services can be considered, and so existing products and services can be tailored to user desires. Statistical Information does not indicate individuals’ identities, and we will not link Statistical Information to any Personal Information. We may share Statistical Information with businesses, without restriction, on commercial terms that we can determine in our sole discretion.
                        `}
                    />
                    <ToSItem
                        header="How We Protect Information"
                        content={`We make efforts to follow generally accepted industry standards to protect Personal Information, both during transmission and once we receive it. However, no method of transmission over the Internet or method of electronic storage is completely secure, therefore, we cannot guarantee its absolute security.
 
                        In addition to staying up to date with security best practices, on a six-month cycle CoffeePass will complete a formal review of our security practices and standards, to ensure that our data infrastructure is safe and compliant. 
                         
                        In the event of a data breach, CoffeePass will (a) immediately contact all users to notify them of the breach; (b) stop the breach; (c) review breach details; (d) communicate detailed findings of the review to all users; and (e) proceed with legal action on behalf of all users.
                        `}
                    />
                    <ToSItem
                        header="Use of CoffeePass by Children"
                        content={`By registering an account with CoffeePass, you represent that you are at least 18 years of age, or that you have the permission of a legal guardian (e.g., a parent) to use the Services.
 
                        We do not knowingly collect personally identifiable information from children under the age of 18. In the event that you become aware that somebody under the age of 18 has enrolled without parental permission, please inform us immediately at support@coffeepass.io.
                        `}
                    />
                    <ToSItem
                        header="Communications"
                        content={`We may send you e-mail or other messages about CoffeePass or our products and services. By accepting the Terms of Service for the App (including the terms of this Privacy Policy which are incorporated therein) or using the App, you affirmatively consent to receive such commercial messages. As a registered member, you can remove your Personal Information from our mailing list and stop receiving future communication from us by following the unsubscribe link located at the bottom of any communication or by emailing us at support@coffeepass.io. Please note that we reserve the right to send you receipts for your purchases, service-related communications, including service announcements and administrative messages, relating either to your account or to your transactions on this site, without offering you the opportunity to opt out of receiving them unless you cancel your account.
                        `}
                    />
                    <ToSItem
                        header="Transfer of Your Information"
                        content={`We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity, behavior or digital content of any user suspected to have engaged in illegal or infringing behavior. We may transfer our databases containing your Personal Information if we sell any part of our business. 
                        `}
                    />
                    <ToSItem
                        header="Changes"
                        content={`We may update this Privacy Policy from time to time - we encourage you to review it periodically.
                        `}
                    />
                    <ToSItem
                        header="Comments and Questions"
                        content={`If you have any comments or questions about our privacy policy, please contact us at support@coffeepass.io.
                        `}
                    />
                </div>
                <svg height='35%' width='100%' viewBox="0 0 100 100" preserveAspectRatio='none' class='contactWedge'>
                    <polygon points='0,100 100,100 100,0' className='wedge' />
                </svg>
                <Footer />
            </div >
        );
    }
}



