import React, { Component } from 'react'
import ReportBox from './ReportBox';
import { Bar, XAxis, BarChart } from 'recharts';
import NoDataYearSales from './NoDataYearSales';
import { getGrossOrdersAction } from './../../../../../actions/GrossOrders';
import { connect } from 'react-redux';
import { relativeTimeRounding } from 'moment';
import DatabaseValidator from './../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangeHelper from '../../../util/DateRangeHelper';
import selectors from '../../../../../reducers/selectors';
class YearSalesReport extends Component {

    constructor(props) {
        super(props);
        const dateRangeHelper = new DateRangeHelper()
        const range = dateRangeHelper.getYear()
        
        let paramObj = {
            shopId: this.props.shopId,
            timeBlock: 'MONTH',
            dateFirst: range.dateFirst,
            dateSecond: range.dateSecond

        }
        this.props.getGrossOrdersAction(paramObj)
    }

    render() {
        let grossToDate = 0;
        let numMonths = 0;
        let dataVis = 0;
        const dbv = new DatabaseValidator();
        if (!dbv.isEmpty(this.props.grossOrders)) {
            if (this.props.grossOrders['val'] != 0) {
                for (let x in JSON.parse(this.props.grossOrders['val'])) {
                    grossToDate += JSON.parse(this.props.grossOrders['val'])[x]['amt']
                    numMonths = (parseInt(x) + 1); //The '+1' accounts for the 0 index to return the total number of months accounted for
                }
                grossToDate = (grossToDate != 0)? (Math.round(grossToDate * 10000) / 10000) : 0
                dataVis = <ReportBox heading="This Year Sales Report" title={"$" + grossToDate.toString(10)}
                    detail={"Past " + numMonths + " Months"}>
                    <BarChart data={JSON.parse(this.props.grossOrders['val'])}>
                        <Bar dataKey='amt' fill='#3f51b5' maxBarSize={12} />
                        <XAxis stroke="#3f51b5" dataKey="date" />
                    </BarChart>
                </ReportBox>;
            } else {
                dataVis = <NoDataYearSales title="This Year Sales Report" type="Sales" />;
            }

        } else {
            dataVis = <div className="col-12">
                <div className="jr-card">
                    <div className="jr-card-header d-flex align-items-center mb-3">
                        <h3 className="card-heading mb-0"><i
                            className="zmdi-hc-fw text-primary text-lighten-2 mr-2" />
                            Year Sales Report
                        </h3>

                    </div>
                    <div className="text-center"><CircularProgress size={60} color="secondary" /></div>
                </div>
            </div>;
        }

        return (
            <div className="col-xl-12 col-md-12 col-12">
                <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                        {dataVis}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        grossOrders: state.analytics.grossOrders
    }
}

const actionCreators = {
    getGrossOrdersAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(YearSalesReport);