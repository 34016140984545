import React, { Component } from 'react';
import './TermsOfService.css'


export default class TermsOfService extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className='tosHeader'>{this.props.header}</div >
                <div className='tosContent'>{this.props.content}</div>
            </div>
            
        );
    }
}



