import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';


class CustomerFreqTableCell extends Component {

    render() {

        const customers = this.props.data['customers'];
        const growth = this.props.data['growth'];
        const id = this.props.num;
        const percentIcon = growth == 'n/a' ? '' : '%';
        const iconName = growth > 0 ? "up" : (growth == 'n/a' || growth == 0) ? "same" : "down";

        const statusStyle = growth < 0 ? "text-danger" : growth == 'n/a' ? 'text-muted' : "text-success";
        var color = '';

        switch (id) {
            case 1: color = 'bg-indigo lighten-4';
                break;
            case 2: color = 'bg-indigo lighten-3';
                break;
            case 3: color = 'bg-indigo lighten-2';
                break;
            case 4: color = 'bg-indigo lighten-1';
                break;
            case 5: color = 'bg-indigo';
                break;
            default: color = 'bg-indigo';
        }
        return (
            <tr
                tabIndex={-1}
                key={id} >
                <td>
                    <div className="user-profile py-1 d-flex flex-row align-items-center">
                        <Avatar className={`size-40 align-self-start mr-3 rounded ${color}`}>
                            <span className="numIcon">{id}</span>
                        </Avatar>
                        <div className="user-detail">
                            <h4 className="user-name">Visits</h4>
                            <p className="user-description"></p>
                        </div>
                    </div>
                </td>
                <td>
                    <h5 className="mb-0">{customers}</h5>
                    <span className="text-muted">Customers</span>
                </td>
                <td className="text-right">
                    <div className={`${statusStyle}`}>
                        <i className={`zmdi zmdi-trending-${iconName}`} />
                        {growth}{percentIcon}
                    </div>
                </td>
            </tr>

        );
    }

}

export default CustomerFreqTableCell;
