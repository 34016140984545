import React, {Component} from 'react';
import {connect} from 'react-redux'
import IconButton from '@material-ui/core/IconButton';
import MenuItemList from './MenuItemList';
import CoffeeIconSelectDialog from './CoffeIconSelectDialog';
import OptionList from './OptionList';
import SaveSureDialog from '../../ShopInfo/SaveSureDialog';
import {
    removeDeletedMenuAction,
    getShopMenuAction,
    changeItemImageAction,
    emptyToDeleteAction,
    validateMenuAction,
    changeFoodViewAction,
    changeDrinkViewAction,
    newCategoryAction,
    editCategoryAction,
    dragCategoryAction,
    updateShopMenuV3Action,
    changeCategoryViewAction,
    deleteCategoryAction,
    dragItemAction,
    changeCategoryVisibilityAction,
    changeCategoryExclusivityAction, changeEventsCategoryAction
} from '../../../../actions/Menu';
import { firstMenuLoadAction, updateDrinkOrFoodAction, updateCurrentItemAction } from '../../../../actions/MenuBuilder'
import { NotificationManager, NotificationContainer } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MixpanelTrack } from '../../../../util/MixpanelHelper';
import { DragDropContext } from 'react-beautiful-dnd';
import MenuTabButtons from './MenuTabButtons';
import DefaultOptionsList from './DefaultOptionsList';
import { dragOptionAction } from '../../../../actions/Menu';
import selectors from '../../../../reducers/selectors';
import { Redirect } from 'react-router-dom'

import { Button, Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CategoryDialog from './CategoryDialog';
import DeleteDialog from './DeleteDialog';

class MenuBuilder extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedImage: null,
            isDialogOpen: false,
            isSaveDialogOpen: false,
            isLoadingSave: false,
            isCategoryDialogOpen: false,
            isCategoryEdit: false,
            categoryToBeEdited: "",
            isDeleteDialogOpen: false,
            firstMenu: null
        }
        this.closeDialog = this.closeDialog.bind(this)
        this.hoverCategory = "**_noCategory_**"
    }

    componentDidMount() {
        if(this.props.menuUI.isFirst) {
            this.props.getShopMenuAction({
                shopId: this.props.shopId,
                menuId: null
            }, (result) => this.setState({firstMenu: result}));
            this.props.firstMenuLoadAction()
        }
        MixpanelTrack("Visited MenuBuilder Page")
    }

    onToggleFoodDrink = (value) => {
        this.props.updateDrinkOrFoodAction(value)
        MixpanelTrack('Clicked Tab ' + value)
    }

    onClickImage = (itemIndex, image) => {
        this.props.updateCurrentItemAction(itemIndex)
        this.setState({
            selectedImage: image,
            isDialogOpen: true
        })
    }

    onSaveClicked = () => {
        this.setState({
            isSaveDialogOpen: true
        })
        MixpanelTrack('Clicked Save Menu')
    }

    handleSaveDialogClose = () => {
        this.setState({isSaveDialogOpen: false})
    }

    handleCategoryDialogClose = () => {
        this.setState({isCategoryDialogOpen: false})
    }
    handleDeleteDialogClose = () => {
        this.setState({isDeleteDialogOpen: false})
    }
    handleCategoryHover = (hoverCategory) => {
        this.hoverCategory = hoverCategory
    }
    onItemDragEnd = (result) => {
        this.props.dragItemAction(result, this.hoverCategory)
    }

    onDialogYesClicked = () => {
        MixpanelTrack('Save Menu Confirmed')
        this.handleSaveDialogClose()
        this.setState({isLoadingSave: true})
        this.props.validateMenuAction((isValid, menu) => {
            if(isValid) {
                this.props.updateShopMenuV3Action(this.props.menu,
                    (resp) => {
                        if(resp == "True") {
                            // given below endpoint call added specialy for the case when a new item is saved,
                            // that's why fetching menu data so that menuItemId gets refreshed from db as integer instead of guid
                            this.props.getShopMenuAction({
                                shopId: this.props.shopId,
                                menuId: null
                            }, (result) => this.setState({firstMenu: result}))

                            NotificationManager.success("The new shop menu is now in CoffeePass!", "Success")
                        } else {
                            NotificationManager.error("There was a problem saving the menu! If the problem persists contact support@coffeepass.io.", "Error Saving Menu!", 9000)
                        }
                        this.setState({isLoadingSave: false})
                    },
                    (error) => {
                        NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Saving Menu!", 9000)
                        this.setState({isLoadingSave: false})
                    })

                //this removes the items, options, and values from the database that were deleted
                this.props.removeDeletedMenuAction(this.props.toDelete,
                    (resp) => {
                        if(resp == "True") {
                            this.props.emptyToDeleteAction()
                            // NotificationManager.success("The new shop menu is now in CoffeePass!", "Success")
                        } else {
                            NotificationManager.error("There was a problem removing items from the menu! If the problem persists contact support@coffeepass.io.", "Error removeing Items!", 9000)
                        }
                    },
                    (error) => {
                        NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Saving Menu!", 9000)
                    })
            } else {
                this.setState({isLoadingSave: false})
                NotificationManager.error("Make sure all fields are filled in the Menu. Fix the errors and save again.", "Invalid Menu", 5000)
            }
        })
    }

    onCategoryDialogYesClicked = (categoryHeader, membersOnly, membersOnlyVisible, events) => {
        if (this.state.isCategoryEdit) {
            let newCategoryHeader = categoryHeader
            this.props.editCategoryAction(this.state.categoryToBeEdited, newCategoryHeader)
            this.setState({isCategoryDialogOpen: false})
        } else {
            this.props.newCategoryAction(categoryHeader, membersOnly, membersOnlyVisible, events)
            this.setState({isCategoryDialogOpen: false})
        }
    }

    onDeleteCategoryClicked = () => {
        if (this.props.menu.categories[this.state.categoryToBeEdited].items.length === 0) {
            this.props.deleteCategoryAction(this.state.categoryToBeEdited)
            this.setState({isCategoryDialogOpen: false})
            //in action:
            //    delete menuCopy.category[categoryHeader]
        } else {
            this.setState({isDeleteDialogOpen: true})
        }
    }

    onCategoryEdit = (currentCategory) => {
        this.setState({
            categoryToBeEdited: currentCategory,
            isCategoryEdit: true,
            isCategoryDialogOpen: true
        })
    }

    onNewCategory = () => {
        this.setState({
            isCategoryEdit: false,
            isCategoryDialogOpen: true
        })
    }

    closeDialog(image) {
        if(image === 'trr-food-none.png'){
            image = ''
        }
        this.props.changeItemImageAction(image)
        this.props.updateCurrentItemAction(null)
        this.setState({
            isDialogOpen: false
        })
    }

    sideBar = () => {
        return (<div>
                {this.props.menuUI.currentItemIndex === null ?
                    <MenuTabButtons
                        menu={this.props.menu}
                        onToggleFoodDrink={this.onToggleFoodDrink}
                        drinkOrFood={this.props.menuUI.drinkOrFood}
                        width={this.props.width}
                        onCategoryEdit={this.onCategoryEdit}
                        onNewCategory={this.onNewCategory}
                        onDragEnd={this.props.dragCategoryAction}
                        handleCategoryHover={this.handleCategoryHover}/>
                    :
                    <DefaultOptionsList />
                }
            </div>)
    }

    showMenu = ({currentItemIndex}, menu) => {
        var itemName = ''
        var hasComplimentary = false
        if(this.props.menuUI.currentItemIndex !== null)
            itemName = this.props.menu.categories[this.props.menuUI.drinkOrFood].items[this.props.menuUI.currentItemIndex].name

        //check if there is at least one complementary
        //this is better done in the backend when saving
        if (menu.categories) {
            var catHeaders = Object.keys(this.props.menu.categories)
            if(catHeaders.length === 0) {
                return false;
            }
            for (let header of catHeaders) {
                if (!hasComplimentary) {
                    let category = this.props.menu.categories[header]
                    if (category.items) {
                        category.items.every(item => {
                              if (item.isComplimentary) {
                                hasComplimentary = true;
                                return false;
                              }
                              return true;
                        })
                    }
                }
            }
        }

        return currentItemIndex === null ?
            <MenuItemList items={menu.categories[this.props.menuUI.drinkOrFood] ?
            menu.categories[this.props.menuUI.drinkOrFood].items
            :
            []}
                    width={this.props.width}
                    onClickImage={this.onClickImage.bind(this)}
                    hasComplimentary={hasComplimentary}
                    onItemDragEnd={this.onItemDragEnd}
                          categories={menu.categories}

            />
            :
            <OptionList options={menu.categories[this.props.menuUI.drinkOrFood].items[currentItemIndex].options}
                    width={this.props.width}
                    itemName={itemName}/>
    }

    membersOnlyToggle = () => {
      let checkboxValue = false
      if (this.props.menu.categories && this.props.menu.categories[this.props.menuUI.drinkOrFood]) {
          checkboxValue = this.props.menu.categories[this.props.menuUI.drinkOrFood].membersOnly
      }
      return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checkboxValue}
                    onChange={(event) => this.props.changeCategoryExclusivityAction(event.target.checked)}
                    color="primary" />
            }
            label="Members Only" />)
    }

    membersOnlyViewSelect = () => {
        let isInitCategory = false
        if (this.props.menu.categories) {
            let initCategory = this.props.menu.categories[this.props.menuUI.drinkOrFood] ?
              this.props.menu.categories[this.props.menuUI.drinkOrFood]
              :
              ''
            if (initCategory === '') {
                isInitCategory = false
                var catHeaders = Object.keys(this.props.menu.categories)
                for (let header of catHeaders) {
                    let category = this.props.menu.categories[header]
                    if (category.index == 0)
                        this.onToggleFoodDrink(header)
                }
            } else {
                isInitCategory = true
            }
        }
          return (
            <FormControl variant="outlined">
                <Select
                    variant="outlined"
                    value={this.props.menu.categories ?
                        isInitCategory ?
                            this.props.menu.categories[this.props.menuUI.drinkOrFood].membersOnlyVisible
                            :
                            false
                        :
                        false
                     }
                    onChange={this.handleVisibilityChange}
                >
                    <MenuItem value= {true}>Only Members</MenuItem>
                    <MenuItem value= {false}>Everyone</MenuItem>
                </Select>
            </FormControl>)
    }

    eventsToggle = () => {
        let checkboxValue = false
        if (this.props.menu.categories && this.props.menu.categories[this.props.menuUI.drinkOrFood]) {
            checkboxValue = this.props.menu.categories[this.props.menuUI.drinkOrFood].events
        }
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        disabled
                        checked={checkboxValue}
                        onChange={(event) => this.props.changeEventsCategoryAction(event.target.checked)}
                        color="primary" />
                }
                label="Events Category" />)
    }

    handleVisibilityChange = (event) => {
      console.log("check view target value", event.target.value)
      this.props.changeCategoryVisibilityAction(event.target.value)
    };

    viewSelect = () => {
        if(this.props.menu.drinkMenuView == undefined) {
            this.props.changeDrinkViewAction('thumbnail')
        }
        if (this.props.menu.foodMenuView == undefined) {
            this.props.changeFoodViewAction('list')
        }

        let isInitCategory = false
        if (this.props.menu.categories) {
            let initCategory = this.props.menu.categories[this.props.menuUI.drinkOrFood] ?
              this.props.menu.categories[this.props.menuUI.drinkOrFood]
              :
              ''
            if (initCategory === '') {
                isInitCategory = false
                var catHeaders = Object.keys(this.props.menu.categories)
                for (let header of catHeaders) {
                    let category = this.props.menu.categories[header]
                    if (category.index == 0)
                        this.onToggleFoodDrink(header)
                }
            } else {
                isInitCategory = true
            }
        }
          return (
            <FormControl variant="outlined">
                <Select
                    variant="outlined"
                    value={this.props.menu.categories ?
                        isInitCategory ?
                            this.props.menu.categories[this.props.menuUI.drinkOrFood].view
                            :
                            ''
                        :
                        ''
                     }
                    onChange={this.handleChange}
                >
                    <MenuItem value="list">List view</MenuItem>
                    <MenuItem value="thumbnail">Thumbnail view</MenuItem>
                </Select>
            </FormControl>)
    }

    handleChange = (event) => {
      /*Old View handler
      if (this.props.menuUI.drinkOrFood.includes('Food')) {
          this.props.changeFoodViewAction(event.target.value)
      } else {
          this.props.changeDrinkViewAction(event.target.value)
      }
      */
      console.log("check view target value", event.target.value)
      this.props.changeCategoryViewAction(event.target.value)
    };

    render() {
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                { (this.props.shop.isOnboarded != null && !this.props.shop.isOnboarded)?
                    <Redirect to="/onboarding" />
                    :
                    null
                }
                <div className="row" style={styles.borderBottom}>
                    <div className="module-logo col-md-2" style={styles.borderRight}>
                        <i className="zmdi zmdi-view-dashboard mr-4"/>
                        <span>Menu</span>
                    </div>
                    <div style={styles.verticalCenter} className='col-md-10'>
                        <div>{this.props.shop.shopName} Menu</div>
                        <div className="col-md-5" style={{color: '#999999'}}>
                            <span style= {{marginRight: 12}}>Display the items in a</span>
                            {this.viewSelect()}
                            <span style= {{marginLeft: 12}}>on the app</span>
                        </div>
                        <div className="col-md-5" style={{color: '#999999'}}>
                            <div> {
                              this.membersOnlyToggle()
                            } </div>
                            {this.props.menu.categories &&
                              this.props.menu.categories[this.props.menuUI.drinkOrFood] &&
                              this.props.menu.categories[this.props.menuUI.drinkOrFood].membersOnly &&
                              <div>
                                <span style= {{marginRight: 12}}>Visible to </span>
                                {this.membersOnlyViewSelect()}
                              </div>
                            }
                            {this.props.menu.categories &&
                                this.props.menu.categories[this.props.menuUI.drinkOrFood] &&
                                <div>
                                    {this.eventsToggle()}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row" style={styles.whiteBackground}>
                    <DragDropContext onDragEnd={this.props.dragOptionAction}>
                        <div className="col-md-2" style={styles.borderRight}>
                            {this.sideBar()}
                        </div>
                        <div className="col-md-9 col-12">
                            {this.showMenu(this.props.menuUI, this.props.menu)}
                        </div>
                        <div className="col-11" style={styles.buttonContainer}>
                            <Button
                                variant="raised"
                                color="primary"
                                className="jr-btn"
                                onClick={this.onSaveClicked}>
                                {this.state.isLoadingSave?
                                    <CircularProgress size={16} style={styles.progress} />
                                    :
                                    "Save"
                                }
                            </Button>
                        </div>
                    </DragDropContext>
                </div>
                <CoffeeIconSelectDialog
                    isDialogOpen={this.state.isDialogOpen}
                    handleClose={() => {
                        this.props.updateCurrentItemAction(null)
                        this.setState({isDialogOpen: false})
                    }}
                    selectedImage={this.state.selectedImage}
                    onClickImage={this.closeDialog }
                    drinkOrFood={this.props.menuUI.drinkOrFood}
                    menu={this.state.firstMenu}/>
                <SaveSureDialog
                    isDialogOpen= {this.state.isSaveDialogOpen}
                    handleClose={this.handleSaveDialogClose}
                    onYesClicked={this.onDialogYesClicked}
                    onNoClicked={this.handleSaveDialogClose}
                    dialogDataToSave="menu" />
                <CategoryDialog
                    isDialogOpen= {this.state.isCategoryDialogOpen}
                    isEdit={this.state.isCategoryEdit}
                    handleClose={this.handleCategoryDialogClose}
                    onYesClicked={this.onCategoryDialogYesClicked}
                    onNoClicked={this.handleCategoryDialogClose}
                    onDeleteClicked={this.onDeleteCategoryClicked}
                    title={this.state.isCategoryEdit ? this.state.categoryToBeEdited : 'New Category'}/>
                <DeleteDialog
                    isDialogOpen= {this.state.isDeleteDialogOpen}
                    handleClose={this.handleDeleteDialogClose}
                    title={this.state.categoryToBeEdited}/>
            </div>
        )
    }
}

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 10
    },
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    },
    borderBottom: {
        borderBottom: 'solid 1px #e9ecef'
    },
    borderRight: {
        borderRight: 'solid 1px #e8ecef'
    },
    whiteBackground: {
        backgroundColor: '#FFFFFF'
    },
    verticalCenter: {
        alignItems: 'center',
        display: 'flex',
        backgroundColor: '#FFFFFF',
        justifyContent: 'space-between'
    }
}

const mapStateToProps = state => {
    return {
        width: selectors(state).width,
        shopId: selectors(state).shopId,
        menu: state.menu.payload,
        toDelete: state.menu.toDelete,
        menuUI: selectors(state).menuUI,
        shop: selectors(state).shop
    }
}

const actionCreators = {
    updateShopMenuV3Action,
    removeDeletedMenuAction,
    getShopMenuAction,
    firstMenuLoadAction,
    updateCurrentItemAction,
    updateDrinkOrFoodAction,
    changeItemImageAction,
    emptyToDeleteAction,
    validateMenuAction,
    dragOptionAction,
    changeFoodViewAction,
    changeDrinkViewAction,
    newCategoryAction,
    editCategoryAction,
    dragCategoryAction,
    changeCategoryViewAction,
    deleteCategoryAction,
    dragItemAction,
    changeCategoryExclusivityAction,
    changeCategoryVisibilityAction,
    changeEventsCategoryAction
}

export default connect(mapStateToProps, actionCreators)(MenuBuilder)
