import React, { Component } from 'react';
import '../home/App.css';

class ReasonCard extends Component {
    render() {

        return (
            <span className="reasonCardWrapper">
                <img src={this.props.reasonIcon} className="cardImage" alt="Icon for why CoffeePass" />
                <div className="cardTextBox">
                    <div className="cardTextTitle">{this.props.textTitle}</div>
                    <div className="cardTextBody">{this.props.textBody}</div>
                </div>
            </span>
        );
    }
}

export default ReasonCard;