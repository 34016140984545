export const FIVE_TO_TEN = '5-10 min'
export const TEN_TO_FIFTEEN = '10-15 min'

export const pickupTimes = [
  	{
        id: 0,
        value: FIVE_TO_TEN,
        text: '5-10 min'
    },
    {
      id: 1,
      value: TEN_TO_FIFTEEN,
      text: '10-15 min'
    },
    {
      id: 2,
      value: '15-20 min',
      text: '15-20 min'
    },
    {
      id: 3,
      value: '20-30 min',
      text: '20-30 min'
    },
    {
      id: 4,
      value: '30-45 min',
      text: '30-45 min'
    },
    {
      id: 5,
      value: '45-60 min',
      text: '45-60 min'
    }
]