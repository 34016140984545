import React, {Component} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import CustomScrollbars from '../../../util/CustomScrollbars';
import CardBox from '../analytics/util/CardBox';
import {TextField} from '@material-ui/core';
import {Link} from 'react-router-dom';
import SelectValidator from '../util/SelectValidator';
import {UNIT_PERCENT, discountUnitTypes} from './DiscountUnitTypes';
import DiscountUnits from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker'
import SaveSureDialog from '../ShopInfo/SaveSureDialog';
import {NotificationManager, NotificationContainer} from 'react-notifications';
import {MixpanelTrack} from '../../../util/MixpanelHelper';
import selectors from '../../../reducers/selectors';
import {addPromoCodeAction} from '../../../actions/PromoCode';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Switch, FormControlLabel} from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";

class PromoCode extends Component {
    constructor(props) {
        super(props)

        this.state = {
            promoCodeId: null,
            shopId: null,
            menuItemId: null,
            code: '',
            description: '',
            valueUnit: UNIT_PERCENT,
            value: null,
            maxValue: null,
            minValue: null,
            userRedeemCount: null,
            totalRedeemCount: null,
            newUsersOnly: false,
            startDate: moment(),
            endDate: moment(),
            isDialogOpen: false,
            isSaveDialogOpen: false,
            repeatInterval: 0,
            unitOfTime: "Hour",
            isRepeating: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this)
        this.handleSaveDialogClose = this.handleSaveDialogClose.bind(this)
        this.onDialogYesClicked = this.onDialogYesClicked.bind(this)
    }

    componentDidMount() {
        console.log("Visited promo code Page")
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleStartDateChange = (date) => {
        this.setState({startDate: date})
        this.setState({endDate: date})
    }
    handleEndDateChange = (date) => {
        this.setState({endDate: date})
    }

    onSaveClicked() {
        if (this.isValidPromoCode()) {
            this.setState({
                isSaveDialogOpen: true
            })
            MixpanelTrack('Save Shop Membership')
        }
    }

    handleSaveDialogClose() {
        this.setState({isSaveDialogOpen: false})
    }

    onDialogYesClicked() { //add parameters here
        this.handleSaveDialogClose()
        const paramsObj = Object.assign({}, this.state, {
            promoCodeId: this.state.promoCodeId,
            shopId: this.props.shopId,
            menuItemId: this.state.menuItemId,
            code: this.state.code,
            description: this.state.description,
            valueUnit: this.state.valueUnit,
            value: this.state.value,
            maxValue: this.state.maxValue,
            minValue: this.state.minValue,
            userRedeemCount: this.state.userRedeemCount,
            totalRedeemCount: this.state.totalRedeemCount,
            startDate: this.state.startDate.utc().format("MM/DD/YYYY"),
            endDate: this.state.endDate != null ? this.state.endDate.utc().format("MM/DD/YYYY") : null,
            newUsersOnly: this.state.newUsersOnly
        })
        this.props.addPromoCodeAction(paramsObj,
            (resp) => {
                if (resp && JSON.parse(resp).promoCodeId > 0) {
                    NotificationManager.success("Promo code is now saved in CoffeePass!", "Success")
                    // this.setState(this.state)
                    this.props.history.push('/app/promo-code-list');
                } else {
                    NotificationManager.error("There was an error in saving promo code. If the problem persists contact support@coffeepass.io.", "Error saving promo code!", 9000)
                }
            },
            (error) => {
                if (error.statusText === 'CONFLICT') {
                    NotificationManager.error("Error: " + "Promo code already exists", "Error saving promo code!", 9000)
                } else {
                    NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error saving promo code!", 9000)
                }
            })
    }

    isValidPromoCode() {
        var isValid = true
        var errorMessage = ''

        if (this.state.code === null || this.state.code === '' || this.state.code.trim().length === 0) {
            errorMessage = 'Promo code is blank'
        } else if (this.state.code.trim().length > 20) {
            errorMessage = 'Promo code is more than 20 characters'
        } else if (this.state.description != null && this.state.description.length > 250) {
            errorMessage = 'Description is more than 250 characters'
        } else if (this.state.valueUnit === null || this.state.valueUnit === '' || this.state.valueUnit.length === 0) {
            errorMessage = 'Unit value is blank'
        } else if (this.state.value === null || this.state.value === '' || this.state.value.trim().length === 0) {
            errorMessage = 'Discount value is blank'
        } else if (this.state.startDate === null || this.state.startDate === '' || this.state.startDate.length === 0) {
            errorMessage = 'Start date is blank'
        }

        if (errorMessage != '') {
            NotificationManager.error(errorMessage, "Error saving membership!", 5000)
            isValid = false
        }
        return isValid
    }

    generateRandomPromoCode = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.setState({code: result});
    }

    render() {
        return (
            <div className="mt-2">
                <CustomScrollbars className="module-list-scroll scrollbar"
                                  style={{height: this.props.width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                    <div className="animated slideInUpTiny animation-duration-3">
                        <CardBox styleName="col-lg-6 ml-3" cardStyle="" heading="Create Promo Code">
                            <form className="row" autoComplete="off">
                                <div className="col-md-12 col-12">
                                    <TextField
                                        required
                                        label="Promo Code"
                                        value={this.state.code}
                                        onChange={this.handleChange('code')}
                                        inputProps={{maxLength: 20}}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <Link
                                        to="#"
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            this.generateRandomPromoCode(20);
                                        }}
                                    >
                                        Generate Promo Code
                                    </Link>
                                </div>
                                <div className="col-md-12 col-12">
                                    <TextField
                                        label="Description"
                                        value={this.state.description}
                                        onChange={this.handleChange('description')}
                                        inputProps={{maxLength: 250}}
                                        multiline={true}
                                        // rows="3"
                                        margin="normal"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="row ml-1">
                                        <div>
                                            <SelectValidator
                                                required
                                                label="Unit"
                                                value={this.state.valueUnit}
                                                onChange={this.handleChange('valueUnit')}
                                                errorMessage=""
                                                inputProps={{
                                                    name: 'type'
                                                }}>
                                                {discountUnitTypes.map(obj => {
                                                    return (<DiscountUnits key={obj.value} value={obj.value}>
                                                        {obj.text}
                                                    </DiscountUnits>)
                                                })}
                                            </SelectValidator>
                                        </div>
                                        <div className="ml-4">
                                            <TextField
                                                required
                                                label="Discount Value"
                                                value={this.state.value}
                                                onChange={this.handleChange('value')}
                                                placeholder="0.00"
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <TextField
                                        label="Max Discount Value"
                                        value={this.state.maxValue}
                                        onChange={this.handleChange('maxValue')}
                                        placeholder="$0.00"
                                        type="number"
                                        margin="normal"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-12 col-12">
                                    <TextField
                                        label="Min Order Value"
                                        value={this.state.minValue}
                                        onChange={this.handleChange('minValue')}
                                        placeholder="$0.00"
                                        type="number"
                                        margin="normal"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-12 col-12">
                                    <TextField
                                        label="Per User Max Redeem Count"
                                        disabled={this.state.newUsersOnly}
                                        value={this.state.userRedeemCount}
                                        onChange={this.handleChange('userRedeemCount')}
                                        placeholder="0"
                                        type="number"
                                        margin="normal"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-12 col-12">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.newUsersOnly}
                                                onChange={(event, checked) => {
                                                    this.setState({newUsersOnly: checked, userRedeemCount: 1});
                                                }}
                                                color="primary"/>
                                        }
                                        label="New Customer Promo"/>
                                </div>
                                <div className="col-md-12 col-12">
                                    <TextField
                                        label="Total Max Redeem Count"
                                        value={this.state.totalRedeemCount}
                                        onChange={this.handleChange('totalRedeemCount')}
                                        placeholder="0"
                                        type="number"
                                        margin="normal"
                                        fullWidth
                                    />
                                </div>
                                {/*<div className="col-md-12 col-12">*/}
                                {/*    <span>Repeat every </span>*/}
                                {/*    <TextField*/}
                                {/*        required*/}
                                {/*        defaultValue="0"*/}
                                {/*        label="Repeat Interval"*/}
                                {/*        value={this.state.repeatInterval}*/}
                                {/*        onChange={this.handleChange('repeatInterval')}*/}
                                {/*        placeholder="0"*/}
                                {/*        type="number"*/}
                                {/*        margin="normal"*/}
                                {/*        size="small"*/}
                                {/*        inputProps={{min: 0, style: {textAlign: 'center'}}}*/}
                                {/*    />*/}
                                {/*    <FormControl variant="outlined">*/}
                                {/*        <Select*/}
                                {/*            variant="outlined"*/}
                                {/*            value={this.state.unitOfTime}*/}
                                {/*            onChange={this.handleChange('unitOfTime')}*/}
                                {/*        >*/}
                                {/*            <DiscountUnits value="Hour">Hour(s)</DiscountUnits>*/}
                                {/*            <DiscountUnits value="Day">Day(s)</DiscountUnits>*/}
                                {/*            <DiscountUnits value="Week">Week(s)</DiscountUnits>*/}
                                {/*            <DiscountUnits value="Month">Month(s)</DiscountUnits>*/}
                                {/*            <DiscountUnits value="Year">Year(s)</DiscountUnits>*/}
                                {/*        </Select>*/}
                                {/*    </FormControl>*/}
                                {/*    <FormControlLabel*/}
                                {/*        control={*/}
                                {/*            <Switch color="primary"*/}
                                {/*                    classes={{*/}
                                {/*                        checked: 'text-primary',*/}
                                {/*                        bar: 'bg-primary',*/}
                                {/*                    }}*/}
                                {/*                    checked={this.state.isRepeating}*/}
                                {/*                    onChange={(event, checked) => this.setState({isRepeating: checked})}*/}
                                {/*            />*/}
                                {/*        }*/}
                                {/*        label={this.state.isRepeating ? "On" : "Off"}/>*/}
                                {/*</div>*/}
                                <div className="col-md-12 col-12 mt-4">
                                    <div className="row ml-1">
                                        <div>
                                            <div><label>Start Date *</label></div>
                                            <div>
                                                <DatePicker
                                                    selected={this.state.startDate}
                                                    onChange={this.handleStartDateChange}
                                                    // dateFormat={"MM/DD/yyyy"}
                                                    placeholderText="Start date"
                                                    minDate={new Date()}
                                                    //maxDate={new Date("04-29-2021")}
                                                    // showTimeSelect
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <div><label>End Date (12:00 AM)</label></div>
                                            <div>
                                                <DatePicker
                                                    selected={this.state.endDate}
                                                    onChange={this.handleEndDateChange}
                                                    placeholderText="End date"
                                                    minDate={(this.state.startDate === null || this.state.startDate.length === 0) ? new Date() : this.state.startDate}
                                                    // showTimeSelect
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CardBox>
                    </div>
                </CustomScrollbars>
                <div>
                    <div className="col-lg-6 mt-4">
                        <div className="row">
                            <div className="col-md-9 col-9"/>
                            <div className="col-md-3 col-3" style={styles.buttonContainer}>
                                <Button
                                    variant="raised"
                                    color="primary"
                                    className="jr-btn"
                                    onClick={this.onSaveClicked}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                    <SaveSureDialog
                        isDialogOpen={this.state.isSaveDialogOpen}
                        handleClose={this.handleSaveDialogClose}
                        onYesClicked={this.onDialogYesClicked}
                        onNoClicked={this.handleSaveDialogClose}
                        dialogDataToSave="promo code"
                    />
                    <NotificationContainer/>
                </div>
            </div>
        )
    }
}

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
    }
}
const actionCreators = {
    addPromoCodeAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(PromoCode);
