import {
    SHOP_LOGIN,
    SHOP_SIGNUP,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    INIT_URL,
    SIGNOUT_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNUP_USER_SUCCESS
} from "../constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    shop: {shopId: localStorage.getItem('shop_id') },
    authUser: localStorage.getItem('user_id'),
    access_token: null
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOP_LOGIN:
            localStorage.setItem('shop_id', action.payload.shopId);
            localStorage.setItem('access_token', action.payload.access_token);
            return {
                ...state,
                shop: action.payload,
                loader: false,
                access_token: action.payload.access_token,
                authuser: action.payload,
                loggedIn: action.payload.val == "True"
            }
        case SHOP_SIGNUP:
            localStorage.setItem('shop_id', action.payload.shopId)
            return {
                ...state,
                shop: action.payload,
                loader: false,
                authuser: action.payload,
                loggedIn: action.payload.val == "True"
            }
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case INIT_URL: {
            return {
                ...state,
                access_token: localStorage.getItem("access_token"),
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                shop: {shopId: null},
                initURL: '/app/dashboard/default',
                loader: false
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        default:
            return state;
    }
}
