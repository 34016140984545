import React, { Component } from 'react';
import CustomerFreqTable from './CustomerFreqTable';
import '../../overviewStyle.css';
import { connect } from 'react-redux';
import { getCustomerFreqFiveAction } from './../../../../../../actions/CustomerFrequency/CustomerFreqFive';
import { getCustomerFreqFourAction } from './../../../../../../actions/CustomerFrequency/CustomerFreqFour';
import { getCustomerFreqThreeAction } from './../../../../../../actions/CustomerFrequency/CustomerFreqThree';
import { getCustomerFreqTwoAction } from './../../../../../../actions/CustomerFrequency/CustomerFreqTwo';
import { getCustomerFreqOneAction } from './../../../../../../actions/CustomerFrequency/CustomerFreqOne';
import NoDataCard from './../NoDataCard';
import DatabaseValidator from '../../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangeHelper from '../../../../util/DateRangeHelper';
import selectors from '../../../../../../reducers/selectors';

class CustomerFreq extends Component {
    constructor(props) {
        super(props);

        this.dateRangeHelper = new DateRangeHelper()

        this.props.getCustomerFreqFiveAction(this.getNVisitsObj(5));
        this.props.getCustomerFreqFourAction(this.getNVisitsObj(4));
        this.props.getCustomerFreqThreeAction(this.getNVisitsObj(3));
        this.props.getCustomerFreqTwoAction(this.getNVisitsObj(2));
        this.props.getCustomerFreqOneAction(this.getNVisitsObj(1));
    }

    getNVisitsObj(n) {
        var week = this.dateRangeHelper.getLastWeek()
        var paramObjFive = {
            shopId: this.props.shopId,
            numVisits: parseInt(n),
            dateFirst: week['dateFirst'],
            dateSecond: week['dateSecond']
        }
        return (paramObjFive);
    }

    render() {
        var dataVis = '';
        const dbv = new DatabaseValidator();
        if (!dbv.isEmpty(this.props.customerFreqFive) && !dbv.isEmpty(this.props.customerFreqFour) && !dbv.isEmpty(this.props.customerFreqThree) && !dbv.isEmpty(this.props.customerFreqTwo) && !dbv.isEmpty(this.props.customerFreqOne)) {
            var freqData = [this.props.customerFreqFive, this.props.customerFreqFour, this.props.customerFreqThree, this.props.customerFreqTwo, this.props.customerFreqOne];
            dataVis = <CustomerFreqTable data={freqData} />
        } else {
            dataVis = <div className="text-center"><CircularProgress size={50} /></div>
        }

        return (
            <div className="col-xl-4 col-lg-12 col-md-12 col-12">
                <div className="jr-card">
                    <div className="jr-card-header d-flex align-items-center mb-3">
                        <h3 className="card-heading mb-0"><i
                            className="zmdi zmdi-chart-donut zmdi-hc-fw text-primary text-lighten-2 mr-2" />
                            Customer Frequency
                                </h3>
                        <span className="badge badge-primary ml-auto">Last Week</span>
                    </div>
                    <div className="freqTable">
                        {dataVis}
                    </div>
                    <hr />
                    <div className="jr-card-body text-muted text-center">
                        <p>How many of your customers visited one, two, three, four, or five times last week.</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        customerFreqFive: selectors(state).customerFreqFive,
        customerFreqFour: selectors(state).customerFreqFour,
        customerFreqThree: selectors(state).custoemrFreqThree,
        customerFreqTwo: selectors(state).customerFreqTwo,
        customerFreqOne: selectors(state).custoemrFreqOne,
    }
}

const actionCreators = {
    getCustomerFreqFiveAction,
    getCustomerFreqFourAction,
    getCustomerFreqThreeAction,
    getCustomerFreqTwoAction,
    getCustomerFreqOneAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(CustomerFreq);