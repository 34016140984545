
import UniqueIdGenerator from '../util/UniqueIdGenerator';
import {
    DELETE_MENU_ITEM,
    DELETE_MENU_OPTION,
    DELETE_MENU_VALUE,
    ITEM_NAME_CHANGE,
    ITEM_PICKUP_TIME_CHANGE,
    ITEM_DISCOUNT_UNIT_CHANGE,
    ITEM_DISCOUNT_VALUE_CHANGE,
    ADD_MENU_ITEM,
    OPTION_VALUE_CHANGE,
    OPTION_TITLE_CHANGE,
    ADD_MENU_OPTION,
    ITEM_IMAGE,
    ADD_MENU_VALUE,
    ON_DRAG_OPTION,
    ON_DRAG_ITEM,
    PASTE_VALUE,
    COPY_VALUE,
    PASTE_OPTION,
    COPY_OPTION,
    PASTE_ITEM,
    VALIDATE_MENU,
    ITEM_AVAILABLE_CHANGE,
    COPY_ITEM,
    MOVE_ITEM,
    CHANGE_COMPLIMENTARY_STATUS,
    CHANGE_UPSELL_STATUS,
    CHANGE_FOOD_VIEW_STATUS,
    CHANGE_DRINK_VIEW_STATUS,
    CHANGE_CATEGORY_VIEW_STATUS,
    CHANGE_CATEGORY_EXCLUSIVITY_STATUS,
    CHANGE_EVENTS_CATEGORY_STATUS,
    CHANGE_CATEGORY_VISIBILITY_STATUS,
    EDIT_CATEGORY,
    NEW_CATEGORY,
    ON_DRAG_CATEGORY,
    DELETE_CATEGORY,
    types, ITEM_QUANTITY_CHANGE
} from '../constants/ActionTypes';
import CoffeeIcons from '../component/dashboard/menu/MenuBuilder/CoffeeIcons';
import { SELECT_MAX, EXACTLY_ONE } from '../component/dashboard/menu/MenuBuilder/WidgetTypes';
import { FIVE_TO_TEN, TEN_TO_FIFTEEN } from '../component/dashboard/menu/MenuBuilder/PickupTimes';
import { DISCOUNT_UNIT_SELECT } from '../component/dashboard/menu/MenuBuilder/DiscountUnitTypes';
import { MixpanelTrack } from '../util/MixpanelHelper';

const menuMiddleware = store => next => action => {
    const load = action.payload

    const menu = store.getState().menu.payload
    const toDelete = store.getState().menu.toDelete
    const clipboard = store.getState().menu.clipboard
    const defaultModifiers = store.getState().menu.defaultModifiers
    const defaultModifiersMenu = store.getState().menu.defaultModifiersMenu
    const toDeleteModifiers = store.getState().menu.toDeleteModifiers
    const menuBuilder = store.getState().menuBuilder

    var menuCopy = JSON.parse(JSON.stringify(menu))
    var deleteCopy = JSON.parse(JSON.stringify(toDelete))
    var clipboardCopy = JSON.parse(JSON.stringify(clipboard))
    var defaultModifiersCopy = JSON.parse(JSON.stringify(defaultModifiers))
    var deleteModifiersCopy = JSON.parse(JSON.stringify(toDeleteModifiers))
    const uniqueIdGenerator = new UniqueIdGenerator()

    //menuObj for either the menu builder or default options
    var menuObj = defaultModifiersCopy[menuBuilder.selectedDefaultIndex]
    if(load != null && load.builderType != null && load.builderType == types.MENU_BUILDER) {
        menuObj = menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.optionIndex]
    }

    switch(action.type) {
        case ITEM_NAME_CHANGE:
            const coffeeIcons = new CoffeeIcons()
            var image = coffeeIcons.iconForCurrentValue(load.name)
            if(image != -1) {
                menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].image = image
            }
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].name = load.name
            MixpanelTrack('Change Item Name to ' + load.name)
            break
        case ITEM_QUANTITY_CHANGE:
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].inventory.quantity = load.quantity;
            MixpanelTrack('Change Max Quantity to ' + load.quantity);
            break
        case ITEM_PICKUP_TIME_CHANGE:
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.optionIndex].pickupTime = load.pickupTime
            MixpanelTrack('Change ' + load.key + ' to ' + load.pickupTime)
            break
        case ITEM_DISCOUNT_UNIT_CHANGE:
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.optionIndex].discountUnit = load.discountUnit
            MixpanelTrack('Change ' + load.key + ' to ' + load.discountUnit)
            break
        case ITEM_DISCOUNT_VALUE_CHANGE:
            if(load.discountValue == '' || load.discountValue == '0' || load.discountValue == 0){
                delete menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].discountValue
                delete menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].discountUnit
            }else{
                menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].discountValue = load.discountValue
            }
            MixpanelTrack('Change Item discount value to ' + load.discountValue)
            break
        case types.ITEM_DESCRIPTION_CHANGE:
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].description = load.description
            MixpanelTrack('Change Item description to ' + load.description)
            break
        case ITEM_IMAGE:
            menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].image = load.image
            MixpanelTrack('Change Item image to ' + load.image)
            break
        case ITEM_AVAILABLE_CHANGE:
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].isAvailable = load.isAvailable
            MixpanelTrack('Change Item sold out to ' + !load.isAvailable)
            break
        case CHANGE_COMPLIMENTARY_STATUS://this is where we should update the store for complementary status
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].isComplimentary = load.isComplimentary
            //MixpanelTrack('Change isComplimentary to ' + !load.isAvailable)
            break
        case CHANGE_UPSELL_STATUS://this is where we should update the store for upsell status
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.itemIndex].isUpsell = load.isUpsell
            //MixpanelTrack('Change isComplimentary to ' + !load.isAvailable)
            break
        case CHANGE_DRINK_VIEW_STATUS://this is where we update the drink view mode (legacy)
            menuCopy.drinkMenuView = load.drinkMenuView
            //MixpanelTrack('Change isComplimentary to ' + !load.isAvailable)
            break
        case CHANGE_CATEGORY_VIEW_STATUS://this is where we update the category view mode
            menuCopy.categories[menuBuilder.drinkOrFood].view = load.categoryMenuView
            //MixpanelTrack('Change isComplimentary to ' + !load.isAvailable)
            break
        case CHANGE_CATEGORY_VISIBILITY_STATUS://this is where we update the category visibility
            menuCopy.categories[menuBuilder.drinkOrFood].membersOnlyVisible = load.categoryMenuVisibility
            //MixpanelTrack('Change isComplimentary to ' + !load.isAvailable)
            break
        case CHANGE_CATEGORY_EXCLUSIVITY_STATUS://this is where we update the category exclusivity
            menuCopy.categories[menuBuilder.drinkOrFood].membersOnly = load.categoryMenuExclusivity
            //MixpanelTrack('Change isComplimentary to ' + !load.isAvailable)
            break
        case CHANGE_EVENTS_CATEGORY_STATUS://this is where we update the category exclusivity
            menuCopy.categories[menuBuilder.drinkOrFood].events = load.events
            //MixpanelTrack('Change isComplimentary to ' + !load.isAvailable)
            break
        case CHANGE_FOOD_VIEW_STATUS://this is where we should update the store for upsell status
            menuCopy.foodMenuView = load.foodMenuView
            //MixpanelTrack('Change isComplimentary to ' + !load.isAvailable)
            break
        case ADD_MENU_ITEM:
            var sizeOption = {
                menuOptionId: uniqueIdGenerator.getId(),
                title: 'Size',
                widgetType: EXACTLY_ONE,
                priceForAll: null,
                initialValue: '',
                isAvailable: true,
                maxNum: null,
                isOnTablet: false,
                values: [
                    Object.assign(getNewValue(), {
                        value: 'Regular'
                    })
                ]
            }

            var newItem = {
                menuItemId: uniqueIdGenerator.getId(),
                name: '',
                image: '',
                pickupTime: menuBuilder.drinkOrFood.includes('drink') ? FIVE_TO_TEN : TEN_TO_FIFTEEN,
                description: '',
                options: [sizeOption],
                isAvailable: true,
                isEvent: load.eventCategory,
                inventory: {
                    maxQuantity: 0,
                    currentQuantity: 0
                },
                discountUnit: null,
                discountValue: null,
            }
            menuCopy.categories[menuBuilder.drinkOrFood].items.push(newItem)
            MixpanelTrack('Clicked add menu item')
            break
        case OPTION_VALUE_CHANGE:
            menuObj.values[load.valueIndex][load.key] = load.value
            //have to set the priceForAll if all the prices are the same
            if(load.key == 'price') {
                const option = menuObj
                const areEqual = arePricesEqual(option.values)
                if(areEqual) {
                    menuObj.priceForAll = option.values[0].price
                } else {
                    menuObj.priceForAll = null
                }
            }
            MixpanelTrack('Change option ' + load.key + ' to ' + load.value)
            break
        case OPTION_TITLE_CHANGE:
            menuObj[load.key] = load.title
            MixpanelTrack('Change ' + load.key + ' to ' + load.title)
            break
        case EDIT_CATEGORY:
            Object.defineProperty(menuCopy.categories, load.newCategoryHeader,
                Object.getOwnPropertyDescriptor(menuCopy.categories, load.oldCategoryHeader))
            delete menuCopy.categories[load.oldCategoryHeader]
            break
        case NEW_CATEGORY:
            menuCopy.categories[load.categoryHeader] = new Object;
            menuCopy.categories[load.categoryHeader]['index'] = Object.keys(menuCopy.categories).length - 1;
            menuCopy.categories[load.categoryHeader]['view'] = 'thumbnail';
            menuCopy.categories[load.categoryHeader]['items'] = [];
            menuCopy.categories[load.categoryHeader]['membersOnly'] = load.membersOnly;
            menuCopy.categories[load.categoryHeader]['membersOnlyVisible'] = load.membersOnlyVisible;
            menuCopy.categories[load.categoryHeader]['events'] = load.events;
            break
        case DELETE_CATEGORY:
            let newCopy = deleteCategory(menuCopy, load.categoryHeader);
            menuCopy = newCopy;
            break
        case ADD_MENU_OPTION:
        case types.ADD_DEFAULT_OPTION:
            var newOption = {
                menuOptionId: uniqueIdGenerator.getId(),
                title: '',
                widgetType: '',
                priceForAll: null,
                initialValue: '',
                isAvailable: true,
                maxNum: null,
                isOnTablet: false,
                values: [
                    getNewValue()
                ]
            }
            if(action.type == types.ADD_DEFAULT_OPTION) {
                defaultModifiersCopy.push(newOption)
            } else {
                menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options.push(newOption)
            }
            MixpanelTrack('Clicked add menu modifier')
            break
        case types.CHANGE_OPTION_ON_TABLET:
            menuObj.isOnTablet = !menuObj.isOnTablet
            break
        case ADD_MENU_VALUE:
            var newValue = getNewValue()
            //set the initialValue for size
            var values = menuObj.values
            if(menuObj.title.toLowerCase() == 'size' && values.length == 2) {
                menuObj.initialValue = '1'
            }
            menuObj.values.push(newValue)
            MixpanelTrack('Clicked add menu option')
            break
        case DELETE_MENU_ITEM:
            //add this as an item to delete from the database
            deleteCopy.items.push(menuCopy.categories[menuBuilder.drinkOrFood].items[load.index].menuItemId)
            menuCopy.categories[menuBuilder.drinkOrFood].items.splice(load.index, 1)
            MixpanelTrack('Clicked delete menu item')
            break
        case DELETE_MENU_OPTION:
            var optionsObj = defaultModifiersCopy
            if(load.builderType == types.MENU_BUILDER) {        //decide if this is the menu builder or default options
                optionsObj = menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options
                //add this as an option to delete from the database
                deleteCopy.options.push(optionsObj[load.index].menuOptionId)
            } else if(load.builderType == types.DELETE_DEFAULT_OPTION) {
                optionsObj = menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options
                //add this as an option to delete from the database
                deleteCopy.defaultOptions.push(optionsObj[load.index].defaultOptionId)
                console.log('delete copy', deleteCopy)
            } else {
                deleteModifiersCopy.options.push(optionsObj[load.index].menuOptionId)
            }
            optionsObj.splice(load.index, 1)
            MixpanelTrack('Clicked delete menu modifier')
            break
        case DELETE_MENU_VALUE:
            //add this as a value to delete from the database
            var values = menuObj.values
            if(menuObj.title.toLowerCase() == 'size' && values.length == 3) {
                menuObj.initialValue = ''
            }
            if(load.builderType == types.MENU_BUILDER) {
                deleteCopy.values.push(menuObj.values[load.valueIndex].optionValueId)
            } else {
                deleteModifiersCopy.values.push(menuObj.values[load.valueIndex].optionValueId)
            }
            menuObj.values.splice(load.valueIndex, 1)
            MixpanelTrack('Clicked delete menu option')
            break
        case ON_DRAG_CATEGORY:
            if (!load.destination) {
                return;
            }
            let catHeaders = Object.keys(menuCopy.categories)
            let sign = load.source.index > load.destination.index ? 1 : -1
            for (let header of catHeaders) {
                menuCopy.categories[header]
                if (menuCopy.categories[header].index == load.source.index) {
                    menuCopy.categories[header].index = load.destination.index
                } else if (sign < 0 && menuCopy.categories[header].index > load.source.index && menuCopy.categories[header].index <= load.destination.index) {
                    menuCopy.categories[header].index += sign
                } else if (sign > 0 && menuCopy.categories[header].index >= load.destination.index && menuCopy.categories[header].index < load.source.index) {
                    menuCopy.categories[header].index += sign
                }
            }
            break
        case ON_DRAG_ITEM:
            // dropped outside the list
            if (load.hoverCategory != '**_noCategory_**') {
                let newDragItem = menuCopy.categories[menuBuilder.drinkOrFood].items[load.result.source.index]
                newDragItem.shopMenuItemCategoryId = load.categoryId;
                menuCopy.categories[load.hoverCategory].items.push(newDragItem);
                console.log(newDragItem);
                menuCopy.categories[menuBuilder.drinkOrFood].items.splice(load.result.source.index, 1)
                console.log(menuCopy)
            } else {
                if (!load.result.destination) {
                    return;
                } else {
                    const items = reorder(
                        menuCopy.categories[menuBuilder.drinkOrFood].items,
                        load.result.source.index,
                        load.result.destination.index
                    )
                    menuCopy.categories[menuBuilder.drinkOrFood].items = items
                    MixpanelTrack('dragged item')
                }
            }
            break
        case ON_DRAG_OPTION:
            // dropped outside the list
            if (!load.destination) {
                return;
            }
            console.log('load', load)
            if (load.source.droppableId === load.destination.droppableId && load.destination.droppableId === 'options-droppable-id') {     //if in the same list
                const options = reorder(
                    menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options,
                    load.source.index,
                    load.destination.index
                )
                menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options = options
            } else if(load.destination.droppableId === 'options-droppable-id') {        //moved to the other list
                var option = defaultModifiersMenu[load.source.index]
                option['isDefault'] = true
                option['defaultOptionId'] = uniqueIdGenerator.getId()
                menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options.push(option)
                const options = reorder(
                    menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options,
                    menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options.length - 1,
                    load.destination.index
                )
                menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options = options
            }
            MixpanelTrack('dragged modifier')
            break
        case types.MAKE_OPTION_NON_DEFAULT:
            var option = menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.index]
            //add this as an option to delete from the database
            deleteCopy.defaultOptions.push(option.defaultOptionId)
            option.defaultOptionId = null
            option.isDefault = false
            option.menuDefaultOptionId = null
            option.menuOptionId = uniqueIdGenerator.getId()
            const op2 = setNewValueIds(option)
            menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.index] = op2
            MixpanelTrack('Make Modifier non-default')
            break
        case COPY_ITEM:
            clipboardCopy.item = JSON.parse(JSON.stringify(menuCopy.categories[menuBuilder.drinkOrFood].items[load.index]))
            clipboardCopy.option = {}
            clipboardCopy.value = {}
            MixpanelTrack('Copied item')
            break
        case PASTE_ITEM:
            var item = JSON.parse(JSON.stringify(clipboard.item))
            item.menuItemId = menuCopy.categories[menuBuilder.drinkOrFood].items[load.index].menuItemId
            const it = setNewOptionIds(item)
            menuCopy.categories[menuBuilder.drinkOrFood].items[load.index] = it
            MixpanelTrack('Pasted item')
            break
        case COPY_OPTION:
            clipboardCopy.item = {}
            clipboardCopy.option = JSON.parse(JSON.stringify(menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.index]))
            clipboardCopy.value = {}
            MixpanelTrack('Copied modifier')
            break
        case PASTE_OPTION:
            var option = JSON.parse(JSON.stringify(clipboard.option))
            option.menuOptionId = menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.index].menuOptionId
            const op = setNewValueIds(option)
            menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.index] = op
            MixpanelTrack('Pasted modifier')
            break
        case COPY_VALUE:
            clipboardCopy.item = {}
            clipboardCopy.option = {}
            clipboardCopy.value = JSON.parse(JSON.stringify(menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.index].values[load.valueIndex]))
            MixpanelTrack('Copied option')
            break
        case PASTE_VALUE:
            menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.index].values[load.valueIndex] = Object.assign(clipboard.value, {
                optionValueId: menuCopy.categories[menuBuilder.drinkOrFood].items[menuBuilder.currentItemIndex].options[load.index].values[load.valueIndex].optionValueId
            })
            MixpanelTrack('Pasted option')
            break
        case MOVE_ITEM:
            var item = JSON.parse(JSON.stringify(menuCopy.categories[menuBuilder.drinkOrFood].items[load]))
            menuCopy.categories[menuBuilder.drinkOrFood].items.splice(load, 1)

            var drinkOrFood = 'drinks'
            if(menuBuilder.drinkOrFood.includes('special')) {
                drinkOrFood = menuBuilder.drinkOrFood.includes('drink')? 'drinks' : 'food'
            } else {
                drinkOrFood = menuBuilder.drinkOrFood.includes('drink')? 'drink_specials' : 'food_specials'
            }
            menuCopy.categories[drinkOrFood].items.push(item)
            MixpanelTrack('Move Item to ' + drinkOrFood)
            break
        case VALIDATE_MENU: //double check
            menuCopy = getMenuAfterValidating(menuCopy);
            //action.onValidate(menuCopy.isDrinksValid && menuCopy.isFoodValid && menuCopy.isDrinkSpecialsValid && menuCopy.isFoodSpecialsValid)
            action.onValidate(menuCopy.isMenuValid);
            break
        case types.VALIDATE_DEFAULT_MODIFIERS:
            defaultModifiersCopy = getDefaultModifiersAfterValidating(defaultModifiersCopy)
            action.onValidate(isDefaultModifiersValid(defaultModifiersCopy))
            break
        default:
            return next(action)
    }

    //if menu is already not valid check again
    /*
    if((menuCopy.isDrinksValid != undefined && !menuCopy.isDrinksValid) ||
                    (menuCopy.isFoodValid != undefined && !menuCopy.isFoodValid) ||
                    (menuCopy.isDrinkSpecialsValid != undefined && !menuCopy.isDrinkSpecialsValid) ||
                    (menuCopy.isFoodSpecialsValid != undefined && !menuCopy.isFoodSpecialsValid)) {
        menuCopy = getMenuAfterValidating(menuCopy)
    }
    */
    if (menuCopy.isValid != undefined && !menuCopy.isValid) {
        menuCopy = getMenuAfterValidating(menuCopy)
    }
    if(defaultModifiersCopy.length > 0 && defaultModifiersCopy[0]['isValid'] != undefined && !isDefaultModifiersValid(defaultModifiersCopy)) {
        defaultModifiersCopy = getDefaultModifiersAfterValidating(defaultModifiersCopy)
    }

    action.payload = menuCopy
    action.toDelete = deleteCopy
    action.clipboard = clipboardCopy
    action.defaultModifiers = defaultModifiersCopy
    action.toDeleteModifiers = deleteModifiersCopy

    next(action)

}

const deleteCategory = (menuCopy, header) => {
    let menu = JSON.parse(JSON.stringify(menuCopy));
    let indexMarker = menu.categories[header]['index'];
    delete menu.categories[header];
    let headerList = Object.keys(menu.categories);
    for (let catHeader of headerList) {
        if (menu.categories[catHeader]['index'] > indexMarker)
            menu.categories[catHeader]['index']--
    }
    return menu;
}

const getNewValue = () => {
    const uniqueIdGenerator = new UniqueIdGenerator()
    return {
        optionValueId: uniqueIdGenerator.getId(),
        value: '',
        price: null,
        isAvailable: true,
        childId: null
    }
}

const arePricesEqual = (values) => {
    if(values.length == 0)
        return true
    var p = values[0].price
    for(var k = 1; k < values.length; k++) {
        var value = values[k]
        if(p != value.price) {
            return false
        }

    }
    return true
}

const setNewOptionIds = (item) => {
    const uniqueIdGenerator = new UniqueIdGenerator()
    for(var k = 0; k < item.options.length; k++) {
        var option = item.options[k]
        option.menuOptionId = uniqueIdGenerator.getId()
        setNewValueIds(option)
    }
    return item
}

const setNewValueIds = (option) => {
    const uniqueIdGenerator = new UniqueIdGenerator()
    for(var k = 0; k < option.values.length; k++) {
        var value = option.values[k]
        value.optionValueId = uniqueIdGenerator.getId()
    }
    return option
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

const getMenuAfterValidating = (menuCopy) => {
    /*
    const drink = validateMenu('drinks', menuCopy)
    menuCopy = drink.menu
    const food = validateMenu('food', menuCopy)
    menuCopy = food.menu
    const drinkSpecials = validateMenu('drink_specials', menuCopy)
    menuCopy = drinkSpecials.menu
    const foodSpecials = validateMenu('food_specials', menuCopy)
    menuCopy = foodSpecials.menu

    menuCopy['isDrinksValid'] = drink.isValid
    menuCopy['isFoodValid'] = food.isValid
    menuCopy['isDrinkSpecialsValid'] = drinkSpecials.isValid
    menuCopy['isFoodSpecialsValid'] = foodSpecials.isValid
    */

    const validatedMenu = validateWholeMenu(menuCopy)
    menuCopy = validatedMenu.menu
    menuCopy['isMenuValid'] = validatedMenu.isValid
    return menuCopy
}

const getDefaultModifiersAfterValidating = (defaultModifiersCopy) => {
    var optValidObj = {isOptionsValid: true}
    for(var i in defaultModifiersCopy) {
        optValidObj = {isOptionsValid: true}
        validateOption(defaultModifiersCopy[i], optValidObj)
        defaultModifiersCopy[i]['isValid'] = optValidObj.isOptionsValid
    }

    return defaultModifiersCopy
}

const isDefaultModifiersValid = (defaultModifiersCopy) => {
    for(var i in defaultModifiersCopy) {
        if(defaultModifiersCopy[i]['isValid'] != undefined && !defaultModifiersCopy[i]['isValid']) {
            return false
        }
    }
    return true
}
const validateWholeMenu = (menuCopy) => {
    var menu = JSON.parse(JSON.stringify(menuCopy))
    var isValid = true
    var catHeaders = Object.keys(menuCopy.categories)
    for (let header of catHeaders) {
        let category = menuCopy.categories[header]
        for(var k in category.items) {
            var item = category.items[k]
            if(item.name == "") {
                item['errorMessage'] = 'this is required'
                isValid = false
            } else {
                item['errorMessage'] = ''
            }
            //only need image for drinks
            //needs adjustment
            // if(header.includes('Drink')) {
            //     if(item.image == "") {
            //         item['isImageValid'] = false
            //         isValid = false
            //     } else {
            //         item['isImageValid'] = true
            //     }
            // }

            if(item.pickupTime == "") {
                item['errorMessagePickupTime'] = 'this is required'
                isValid = false
            } else {
                item['errorMessagePickupTime'] = ''
            }
            if(typeof item.discountValue !== 'undefined' && (typeof item.discountUnit == 'undefined' || item.discountUnit == DISCOUNT_UNIT_SELECT)){
                item['errorMessageDiscountUnit'] = 'this is required'
                isValid = false
            } else {
                item['errorMessageDiscountUnit'] = ''
            }

            var optValidObj = {isOptionsValid: true}
            for(var i in item.options) {
                validateOption(item.options[i], optValidObj)
            }

            isValid = optValidObj.isOptionsValid && isValid
            item['isOptionsValid'] = optValidObj.isOptionsValid

        }
    }

    return {isValid: isValid, menu: menu}
}

const validateMenu = (drinkOrFood, menuCopy) => {
    var menu = JSON.parse(JSON.stringify(menuCopy))
    var isValid = true
    for(var k in menu[drinkOrFood]) {
        var item = menu[drinkOrFood][k]
        if(item.name == "") {
            item['errorMessage'] = 'this is required'
            isValid = false
        } else {
            item['errorMessage'] = ''
        }
        //only need image for drinks
        if(drinkOrFood.includes('drink')) {
            if(item.image == "") {
                item['isImageValid'] = false
                isValid = false
            } else {
                item['isImageValid'] = true
            }
        }

        if(item.pickupTime == "") {
            item['errorMessagePickupTime'] = 'this is required'
            isValid = false
        } else {
            item['errorMessagePickupTime'] = ''
        }
        if(typeof item.discountValue !== 'undefined' && (typeof item.discountUnit == 'undefined' || item.discountUnit == DISCOUNT_UNIT_SELECT)){
            item['errorMessageDiscountUnit'] = 'this is required'
            isValid = false
        } else {
            item['errorMessageDiscountUnit'] = ''
        }

        var optValidObj = {isOptionsValid: true}
        for(var i in item.options) {
            validateOption(item.options[i], optValidObj)
        }

        isValid = optValidObj.isOptionsValid && isValid
        item['isOptionsValid'] = optValidObj.isOptionsValid

    }
    return {isValid: isValid, menu: menu}
}

const validateOption = (option, optValidObj) => {
    if(option.title == "") {
        option['errorMessageTitle'] = 'this is required'
        optValidObj.isOptionsValid = false
    } else {
        option['errorMessageTitle'] = ''
    }
    if(option.widgetType == "") {
        option['errorMessageWidget'] = 'this is required'
        optValidObj.isOptionsValid = false
    } else {
        option['errorMessageWidget'] = ''
    }
    if(option.widgetType == SELECT_MAX && (option.maxNum == null || option.maxNum == '')) {
        option['errorMessageMax'] = 'this is required'
        optValidObj.isOptionsValid = false
    } else {
        option['errorMessageMax'] = ''
    }


    var isValuesValid = true
    for(var j in option.values) {
        var value = option.values[j]
        if(value.value == "") {
            value['errorMessageValue'] = 'this is required'
            isValuesValid = false
        } else {
            value['errorMessageValue'] = ''
        }
        if((value.price == null || value.price == "") && value.price != 0) {
            value['errorMessagePrice'] = 'this is required'
            isValuesValid = false
        } else {
            value['errorMessagePrice'] = ''
        }
    }
    optValidObj.isOptionsValid = isValuesValid && optValidObj.isOptionsValid
    option['isValuesValid'] = isValuesValid
}

export default menuMiddleware
