import React, {Component} from 'react';
import WorkBlock from './WorkBlock';
import reasonImage1 from '../../../src/assets/images/guy_making_coffee.jpg';
import reasonImage2 from '../../../src/assets/shopLanding/intuitive_integration.png';
import reasonImage3 from '../../../src/assets/shopLanding/menu_control.png';
import reasonImage4 from '../../../src/assets/shopLanding/analytics.png';
import SectionHeader from './SectionHeader';

class WorkWithMojo extends Component {

    render(){
        const reasons = [{
            body: "CoffeePass is designed with independent coffee in mind; we understand the unique challenges of operating a local shop!",
            title: "Tailored to Coffee Shops",
            className: "genericBlockReverse workBlock row",
            imageURL: reasonImage1
        },
        {
            body: "It’s easy to sign up for CoffeePass! Our owner dashboard walks you through the process, and we’ll be there to support you every step of the way!",
            title: "Intuitive Integration",
            className: "genericBlock workBlock row offWhiteBackground",
            imageURL: reasonImage2
        },
        {
            body: "CoffeePass's owner dashboard makes it easy to update your shop menu, hours, and other information, as well as temporarily turn off order-ahead if needed.",
            title: "You Have Control",
            className: "genericBlock workBlock row",
            imageURL: reasonImage3
        },
        {
            body: "We understand that analytics are important to you, so we seek to offer the same data available to you from your POS.",
            title: "Insightful Analytics",
            className: "genericBlockReverse workBlock row offWhiteBackground",
            imageURL: reasonImage4
        }];

        const reasonsView = reasons.map((reasons, index) => {
            return <WorkBlock 
                        key={reasons.title}
                        title={reasons.title}
                        class={reasons.className}
                        body={reasons.body}
                        imageURL={reasons.imageURL} 
                        textClass='stepGenericText col-md-5 col-9'
                        imageClass='shopGenericImage workImage col-md-4 col-12'/>
        })
        return(
            <div className="blockWrapper workWithBlock">
                <SectionHeader title="We're easy to work with" />
                {reasonsView}
            </div>
        );
    }
}

export default WorkWithMojo;
