
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import CoffeeImage from './CoffeeImage';
import CoffeeIcons from './CoffeeIcons';
import CoffeeImageUpload from "./CoffeeImageUpload";
import AppBar from "@material-ui/core/AppBar";
import {Tab, Tabs} from "@material-ui/core";
import CardBox from "../../analytics/util/CardBox";

export default class CoffeeIconSelectDialog extends Component {
    constructor(props) {
        super(props)

        // const coffeeIcons = new CoffeeIcons()
        // this.icons = coffeeIcons.getImages()
        this.icons = []
        this.state = {
            tab: 0
        }
    }

    getIcons = () => {
        const coffeeIcons = new CoffeeIcons()
        this.icons = coffeeIcons.getImages()
    }
    handleTabChange = (event, tab) => {
        this.setState({tab: tab});
    }

    render(props) {
        this.getIcons(this.props.drinkOrFood)

        var items = this.icons.map((i, index) => {
            if(index % 4 == 0) {
                var row = this.icons.slice(index, index + 4).map(icon => {
                    var color = (icon.key === this.props.selectedImage)? 'primary' : '#c5c5c5'
                    return (
                        <div key={icon.key} style={styles.container}>
                            <Button variant="contained" color={color} style={styles.button} onClick={() => this.props.onClickImage(icon.key)}>
                                <CoffeeImage name={icon.key} style={styles.image}/>
                            </Button>
                            <div>{icon.defaultName}</div>
                        </div>
                    )
                })

                return (<div key={index} className='row'>
                            {row}
                        </div>)
            }
            return (<span />)
        })

        return(
            <Dialog
                open={this.props.isDialogOpen}
                aria-labelledby="coffee icon select"
                aria-describedby="alert-dialog-description">
                <DialogTitle>{"Add image"}</DialogTitle>
                <DialogContent>
                    <div>
                        <AppBar position="static">
                            <Tabs value={this.state.tab} onChange={this.handleTabChange}>
                                <Tab label="Upload image" />
                                <Tab label="Select from library" />
                            </Tabs>
                        </AppBar>
                        {this.state.tab === 0 &&
                            <CoffeeImageUpload menu={this.props.menu} selectedImage={this.props.selectedImage} onClickImage={this.props.onClickImage}/>
                        }
                        {this.state.tab === 1 &&
                            <div>
                                <h4>Default Images</h4>
                                {items}
                            </div>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.handleClose()} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>)
    }
}

const styles = {
    image: {
        width: 80,
        height: 80,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10
    },
    tile: {
        margin: 10
    }
}

CoffeeIconSelectDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onClickImage: PropTypes.func.isRequired
}
