import React, { Component } from "react";
import HeaderBlock from '../home/HeaderBlock';
import "../home/App.css";
import '../../styles/jumbo.css';
import ShopHead from './ShopHead';
import WhyMojo from './WhyMojo';
import WorkWithMojo from './WorkWithMojo'
import MoneyStats from './MoneyStats';
import OwnerTestimonials from './OwnerTestimonials';
import Footer from '../home/Footer';
import Faq from './Faq';
import * as qs from 'query-string';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import ReactPixel from 'react-facebook-pixel';

class ShopLanding extends Component {

    componentDidMount() {
        MixpanelTrack("Visited ShopLanding Page")
        ReactPixel.pageView()
        ReactPixel.track('Visited ShopLanding Page', 'no data')
        var refVal = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).ref
        if(refVal != undefined) {
            MixpanelTrack("Visited ShopLanding ref: " + refVal)
            ReactPixel.track("Visited ShopLanding ref: " + refVal, 'no data')
        }
    }

    render() {
        return (
            <div className="app">
                <div className="shopTopBlock">
                    <div className="navBarBackground">
                        <HeaderBlock page={'/shopLanding'}/>
                    </div>
                    <ShopHead />
                </div>
                <div className="shopMidBlock">
                    <WhyMojo />
                    <WorkWithMojo />
                    <MoneyStats />
                    <OwnerTestimonials />
                    <Faq />
                    <Footer />
                </div>
                <div className="shopEndBlock">

                </div>
            </div>
        );
    }
}

export default ShopLanding;
