import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import CoffeeImage from '../menu/MenuBuilder/CoffeeImage';
import ExtraMenu from '../menu/MenuBuilder/ExtraMenu';
import TextFieldValidator from '../util/TextFieldValidator'

class Benefit extends Component {

    onSelectOption(option) {
        if(option == 'Delete') {
            this.props.onDeleteBenefit(this.props.i)
        }
    }

    render() {
        const {i, benefit, onClickImage} = this.props
        var extraOptions = ['Delete']

        return (
            <div>
                <div className="row">
                    <div className="col-2" style={styles.button}>
                        <Button variant="contained" style={styles.imageButton} color="default" onClick={() => onClickImage(i, benefit.icon)}>
                            {(benefit.icon === "" || benefit.icon === null)?
                                <span style={styles.buttonFont}>Select Icon</span>
                                :
                                <CoffeeImage name={benefit.icon} style={styles.image}/>
                            }   
                        </Button>
                    </div>
                    <div className="col-md-9 col-9">
                        <TextFieldValidator
                            required
                            label="Title"
                            value={benefit.title}
                            onChange={event => this.props.onChangeBenefit(i, event.target.value)}
                            defaultValue=""
                            margin="normal"
                            fullWidth
                            />
                    </div>
                    <div className="col-md-1 col-1 mt-2">
                        <ExtraMenu options={extraOptions} onSelectOption={option => this.onSelectOption(option)} />
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    image: {
        width: 30,
        height: 30,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
    },
    imageButton: {
        padding: 0
    },
    buttonFont: {
        fontSize: 11
    },
    error: {
        position: 'absoliute',
        color: '#ff0000',
        marginLeft: -10,
        marginTop: -35,
        zIndex: 10
    }
}

export default Benefit;