import React, { Component } from 'react';
import CustomerRecencyTableCell from './CustomerRecencyTableCell';

const CustomerRecencyTable = ({ data }) => {
    var keyVal = 0;
    const dataVis = ('key' in data) ? <h1 className="text-muted">No Recency Data</h1> : data.map(data => { keyVal += 1; return (<CustomerRecencyTableCell id={keyVal.toString()} data={data} />); })
    return (
        <div className="table-responsive-material">
            <table className="table default-table table-sm remove-table-border table-hover mb-0">
                <tbody>
                    {dataVis}
                </tbody>
            </table>
        </div>
    );

};

export default CustomerRecencyTable;