import React from 'react';
import Question from './Question';
import Button from '@material-ui/core/Button';

const QuestionList =(({questions, width, addQuestion, onDeleteQuestion, onChangeQuestion, onQuestionRequiredChange}) => {
    return (
        <div>
            <h4>Questionnaire</h4>
            <div>
                {questions.length>0 && questions.map((question, index) =>
                        <Question key={index}
                            i={index}
                            question={question}
                            onDeleteQuestion={(idx) => onDeleteQuestion(idx)}
                            onChangeQuestion={(idx, value) => onChangeQuestion(idx, value)}
                            onQuestionRequiredChange={(idx, value) => onQuestionRequiredChange(idx, value)}/>
                    )
                }                
            </div>
            <div style={{textAlign:'right'}}>
                <Button color="primary" onClick={addQuestion} style={{marginTop: -20, marginBottom: 30}}>
                    + Add Another
                </Button>
            </div>
        </div>
    )
})

export default QuestionList;