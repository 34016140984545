import { analyticsConstants } from "../constants/ActionTypes";
import { baseUrl } from '../constants/baseUrl';

export const getPayoutTableData = (paramObj, onSuccess, onFailure) => ({
    type: analyticsConstants.GET_PAYOUT_TABLE_DATA,
    payload: paramObj,
    meta: {
        type: 'apijson',
        url: baseUrl + '/payouts_table',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
        },
        options: {
            method: 'GET'
        }
    }
})

