import React from 'react';

const PopularProduct = ({ product, styleName }) => {
    const title = product['product'];
    const orderCount = product['count'];
    const offerPrice = product['cost'];
    const orderPlural = (offerPrice == 1) ? "time" : "times";

    if (!styleName) {
        styleName = "col-sm-12 col-md-6 col-lg-12 col-12 mb-4";
    }
    return (
        <div className={`${styleName}`}>
            <div className="row">
                <div className="col-lg-5 col-md-4 col-6 mb-2">
                    <h2>{title}</h2>
                    {/*<img className="img-fluid rounded" src={image} alt={title} />*/}
                </div>
                <div className="col-lg-7 col-md-8 col-6">
                    <h4 className="mb-2"> Ordered {orderCount} {orderPlural}</h4>
                    {/*<p className="text-muted mb-1"> {description}</p>*/}
                    <div className="d-flex align-items-end">
                        <h4><i className="zmdi zmdi-money" />{offerPrice.toFixed(2)} </h4>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PopularProduct;

