import React, { Component } from 'react'
import CardBox from './CardBox';

class NoDataCard extends Component {
    render() {
        return (
            <CardBox styleName="col-12 graphCard" cardStyle="mb-0 p-0">
                <div className="col-12 noDataDisplay">
                    <h1 className="text-muted">No {this.props.type} data for this date range.</h1>
                </div>
            </CardBox>

        );
    }
}

export default NoDataCard;