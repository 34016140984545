import React, { Component } from "react";
import "./App.css";
import HeaderBlock from "./HeaderBlock";
import CustomerBlock from "./CustomerBlock";
import HowItWorks from "./HowItWorks";
import Footer from "./Footer";
import PartnersBlock from "./PartnersBlock";
import * as qs from 'query-string';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import ReactPixel from 'react-facebook-pixel';
import windowSize from 'react-window-size';

class Home extends Component {

    componentDidMount() {
        MixpanelTrack("Visited Home Page")
        ReactPixel.pageView()
        ReactPixel.track('Visited ShopLanding Page', 'no data')
        var refVal = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).ref
        if(refVal != undefined) {
            MixpanelTrack("Visited Home Page ref: " + refVal)
            ReactPixel.track("Visited ShopLanding ref: " + refVal, 'no data')
        }
    }

    render() {
        //console.log("windowWidth", this.props.windowWidth)
        const isMobileDevice = this.props.windowWidth < 770 ? true : false

        return (
            <div className="app">
                {/*This needs to be cleaned up into blocks*/}
                <div className="topBlock">
                <div className="stripes">
                    <span className="topStripe"></span>
                </div>
                <div className={isMobileDevice ? 'leafbg-mobile' : 'leafbg'}></div>
                {/*<div style={styles.banner}>
                    Looking for Mojo? We've rebranded to CoffeePass; a different name with the same great features that you know and love!
                </div>
                */}
                <div className="introBlock">
                    <HeaderBlock page={'/'}/>
                    <CustomerBlock />
                </div>
                </div>

                <div className="middleBlock">
                    <HowItWorks />
                </div>


                <div className="endBlock">
                    <PartnersBlock />
                    <Footer />
                </div>
            </div>)
    }
}

const styles = {
    banner: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'auto',
        justifyContent: 'center',
        transformOrigin: 0,
        width: '100%',
        position: 'relative',
        backgroundColor: '#78A852',
        fontSize: 16,
        color: 'white',
        fontFamily: 'Gotham-Medium',
        padding: 10,
        textAlign: 'center',
    }
}

export default windowSize(Home);
