import React, {Component} from 'react';
import {Cropper} from 'react-image-cropper'
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import S3FileUpload from '../../ShopInfo/S3Upload/ReactS3';
import { doesUrlExistAction, setUploadShopImageAction } from '../../../../actions/ShopInfo';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { MixpanelTrack } from '../../../../util/MixpanelHelper';
import selectors from '../../../../reducers/selectors';
import {awsBuckets as awsBucket, awsBuckets} from "../../../../constants/awsBucket";
import imageCompression from 'browser-image-compression';

class CoffeeImageUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            accepted: [],
            rejected: [],
            src: null,
            loading: false,
            fileName: "",
            imageUrls: [],
        }

        this.s3Config = {
            bucketName: awsBuckets.menu_image_bucket,
            dirName: this.props.shopId,
            region: 'us-east-1'
        }

        this.onCancelClicked = this.onCancelClicked.bind(this)
        this.onSaveClicked = this.onSaveClicked.bind(this)
    }

    async componentDidMount() {
        this.setState({menu: this.props.menu});
        await this.refreshImages();
    }

    async refreshImages() {
        this.setState({loading: true});
        const result = await S3FileUpload.getFiles(this.s3Config, this.props.shopId);
        if(result.Contents !== undefined) {
            const images = result.Contents;
            let imageUrls = [];
            for (let i = 0; i < images.length; i++) {
                imageUrls.push(`https://${awsBucket.menu_image_bucket}.s3.amazonaws.com/${images[i].Key}`)
            }
            this.setState({imageUrls: imageUrls});
        } else {
            this.setState({imageUrls: []});
        }
        this.setState({loading: false});
    }

    onSelectFile = (accepted, rejected) => {
        this.setState({accepted, rejected});
        if (accepted && accepted.length > 0) {
            const reader = new FileReader()
            reader.addEventListener(
                'load',
                () =>
                    this.setState({
                        src: reader.result,
                    }),
                false
            )
            reader.readAsDataURL(accepted[0])
            this.setState({fileName: accepted[0].name});
        }
        MixpanelTrack('selected image file')
    }

    onCancelClicked() {
        this.setState({src: null, loading: false});
        MixpanelTrack('Clicked Cancel image')
    }

    onCancelUpdateClicked = () => {
        this.setState({isUpdating: false})
        MixpanelTrack('Clicked Cancel update image')
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    async onSaveClicked() {
        this.setState({loading: true});
        const options = {
            maxSizeMB: 1,
            useWebWorker: true,
            maxWidthOrHeight: 750
        }

        MixpanelTrack('Saved image');
        var file = this.dataURLtoFile(this.croppedImage.crop(), this.state.fileName);
        const compressed = await imageCompression(file, options);
        S3FileUpload
            .uploadImage(compressed, this.s3Config)
            .then(async data => {
                if(this.props.activeStep === undefined) {
                    if(data.result === "Successfully uploaded file.") {
                        NotificationManager.success(this.state.fileName + " is now saved in CoffeePass!", "Success")
                        this.setState({accepted: [], rejected: [], fileName: "", src: null, loading: false});
                        await this.refreshImages();
                    } else {
                        this.setState({loading: false});
                        NotificationManager.error("Error: " + err + ". If the problem persists contact support@coffeepass.io.", "Error Saving Image!", 9000)
                    }

                }
            })
            .catch(err => {
                console.error(err)
                this.setState({loading: false});
                NotificationManager.error("Error: " + err + ". If the problem persists contact support@coffeepass.io.", "Error Saving Image!", 9000)
            })
    }

    isImageUsed(image) {
        if (this.props.menu !== null) {
            const categories = Object.keys(this.state.menu.categories);
            let isUsed = false;
            for(const c of categories) {
                if(this.state.menu.categories[c].items.find(i => i.image === image) !== undefined) {
                   isUsed = true;
                }
            }
            return isUsed;
        } else return true;
    }

    async deleteImage(image) {
        const fileName = image.replace(`https://${awsBucket.menu_image_bucket}.s3.amazonaws.com/${this.props.shopId}/`,'');
        const deleteResult = await S3FileUpload.deleteUpload(this.s3Config, fileName);
        console.log(deleteResult);
        await this.refreshImages();
    }

    render() {
        var items = this.state.imageUrls.map((i, index) => {
            if(index % 4 === 0) {
                var row = this.state.imageUrls.slice(index, index + 4).map((icon) => {
                    var color = (icon === this.props.selectedImage)? 'primary' : '#c5c5c5'
                    const isUsed = this.isImageUsed(icon);
                    return (
                        <div key={index} style={styles.container}>
                            <Button variant="text" disabled={this.state.loading || isUsed} size="small" onClick={() => this.deleteImage(icon)} style={styles.deleteButton}>{isUsed ? '' : 'X'}</Button>
                            <Button variant="contained" color={color} style={styles.button} onClick={() => this.props.onClickImage(icon)}>
                                <img src={icon} style={styles.image}/>
                            </Button>
                            <div>{icon.replace(`https://${awsBucket.menu_image_bucket}.s3.amazonaws.com/${this.props.shopId}/`,'').substring(0,13)}</div>
                        </div>
                    )
                })

                return (<div key={index} className='row'>
                    {row}
                </div>)
            }
            return (<span />)
        })
        return(
            <div>
                <span style={styles.text}>{this.props.text}</span>
                {this.state.loading && <CircularProgress size={16} style={styles.progress} />}
                    <div>
                        {(this.state.src)?
                            <div>
                                <Cropper
                                    src={this.state.src}
                                    ref={ ref => { this.croppedImage = ref }}
                                    ratio={this.props.imgRatio}
                                    allowNewSelection={false}
                                />
                                <div style={styles.buttonContainer}>
                                    <QuickButton
                                        onClick={this.onCancelClicked}
                                        text='Cancel' />
                                    <Button
                                        variant="raised"
                                        color="primary"
                                        className="jr-btn"
                                        disabled={this.state.loading}
                                        onClick={this.onSaveClicked}>
                                        {this.state.loading ?
                                            <CircularProgress size={16} style={styles.progress} />
                                            :
                                            "Save"
                                        }
                                    </Button>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="dropzone">
                                    <Dropzone
                                        accept="image/jpeg, image/png"
                                        onDrop={(accepted, rejected) => { this.onSelectFile(accepted, rejected)}} >
                                        <div style={styles.buttonContainer}>
                                            <Button
                                                variant="raised"
                                                color="primary"
                                                className="jr-btn">
                                                Upload {this.props.imgName}
                                            </Button>
                                        </div>
                                    </Dropzone>
                                </div>
                                {this.state.imageExist && this.state.isUpdating?
                                    <div style={styles.buttonContainer}>
                                        <QuickButton
                                            onClick={this.onCancelUpdateClicked}
                                            text='Cancel' />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        }
                    </div>
                {items.length === 0 ? <span>No Custom Images</span> : items}
                <NotificationContainer />
            </div>
        )
    }
}

const QuickButton = ({onClick, text}) => (
    <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={onClick}>
        {text}
    </Button>
)

const styles = {
    image: {
        width: 80,
        height: 80,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10
    },
    tile: {
        margin: 10
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'center',
        marginTop: 10
    },
    logo: {
        width: 'auto',
        height: 200
    },
    imageContainer: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    },
    text: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10
    },
    deleteButton: {
        position: 'relative',
        top: '30px',
        left: '-50px',
        zIndex: 5,
        minWidth: 0
    }
}

CoffeeImageUpload.propTypes = {
    imgNameEnding: PropTypes.string.isRequired,
    imgName: PropTypes.string.isRequired,
    isPickupImage: PropTypes.bool.isRequired,
    imgRatio: PropTypes.number.isRequired,
    imgUrl: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    doesUrlExistAction,
    setUploadShopImageAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(CoffeeImageUpload);
