
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default class SaveSureDialog extends Component {

    render(props) {
        return(
            <Dialog
                open={this.props.isDialogOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to save? This will update the CoffeePass application immediately to show the <strong>{this.props.dialogDataToSave}</strong> as you have set here.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onNoClicked} color="primary">
                        No
                    </Button>
                    <Button onClick={this.props.onYesClicked} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            )
    }
}

SaveSureDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onYesClicked: PropTypes.func.isRequired,
    onNoClicked: PropTypes.func.isRequired,
    dialogDataToSave: PropTypes.string.isRequired
}
