import React, { Component } from 'react';
import ContainerHeader from './../../util/ContainerHeader';
import CardBox from './../util/CardBox';
import { Divider } from '@material-ui/core';
import TopFiveGraph from './../util/TopFiveGraph';
import DatePicker from './components/DatePicker';
import { connect } from 'react-redux';
import DatabaseValidator from './../../util/DatabaseValidator';
import NestedDataTable from './components/NestedDataTable';
import NoDataCard from './../util/NoDataCard';
import GraphFormatter from '../util/GraphFormatter';

class ModifierSales extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
        }
    }

    handleChangePage = (page) => {
        this.setState({page: page})
    }

    render() {
        const columnData = [
            { id: 'name', numeric: false, disablePadding: true, label: 'Modifiers' },
            { id: 'count', numeric: true, disablePadding: false, label: 'Quantity Sold' },
            // { id: 'gross', numeric: true, disablePadding: false, label: 'Gross Sales' },
        ];
        let dv = new DatabaseValidator();
        let gf = new GraphFormatter();
        let dataVis = '';
        if (!dv.isEmpty(this.props.addonSales)) {
            dataVis = <NestedDataTable className="tableCard" title='Item Sales' columnData={columnData} dataFormatter={gf.formatModifierData} data={this.props.addonSales}
                onChangePage={this.handleChangePage}
                page={this.state.page} />
        } else {
            dataVis = <NoDataCard type="item sale" />
        }

        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <ContainerHeader match={this.props.match} title="Addon Sales" />

                <div className="row col-12 datePicker"><DatePicker /></div>
                <Divider />
                <CardBox styleName="col-12 graphCard" cardStyle="mb-0 p-0">
                    <div className="row col-12 "><TopFiveGraph data={this.props.topFiveAddonsData} isMoney={false} /></div>
                </CardBox>

                {/*dataVis for the table*/}
                {dataVis}

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        nothing: "",
        addonSales: state.analytics.addonSales,
        topFiveAddonsData: state.analytics.topFiveAddons
    }
}

export default connect(
    mapStateToProps
)(ModifierSales);