import React, { Component } from 'react';
import { sendContactEmailAction } from '../../actions/ContactUs';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import ValidateEmail from '../dashboard/util/ValidateEmail';
import { connect } from 'react-redux';
import Footer from '../home/Footer';
import HeaderBlock from '../home/HeaderBlock';
import '../home/App.css';
import { MixpanelTrack } from '../../util/MixpanelHelper';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            subject: '',
            message: '',
            clientEmail: ''
        };


    }

    componentDidMount() {
        MixpanelTrack("Visited Contact Us Page")
    }

    onSubmit() {
        MixpanelTrack("Clicked On Submit Contact")
        const validateEmail = new ValidateEmail();
        var validTest = true;
        var problemProp = '';
        var property = '';
        for (property in this.state) {
            if (this.state[property] == '') {
                validTest = false;
                problemProp = property;
            }
        }
        if (validateEmail.isValid(this.state.clientEmail) && validTest) {
            this.props.sendContactEmailAction({ firstName: this.state.firstName, lastName: this.state.lastName, subject: this.state.subject, message: this.state.message, clientEmail: this.state.clientEmail },
                (resp) => {
                    if (resp.val != false) {
                        NotificationManager.success('Email was sucessfully sent!', "Success")
                    }
                },
                (error) => {
                    NotificationManager.error('Could not send email. Contact Noah at support@coffeepass.io if the problem persists.', 'Error')
                })
        } else {
            switch (problemProp) {
                case 'firstName':
                    NotificationManager.error('Please enter your first name.', 'Empty Field: ');
                    break;
                case 'lastName':
                    NotificationManager.error('Please enter your last name.', 'Empty Field: ');
                    break;
                case 'subject':
                    NotificationManager.error('Please enter your subject.', 'Empty Field: ');
                    break;
                case 'message':
                    NotificationManager.error('Please enter your message.', 'Empty Field: ');
                    break;
                case 'clientEmail':
                    NotificationManager.error('Please enter your email.', 'Empty Field: ');
                    break;
                default:
                NotificationManager.error('Please make sure the email is valid', 'Invalid Email');
                    break;
            }
        }

    }

    render() {
        const { match } = this.props;
        return (
            <div className='app'>
                <NotificationContainer />
                <div className="navBarBackground">
                    <HeaderBlock />
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-2 col-sm-1 col-1"></div>
                    <div className="col-lg-6 col-md-8 col-sm-10 col-12"><h1 className="blockTitle">Contact Us</h1> </div>
                </div>

                <div className="row emailWindow">
                    <div className="col-lg-3 col-md-2 col-sm-1 col-1"></div>
                    <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                        <form action="" className="contact-form jr-card">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label form="firstName">Name</label>
                                        <input className="form-control form-control-lg" id="firstName" type="text"
                                            placeholder="First Name" value={this.state.firstName} onChange={(event) => this.setState({ firstName: event.target.value })} />
                                    </div>
                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label htmlFor="lastName">&nbsp;</label>
                                        <input className="form-control form-control-lg" id="lastName" type="text"
                                            placeholder="Last Name" value={this.state.lastName} onChange={(event) => this.setState({ lastName: event.target.value })} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input className="form-control form-control-lg" id="email" type="email"
                                            placeholder="E-mail" value={this.state.clientEmail} onChange={(event) => this.setState({ clientEmail: event.target.value })} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="webSite">Subject</label>
                                        <input className="form-control form-control-lg" id="webSite" type="text"
                                            placeholder="Subject" value={this.state.subject} onChange={(event) => this.setState({ subject: event.target.value })} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>How can we help you?</label>
                                        <textarea className="form-control form-control-lg" rows="6" value={this.state.message} onChange={(event) => this.setState({ message: event.target.value })} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mb-0">
                                        <button type="submit" className="btn btn-primary" onClick={() => this.onSubmit()}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-1 col-1"></div>
                </div>
                <svg height='35%' width='100%' viewBox="0 0 100 100" preserveAspectRatio='none' className='contactWedge'>
                    <polygon points='0,100 100,100 100,0' className='wedge' />
                </svg>
                <Footer />
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        nothing: "",
    }
}

const actionCreators = {
    sendContactEmailAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(ContactUs);

