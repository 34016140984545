import {NOT_IN_STORE} from "../constants/ActionTypes";
import {baseUrl} from "../constants/baseUrl";

export const getGuestList = (menuItemId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    meta: {
        type: 'apijson',
        url: baseUrl + `/guest_list`,
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            menuItemId
        },
        options: {
            method: 'GET'
        }
    }
})
