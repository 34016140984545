import React, { Component } from 'react';
import copyright from '../../assets/icons/copyright.svg'
import './App.css';
import { NavLink } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <div className='footerWrapper'>
                <div className="footerSpacer">
                    <div className='allLinksWrapper'>
                        <div className="linksWrapper productsLinks">
                            <div className="linksTitle">
                                Products
                            </div>
                            <div className="productLinks">
                                <NavLink to={'/'} style={{ textDecoration: 'none' }}>
                                    <div className="footerLink">
                                        Coffee Lovers
                                    </div>
                                </NavLink>
                                <NavLink to={'/shoplanding'} style={{ textDecoration: 'none' }}>
                                    <div className="footerLink">
                                        Shop Owners
                                    </div>
                                </NavLink>
                                <NavLink to={'app/overview'} style={{ textDecoration: 'none' }}>
                                    <div className="footerLink">
                                        Shop Dashboard
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="linksWrapper companyLinks">
                            <div className="linksTitle">
                                Company
                            </div>
                            <div className="companyLinks">
                                <a href={'http://blog.coffeepass.io'} style={{ textDecoration: 'none' }}>
                                    <div className="footerLink">
                                        Blog
                                    </div>
                                </a>
                                {/* <NavLink to={'/aboutus'} style={{ textDecoration: 'none' }}>
                                    <div className="footerLink">
                                        About
                                    </div>
                                </NavLink> */}
                                <NavLink to={'/contactus'} style={{ textDecoration: 'none' }}>
                                    <div className="footerLink">
                                        Contact Us
                                    </div>
                                </NavLink>
                                <NavLink to={'/terms'} style={{ textDecoration: 'none' }}>
                                    <div className="footerLink">
                                        Terms of Service
                                    </div>
                                </NavLink>
                                <NavLink to={'/privacy'} style={{ textDecoration: 'none' }}>
                                    <div className="footerLink">
                                        Privacy Policy
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="copyrightFooter">
                        <p><img src={copyright} className="copyrightFooterIcon" alt="copyright"></img> CoffeePass 2020</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
