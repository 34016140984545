import React, {Component} from 'react';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

const CoffeeImage = ({ name, style }) => {
    if(name == null || name == '') {
        return <NotInterestedIcon />
    } else if (name.includes('https://')) {
        return <img src={name} style={style} alt="item-icon" />
    } else {
        return <img src={require(`../../../../assets/item_images/${name}`).default} style={style} alt="item-icon" />
    }
}

export default CoffeeImage
