export const DISCOUNT_UNIT_SELECT = '--discount unit--'

export const discountUnitTypes = [
  	{
        id: 0,
        value: DISCOUNT_UNIT_SELECT,
        text: DISCOUNT_UNIT_SELECT,
    },
    {
      id: 0,
      value: '%',
      text: '%'
    },
    {
      id: 1,
      value: '$',
      text: '$'
    }
]