import { NOT_IN_STORE, GET_SHOP } from "../constants/ActionTypes";
import { baseUrl } from '../constants/baseUrl';

export const sendContactEmailAction = (paramObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/contact_email',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            first_name: paramObj.firstName,
            last_name: paramObj.lastName,
            subject: paramObj.subject,
            message: paramObj.message,
            email: paramObj.clientEmail,
        },
        options: {
            method: 'PUT'
        }
    }
})