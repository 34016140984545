import { analyticsConstants } from "../../constants/ActionTypes";
import { baseUrl } from '../../constants/baseUrl';

export const getCustomerFreqFourAction = (paramObj, onSuccess, onFailure) => ({
    type: analyticsConstants.GET_CUSTOMER_FREQ_FOUR,
    payload: paramObj,
    meta: {
        type: 'apijson', //use apijson for returning json objects, and api for returning strings
        url: baseUrl + '/customer_frequency',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
            date_first: paramObj.dateFirst,
            date_second: paramObj.dateSecond,
            num_visits: paramObj.numVisits
        },
        options: {
            method: 'GET'
        }
    }
})