import {NOT_IN_STORE} from "../constants/ActionTypes";
import {baseUrl} from "../constants/baseUrl";

export const uploadToS3 = (params, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    meta: {
        type: 'apijson',
        url: `${baseUrl}/uploadS3Image`,
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            key: params.key,
            bucket: params.bucket
        },
        options: {
            method: 'PUT',
            body: params.form
        }
    }
})

export const deleteFromS3 = (params, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    meta: {
        type: 'apijson',
        url: `${baseUrl}/deleteS3Image`,
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            key: params.key,
            bucket: params.bucket
        },
        options: {
            method: 'DELETE',
        }
    }
})

export const getS3Images = (params, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    meta: {
        type: 'apijson',
        url: `${baseUrl}/getS3Images`,
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shopId: params.shopId,
            bucket: params.bucket
        },
        options: {
            method: 'GET',
        }
    }
})

