import { NOT_IN_STORE } from "../constants/ActionTypes";
import { baseUrl } from "../constants/baseUrl";

export const resetPasswordAction = (params, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: params,
    meta: {
        type: 'api',
        url: baseUrl + '/reset_password',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            password: params.password,
            token: params.token
        },
        options: {
            method: 'PUT'
        }
    }
})

export const isTokenExpired = (token, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: token,
    meta: {
        type: 'api',
        url: baseUrl + '/is_token_expired',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            token: token
        },
        options: {
            method: 'GEt'
        }
    }
})