import React from 'react';
import CustomerFreqTableCell from './CustomerFreqTableCell';

const CustomerFreqTable = ({ data }) => {
    var keyVal = 6;
    const dataVis = data.map(row => { keyVal -= 1; return (<CustomerFreqTableCell key={keyVal} num={keyVal} data={row} />); })
    return (
        <div className="table-responsive-material">
            <table className="table default-table table-sm remove-table-border table-hover mb-0">
                <tbody>
                    {dataVis}
                </tbody>
            </table>
        </div>
    );

};

export default CustomerFreqTable;