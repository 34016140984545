
const selectors = (state) => {
    return {
        shopId: state.auth.shop.shopId,
        topFiveItems: state.analytics.topFiveItems,
        itemSales: state.analytics.itemSales,
        customerFreqFive: state.analytics.customerFreqFive,
        customerFreqFour: state.analytics.customerFreqFour,
        custoemrFreqThree: state.analytics.customerFreqThree,
        customerFreqTwo: state.analytics.customerFreqTwo,
        custoemrFreqOne: state.analytics.customerFreqOne,
        bestSellers: state.analytics.bestSellers,
        recentOrders: state.analytics.recentOrders,
        defaultModifiers: state.menu.defaultModifiers,
        width: state.settings.width,
        menuUI: state.menuBuilder,
        clipboard: state.menu.clipboard,
        shop: state.shop.payload
    }
}

export default selectors