
import onlyOne from '../../../../assets/gifs/only_one.gif'
import oneOrMore from '../../../../assets/gifs/one_or_more.gif'
import selectMax from '../../../../assets/gifs/select_max.gif'
import zeroManyEach from '../../../../assets/gifs/zero_many_each.gif'
import zeroOrMany from '../../../../assets/gifs/zero_or_many.gif'
import zeroOrOne from '../../../../assets/gifs/zero_or_one.gif'
export const SELECT_MAX = 'select_max'
export const EXACTLY_ONE = 'exactly_one'

export const widgetTypes = [
    { value: EXACTLY_ONE, text: 'Exactly One', gif: onlyOne, description: 'Only allows the user to select exactly one of the buttons.' },
    // { value: 'exactly_one_parent', text: 'Exactly One Parent' },
    { value: 'allow_one', text: 'Zero or One', gif: zeroOrOne, description: 'These buttons are optional. But the user can select one if they want to.' },
    { value: 'allow_multiple', text: 'Zero to Many', gif: zeroOrMany, description: 'The user can select zero or as many of the buttons as they want.' },
    { value: "one_or_more", text: 'One or More', gif: oneOrMore, description: 'The user has to at least select one button but they can select more if they want to.' },
    { value: 'multiple_of_each', text: 'Zero to Many of Each', gif: zeroManyEach, description: "The user doesn't have to select anything. But they can select buttons and they can even select a single button more than once." },
    { value: SELECT_MAX, text: 'Select Up To Max Items', gif: selectMax, description: 'The user has to select up to the max number of options that you specifiy.' }
]