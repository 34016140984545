import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import Menu from './Menu';
import MenuBuilder from './MenuBuilder';
import Shop from './Shop';
import Analytics from './Analytics';
import ManageShops from './ManageShops';
import Membership from "./Membership";

const reducers = combineReducers({
    routing: routerReducer,
    settings: Settings,
    auth: Auth,
    menu: Menu,
    shop: Shop,
    menuBuilder: MenuBuilder,
    analytics: Analytics,
    manageShops: ManageShops,
    membership: Membership
});

export default reducers;
