import React, { Component } from 'react'

class NoDataCard extends Component {
    render() {
        return (
            <div className="col-12 noDataDisplay">
                <h1 className="text-muted">No {this.props.type} Data</h1>
            </div>
        );
    }
}

export default NoDataCard;