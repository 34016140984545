import React, { Component } from 'react';
import ContainerHeader from '../util/ContainerHeader';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import CardBox from '../analytics/util/CardBox';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import { baseUrl } from '../../../constants/baseUrl';
import selectors from '../../../reducers/selectors';
import * as qs from 'query-string';

class MemberDetails extends Component {
    constructor(props) {
        super(props)
        var params = qs.parse(this.props.location.search)
        this.state = {
            shopID: this.props.shopId,
            isLoading: false,
            userMembershipId: params.id,
            userMembershipDetails: null,
            questionsAnswers:[]
        };
    }

    componentDidMount() {
        MixpanelTrack("Visited Member Details Page")
        this.getMemberDetails()
    }

    getMemberDetails = () => {
        this.setState({ isLoading: true })

        fetch(baseUrl + '/get_user_membership_details?userMembershipId=' + this.state.userMembershipId, {
            method: 'GET',
            headers: {Authorization: `Bearer ${this.props.access_token}`}
        })
        .then(result => result.json())
        .then(result => {
            this.setState({
                userMembershipDetails: result,
                questionsAnswers: JSON.parse(result.questions),
                isLoading: false
            })
        })
    }

    render() {
        const details = this.state.userMembershipDetails
        const questionsAnswers = this.state.questionsAnswers
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={"User Membership Details"} match={this.props.match} />
                <div style={{margin: 0}}>
                    <CardBox styleName="col-lg-12" heading={"Member Details"}>
                        <div>
                            {details &&
                                <div className="row" style={{margin: 10}}>
                                    <div>
                                        <ul style={{listStyleType: 'none', fontWeight: 'bold'}} >
                                            <li className="mt-2">Name</li>
                                            <li className="mt-2">Email</li>
                                            <li className="mt-2">Mobile</li>
                                            <li className="mt-2">Status</li>
                                            <li className="mt-2">Membership Request Date</li>
                                            <li className="mt-2">Membership Approval Date</li>
                                            <li className="mt-2">membership Request Cancelled Date</li>
                                            <li className="mt-2">Membership Cancelled Date</li>
                                            <li className="mt-2">Price</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul style={{listStyleType: 'none'}} >
                                            <li className="mt-2">{details.name}</li>
                                            <li className="mt-2">{details.email}</li>
                                            <li className="mt-2">{details.phone}</li>
                                            <li className="mt-2">{details.status}</li>
                                            <li className="mt-2">{details.membershipRequestDate === null ?
                                                    'N/A'
                                                    : details.membershipRequestDate
                                                }
                                            </li>
                                            <li className="mt-2">{details.membershipApprovalDate === null ?
                                                    'N/A'
                                                    : details.membershipApprovalDate
                                                }
                                            </li>
                                            <li className="mt-2">{details.membershipRequestCancelledDate === null ?
                                                    'N/A'
                                                    : details.membershipRequestCancelledDate
                                                }
                                            </li>
                                            <li className="mt-2">{details.membershipCancelledDate === null ?
                                                    'N/A'
                                                    : details.membershipCancelledDate
                                                }
                                            </li>
                                            <li className="mt-2">${details.price}</li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    </CardBox>
                    {questionsAnswers && <CardBox styleName="col-lg-12" heading={"Questions & Answers"}>
                        <div style={{margin: 10}}>
                            <ol>
                                {questionsAnswers.length>0 && questionsAnswers.map((quesAns, index) =>
                                        <li className="mt-2">
                                            <div><span><b>Question: </b></span><span>{quesAns.question}</span></div>
                                            <div><span><b>Answer: </b></span><span>{quesAns.response}</span></div>
                                        </li>
                                    )
                                }
                            </ol>
                        </div>
                    </CardBox>}
                </div>
            </div>)
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        access_token: state.auth.access_token
    }
}
const actionCreators = {

}

export default connect(
    mapStateToProps,
    actionCreators
)(MemberDetails);
