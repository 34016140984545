import React, { Component } from 'react';
import PopularProduct from "./PopularProduct";
import CardHeader from '../CardHeader';
import { getBestSellersAction } from '../../../../../../actions/BestSellers';
import { connect } from 'react-redux';
import DatabaseValidator from '../../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangeHelper from '../../../../util/DateRangeHelper';
import selectors from '../../../../../../reducers/selectors';

class PopularProductTable extends Component {

    constructor(props) {
        super(props);

        const dateRangeHelper = new DateRangeHelper()
        const range = dateRangeHelper.getLastMonth()
        let paramObj = {
            shopId: this.props.shopId,
            numOfProducts: 4,
            dateFirst: range.dateFirst,
            dateSecond: range.dateSecond
        }
        this.props.getBestSellersAction(paramObj)
    }

    render() {
        var dbv = new DatabaseValidator();
        let dataVis = dbv.isEmpty(this.props.bestSellers) ? <div className="text-center"><CircularProgress size={50} /></div> : <div className="row"> {JSON.parse(this.props.bestSellers['val']).map(data => { return (<PopularProduct product={data} />) })} </div>;
        return (
            <div className="col-xl-4 col-md-12 order-xl-2">
                <div className="jr-card">
                    <CardHeader heading="Popular Products"
                        subHeading='These are the best-selling products in your shop.' styleName="mb-4" />
                    {dataVis}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        bestSellers: selectors(state).bestSellers
    }
}

const actionCreators = {
    getBestSellersAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(PopularProductTable);
