import React, { Component } from "react";
import "../home/App.css";
import { NavLink } from 'react-router-dom';
import baristaImage from '../../assets/images/coffee_guy.jpg'
import windowSize from 'react-window-size';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import ReactPixel from 'react-facebook-pixel';

class ShopHead extends Component {

    render() {
        return (
            <div className="shopHead row">
                <span className="shopHeadImageContainer col-md-6">
                    <img src={baristaImage} className="shopHeadImage" />
                    {(this.props.windowWidth < 770)?
                        <span className="shopHeadTextContainer" style={styles.mobileHeader}>
                            <div className="tagline" style={styles.text}>
                                Create an amazing order-ahead experience!
                            </div>
                            <div className="shopHeadButtonContainer">
                                <NavLink className="shopHeadButton" 
                                            to="/signup" 
                                            style={{ textDecoration: 'none' }} 
                                            onClick={() => {
                                                MixpanelTrack('Clicked Get Started')
                                                ReactPixel.track("Clicked Get Started", 'no data')
                                            }}>
                                            Get Started
                                </NavLink>
                            </div>
                        </span>
                        :
                        null
                    }
                </span>
                {(this.props.windowWidth >= 770)?
                    <span className="shopHeadTextContainer col-md-6">
                        <div className="tagline">
                            Create an amazing order-ahead experience!
                        </div>
                        <div className="shopHeadButtonContainer">
                            <NavLink className="shopHeadButton" 
                                        to="/signup" 
                                        style={{ textDecoration: 'none' }} 
                                        onClick={() => {
                                            MixpanelTrack('Clicked Get Started')
                                            ReactPixel.track("Clicked Get Started", 'no data')
                                        }}>
                                        Get Started
                            </NavLink>
                        </div>
                    </span>
                    :
                    null
                }
                <svg height='35%' width='100%' viewBox="0 0 100 100" preserveAspectRatio='none'>
                    <polygon points='0,100 100,100 100,0' className='wedge' />
                </svg>
            </div>
        );
    }
}

const styles = {
    mobileHeader: {
        position: 'absolute',
        justifyContent: 'center',
        marginLeft: 15,
        marginRight: 15,
        marginTop: '43%'
    },
    text: {
        backgroundColor: 'rgba(250, 250, 250, .7)',
        padding: 10,
        borderRadius: 10
    }
}

export default windowSize(ShopHead);