import React, { Component } from 'react';
import ChartCard from './ChartCard';
import { ResponsiveContainer, BarChart, Bar } from 'recharts';
import { getNumOrdersAction } from './../../../../../actions/NumOrders';
import { connect } from 'react-redux';
import DatabaseValidator from './../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangeHelper from '../../../util/DateRangeHelper';
import selectors from '../../../../../reducers/selectors';
class OrdersToday extends Component {

    constructor(props) {
        super(props);

        const dateRangeHelper = new DateRangeHelper()
        const range = dateRangeHelper.getDay()
        const paramObj = {
            shopId: this.props.shopId,
            dateFirst: range.dateFirst,
            dateSecond: range.dateSecond
        }
        this.props.getNumOrdersAction(paramObj)
    }

    render() {
        const dbv = new DatabaseValidator();
        const dataVis = dbv.isEmpty(this.props.numOfOrders['val']) ? <CircularProgress /> : <h2>{this.props.numOfOrders['val']}</h2>;
        return (
            <div className="col-lg-3 col-sm-6 col-12">
                <ChartCard styleName="bg-secondary text-white">
                    <div className="chart-title">
                        {dataVis}
                        <p>Orders Today</p>
                    </div>
                    <div style={{height: 25}} />
                    {/* <ResponsiveContainer width="100%" height={110}>
                        <BarChart data={this.props.data}>

                            <Bar dataKey="pv" fill="white" />
                            <Bar dataKey="uv" fill="white" />
                        </BarChart>
                    </ResponsiveContainer> */}
                </ChartCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        numOfOrders: state.analytics.numOfOrders
    }
}

const actionCreators = {
    getNumOrdersAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(OrdersToday);
