import React, { Component } from 'react';
import ChartCard from './ChartCard';
import { getUniqueCustomersAction } from '../../../../../actions/UniqueCustomers'
import { connect } from 'react-redux';
import DatabaseValidator from './../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import selectors from '../../../../../reducers/selectors';
class Customers extends Component {

    constructor(props) {
        super(props);
        this.props.getUniqueCustomersAction({ "shopId": this.props.shopId })
    }

    render() {
        var display;
        const dbv = new DatabaseValidator();
        if (dbv.isEmpty(this.props.numCustomers)) {
            //Display Loading
            display = <CircularProgress />
        } else {
            //Display current value
            display = <h2>{JSON.parse(this.props.numCustomers['val'])}</h2>
        }
        return (
            <div className="col-lg-3 col-sm-6 col-12">
                <ChartCard styleName="bg-teal lighten-1 text-white">
                    <div className="chart-title">
                        {display}
                        <p>Your Customers</p>
                    </div>

                    <div style={{height: 25}} />
                    {/* <ResponsiveContainer width="100%" height={110}>
                        <LineChart data={this.props.data}>
                            <Line type="monotone" dataKey="uv" stroke="#ffffff" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="pv" stroke="#ffffff" />
                            <Line type="monotone" dataKey="amt" stroke="#ffffff" />
                        </LineChart>
                    </ResponsiveContainer> */}

                </ChartCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        numCustomers: state.analytics.uniqueCustomers
    }
}

const actionCreators = {
    getUniqueCustomersAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(Customers);