import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

export default class SaveSureDialog extends Component {

    render(props) {
        return(
            <Dialog
                open={this.props.isDialogOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This announcement will be sent to {this.props.dialogDataToSave} members. It will be visible on the app for the next 6 days.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onNoClicked} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.props.onYesClicked} color="primary" autoFocus>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SaveSureDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onYesClicked: PropTypes.func.isRequired,
    onNoClicked: PropTypes.func.isRequired,
    dialogDataToSave: PropTypes.number
}
