import React, { Component } from 'react'
import NoDataCard from './NoDataCard';
import CardHeader from './CardHeader';
import UserDetailTable from './UserDetailTable';
import { getNewCustomersAction } from './../../../../../actions/NewCustomers';
import { connect } from 'react-redux';
import DatabaseValidator from './../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomScrollbars from '../../../../../util/CustomScrollbars';
import DateRangeHelper from '../../../util/DateRangeHelper';
import selectors from '../../../../../reducers/selectors';


class NewCustomers extends Component {

    constructor(props) {
        super(props);

        const dateRangeHelper = new DateRangeHelper()
        const range = dateRangeHelper.getMonth()
        let paramObj = {
            shopId: this.props.shopId,
            dateFirst: range.dateFirst,
            dateSecond: range.dateSecond
        }
        this.props.getNewCustomersAction(paramObj);
    }
    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return true;
    }
    render() {

        const dbv = new DatabaseValidator();
        let dataVis = '';
        if (!dbv.isEmpty(this.props.newCustomers)) {
            if (this.props.newCustomers == 'No new customers') {
                dataVis = [<NoDataCard name="New Customers" type="Customer" />];
            } else {
                dataVis = [<UserDetailTable data={JSON.parse(this.props.newCustomers['val'])} />];
            }
        } else {
            dataVis = [<div className="text-center"><CircularProgress size={50} /></div>];
        }
        return (
            <div className="col-xl-4 col-lg-6 col-md-8 col-6">
                <div className="jr-card">
                    <div className="jr-card-header d-flex align-items-center mb-3">
                        <h3 className="card-heading mb-0"><i
                            className="zmdi-hc-fw text-primary text-lighten-2 mr-2" />
                            New Customers
                                </h3>
                        <span className="badge badge-primary ml-auto">This Month</span>
                    </div>
                    <CustomScrollbars className="module-list-scroll scrollbar"style={{height: 230}}>
                        {dataVis}
                    </CustomScrollbars>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        newCustomers: state.analytics.newCustomers
    }
}

const actionCreators = {
    getNewCustomersAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(NewCustomers);