
import UniqueIdGenerator from '../util/UniqueIdGenerator';
import {
    DELETE_MENU_ITEM,
    DELETE_MENU_OPTION,
    DELETE_MENU_VALUE,
    GET_SHOPS_DATABASE,
    ITEM_NAME_CHANGE,
    ITEM_PICKUP_TIME_CHANGE,
    ITEM_DISCOUNT_UNIT_CHANGE,
    ITEM_DISCOUNT_VALUE_CHANGE,
    ADD_MENU_ITEM,
    OPTION_VALUE_CHANGE,
    OPTION_TITLE_CHANGE,
    ADD_MENU_OPTION,
    ITEM_IMAGE,
    EMPTY_TO_DELETE,
    ADD_MENU_VALUE,
    ON_DRAG_OPTION,
    ON_DRAG_ITEM,
    PASTE_VALUE,
    COPY_VALUE,
    PASTE_OPTION,
    COPY_OPTION,
    PASTE_ITEM,
    VALIDATE_MENU,
    MOVE_ITEM,
    ITEM_AVAILABLE_CHANGE,
    COPY_ITEM,
    CHANGE_COMPLIMENTARY_STATUS,
    CHANGE_UPSELL_STATUS,
    CHANGE_DRINK_VIEW_STATUS,
    CHANGE_FOOD_VIEW_STATUS,
    CHANGE_CATEGORY_VIEW_STATUS,
    CHANGE_CATEGORY_VISIBILITY_STATUS,
    CHANGE_CATEGORY_EXCLUSIVITY_STATUS,
    CHANGE_EVENTS_CATEGORY_STATUS,
    EDIT_CATEGORY,
    NEW_CATEGORY,
    ON_DRAG_CATEGORY,
    DELETE_CATEGORY,
    types, ITEM_QUANTITY_CHANGE
} from '../constants/ActionTypes';


const getUniqueId = () => {
    const uniqueIdGenerator = new UniqueIdGenerator()
    return uniqueIdGenerator.getId()
}

const INIT_STATE = {
    payload: {
        menuId: getUniqueId(),
        shopId: 'unknown',
        menuName: 'Shop Menu',
        isActive: true,
        drinks: [],
        food: [],
        categories: [],
        drink_specials: [],
        food_specials: []
    },
    toDelete: {
        items: [],
        options: [],
        defaultOptions: [],
        values: []
    },
    toDeleteModifiers: {
        options: [],
        values: []
    },
    clipboard: {
        item: {},
        option: {},
        value: {}
    },
    defaultModifiers: [],
    defaultModifiersMenu: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SHOPS_DATABASE:
        case ITEM_NAME_CHANGE:
        case ITEM_QUANTITY_CHANGE:
        case ITEM_PICKUP_TIME_CHANGE:
        case ITEM_DISCOUNT_UNIT_CHANGE:
        case ITEM_DISCOUNT_VALUE_CHANGE:
        case CHANGE_COMPLIMENTARY_STATUS:
        case CHANGE_UPSELL_STATUS:
        case CHANGE_DRINK_VIEW_STATUS:
        case CHANGE_FOOD_VIEW_STATUS:
        case CHANGE_CATEGORY_VIEW_STATUS:
        case CHANGE_CATEGORY_VISIBILITY_STATUS:
        case CHANGE_CATEGORY_EXCLUSIVITY_STATUS:
        case CHANGE_EVENTS_CATEGORY_STATUS:
        case types.ITEM_DESCRIPTION_CHANGE:
        case ITEM_AVAILABLE_CHANGE:
        case ADD_MENU_ITEM:
        case ADD_MENU_OPTION:
        case ITEM_IMAGE:
        case ON_DRAG_ITEM:
        case ON_DRAG_OPTION:
        case ON_DRAG_CATEGORY:
        case MOVE_ITEM:
        case EDIT_CATEGORY:
        case NEW_CATEGORY:
        case DELETE_CATEGORY: {return {
                ...state,
                payload: action.payload
            }
        }
        case DELETE_MENU_ITEM:
        case types.MAKE_OPTION_NON_DEFAULT: {
            return {
                ...state,
                payload: action.payload,
                toDelete: action.toDelete
            }
        }
        case types.GET_DEFAULT_OPTIONS: {
            return {
                ...state,
                defaultModifiers: action.payload
            }
        }
        case types.GET_DEFAULT_OPTIONS_MENU: {
            return {
                ...state,
                defaultModifiersMenu: action.payload
            }
        }
        case types.ADD_DEFAULT_OPTION:
        case types.VALIDATE_DEFAULT_MODIFIERS: {
            return {
                ...state,
                defaultModifiers: action.defaultModifiers
            }
        }
        case OPTION_VALUE_CHANGE:
        case OPTION_TITLE_CHANGE:
        case types.CHANGE_OPTION_ON_TABLET:
        case ADD_MENU_VALUE: {
            return {
                ...state,
                payload: action.payload,
                defaultModifiers: action.defaultModifiers
            }
        }
        case DELETE_MENU_OPTION:
        case DELETE_MENU_VALUE: {
            return {
                ...state,
                payload: action.payload,
                defaultModifiers: action.defaultModifiers,
                toDelete: action.toDelete,
                toDeleteModifiers: action.toDeleteModifiers
            }
        }
        case EMPTY_TO_DELETE: {
            return {
                ...state,
                toDelete: action.payload
            }
        }
        case types.EMPTY_TO_DELETE_MODIFIERS: {
            return {
                ...state,
                toDeleteModifiers: action.payload
            }
        }
        case COPY_ITEM:
        case PASTE_ITEM:
        case COPY_OPTION:
        case PASTE_OPTION:
        case COPY_VALUE:
        case PASTE_VALUE:
            return {
                ...state,
                payload: action.payload,
                clipboard: action.clipboard,
                defaultModifiers: action.defaultModifiers
            }
        case VALIDATE_MENU: {
            return {
                ...state,
                payload: action.payload
            }
        }
        default:
            return state
    }
}
