import moment from 'moment';

export default class DateRangeHelper {

    getDay() {
        var midnightToday = moment.utc().startOf('day')
        var midnightTomorrow = (new Date().setUTCHours(24, 0, 0, 0))

        const paramObjDay = {
            dateFirst: (midnightToday / 1000), //sets to 
            //midnight of the current day
            dateSecond: (midnightTomorrow / 1000) //sets to the next midnight in the future
        }
        return (paramObjDay);
    }

    getLastDay() {
        const startDate = moment.utc().startOf('day').subtract(1, 'days') / 1000
        const endDate = moment.utc().endOf('day').subtract(1, 'days') / 1000
        
        const paramObjDay = {
            dateFirst: Math.round(startDate),
            dateSecond: Math.round(endDate)
        }
        return (paramObjDay);
    }

    getLastWeek() {
        const startDate = moment.utc().startOf('week').subtract(1, 'weeks') / 1000
        const endDate = moment.utc().endOf('week').subtract(1, 'weeks') / 1000

        const paramObjWeek = {
            dateFirst: Math.round(startDate),
            dateSecond: Math.round(endDate)
        }
        return (paramObjWeek);
    }

    getMonth() {
        const startDate = moment.utc().startOf('month') / 1000
        const endDate = moment.utc().endOf('month') / 1000

        const paramObjMonth = {
            dateFirst: Math.round(startDate),
            dateSecond: Math.round(endDate)
        }
        return (paramObjMonth);
    }

    getLastMonth() {
        const startDate = moment.utc().subtract(1, 'months').startOf('month') / 1000
        const endDate = moment.utc().subtract(1, 'months').endOf('month') / 1000

        const paramObjMonth = {
            dateFirst: Math.round(startDate),
            dateSecond: Math.round(endDate)
        }
        return (paramObjMonth);
    }

    getYear() {
        var today = new Date();
        //Finding the first and last of the year
        var firstDayYear = new Date(today.getFullYear(), 0, 1, 0, 0);
        var lastDayYear = new Date(today.getFullYear() + 1, 0, 0, 0, 0);
        const paramObjYear = {
            dateFirst: (firstDayYear.getTime() / 1000),
            dateSecond: (lastDayYear.getTime() / 1000)
        }
        return (paramObjYear);
    }

    getLastYear() {
        const startDate = moment.utc().subtract(1, 'years').startOf('year') / 1000
        const endDate = moment.utc().subtract(1, 'years').endOf('year') / 1000

        const paramObjYear = {
            dateFirst: Math.round(startDate),
            dateSecond: Math.round(endDate)
        }
        return (paramObjYear);
    }

}