import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';

const columnData = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
    { id: 'purchaseDate', numeric: false, disablePadding: false, label: 'PurchaseDate' },
    { id: 'button', numeric: false, disablePadding: false, label: 'Refund' },
];

class DataTableHead extends Component {

    render() {

        return (
            <TableHead>
                <TableRow>
                    {columnData.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                numeric={column.numeric}
                                padding={column.disablePadding ? 'none' : 'default'}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={false}
                                        direction={'asc'}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}


class LoadingTable extends Component {
    render() {
        return (
            <div className="table-responsive-material" style={{ marginRight: 10, marginLeft: 10 }}>
                <Table className="" style={{ marginLeft: 0, marginTop: 10, marginBottom: 0 }}>
                    <DataTableHead />
                    <TableCell colSpan={columnData.length}><CircularProgress style={{ marginLeft: "50%", marginTop: 15, marginBottom: 15 }} /></TableCell>
                </Table>
            </div>
        );
    }
}

export default LoadingTable;
