
import { 
    types
} from '../constants/ActionTypes';

const INIT_STATE = {
    shops: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.GET_CHAIN_SHOPS: {
            return {
                ...state,
                shops: action.payload
            }
        }
        default:
            return state
    }
}