
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default class DefaultModifierDialog extends Component {

    render(props) {
        return(
            <Dialog
                open={this.props.isDialogOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Continue Editing this Default Modifier?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You must edit Default Modifiers from the Default Modifier sub menu. Then it will change all of the Default Modifiers used in the menu. If you want this Modifier to be different you can continue to edit it. This will remove it's attachment to the default modifier and it will become its own modifier. Do you want to continue editing this modifier?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onNoClicked} color="primary">
                        No
                    </Button>
                    <Button onClick={this.props.onYesClicked} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            )
    }
}

DefaultModifierDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onYesClicked: PropTypes.func.isRequired,
    onNoClicked: PropTypes.func.isRequired
}