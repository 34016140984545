import React, { Component } from 'react';
import ContainerHeader from '../util/ContainerHeader';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import CardBox from '../analytics/util/CardBox';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import { NotificationContainer } from 'react-notifications';
import { baseUrl } from '../../../constants/baseUrl';
import selectors from '../../../reducers/selectors';
import { getShopUserAction } from '../../../actions/ShopInfo';
import {TextField} from "@material-ui/core";
import SaveSureDialog from "./SaveSureDialog";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {sendMemberUpdateAction} from "../../../actions/SendMemberUpdateAction";

class NewAnnouncement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shopID: this.props.shopId,
            isLoading: false,
            membershipEnabled: false,
            membershipId: 0,
            updateMessage: '',
            cta: '',
            url: '',
            submitting: false,
            isDialogOpen: false,
            errorMessage: '',
            error: false
        };

        this.props.getShopUserAction(this.props.shopId,
            (resp) => {
                this.setState({ membershipEnabled: resp.membershipEnabled, membershipId: resp.membershipId })
                if(resp.membershipEnabled && resp.membershipId > 0){
                    this.getMembers(resp.membershipId)
                }
            })

        this.handleChange = this.handleChange.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onSendClicked = this.onSendClicked.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);

    }
    componentDidMount() {
        MixpanelTrack("Visited Announcement Page")
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        MixpanelTrack('Change to announcement page ' + page)
    }

    getMembers = (membershipId) => {
        this.setState({ isLoading: true })
        fetch(baseUrl + '/get_members?membershipId=' + membershipId, {
            method: 'GET',
            headers: {Authorization: `Bearer ${this.props.access_token}`},
        })
            .then(result => result.json())
            .then(result => {
                this.setState({
                    memberCount: result.filter(member => member.active === true).length,
                    isLoading: false
                })
            })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    onSaveClicked() {
        this.setState({
            isDialogOpen: true
        })
    }

    onSuccess() {
        this.setState({sending: false, updateMessage: '', cta: '', url: ''});
        alert('Message sent successfully!')
        MixpanelTrack('Member announcement sent')
    }

    onFailure() {
        this.setState({sending: false, errorMessage: 'There was an error sending your update. Please try again or contact a member of the CoffeePass team.', error: true});
    }


    async onSendClicked() {
        this.setState({
            isDialogOpen: false,
            sending: true
        });

        this.props.sendMemberUpdateAction({shopId: this.state.shopID, updateText: this.state.updateMessage, url: this.state.url, cta: this.state.cta}, () => this.onSuccess(), () => this.onFailure());
    }

    handleDialogClose() {
        this.setState({isDialogOpen: false})
    }

    render() {
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={"Announcements"} match={this.props.match} />
                <CardBox styleName="col-sm-5" heading={"Make a new announcement"}>
                        <div className="col-md-12 col-12">
                            <TextField
                                error={this.state.error}
                                required
                                id="message"
                                label="Enter your message"
                                helperText={<span style={{color: (this.state.updateMessage.length > 140 || this.state.error) ? 'red' : 'black'}}><strong>{this.state.error? this.state.errorMessage : 'Limit your message to 140 characters'}</strong></span>}
                                value={this.state.updateMessage}
                                onChange={this.handleChange('updateMessage')}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={this.state.error}
                                required
                                id="CTA"
                                label="Enter CTA"
                                helperText={<span style={{color: (this.state.cta.length > 150 || this.state.error) ? 'red' : 'black'}}><strong>{this.state.error? this.state.errorMessage : 'Limit your CTA to 150 characters'}</strong></span>}
                                value={this.state.cta}
                                onChange={this.handleChange('cta')}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={this.state.error}
                                required
                                id="URL"
                                label="Enter URL"
                                helperText={<span style={{color: (this.state.url.length > 200 || this.state.error) ? 'red' : 'black'}}><strong>{this.state.error ? this.state.errorMessage : 'Limit your url to 200 characters'}</strong></span>}
                                value={this.state.url}
                                onChange={this.handleChange('url')}
                                margin="normal"
                                fullWidth
                            />
                            <div className="col-md-12 col-12" style={styles.buttonContainer}>
                                <span style={{color: 'red', marginRight: '20px', alignSelf: 'center', fontStyle: 'italic'}}>
                                    This announcement will be sent to {this.state.memberCount} members
                                </span>
                                <Button
                                    variant="raised"
                                    color="primary"
                                    className="jr-btn"
                                    disabled={(this.state.updateMessage.length === 0 || this.state.updateMessage.length > 140) || this.state.sending}
                                    onClick={this.onSaveClicked}>
                                    {this.state.sending ?
                                        <CircularProgress size={16} style={styles.progress} />
                                        :
                                        "Send"
                                    }
                                </Button>
                            </div>
                        </div>
                        <SaveSureDialog
                            isDialogOpen= {this.state.isDialogOpen}
                            handleClose={this.handleDialogClose}
                            onYesClicked={this.onSendClicked}
                            onNoClicked={this.handleDialogClose}
                            dialogDataToSave={this.state.memberCount}
                        />
                        <NotificationContainer />
                </CardBox>
            </div>)
    }
}

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        access_token: state.auth.access_token
    }
}
const actionCreators = {
    getShopUserAction,
    sendMemberUpdateAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(NewAnnouncement);
