
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContentText from "@material-ui/core/DialogContentText";

export default class PickupButtonDialogue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberOnly: false,
            requireTableNumber: false,
            label: "",
            deleteDialogOpen: false,
            inputText: "Add a pick-up or dine-in option",
            error: false
        }

        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
        this.onDeleteClicked = this.onDeleteClicked.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.selectedOption !== prevProps.selectedOption && this.props.selectedOption !== null) {
            this.setState({
                memberOnly: this.props.selectedOption.memberOnly,
                label:this.props.selectedOption.label,
                requireTableNumber: this.props.selectedOption.requireTableNumber});
        } else if(this.props.selectedOption !== prevProps.selectedOption && this.props.selectedOption === null){
            this.setState({
                memberOnly: false,
                label: "",
                requireTableNumber: false});
        }

    }

    getDeleteDialogue() {
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={() => this.setState({deleteDialogOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this option?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                        <Button disabled={this.props.saving} onClick={() => this.setState({deleteDialogOpen: false})} color="primary">
                            Cancel
                        </Button>
                    <Button disabled={this.props.saving} onClick={this.onDeleteConfirmed} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    onSaveClicked() {
        if(this.state.label.length > 50) {
            this.setState({inputText: 'Limit of 50 characters.', error: true});
        } else {
            let option = {
                label: this.state.label,
                memberOnly: this.state.memberOnly,
                requireTableNumber: this.state.requireTableNumber
            }

            this.props.onSaveClicked(option, false);
            this.handleClose();
        }
    }

    handleClose() {
        this.setState({memberOnly: false, label: "", requireTableNumber: false});
        this.props.handleClose();
    }

    onDeleteClicked() {
        this.setState({deleteDialogOpen: true});
    }

    onDeleteConfirmed() {
        this.props.onSaveClicked(null, true);
        this.setState({deleteDialogOpen: false});
    }

    render(props) {
        return(
            <>
            <Dialog
                open={this.props.isDialogOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Add option"}</DialogTitle>
                <DialogContent>
                    <form className="row" autoComplete="off">
                        <div className="col-md-12 col-12">
                            <TextField
                                required
                                id="label"
                                label={this.state.inputText}
                                onBlur={() => this.setState({inputText: "Add a pick-up or dine-in option", error: false})}
                                error={this.state.error}
                                onChange={(e) => this.setState({label:e.target.value})}
                                value={this.state.label}
                                margin="normal"
                                fullWidth
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={this.props.selectedIndex === 0}
                                        checked={this.state.requireTableNumber}
                                        onChange={(event, checked) => this.setState({requireTableNumber:checked})}
                                        color="primary"/>
                                }
                                label="Require table #"/>
                            {this.props.membershipEnabled && <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={this.props.selectedIndex === 0}
                                        checked={this.state.memberOnly}
                                        onChange={(event, checked) => this.setState({memberOnly:checked})}
                                        color="primary"/>
                                }
                                label="Members only"/>}
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    {this.props.selectedOption !== null && this.props.selectedIndex !== 0 &&
                    <Button disabled={this.props.saving} onClick={this.onDeleteClicked} color="primary">
                        Delete
                    </Button>}
                    <Button disabled={this.props.saving} onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={this.props.saving} onClick={this.onSaveClicked} color="primary">
                        {this.props.saving ? <CircularProgress size={16} style={styles.progress} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            {this.getDeleteDialogue()}
            </>
        )
    }
}

const styles = {
    progress: {
        color: 'gray',
        marginLeft: 7,
        marginRight: 7
    },
}
