import React, { Component } from 'react';
import ContainerHeader from '../util/ContainerHeader';
import CardBox from '../analytics/util/CardBox';
import DataTable from './components/GuestListTable';
import { connect } from 'react-redux';
import { getPayoutTableData } from '../../../actions/PayoutTable';
import DatabaseValidator from './../util/DatabaseValidator';
import LoadingTable from './components/LoadingTable';
import selectors from '../../../reducers/selectors';
import ReactExport from "react-data-export";
import moment from "moment";
import {getGuestList} from "../../../actions/GetGuestList";
import {MixpanelTrack} from "../../../util/MixpanelHelper";
import Button from "@material-ui/core/Button";
import {refundItem} from "../../../actions/RefundItem";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class GuestList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            guestList: [],
            refunding: false,
            eventName: "Event"
        }

        this.formatGuestListData = this.formatGuestListData.bind(this);
        this.getGuestList = this.getGuestList.bind(this);
    }

    async componentDidMount() {
        await this.getGuestList();
    }

    async getGuestList() {
        this.setState({loading: true});
        const menuItemId = this.props.match.params.menuItemId;
        const params = new URLSearchParams(this.props.location.search)
        const eventName = params.get('eventName');
        await this.props.getGuestList(menuItemId, (result) =>this.setState({guestList: result, loading: false, eventName}), (error) => console.log(error));
    }

    async refundItem(orderItemId) {
        this.setState({refunding: true});
        await this.props.refundItem(orderItemId, async (result) => {
            if(result.success) {
                await this.getGuestList();
            }}, (error) => console.log(error));
        this.setState({refunding: false});
    }

    formatGuestListData(data) {
        let guestList = data;
        let resultArray = [
            { value: guestList.name, numeric: false },
            { value: guestList.email, numeric: false },
            { value: guestList.number, numeric: false },
            { value: moment(guestList.purchaseDate).format('M/D/yyyy'), numeric: false },
            { value: <Button disabled={this.state.refunding} style={{marginLeft:10}} variant="contained" color="#c5c5c5" onClick={() => {
                    this.refundItem(data.orderItemId);
                }}>Refund</Button>, numeric: false },
        ];
        return resultArray;
    }
    formatDate(date){
        return ((new Date(date)).toLocaleDateString("en-US", {timeZone: 'UTC'}))
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    }


    render() {
        const columnData = [
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
            { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
            { id: 'purchaseDate', numeric: false, disablePadding: false, label: 'PurchaseDate' },
            { id: 'button', numeric: false, disablePadding: false, label: 'Refund' },
        ];
        let dv = new DatabaseValidator();
        let dataVis;
        let showDownloadButton = false;

        if(this.state.guestList.length > 0){
            showDownloadButton = true
        }

        if (this.state.loading === 0) {
            dataVis = <LoadingTable />
        } else {
            dataVis = <DataTable title={`Guest List for ${this.state.eventName}`} columnData={columnData} dataFormatter={this.formatGuestListData} data={this.state.guestList}
                onChangePage={this.handleChangePage}
                page={this.state.page} />
        }
        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <ContainerHeader match={this.props.match} title="Guest List" />
                <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                    {dataVis}
                </CardBox>

                { showDownloadButton && <div className="p-3">
                        <ExcelFile element={<button className="btn btn-primary">Download Guest List</button>} filename={`GuestList-${this.state.eventName}`}>
                            <ExcelSheet data={this.getExcelData()} name="Orders">
                                <ExcelColumn label="Name" value="name"/>
                                <ExcelColumn label="Email" value="email"/>
                                <ExcelColumn label="Phone" value="phone"/>
                                <ExcelColumn label="Purchase Date" value="purchaseDate"/>
                            </ExcelSheet>
                        </ExcelFile>
                    </div> }
            </div>
        );
    }
    getExcelData() {
        return this.state.guestList.map(guest => {
            let g = {
                name: guest.name,
                email: guest.email,
                phone: guest.number,
                purchaseDate: guest.purchaseDate,
            }
            return g;
        })
    }

}
const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        payoutTableData: state.analytics.payoutTableData
    }
}

const actionCreators = {
    getGuestList,
    refundItem

}

export default connect(
    mapStateToProps,
    actionCreators
)(GuestList);
