//this is the list of tab buttons
import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import MenuTabsButton from './MenuTabsButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomScrollbars from '../../../../util/CustomScrollbars';
import { Button } from '@material-ui/core';
import { dragCategoryAction } from '../../../../actions/Menu';
import { connect } from 'react-redux';

export default class MenuTabButtons extends Component {
    //funtion that can iterate through categories
    tabButtonContent(provided) {
        let elem = []
        if (this.props.menu.categories) {
            var catHeaders = Object.keys(this.props.menu.categories)
            for (let header of catHeaders) {
                let category = this.props.menu.categories[header]
                elem[category.index] =
                <Draggable key={category.id} draggableId={category.index} index={category.index}>
                    {(provided, snapshot) => (
                      <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <MenuTabsButton
                              title= {header}
                              itemType= {header}
                              isDrinkOrFood={this.props.drinkOrFood}
                              isValid={this.props.menu.isValid}
                              onToggleFoodDrink={this.props.onToggleFoodDrink}
                              onCategoryEdit={this.props.onCategoryEdit}
                              handleCategoryHover={this.props.handleCategoryHover}
                              isMemberOnly={category.membersOnly}
                          />
                      </div>
                    )}
                </Draggable>
            }
        }
        return elem
    }

    render() {
        return (
          <DragDropContext onDragEnd={this.props.onDragEnd}>
              <div style={styles.foodDrinksContainer}>
                  <div></div>
                  <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                          <div ref={provided.innerRef} className="module-list">
                              <CustomScrollbars className="module-list-scroll scrollbar"
                                              style={{height: this.props.width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                                  {this.tabButtonContent(provided)}
                                  {
                                    <Button color="primary" size = 'small' onClick={this.props.onNewCategory} style={{marginTop: 10, marginBottom: 10}}>
                                        + ADD CATEGORY
                                    </Button>

                                  }
                              </CustomScrollbars>
                          </div>
                      )}
                  </Droppable>
              </div>
          </DragDropContext>
        )
    }
}

const styles = {
    foodDrinksContainer: {
        margin: 10
    }
}

MenuTabButtons.Proptypes = {
    menu: PropTypes.object.isRequired,
    onToggleFoodDrink: PropTypes.func.isRequired,
    drinkOrFood: PropTypes.string.isRequired
}
