import React, { Component } from 'react';
import ContainerHeader from '../../util/ContainerHeader';
import CardBox from '../util/CardBox';
import DataTable from './components/PayoutsTable';
import { connect } from 'react-redux';
import { getPayoutTableData } from '../../../../actions/PayoutTable';
import DatabaseValidator from './../../util/DatabaseValidator';
import LoadingTable from './components/LoadingTable';
import selectors from '../../../../reducers/selectors';
import ReactExport from "react-data-export";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Payouts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
        }
        let paramObj = {
            shopId: this.props.shopId
        }
        this.props.getPayoutTableData(paramObj)
    }

    formatPayoutData(data) {
        let resultArray = [
            { value: `${(moment(new Date(data.date).toLocaleDateString("en-US", {timeZone: 'UTC'}))).subtract(7, 'days').format("M/DD")} - ${(moment(data.date)).subtract(1, 'days').toDate().toLocaleDateString("en-US", {timeZone: 'UTC'})}`, numeric: false },
            { value: `${(data.new.count).toString()}`, numeric: true },
            { value: `$${(data.new.subtotal*-1).toFixed(2).toString()}`, numeric: true },
            { value: ("$" + (data.new.tax*-1).toFixed(2).toString()), numeric: true },
            { value: ("$" + (data.new.tip*-1).toFixed(2).toString()), numeric: true },
            { value: ("($" + ((data.new.discounts).toFixed(2)).toString() + ")"), numeric: true },
            { value: ("$" + (data.new.total*-1).toFixed(2).toString()), numeric: true },
            { value: ("($" + ((data.new.fee)*-1).toFixed(2).toString() + ")"), numeric: true },
            { value: ("$" + (data.new.paid * -1).toFixed(2).toString()), numeric: true },
            // { value: <span style={{textAlign: 'right', width: '100%'}}>{`$${(data.non_member_orders*-1).toFixed(2).toString()}`}<br/><sub>{data.non_member_orders_count} orders</sub></span>, numeric: false },
            // { value: <span style={{textAlign: 'right', width: '100%'}}>{`$${(data.member_orders*-1).toFixed(2).toString()}`}<br/><sub>{data.member_orders_count} orders</sub></span>, numeric: false },
            //
            // { value: <span style={{textAlign: 'right', width: '100%'}}>{`$${(data.order_refunds*-1).toFixed(2).toString()}`}<br/><sub>{data.order_refunds_count || 0} refunds</sub></span>, numeric: false },
            // { value: ("($" + (data.promo_code_discounts.toFixed(2)).toString() + ")"), numeric: true },
            // { value: ("($" + ((data.membership_discounts).toFixed(2)).toString() + ")"), numeric: true },
            // { value: ("$" + (data.tip*-1).toFixed(2).toString()), numeric: true },
            // { value: ("$" + (data.tax*-1).toFixed(2).toString()), numeric: true },
            // { value: ("$" + (data.total*-1).toFixed(2).toString()), numeric: true },
            // { value: ("($" + ((data.fee)*-1).toFixed(2).toString() + ")"), numeric: true },
            // { value: <span>{`$${(data.membership_fees*-1).toFixed(2).toString()}`}<br/><sub>{data.membership_count  || 0} members</sub></span>, numeric: false },
            // { value: <span>{`($${(data.membership_refunds).toFixed(2).toString()})`}<br/><sub>{data.membership_refund_count  || 0} refunds</sub></span>, numeric: false },
            // { value: ("($" + (data.membership_service_fee * -1).toFixed(2).toString() + ")"), numeric: true },

        ];
        return resultArray;
    }
    formatDate(date){
        return ((new Date(date)).toLocaleDateString("en-US", {timeZone: 'UTC'}))
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    }


    render() {
        const columnData = [
            { id: 'date', numeric: false, disablePadding: true, label: 'Date' },
            { id: 'count', numeric: true, disablePadding: false, label: 'Transaction Count' },
            { id: 'subtotal', numeric: true, disablePadding: false, label: 'Subtotal' },
            { id: 'tax', numeric: true, disablePadding: false, label: 'Tax' },
            { id: 'tip', numeric: true, disablePadding: false, label: 'Tip' },
            { id: 'discounts', numeric: true, disablePadding: false, label: 'Discounts' },
            { id: 'total', numeric: true, disablePadding: false, label: 'Total' },
            { id: 'fees', numeric: true, disablePadding: false, label: 'Fees' },
            { id: 'paid', numeric: true, disablePadding: false, label: 'Grand Total: Paid' },
        ];
        let dv = new DatabaseValidator();
        let dataVis;
        let showDownloadButton = false;

        if(this.props.payoutTableData.length > 0){
            showDownloadButton = true
        }

        if (dv.isEmpty(this.props.payoutTableData)) {
            dataVis = <LoadingTable />
        } else {
            dataVis = <DataTable title="Payout History" columnData={columnData} dataFormatter={this.formatPayoutData} data={this.props.payoutTableData}
                onChangePage={this.handleChangePage}
                page={this.state.page} />
        }
        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <ContainerHeader match={this.props.match} title="Payouts" />
                <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                    {dataVis}
                </CardBox>

                { showDownloadButton && <div className="p-3">
                        <ExcelFile element={<button className="btn btn-primary">Download Payouts Data</button>} filename="PayoutsData">
                            <ExcelSheet data={this.getExcelData()} name="Orders">
                                <ExcelColumn label="Date" value="date"/>
                                <ExcelColumn label="Transaction Count" value="count"/>
                                <ExcelColumn label="Subtotal" value="subtotal"/>
                                <ExcelColumn label="Tax" value="tax"/>
                                <ExcelColumn label="Tip" value="tip"/>
                                <ExcelColumn label="Discounts" value="discounts"/>
                                <ExcelColumn label="Total" value="total"/>
                                <ExcelColumn label="Fees" value="fees"/>
                                <ExcelColumn label="Grand Total: Paid" value="paid"/>
                            </ExcelSheet>
                        </ExcelFile>
                    </div> }
            </div>
        );
    }
    getExcelData() {
        return this.props.payoutTableData.map(data => {
            let week = {
                date: `${(moment(new Date(data.date).toLocaleDateString("en-US", {timeZone: 'UTC'}))).subtract(7, 'days').format("M/DD")} - ${(moment(data.date)).subtract(1, 'days').toDate().toLocaleDateString("en-US", {timeZone: 'UTC'})}`,
                count: data.new.count,
                subtotal: (data.new.subtotal*-1).toFixed(2),
                tax: (data.tax*-1).toFixed(2),
                tip: (data.tip*-1).toFixed(2),
                discounts: (data.new.discounts*-1).toFixed(2),
                total: (data.new.total*-1).toFixed(2),
                fees: (data.new.fee).toFixed(2),
                paid: (data.new.paid * -1).toFixed(2),
            }
            return week;
        })
    }

}
const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        payoutTableData: state.analytics.payoutTableData
    }
}

const actionCreators = {
    getPayoutTableData

}

export default connect(
    mapStateToProps,
    actionCreators
)(Payouts);
