import moment from 'moment'

export default class GraphFormatter {
    moneyFormatter = (value) => {
        let newVal = "$" + (value).toString();
        return ([newVal]);
    }

    timeFormatter = (value) => {
        return moment.utc(value, "h").local().format("ha")
    }

    formatSalesData = (data) => {
        let resultArray = [
            { value: data.name, numeric: false },
            { value: (data.count.toString()), numeric: true },
            { value: ("$" + data.gross.toFixed(2).toString()), numeric: true }
        ];
        return resultArray;
    }

    formatModifierData = (data) => {
        let resultArray = [
            { value: data.name, numeric: false },
            { value: (data.count.toString()), numeric: true },
            //gross is currently just always zeros
            // { value: ("$" + data.gross.toFixed(2).toString()), numeric: true }
        ];
        return resultArray;
    }
}