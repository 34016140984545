import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { UNIT_PERCENT, discountUnitTypes } from './DiscountUnitTypes';
import Button from '@material-ui/core/Button';
import CustomScrollbars from '../../../util/CustomScrollbars';
import DataTable from '../analytics/util/DataTable';
import { baseUrl } from '../../../constants/baseUrl';
import selectors from '../../../reducers/selectors';
import moment from 'moment';

class PromoCodeUsage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promoCodes:[],
            isLoading: false,
            page: 0,
            promoCodesCopy:[],
            code: '',
            userName: '',
            value: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSearchClicked = this.onSearchClicked.bind(this)
        this.onClearFiltersClicked = this.onClearFiltersClicked.bind(this)
    }

    componentDidMount() {
        this.getPromoCodeUsage(this.props.shopId)
    }

    getPromoCodeUsage = (shopId) => {
        this.setState({ isLoading: true })
        fetch(baseUrl + '/promo_code_usage?shopId=' + shopId, {
            method: 'GET',
            headers: {Authorization: `Bearer ${this.props.access_token}`}
        })
        .then(result => result.json())
        .then(result => {
            this.setState({
                promoCodes: this.sortDesc(result, 'promoCodeId'),
                promoCodesCopy: this.sortDesc(result, 'promoCodeId'),
                isLoading: false
            })
        })
    }

    sortAsc = (arr, field) => {
        return arr.sort((a, b) => {
          if (a[field] > b[field]) { return 1; }
          if (b[field] > a[field]) { return -1; }
          return 0;
        })
    }

    sortDesc = (arr, field) => {
        return arr.sort((a, b) => {
          if (a[field] > b[field]) { return -1; }
          if (b[field] > a[field]) { return 1; }
          return 0;
        })
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    }

    formatPromoCodesData(data) {
        let arrayResult = [
            { value: data.promoCodeId, numeric: true },
            { value: data.code, numeric: false },
            { value: (data.valueUnit === UNIT_PERCENT ? '%' : '$'), numeric: false },
            { value: (data.valueUnit === UNIT_PERCENT ? data.value + '%' : '$' + data.value), numeric: true },
            { value: (data.promoCodeCreatedDate != null ? moment(data.promoCodeCreatedDate).format("MM/DD/YYYY") : data.promoCodeCreatedDate), numeric: false },
            { value: data.userId, numeric: true },
            { value: data.userName, numeric: false },
            { value: data.shopId, numeric: true },
            { value: data.shopName, numeric: false },
            { value: data.orderId, numeric: true },
            { value: (data.orderTotal != null ? '$' + data.orderTotal : data.orderTotal), numeric: true },
            { value: (data.orderDiscountAmount != null ? '$' + data.orderDiscountAmount : data.orderDiscountAmount), numeric: true },
            { value: (data.orderDateTime != null ? moment(data.orderDateTime).format("MM/DD/YYYY") : data.orderDateTime), numeric: false }
        ];
        return arrayResult;
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    onSearchClicked() {
        console.log('code:'+ this.state.code)
        console.log('userName:'+ this.state.userName)
        console.log('value:'+ this.state.value)

        var filteredCodes = []
        if(this.state.code.length==0 && this.state.userName.length==0 && this.state.value.length==0){
            return;
        }else if(this.state.code.length>0 && this.state.userName.length==0 && this.state.value.length==0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.code.toLowerCase() == this.state.code.toLowerCase())
        }else if(this.state.code.length==0 && this.state.userName.length>0 && this.state.value.length==0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.userName.toLowerCase().includes(this.state.userName.toLowerCase()))
        }else if(this.state.code.length==0 && this.state.userName.length==0 && this.state.value.length>0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.value == this.state.value)
        }else if(this.state.code.length>0 && this.state.userName.length>0 && this.state.value.length==0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.code.toLowerCase() == this.state.code.toLowerCase() && c.userName.toLowerCase().includes(this.state.userName.toLowerCase()))
        }else if(this.state.code.length>0 && this.state.userName.length==0 && this.state.value.length>0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.code.toLowerCase() == this.state.code.toLowerCase() && c.value == this.state.value)
        }else if(this.state.code.length==0 && this.state.userName.length>0 && this.state.value.length>0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.userName.toLowerCase().includes(this.state.userName.toLowerCase()) && c.value == this.state.value)
        }else if(this.state.code.length>0 && this.state.userName.length>0 && this.state.value.length>0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.code.toLowerCase() == this.state.code.toLowerCase() && c.userName.toLowerCase().includes(this.state.userName.toLowerCase()) && c.value == this.state.value)
        }
        this.setState({ promoCodes: filteredCodes});
    }

    onClearFiltersClicked() {
        this.setState({ code: '', userName: '', value: '', promoCodes: this.state.promoCodesCopy })
    }

    render() {
        const columnData = [
            { id: 'promoCodeId', numeric: true, disablePadding: false, label: 'Id' },
            { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
            { id: 'valueUnit', numeric: false, disablePadding: false, label: 'Unit' },
            { id: 'value', numeric: true, disablePadding: false, label: 'Value' },
            { id: 'promoCodeCreatedDate', numeric: false, disablePadding: false, label: 'CreatedDate' },
            { id: 'userId', numeric: true, disablePadding: false, label: 'UserId' },
            { id: 'userName', numeric: false, disablePadding: false, label: 'UserName' },
            { id: 'shopId', numeric: true, disablePadding: false, label: 'ShopId' },
            { id: 'shopName', numeric: false, disablePadding: false, label: 'ShopName' },
            { id: 'orderId', numeric: true, disablePadding: false, label: 'OrderId' },
            { id: 'orderTotal', numeric: true, disablePadding: false, label: 'OrderTotal' },
            { id: 'orderDiscountAmount', numeric: true, disablePadding: false, label: 'OrderDiscountAmount' },
            { id: 'orderDateTime', numeric: false, disablePadding: false, label: 'UsedOn' },
        ];
        return (
            <div className="app-wrapper">
                <div className="animated slideInUpTiny animation-duration-3">
                    <div className="app-module">
                        <div className="app-module-sidenav d-none d-xl-flex">
                            <div className="module-side">
                                <div className="module-side-header">
                                    <div className="module-logo">
                                        <i className="zmdi zmdi-label mr-4"/>
                                        <span>Code Usage</span>
                                    </div>
                                </div>
                                <div className="module-side-content">
                                    <div className="col-md-12 col-12 p-4">
                                        <div>
                                            <TextField
                                                label="Promo Code"
                                                value={this.state.code}
                                                onChange={this.handleChange('code')}
                                                inputProps={{ maxLength: 20 }}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                label="User Name"
                                                value={this.state.userName}
                                                onChange={this.handleChange('userName')}
                                                inputProps={{ maxLength: 25 }}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                label="Discount Value"
                                                value={this.state.value}
                                                onChange={this.handleChange('value')}
                                                placeholder="0.00"
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <Button
                                                variant="raised"
                                                color="primary"
                                                className="jr-btn"
                                                onClick={this.onSearchClicked}>
                                                Search
                                            </Button>
                                            <Button
                                                variant="raised"
                                                color="primary"
                                                className="jr-btn"
                                                onClick={this.onClearFiltersClicked}>
                                                Clear
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="module-box">
                            <div className="module-box-header">
                            </div>
                            <div className="module-box-content">
                                <CustomScrollbars className="module-list-scroll scrollbar" style={{height: this.props.width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                                    <div className="animated slideInUpTiny animation-duration-3">
                                        <DataTable
                                            // title="Promo Code List"
                                            data={this.state.promoCodes}
                                            dataFormatter={this.formatPromoCodesData}
                                            columnData={columnData}
                                            isLoading={this.state.isLoading}
                                            onChangePage={this.handleChangePage}
                                            page={this.state.page} />
                                    </div>
                                </CustomScrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        width: selectors(state).width,
        shopId: selectors(state).shopId,
        access_token: state.auth.access_token
    }
}
export default connect(
    mapStateToProps,
)(PromoCodeUsage);
