
export default class DatabaseValidator {

    isEmpty(obj) {
        for (var props in obj) {
            if (obj.hasOwnProperty(props)) {
                return false;
            }
        }
        return true;
    }

    getLastCompleteWeek() {
        var firstDayWeek = new Date();
        firstDayWeek.setUTCHours(0, 0, 0, 0); //getting midnight
        var day = firstDayWeek.getDay() || 7; //1 is Monday - 7 is Sunday
        if (day !== 1) {
            firstDayWeek.setUTCHours(-24 * (day)) //Getting monday of this week
        }
        firstDayWeek.setUTCHours(-24 * 7) //Getting the monday of last week

        var lastDayWeek = new Date(firstDayWeek);
        lastDayWeek.setUTCHours(24 * 7); //finding the last of the week 
        const paramObjWeek = {
            dateFirst: (firstDayWeek / 1000),
            dateSecond: (lastDayWeek / 1000)
        }
        return (paramObjWeek);
    }

}