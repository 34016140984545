import React, { Component } from "react";
import ReasonCard from './ReasonCard'
import cashMoney from '../../assets/icons/attach_money.svg'
import users from '../../assets/icons/user_circle.svg'
import upAndUp from '../../assets/icons/trending_up.svg'
import moneyGrowth from '../../assets/icons/money-growth.png'
import speedometer from '../../assets/icons/speedometer.png'
import support from '../../assets/icons/support.png'
import SectionHeader from './SectionHeader';

class WhyMojo extends Component {
    render() {
        const reasons = [
            {
                reasonIcon: moneyGrowth,
                textTitle: 'More Revenue',
                textBody: 'CoffeePass makes it easy for customers to order more often. Customers aren’t forced to choose between convenient and local'
            },
            {
                reasonIcon: support,
                textTitle: 'Better Customer Experience',
                textBody: 'Your customers have tried order-ahead at a big chain, and would love the same convenience from you. CoffeePass decreases a customer’s average wait time, improving the experience for those who aren’t even using the platform!'
            },
            {
                reasonIcon: speedometer,
                textTitle: 'More Efficient',
                textBody: 'CoffeePass reduces the amount of time it takes a barista to complete an order. It also allows more orders to be placed simultaneously, increasing overall efficiency'
            }
        ];

        const reasonsView = reasons.map((reasons) => {
            return <ReasonCard key={reasons.textTitle}
                reasonIcon={reasons.reasonIcon}
                textTitle={reasons.textTitle}
                textBody={reasons.textBody} />
        });
        return (
            <div className="blockWrapper whyMojoWrapper">
                <SectionHeader title="Why CoffeePass is right for you" />
                <div className="cardWrapper">
                    {reasonsView}
                </div>
            </div>
        );
    }
}

export default WhyMojo
