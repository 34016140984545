import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';

class TextFieldValidator extends Component {

    render() {
        const { errorMessage, ...rest } = this.props;
        const isError = errorMessage != undefined && errorMessage != ""
        
        return (
            <div>
                <TextField
                    {...rest}
                    error={isError}
                />
                {(isError)?
                    <div style={styles.errorText}>
                        {errorMessage}
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

const styles = {
    errorText: {
        left: 13,
        fontSize: '12px',
        color: '#ff0000',
        position: 'absolute',
        marginTop: '-8px',
    }
}

export default TextFieldValidator;