import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import './../../util/utilComp.css'
import CircularProgress from '@material-ui/core/CircularProgress';

let counter = 0;

class DataTableHead extends Component {
    static propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.string.isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
        columnData: PropTypes.object.isRequired

    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
        return (
            <TableHead>
                <TableRow>
                    {this.props.columnData.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                numeric={column.numeric}
                                padding={column.disablePadding ? 'none' : 'default'}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}


class DataTableToolbar extends Component {
    render() {
        const { numSelected } = this.props.numSelected;
        return (
            <Toolbar
                className={classNames("table-header")}
            >
                <div className="title">
                    <Typography type="title">{this.props.title}</Typography>
                </div>
                <div className="spacer" />
            </Toolbar>
        );
    }
}

DataTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
};


class NestedDataTable extends Component {
    createDefaultSortHandler = event => {
        this.handleRequestSort(event, 'gross');
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'asc';
        if (this.state.orderBy === property && this.state.order === 'asc') {
            order = 'desc';
        }
        let data =
            order === 'desc'
                ? this.props.data['addonData'].sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                : this.props.data['addonData'].sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ data, order, orderBy });
    };


    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState({ selected: this.props.data.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };
    handleKeyDown = (event, id) => {
        if (keycode(event) === 'space') {
            this.handleClick(event, id);
        }
    };
    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    isSelected = id => this.state.selected.indexOf(id) !== -1;

    constructor(props, context) {
        super(props, context);

        this.state = {
            order: 'desc',
            orderBy: '',
            selected: [],
            rowsPerPage: 10,
        };

        this.createDefaultSortHandler();

    }

    render() {
        const { order, orderBy, selected, rowsPerPage } = this.state;
        const { page } = this.props;
        const data = this.props.data;
        let parentRows = [];
        for (let item in data.addonData) {
            //build parent row
            let d = this.props.dataFormatter(data.addonData[item]);
            parentRows.push(<TableRow
                hover
                onClick={event => this.handleClick(event, d.id)}
                onKeyDown={event => this.handleKeyDown(event, d.id)}
                role="checkbox"
                aria-checked={this.isSelected}
                tabIndex={-1}
                key={d.id}
                selected={this.isSelected}
            >
                {d.map((cell) => {
                    return <TableCell className="parentRow" numeric={cell.numeric}>{cell.value}</TableCell>
                })}
            </TableRow>)
            let currItemOptions = data['typeData'][data.addonData[item].name]
            for (let option in currItemOptions) {
                let o = this.props.dataFormatter(currItemOptions[option]);
                //build child row
                parentRows.push(<TableRow
                                    hover
                                    onClick={event => this.handleClick(event, o.id)}
                                    onKeyDown={event => this.handleKeyDown(event, o.id)}
                                    role="checkbox"
                                    aria-checked={this.isSelected}
                                    tabIndex={-1}
                                    key={o.id}
                                    selected={this.isSelected} >
                    {o.map((cell) => {
                        return <TableCell numeric={cell.numeric}>{cell.value}</TableCell>
                    })}
                </TableRow>)
            }
        }
        return (
            <Paper>
                <DataTableToolbar numSelected={selected.length} title={this.props.title} />
                <div className="flex-auto">
                    {(data.length == 0) ?
                        <span style={styles.noOrders}>No orders for this date</span>
                        :
                        <div className="table-responsive-material tableCard" style={{ marginRight: 10, marginLeft: 10 }}>
                            <Table style={{ marginLeft: 0 }}>
                                <DataTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    columnData={this.props.columnData}
                                />
                                <TableBody>
                                    {parentRows}
                                </TableBody>
                            </Table>
                            {/* <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.props.onChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                                </TableRow>
                            </TableFooter> */}
                        </div>
                    }
                </div>
            </Paper>

        );
    }
}

const styles = {
    progress: {
        display: 'flex',
        margin: 200,
        paddingBottom: 100,
        flexDirection: 'column',
        alignItems: 'center'
    },
    noOrders: {
        fontSize: 16,
        display: 'flex',
        marginTop: 100,
        paddingBottom: 100,
        flexDirection: 'column',
        alignItems: 'center'
    }
}

export default NestedDataTable;