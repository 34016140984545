
import React, {Component} from 'react';
import { FormControlLabel, TextField } from '@material-ui/core';
import { getShopUserAction } from '../../../actions/ShopInfo';
import { connect } from 'react-redux';
import selectors from '../../../reducers/selectors';

class ShopUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            name: '',
            isChainShop: false,
            fee: 0.0,
            membershipEnabled: false,
            membershipId: null
        }

        this.stateCopy = Object.assign(this.state, {})

        this.props.getShopUserAction(this.props.shopId,
            (resp) => {
                if(resp.val != false) {
                    this.stateCopy = Object.assign(this.stateCopy, resp, {})
                    this.setState(resp)
                }
            },
            (error) => {
                console.log("get shop user error", error)
            }
        )
    }


    render(props) {
        return(
                <div>
                    <div>
                        <h3>Email: {this.state.email}</h3>
                    </div>
                    <div>
                        <h3>Name: {this.state.name}</h3>
                    </div>
                    <div>
                        <h3>Is shop chain : {this.state.isChainShop? "Yes" : "No"}</h3>
                    </div>
                    <div>
                        <h3>Fee: {this.state.fee}</h3>
                    </div>
                </div>
            )
    }
}


const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    getShopUserAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(ShopUser);