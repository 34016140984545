import React from 'react';
import UserDetailCell from './UserDetailCell';
import NoDataCard from './NoDataCard';
import DatabaseValidator from './../../../util/DatabaseValidator';

const UserDetailTable = ({ data }) => {
    const dbv = new DatabaseValidator();
    const dataVis = dbv.isEmpty(data) ? <NoDataCard /> : data.map(data => { return (<UserDetailCell key={data.id} data={data} />); });
    return (
        <div className="table-responsive-material">
            <table className="default-table table-sm table remove-table-border mb-0 ">
                <tbody>
                    {dataVis}
                </tbody>
            </table>
        </div>
    );
};

export default UserDetailTable;