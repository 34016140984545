import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './App.css'

class StepBlock extends Component {
    render() {
        return (
            <div className={this.props.className}>
                {/* <div className={this.props.className}> */}
                <span className='col'></span>
                <div className="stepGenericText col-md-3 col-9">
                    <div className="numberCircle">{this.props.index}</div>
                    <div className="stepGenericBody"><p>{this.props.body}</p></div>
                </div>
                <span className='col'></span>
                <div className="stepGenericImage col-md-6 col-12">
                    <img src={this.props.imageURL} className="stepByStepIcon" alt="image" />
                </div>
                {/* </div> */}
            </div>
        );
    }

}

export default StepBlock;