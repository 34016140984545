import { analyticsConstants } from "../constants/ActionTypes";
import { baseUrl } from '../constants/baseUrl';

export const getAddonSalesData = (paramObj, onSuccess, onFailure) => ({
    type: analyticsConstants.GET_ADDON_SALES_DATA,
    payload: paramObj,
    meta: {
        type: 'apijson',
        url: baseUrl + '/addon_sales',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
            date_first: paramObj.dateFirst,
            date_second: paramObj.dateSecond,
        },
        options: {
            method: 'GET'
        }
    }
})