
import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CustomScrollbars from "../../../../util/CustomScrollbars";
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import { getDefaultOptionsAction } from '../../../../actions/Menu';
import { types } from '../../../../constants/ActionTypes';
import { connect } from 'react-redux';
import selectors from '../../../../reducers/selectors';

const DragHandle = SortableHandle(() =>
    <i className="zmdi zmdi-menu draggable-icon d-none d-sm-flex" style={{fontSize: 25}}/>);

class DefaultOptionsList extends Component {
    constructor(props) {
        super(props)

        this.props.getDefaultOptionsAction(types.GET_DEFAULT_OPTIONS_MENU,
            {
                shopId: this.props.shopId
            })
    }

    isModifierUsed(modifier) {
        for(var k = 0; k < this.props.options.length; k++) {
            var option = this.props.options[k]
            if(option.menuDefaultOptionId == modifier.menuDefaultOptionId) {
                return true
            }
        }
        return false
    }

    render() {
        console.log('option', this.props.options)
        return (
            <div>
                <div style={styles.borderBottom}>
                    <span style={styles.text}>
                        Default Modifiers
                    </span>
                </div>
                <Droppable droppableId="default-droppable-id">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} className="module-list">
                            <CustomScrollbars className="module-list-scroll scrollbar"
                                            style={{height: this.props.width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                                {this.props.defaulModifiersMenu.map((modifier, index) => {
                                    if(this.isModifierUsed(modifier)) {
                                        return
                                    }
                                    return (<Draggable key={index} draggableId={modifier.menuOptionId} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <div className="row" style={styles.listItem}>
                                                    <div className="module-list-icon col-1">
                                                        <span className="bar-icon">
                                                            <DragHandle/>
                                                        </span>
                                                    </div>
                                                    <div style={styles.marginLeft} className="col-10">
                                                        {modifier.title}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>)
                                })}
                            </CustomScrollbars>
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

const styles = {
    listItem: {
        alignItems: 'center',
        padding: '15px 28px',
        borderBottom: 'solid 1px #e9ecef',
        position: 'relative',
        cursor: 'pointer'
    },
    marginLeft: {
        marginLeft: 8
    },
    text: {
        fontSize: 16,
        marginLeft: 10
    },
    borderBottom: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        borderBottom: 'solid 1px #e9ecef'
    }
}

// DefaultOptionsList.Proptypes = {
// }

const mapStateToProps = state => {
    return {
        defaulModifiersMenu: state.menu.defaultModifiersMenu,
        shopId: selectors(state).shopId,
        options: state.menu.payload.categories[state.menuBuilder.drinkOrFood].items[state.menuBuilder.currentItemIndex].options

    }
}

const actionCreators = {
    getDefaultOptionsAction
}

export default connect(mapStateToProps, actionCreators)(DefaultOptionsList)
