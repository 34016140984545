import React, {Component} from 'react';
import Benefit from './Benefit';
import Button from '@material-ui/core/Button';

const BenefitList =(({memberBenefits, width, addBenefit, onDeleteBenefit, onChangeBenefit, onClickImage}) => {
    return (
        <div>
            <h4>Member Benefits</h4>
            <div>
                {memberBenefits.length > 0 && memberBenefits.map((benefit, index) =>
                        <Benefit key={index}
                            i={index}
                            benefit={benefit}
                            onDeleteBenefit={(idx) => onDeleteBenefit(idx)}
                            onChangeBenefit={(idx, value) => onChangeBenefit(idx, value)}
                            onClickImage={onClickImage}
                        />
                    )
                }
            </div>
            <div style={{textAlign:'right'}}>
                <Button color="primary" onClick={addBenefit} style={{marginTop: 5, marginBottom: 10}}>
                    + Add Another
                </Button>
            </div>
        </div>
    )
})

export default BenefitList;