
import { baseUrl } from '../constants/baseUrl';
import { NOT_IN_STORE, 
        UPDATE_ITEM_INDEX,
        DRINK_OR_FOOD,
        FIRST_MENU_LOAD,
        UPDATE_DEFAULT_MODIFIER_INDEX,
        types } from '../constants/ActionTypes';

export const updateCurrentItemAction = (index) => ({
    type: UPDATE_ITEM_INDEX,
    payload: index,
})

export const updateDrinkOrFoodAction = (drinkOrFood) => ({
    type: DRINK_OR_FOOD,
    payload: drinkOrFood,
})

export const firstMenuLoadAction = () => ({
    type: FIRST_MENU_LOAD,
    payload: {},
})

export const firstDefaultModifiersLoadAction = () => ({
    type: types.FIRST_DEFAULT_MODIFIERS,
    payload: {},
})

export const updateDefaultModifierIndexAction = (index) => ({
    type: UPDATE_DEFAULT_MODIFIER_INDEX,
    payload: index,
})

export const updateShowItemDescriptionAction = (isShow) => ({
    type: types.SHOW_ITEM_DESCRIPTION,
    payload: isShow,
})

export const updateShowItemDiscountAction = (isShow) => ({
    type: types.SHOW_ITEM_DISCOUNT,
    payload: isShow,
})