import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import '../analytics/util/utilComp.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

let counter = 0;

class DataTableHead extends Component {
    static propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.string.isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
        columnData: PropTypes.object.isRequired,
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
        return (
            <TableHead>
                <TableRow>
                    {this.props.columnData.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                numeric={column.numeric}
                                padding={column.disablePadding ? 'none' : 'default'}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}


class DataTableToolbar extends Component {
    render() {
        const { numSelected } = this.props.numSelected;
        return (
            <Toolbar className={classNames("table-header")}>
                <div className="title">
                    <Typography type="title">{this.props.title}</Typography>
                </div>
                <div className="spacer" />
            </Toolbar>
        );
    }
}

DataTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
};


class DataTable extends Component {
    createDefaultSortHandler = event => {
        this.handleRequestSort(event, 'lastUpdated');
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'asc';
        if (this.state.orderBy === property && this.state.order === 'asc') {
            order = 'desc';
        }
        let data;
        if (orderBy == 'lastUpdated') {
            data =
                order === 'desc'
                    ? this.props.data.sort((a, b) => ((new Date(a[orderBy])).getTime() - (new Date(b[orderBy])).getTime() < 0 ? -1 : 1))
                    : this.props.data.sort((a, b) => ((new Date(b[orderBy])).getTime() - (new Date(a[orderBy])).getTime() < 0 ? -1 : 1));
        } else {
            data =
                order === 'desc'
                    ? this.props.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                    : this.props.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        }

        this.setState({ data, order, orderBy });
    };


    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState({ selected: this.props.data.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };
    handleKeyDown = (event, id) => {
        if (keycode(event) === 'space') {
            this.handleClick(event, id);
        }
    };
    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };
    isSelected = id => this.state.selected.indexOf(id) !== -1;

    constructor(props, context) {
        super(props, context);

        this.state = {
            order: 'desc',
            orderBy: 'lastUpdated',
            selected: [],
            rowsPerPage: 25,
        };

        this.createDefaultSortHandler();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data !== prevProps.data) {
            this.createDefaultSortHandler();
        }
    }

    memberClick = (event, memberData) => {
        //console.log(memberData.membership.id);
    }

    render() {
        const { order, orderBy, selected, rowsPerPage } = this.state;
        const { page } = this.props;
        const data = this.props.data;
        return (
            <Paper>
                <DataTableToolbar numSelected={selected.length} title={this.props.title} />
                <div className="flex-auto">
                    {(data.length == 0) ?
                        <span style={styles.noMembers}>No data found</span>
                        :
                        <div className="table-responsive-material tableCard" style={{ marginRight: 10, marginLeft: 10 }}>
                            <Table style={{ marginLeft: 0, tableLayout: 'auto', whiteSpace: 'nowrap'}}>
                                <DataTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    columnData={this.props.columnData}
                                />
                                <TableBody>
                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                        const isSelected = this.isSelected(n.id);
                                        let d = this.props.dataFormatter(n);
                                        return (
                                            <TableRow
                                                hover
                                                onClick={event => this.handleClick(event, n.id)}
                                                onKeyDown={event => this.handleKeyDown(event, n.id)}
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.id}
                                                selected={isSelected}
                                            >
                                                {d.map((cell, index) => {
                                                    if(index === 6){
                                                        return <TableCell numeric={cell.numeric}><Link to={"/app/member_details/?id=" + cell.value} onClick={event => this.memberClick(event, n)}>See details</Link></TableCell>
                                                    }else if(index === 7){
                                                        var actionText = ''
                                                        switch(cell.value){
                                                            case 'pending':
                                                                return <TableCell numeric={cell.numeric}>
                                                                        <Button disabled={this.props.buttonsDisabled} color="primary" className="jr-btn" onClick={() => this.props.onActionChangeStatus('approved', n, false)}>Approve</Button>
                                                                        <Button disabled={this.props.buttonsDisabled} color="primary" className="jr-btn" onClick={() => this.props.onActionChangeStatus('declined', n, false)}>Decline</Button>
                                                                    {this.props.freeStripePriceId !== null && <Button color="primary" className="jr-btn" onClick={() => this.props.onActionChangeStatus('approved', n, true)}>Approve Free</Button>}
                                                                    </TableCell>
                                                                break;
                                                            case 'request cancelled':
                                                                return <TableCell numeric={cell.numeric}>
                                                                        <Button disabled={this.props.buttonsDisabled} color="primary" className="jr-btn" onClick={() => this.props.onActionChangeStatus('approved', n, false)}>Cancel cancellation</Button>
                                                                    </TableCell>
                                                                break;
                                                            case 'declined':
                                                                return <TableCell numeric={cell.numeric}>
                                                                        <Button disabled={this.props.buttonsDisabled} color="primary" className="jr-btn" onClick={() => this.props.onActionChangeStatus('approved', n, false)}>Approve</Button>
                                                                    </TableCell>
                                                                break;
                                                            case 'approved':
                                                                return <TableCell numeric={cell.numeric}>
                                                                        {/* <a href="javascript:void(0)" onClick={event => this.memberClick(event, n)}>Cancel membership</a> */}
                                                                        <Button disabled={this.props.buttonsDisabled} color="primary" className="jr-btn" onClick={() => this.props.onActionChangeStatus('membership cancelled by shop', n, false)}>Cancel membership</Button>
                                                                    </TableCell>
                                                                break;
                                                            default:
                                                                return <TableCell numeric={cell.numeric}></TableCell>
                                                                break;
                                                        }
                                                    }else{
                                                        var statusToShow = ""
                                                        switch(cell.value){
                                                            case "pending":
                                                                statusToShow = "Pending approval"
                                                                break;
                                                            case "request cancelled":
                                                                statusToShow = "Request cancelled"
                                                                break;
                                                            case "declined":
                                                                statusToShow = "Request declined"
                                                                break;
                                                            case "approved":
                                                                statusToShow = "Active"
                                                                break;
                                                            case "membership cancelled by customer":
                                                                statusToShow = "Membership cancelled by customer"
                                                                break;
                                                            case "membership cancelled by shop":
                                                                statusToShow = "Membership cancelled by shop"
                                                                break;
                                                            default:
                                                                statusToShow = cell.value
                                                                break;
                                                        }
                                                        // return <TableCell numeric={cell.numeric}>{cell.value}</TableCell>
                                                        return <TableCell numeric={cell.numeric}>{statusToShow}</TableCell>
                                                    }
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.props.onChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </div>
                    }
                </div>
            </Paper>

        );
    }
}

const styles = {
    progress: {
        display: 'flex',
        margin: 200,
        paddingBottom: 100,
        flexDirection: 'column',
        alignItems: 'center'
    },
    noMembers: {
        fontSize: 16,
        display: 'flex',
        marginTop: 100,
        paddingBottom: 100,
        flexDirection: 'column',
        alignItems: 'center'
    }
}

export default DataTable;
