import React, { Component } from 'react';
import CustomerRecencyTable from './CustomerRecencyTable';
import { getCustomerRecencyDayAction } from '../../../../../../actions/CustomerRecency/CustomerRecencyDay';
import { connect } from 'react-redux';
import { getCustomerRecencyWeekAction } from './../../../../../../actions/CustomerRecency/CustomerRecencyWeek';
import { getCustomerRecencyMonthAction } from './../../../../../../actions/CustomerRecency/CustomerRecencyMonth';
import { getCustomerRecencyYearAction } from './../../../../../../actions/CustomerRecency/CustomerRecencyYear';
import NoDataCard from '../NoDataCard';
import DatabaseValidator from '../../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangeHelper from '../../../../util/DateRangeHelper';
import selectors from '../../../../../../reducers/selectors';

class CustomerRecency extends Component {

    constructor(props) {
        super(props)
        const dateRangeHelper = new DateRangeHelper()

        this.props.getCustomerRecencyDayAction(Object.assign(dateRangeHelper.getLastDay(), {
            shopId: this.props.shopId
        }))
        this.props.getCustomerRecencyWeekAction(Object.assign(dateRangeHelper.getLastWeek(), {
            shopId: this.props.shopId
        }))
        this.props.getCustomerRecencyMonthAction(Object.assign(dateRangeHelper.getLastMonth(), {
            shopId: this.props.shopId
        }))
        this.props.getCustomerRecencyYearAction(Object.assign(dateRangeHelper.getLastYear(), {
            shopId: this.props.shopId
        }))
    }

    render() {

        var dataVis = '';
        var dbv = new DatabaseValidator();

        if (!dbv.isEmpty(this.props.customerRecencyDay) && !dbv.isEmpty(this.props.customerRecencyWeek) && !dbv.isEmpty(this.props.customerRecencyMonth) && !dbv.isEmpty(this.props.customerRecencyYear)) {
            var Day = this.props.customerRecencyDay
            if (typeof Day === 'object')
                Day['name'] = 'Day';
            var Week = this.props.customerRecencyWeek
            if (typeof Week === 'object')
                Week['name'] = 'Week';
            var Month = this.props.customerRecencyMonth
            if (typeof Month === 'object')
                Month['name'] = 'Month';
            var Year = this.props.customerRecencyYear
            if (typeof Year === 'object')
                Year['name'] = 'Year';
            var data = [Day, Week, Month, Year]
            dataVis = <CustomerRecencyTable data={data} />
        } else {
            dataVis = <div className="text-center"><CircularProgress size={50} /></div>

        }


        return (
            <div className="col-xl-4 col-lg-12 col-md-12 col-12">
                <div className="jr-card">
                    <div className="jr-card-header d-flex align-items-center mb-3">
                        <h3 className="card-heading mb-0"><i
                            className="zmdi zmdi-chart-donut zmdi-hc-fw text-primary text-lighten-2 mr-2" />
                            Customer Recency
                            </h3>
                    </div>
                    <div className="freqTable">
                        {dataVis}
                    </div>
                    <hr />
                    <div className="jr-card-body text-muted text-center">
                        <p>How many of your customers ordered in the last year, month, week, and day.</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        customerRecencyDay: state.analytics.customerRecencyDay,
        customerRecencyWeek: state.analytics.customerRecencyWeek,
        customerRecencyMonth: state.analytics.customerRecencyMonth,
        customerRecencyYear: state.analytics.customerRecencyYear
    }
}

const actionCreators = {
    getCustomerRecencyDayAction,
    getCustomerRecencyWeekAction,
    getCustomerRecencyMonthAction,
    getCustomerRecencyYearAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(CustomerRecency);