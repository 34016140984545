import React, { Component } from 'react';
import DatabaseValidator from './../../util/DatabaseValidator';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';
import { connect } from 'react-redux';
import GraphFormatter from './GraphFormatter';

class TopFiveGraph extends Component {
    render() {
        let graphFormatter = new GraphFormatter();
        let dv = new DatabaseValidator();
        let dataVis = ''
        let colors = ['#8884d8', '#82CA9D', '#EE8434', '#C6AF00', '#2e4057']
        if (!dv.isEmpty(this.props.data)) {
            dataVis = Object.keys(this.props.data[0]).map(key => {
                if (key != 'hour') {
                    return this.createLine(key, colors.pop())
                }
            })
        }
        let toolTipYaxis = this.props.isMoney == true ? [<YAxis tickFormatter={graphFormatter.moneyFormatter} />, <Tooltip formatter={graphFormatter.moneyFormatter} labelFormatter={graphFormatter.timeFormatter} />] : [<YAxis />, <Tooltip labelFormatter={graphFormatter.timeFormatter} />];
        return (

            < LineChart data={this.props.data} width={900} height={350} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hour" tickFormatter={graphFormatter.timeFormatter} />
                {toolTipYaxis}
                <Tooltip formatter={graphFormatter.moneyFormatter} labelFormatter={graphFormatter.timeFormatter} />
                <Legend />
                {dataVis}
            </LineChart >
        );
    }

    createLine(key, color) {
        return (<Line type="monotone" dataKey={key} stroke={color} />)
    }
}

const mapStateToProps = state => {
    return {
        nothing: "",
    }
}

export default connect(
    mapStateToProps
)(TopFiveGraph);