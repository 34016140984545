import React, {Component} from 'react';

class SectionHeader extends Component {
    render() {
        return(
        <div className='headerTextWrapper'>
            <h1 className='headerText'>{this.props.title}</h1>
        </div>            
        );
    }
}

export default SectionHeader;