import React, { Component } from 'react';
import ChartCard from './ChartCard';
import { ResponsiveContainer, LineChart, Line } from 'recharts';
import { getAverageTransactionAction } from './../../../../../actions/AverageTransaction';
import { connect } from 'react-redux';
import DatabaseValidator from './../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import selectors from '../../../../../reducers/selectors';

class AverageSpent extends Component {

    constructor(props) {
        super(props);
        let paramObj = {
            shopId: this.props.shopId,
            dateFirst: '0000000000', //Date range allows us to easily add functionality to see the average for a given time period.
            dateSecond: Math.round(Date.now() / 1000)
        }
        this.props.getAverageTransactionAction(paramObj)
    }

    render() {
        var dbv = new DatabaseValidator();
        const dataVis = dbv.isEmpty(this.props.averageSpent) ? <CircularProgress color='secondary' /> : <h2><i className="zmdi zmdi-money" /> {this.props.averageSpent['val']}</h2>
        return (
            <div className="col-lg-3 col-sm-6 col-12">
                <ChartCard styleName="bg-blue text-white">
                    <div className="chart-title">
                        {dataVis}
                        <p>Average Spent Per Visit</p>
                    </div>

                    <div style={{height: 25}} />
                    {/* <ResponsiveContainer width="100%" height={110}>
                        <LineChart data={this.props.data}>
                            <Line dataKey="uv" stroke="#ffffff" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer> */}
                </ChartCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        nothing: "",
        shopId: selectors(state).shopId,
        averageSpent: state.analytics.averageTransaction
    }
}

const actionCreators = {
    getAverageTransactionAction
}
export default connect(
    mapStateToProps,
    actionCreators
)(AverageSpent);