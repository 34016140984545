import React, { Component } from 'react';
import DatePicker from 'react-datepicker'
import moment from 'moment';
import ContainerHeader from '../util/ContainerHeader';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import MainDataPoints from './MainDataPoints';
import CardBox from './util/CardBox';
import DataTable from './util/DataTable';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import { baseUrl } from '../../../constants/baseUrl';
import selectors from '../../../reducers/selectors';
import ReactExport from "react-data-export";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Analytics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: moment(),
            endDate: moment(),
            shopID: this.props.shopId,
            isLoading: false,
            transactions: [],
            numTransactions: 0,
            totalSubtotal: 0,
            totalTax: 0,
            totalTip: 0,
            totalFee: 0,
            totalTotal: 0,
            refunds: 0,
            discounts: 0,
            fee: 0,
            net: 0,
            page: 0,
            transactionType: "all"
        };
    }
    componentDidMount() {
        this.getTransactions()
        MixpanelTrack("Visited Analytics Page")
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: date, page: 0 })
        const d = new Date(date)
        MixpanelTrack('Change startdate to ' + (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear())
    }
    handleEndDateChange = (date) => {
        this.setState({ endDate: date, page: 0 })
        const d = new Date(date)
        MixpanelTrack('Change enddate to ' + (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear())
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
        MixpanelTrack('Change to analytics page ' + page)
    }

    getTransactions = () => {
        this.setState({ isLoading: true })

        fetch(baseUrl + '/get_transactions_by_date_and_shop', {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${this.props.access_token}`},
            body: JSON.stringify({
                shopID: this.state.shopID,
                startDate: this.state.startDate.utc().format("MM/DD/YYYY"),
                endDate: this.state.endDate.utc().format("MM/DD/YYYY"),
                type: this.state.transactionType
            })
        })
            .then(result => result.json())
            .then(result => {
                let numTransactions = 0;
                let totalSubtotal = 0;
                let totalTax = 0;
                let totalTip = 0;
                let totalTotal = 0;
                let refunds = 0;
                let discounts = 0;
                let fee = 0;
                let net = 0;
                for(let i = 0; i < result.length; i++) {
                    numTransactions += 1;
                    totalSubtotal += result[i].subtotal;
                    totalTax += result[i].tax;
                    totalTip += result[i].tip;
                    totalTotal += result[i].orderAmount;
                    discounts += result[i].discounts;
                    fee += result[i].fee;
                    net += result[i].net;
                    if(result[i].transactionDescription.toLowerCase().includes("refund")) {
                        refunds += result[i].orderAmount;
                    }
                }


                this.setState({
                    transactions: result,
                    numTransactions,
                    totalSubtotal,
                    totalTax,
                    totalTip,
                    totalTotal,
                    refunds,
                    discounts,
                    fee,
                    net,
                    isLoading: false,
                    showDownloadButton: (result.length > 0 ? true : false)
                })
            })
    }

    formatOrdersData(data) {
        let arrayResult = [
            { value: data.transactionId, numeric: true },
            { value: data.transactionDescription, numeric: false },
            { value: data.orderId, numeric: true },
            { value: data.userName, numeric: false },
            { value: moment(data.transactionDate).format("MM/DD/YYYY HH:mm:ss"), numeric: false },
            { value: (`$ ${data.subtotal.toFixed(2).toString()}`), numeric: true },
            { value: (`$ ${data.tip.toFixed(2).toString()}`), numeric: true },
            { value: (`$ ${data.tax.toFixed(2).toString()}`), numeric: true },
            { value: data.code, numeric: false },
            { value: (`$ ${data.discounts.toFixed(2).toString()}`), numeric: true },
            { value: (`$ ${data.orderAmount.toFixed(2).toString()}`), numeric: true },
            { value: (`$ ${data.fee.toFixed(2).toString()}`), numeric: true },
            { value: (`$ ${data.net.toFixed(2).toString()}`), numeric: true },
        ];
        return arrayResult;
    }

    render() {
        const columnData = [
            { id: 'transactionId', numeric: true, disablePadding: false, label: 'Transaction ID' },
            { id: 'transactionDescription', numeric: false, disablePadding: true, label: 'Transaction Type' },
            { id: 'orderId', numeric: true, disablePadding: false, label: 'Order ID' },
            { id: 'userName', numeric: false, disablePadding: false, label: 'User Name' },
            { id: 'transactionDate', numeric: false, disablePadding: false, label: 'Transaction Date' },
            { id: 'subtotal', numeric: true, disablePadding: false, label: 'Subtotal' },
            { id: 'tip', numeric: true, disablePadding: false, label: 'Tip' },
            { id: 'tax', numeric: true, disablePadding: false, label: 'Tax' },
            { id: 'code', numeric: false, disablePadding: false, label: 'Promo Code' },
            { id: 'discounts', numeric: true, disablePadding: false, label: 'Discount' },
            { id: 'orderAmount', numeric: true, disablePadding: false, label: 'Order Amount' },
            { id: 'fee', numeric: true, disablePadding: false, label: 'Fee' },
            { id: 'net', numeric: true, disablePadding: false, label: 'Net' }
        ];
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={"Transactions"} match={this.props.match} />
                <MainDataPoints
                    subtotal={this.state.totalSubtotal.toFixed(2)}
                    discounts={this.state.discounts.toFixed(2)}
                    tax={this.state.totalTax.toFixed(2)}
                    tip={this.state.totalTip.toFixed(2)}
                    total={this.state.totalTotal.toFixed(2)}
                    refunds={this.state.refunds.toFixed(2)}
                    fee={this.state.fee.toFixed(2)}
                    net={this.state.net.toFixed(2)}
                />
                <div style={{display: 'flex', alignContent: 'center', alignItems: 'center', marginBottom: 10}}>
                    <div style={{alignContent: 'center', alignItems: 'center'}} className="analyticsInputs">
                        <label> Start Date: </label>
                        <DatePicker selected={this.state.startDate} onChange={this.handleStartDateChange}  dropdownMode={"scroll"}/>
                        <label style={{marginLeft: 5}}> End Date: </label>
                        <DatePicker selected={this.state.endDate} onChange={this.handleEndDateChange} dropdownMode={"scroll"} />
                    </div>
                    <FormControl variant="outlined" style={{marginLeft: 15, width:200}}>
                        <InputLabel>Transaction Type</InputLabel>
                        <Select
                            variant="outlined"
                            value={this.state.transactionType}
                            onChange={(e) => this.setState({transactionType: e.target.value})}
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value= {0}>Orders</MenuItem>
                            <MenuItem value= {6}>Member Orders</MenuItem>
                            <MenuItem value= {2}>Order Refunds</MenuItem>
                            <MenuItem value= {3}>Membership Dues</MenuItem>
                            <MenuItem value= {10}>Membership Due Refunds</MenuItem>
                        </Select>
                    </FormControl>
                    <div>
                        <Button style={{marginLeft: 10}} variant="contained" color={"primary"} onClick={() => this.getTransactions()}>Get Transactions</Button>
                    </div>
                </div>
                <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                    <DataTable
                        title="Transaction History"
                        data={this.state.transactions}
                        dataFormatter={this.formatOrdersData}
                        columnData={columnData}
                        isLoading={this.state.isLoading}
                        onChangePage={this.handleChangePage}
                        page={this.state.page} />
                </CardBox>

                { this.state.showDownloadButton && <div className="p-3">
                        <ExcelFile element={<button className="btn btn-primary">Download Transaction Data</button>} filename="TransactionsData">
                            <ExcelSheet data={this.state.transactions} name="Transactions">
                                <ExcelColumn label="Transaction ID" value="transactionId"/>
                                <ExcelColumn label="Transaction Description" value="transactionDescription"/>
                                <ExcelColumn label="Order ID" value="orderId"/>
                                <ExcelColumn label="User Name" value="userName"/>
                                <ExcelColumn label="Date" value="transactionDate"/>
                                <ExcelColumn label="Subtotal" value="subtotal"/>
                                <ExcelColumn label="Tip" value="tip"/>
                                <ExcelColumn label="Tax" value="tax"/>
                                <ExcelColumn label="Promo Code" value="code"/>
                                <ExcelColumn label="Discount" value="discounts"/>
                                <ExcelColumn label="Transaction Amount" value="orderAmount"/>
                                <ExcelColumn label="Fee" value="fee"/>
                                <ExcelColumn label="Net" value="net"/>
                            </ExcelSheet>
                        </ExcelFile>
                    </div> }
            </div>)
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        access_token: state.auth.access_token
    }
}
const actionCreators = {

}

export default connect(
    mapStateToProps,
    actionCreators
)(Analytics);



