import React, { Component } from 'react'
import ReportBox from './ReportBox';
import { Bar, XAxis, YAxis, BarChart, Tooltip } from 'recharts';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatabaseValidator from './../../../../../util/DatabaseValidator';
import NoDataCard from '../../../../util/NoDataCard';
import GraphFormatter from './../../../../util/GraphFormatter';

class WeekGraphReport extends Component {

    constructor(props) {
        super(props);
    }

    getDayOfWeek(date) {
        //then convert to day of the week
        var dayOfWeek = new Date(this.getParseableDate(date)).getDay();
        return isNaN(dayOfWeek) ? null : ['M', 'T', 'W', 'R', 'F', 'Sat', 'Sun'][dayOfWeek];
    }

    getParseableDate(date) {
        //Convert the date string I got from the database to one that is parsable by the Date constructor
        date = String(date);
        var year = date.slice(6, 10);
        var month = date.slice(0, 2);
        var day = date.slice(3, 5);
        let parseableDate = year + '-' + month + '-' + day;
        return (parseableDate);
    }

    getDatabaseMatchDate(date) {
        //Takes in a Date object and returns a string that matches the format of what the database returns 
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (String(month).length < 2) {
            month = '0' + month;
        }
        let day = date.getDate();
        if (String(day).length < 2) {
            day = '0' + day;
        }
        return (String(month + '/' + day + '/' + year));
    }

    setupData(data) {
        //Should receive an array of objects that have an 'amt' field and a 'date' field
        //Will make the date a day of the week and add a 'key' field
        var dateFirst = data[0].date;
        var dateSecond = data[data.length - 1].date;
        let key = 1;
        data.map(data => {
            data['date'] = this.getDayOfWeek(data['date']);
            data['key'] = key;
            key += 1;
        })
        var result = {}
        var dayCounter = { M: 0, T: 0, W: 0, R: 0, F: 0, Sat: 0, Sun: 0 }
        var newDayAv = { M: 0, T: 0, W: 0, R: 0, F: 0, Sat: 0, Sun: 0 }
        for (var day in data) {
            this.averageMatchingDays(data[day], data[day].date, dayCounter, newDayAv, result)
        }
        var returnData = []
        for (let key in result['name']) {
            let obj = {
                date: key, amt: result['name'][key]
            }
            returnData.push(obj)
        }
        return (returnData);
    }

    averageMatchingDays(day, name, dayCounter, newDayAv, result) {
        newDayAv[name] = newDayAv[name] == 0 ? day.amt : Math.round((((newDayAv[name] * dayCounter[name]) + day.amt) / (dayCounter[name] + 1)) * 100) / 100
        Object.assign(result, { name: newDayAv })
        dayCounter[name] += 1;
    }


    //The backend isn't returning data for any sundays
    render() {
        let graphFormatter = new GraphFormatter();
        let dataVis = '';
        let dv = new DatabaseValidator();
        if (dv.isEmpty(this.props.grossOrders)) {
            dataVis = <CircularProgress size={70} />
        } else if (JSON.parse(this.props.grossOrders['val']).date == 0) {
            //No data in database. Just display graph without money values.
            dataVis = <NoDataCard type="week sales" />
        } else {
            dataVis = <BarChart data={this.setupData(JSON.parse(this.props.grossOrders['val']))}>
                <Bar dataKey='amt' fill='#3F51B5' maxBarSize={50} />
                <XAxis stroke="#3F51B5" dataKey="date" />
                <YAxis stroke="#3F51B5" dataKey="amt" tickFormatter={graphFormatter.moneyFormatter} />
                <Tooltip cursor={false} formatter={graphFormatter.moneyFormatter} />
            </BarChart>
        }
        return (
            <div className="col-3">
                <ReportBox heading="Average Gross for Day of the Week">
                    {dataVis}
                </ReportBox>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        nothing: "",
        shopId: state.auth.shop.shopId,
        grossOrders: state.analytics.grossOrders
    }
}

export default connect(
    mapStateToProps
)(WeekGraphReport);