import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import SelectValidator from '../util/SelectValidator';
import { UNIT_PERCENT, discountUnitTypes } from './DiscountUnitTypes';
import DiscountUnits from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CustomScrollbars from '../../../util/CustomScrollbars';
import DataTable from '../analytics/util/DataTable';
import { baseUrl } from '../../../constants/baseUrl';
import selectors from '../../../reducers/selectors';
import moment from 'moment';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { deactivatePromoCodeAction } from '../../../actions/PromoCode';

class PromoCodeList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promoCodes:[],
            isLoading: false,
            page: 0,
            promoCodesCopy:[],
            code: '',
            valueUnit: '',
            value: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSearchClicked = this.onSearchClicked.bind(this)
        this.onClearFiltersClicked = this.onClearFiltersClicked.bind(this)
    }

    componentDidMount() {
        console.log('component mount called')
        this.getPromoCodes(this.props.shopId)
    }

    getPromoCodes = (shopId) => {
        this.setState({ isLoading: true })
        fetch(baseUrl + '/promo_codes?shopId=' + shopId, {
            method: 'GET',
            headers: {Authorization: `Bearer ${this.props.access_token}`}
        })
        .then(result => result.json())
        .then(result => {
            this.setState({
                promoCodes: this.sortDesc(result, 'promoCodeId'),
                promoCodesCopy: this.sortDesc(result, 'promoCodeId'),
                page: this.state.page,
                isLoading: false
            })
        })
    }

    sortAsc = (arr, field) => {
        return arr.sort((a, b) => {
          if (a[field] > b[field]) { return 1; }
          if (b[field] > a[field]) { return -1; }
          return 0;
        })
    }

    sortDesc = (arr, field) => {
        return arr.sort((a, b) => {
          if (a[field] > b[field]) { return -1; }
          if (b[field] > a[field]) { return 1; }
          return 0;
        })
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    }

    formatPromoCodesData(data) {
        if (data.endDate != null) {
          var newEndDate = moment(data.endDate)
          newEndDate.set({h: 0, m: 0, s: 0, })
        }
        let arrayResult = [
            { value: data.promoCodeId, numeric: true },
            //{ value: data.menuItemId, numeric: true },
            { value: data.code, numeric: false },
            { value: (data.isActive === true ? (data.endDate === null ? 'Active' : (((new Date(data.endDate)).getTime() > (new Date()).getTime()) ? 'Active' : 'Expired')) : 'Disabled'), numeric: false },
            { value: (data.valueUnit === UNIT_PERCENT ? '%' : '$'), numeric: false },
            { value: (data.valueUnit === UNIT_PERCENT ? data.value + '%' : '$' + data.value), numeric: true },
            { value: (data.maxValue != null ? '$' + data.maxValue : data.maxValue), numeric: true },
            { value: (data.minValue != null ? '$' + data.minValue : data.minValue), numeric: true },
            { value: data.userRedeemCount, numeric: true },
            { value: data.totalRedeemCount, numeric: true },
            // { value: data.startDate, numeric: false },
            // { value: ((new Date(data.startDate)).toUTCString()), numeric: false },
            { value: (data.startDate != null ? moment(data.startDate).format("MM/DD/YYYY") : data.startDate), numeric: false },
            { value: (data.endDate != null ? newEndDate.format("MM/DD/YYYY, h:mm A") : data.endDate), numeric: false },
            { value: (data.createdDate != null ? moment(data.createdDate).format("MM/DD/YYYY") : data.createdDate), numeric: false },
            { value: (data.isActive === true ? <a href="javascript:void(0)" onClick={() => this.onActionDeactivatePromoCode(data.promoCodeId)}>Deactivate</a> : 'N/A'), numeric: false }
        ];
        return arrayResult;
    }

    actionDeactivatePromoCode = (promoCodeId) => {
        // console.log(promoCodeId);
        this.setState({ isLoading: true })
        this.props.deactivatePromoCodeAction(promoCodeId,
            (resp) => {
                if(resp && JSON.parse(resp).isActive == false){
                    this.getPromoCodes(this.props.shopId)
                    NotificationManager.success("Promo code deactivated", "Success")
                }
            },
            (error) => {
                this.setState({ isLoading: false })
                NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error updating promo code!", 9000)
            })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    onSearchClicked() {
        console.log('code:'+ this.state.code)
        console.log('valueUnit:'+ this.state.valueUnit)
        console.log('value:'+ this.state.value)

        var filteredCodes = []
        if(this.state.code.length==0 && this.state.valueUnit.length==0 && this.state.value.length==0){
            return;
        }else if(this.state.code.length>0 && this.state.valueUnit.length==0 && this.state.value.length==0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.code.toLowerCase() == this.state.code.toLowerCase())
        }else if(this.state.code.length==0 && this.state.valueUnit.length>0 && this.state.value.length==0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.valueUnit == this.state.valueUnit)
        }else if(this.state.code.length==0 && this.state.valueUnit.length==0 && this.state.value.length>0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.value == this.state.value)
        }else if(this.state.code.length>0 && this.state.valueUnit.length>0 && this.state.value.length==0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.code.toLowerCase() == this.state.code.toLowerCase() && c.valueUnit == this.state.valueUnit)
        }else if(this.state.code.length>0 && this.state.valueUnit.length==0 && this.state.value.length>0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.code.toLowerCase() == this.state.code.toLowerCase() && c.value == this.state.value)
        }else if(this.state.code.length==0 && this.state.valueUnit.length>0 && this.state.value.length>0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.valueUnit == this.state.valueUnit && c.value == this.state.value)
        }else if(this.state.code.length>0 && this.state.valueUnit.length>0 && this.state.value.length>0){
            filteredCodes = this.state.promoCodesCopy.filter(c => c.code.toLowerCase() == this.state.code.toLowerCase() && c.valueUnit == this.state.valueUnit && c.value == this.state.value)
        }
        this.setState({ promoCodes: filteredCodes});
    }

    onClearFiltersClicked() {
        this.setState({ code: '', valueUnit: '', value: '', promoCodes: this.state.promoCodesCopy })
    }

    render() {
        const columnData = [
            { id: 'promoCodeId', numeric: true, disablePadding: false, label: 'Id' },
            //{ id: 'menuItemId', numeric: true, disablePadding: false, label: 'MenuItemId' },
            { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
            { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
            { id: 'valueUnit', numeric: false, disablePadding: false, label: 'Unit' },
            { id: 'value', numeric: true, disablePadding: false, label: 'DiscountValue' },
            { id: 'maxValue', numeric: true, disablePadding: false, label: 'MaxDiscountValue' },
            { id: 'minValue', numeric: true, disablePadding: false, label: 'MinOrderValue' },
            { id: 'userRedeemCount', numeric: true, disablePadding: false, label: 'PerUserMaxRedeemCount' },
            { id: 'totalRedeemCount', numeric: true, disablePadding: false, label: 'TotalMaxRedeemCount' },
            { id: 'startDate', numeric: false, disablePadding: false, label: 'StartDate' },
            { id: 'endDate', numeric: false, disablePadding: false, label: 'EndDate' },
            { id: 'createdDate', numeric: false, disablePadding: false, label: 'CreatedDate' },
            { id: 'isActive', numeric: false, disablePadding: false, label: 'Action' }
        ];
        return (
            <div className="app-wrapper">
                <div className="animated slideInUpTiny animation-duration-3">
                    <div className="app-module">
                        <div className="app-module-sidenav d-none d-xl-flex">
                            <div className="module-side">
                                <div className="module-side-header">
                                    <div className="module-logo">
                                        <i className="zmdi zmdi-label mr-4"/>
                                        <span>Promo Code List</span>
                                    </div>
                                </div>
                                <div className="module-side-content">
                                    <div className="col-md-12 col-12 p-4">
                                        <div>
                                            <TextField
                                                label="Promo Code"
                                                value={this.state.code}
                                                onChange={this.handleChange('code')}
                                                inputProps={{ maxLength: 20 }}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </div>
                                        <div>
                                            <SelectValidator
                                                label="Unit"
                                                value={this.state.valueUnit}
                                                onChange={this.handleChange('valueUnit')}
                                                errorMessage=""
                                                inputProps={{
                                                    name: 'type'
                                                }}>
                                                {discountUnitTypes.map(obj => {
                                                    return (<DiscountUnits key={obj.value} value={obj.value}>
                                                                {obj.text}
                                                            </DiscountUnits>)
                                                })}
                                            </SelectValidator>
                                        </div>
                                        <div>
                                            <TextField
                                                label="Discount Value"
                                                value={this.state.value}
                                                onChange={this.handleChange('value')}
                                                placeholder="0.00"
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <Button
                                                variant="raised"
                                                color="primary"
                                                className="jr-btn"
                                                onClick={this.onSearchClicked}>
                                                Search
                                            </Button>
                                            <Button
                                                variant="raised"
                                                color="primary"
                                                className="jr-btn"
                                                onClick={this.onClearFiltersClicked}>
                                                Clear
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="module-box">
                            <div className="module-box-header">
                            </div>
                            <div className="module-box-content">
                                <CustomScrollbars className="module-list-scroll scrollbar" style={{height: this.props.width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                                    <div className="animated slideInUpTiny animation-duration-3">
                                        <DataTable
                                            // title="Promo Code List"
                                            data={this.state.promoCodes}
                                            dataFormatter={this.formatPromoCodesData}
                                            columnData={columnData}
                                            isLoading={this.state.isLoading}
                                            onChangePage={this.handleChangePage}
                                            onActionDeactivatePromoCode= {this.actionDeactivatePromoCode}
                                            page={this.state.page} />
                                    </div>
                                </CustomScrollbars>
                            </div>
                            <NotificationContainer />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        width: selectors(state).width,
        shopId: selectors(state).shopId,
        access_token: state.auth.access_token
    }
}
const actionCreators = {
    deactivatePromoCodeAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(PromoCodeList);
