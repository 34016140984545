import React, { Component } from 'react';
import OrderTable from './OrderTable';

class RecentOrders extends Component {
    onOptionMenuSelect = event => {
        this.setState({ menuState: true, anchorEl: event.currentTarget });
    };
    render() {
        return (
            <div className="col-xl-8 col-lg-12 col-12">
                <div className="jr-card">
                    <div className="jr-card-header d-flex align-items-center">
                        <div className="mr-auto">
                            <h3 className="d-inline-block mb-0">Recent Orders</h3>
                        </div>
                    </div>
                    <OrderTable />
                </div>
            </div>
        );
    }
}

export default RecentOrders;