import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import CoffeeImage from '../menu/MenuBuilder/CoffeeImage';
import MemberBenefitIcons from './MemberBenefitIcons';

export default class BenefitIconSelectDialog extends Component {
    constructor(props) {
        super(props)

        const benefitIcons = new MemberBenefitIcons()
        this.icons = benefitIcons.getImages()
    }
    render(props) {

        var items = this.icons.map((i, index) => {
            if(index % 4 == 0) {
                var row = this.icons.slice(index, index + 4).map(icon => {
                    var color = (icon.key === this.props.selectedImage)? 'primary' : 'default'
                    return (<div key={icon.key} style={styles.container}>
                                <Button variant="contained" color={color} style={styles.button} onClick={() => this.props.onClickImage(icon.key)}>
                                    <CoffeeImage name={icon.key} style={styles.image}/>
                                </Button>
                                <div>{icon.defaultName}</div>
                            </div>)
                })

                return (<div key={index} className='row'>
                            {row}
                        </div>)
            }
            return (<span key={index} />)
        })

        return(
            <Dialog
                open={this.props.isDialogOpen}
                aria-labelledby="coffee icon select"
                aria-describedby="alert-dialog-description">
                <DialogTitle>{"Select An Icon"}</DialogTitle>
                <DialogContent>
                    {items}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.handleClose()} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>)
    }
}

const styles = {
    image: {
        width: 50, 
        height: 50,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10
    },
    tile: {
        margin: 10
    }
}

BenefitIconSelectDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onClickImage: PropTypes.func.isRequired,
}