import React, { Component } from 'react';
import HeaderBlock from '../home/HeaderBlock';
import Footer from '../home/Footer';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {verifyEmailAction} from "../../actions/VerifyEmail";
import CircularProgress from "@material-ui/core/CircularProgress";

class Verify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            verified: false
        }
    }
    componentDidMount() {
        MixpanelTrack("User Verified");
        const params = new URLSearchParams(this.props.location.search.replace("+", '%2B'));
        this.props.verifyEmailAction({email:params.get('email'), token: params.get('token')},
            (response) => {
                if(!response.error) {
                    this.setState({loading: false, verified: true})
                } else {
                    this.setState({loading: false})
                }
            },
            () => this.setState({loading: false, verified: false}))
    }

    render() {
        return (
            <div className='app'>
                <div className='aboutUsWrapper'>
                    <div className="topBlock">
                        <div className="navBarBackground">
                            <HeaderBlock isMainPage={false} />
                        </div>
                    </div>
                    <div>
                        {this.state.loading ?
                            <div style={styles.progress} >
                                <CircularProgress size={50} />
                            </div>
                        :
                            <div style={styles.message}>
                                {this.state.verified ? <><h2>Email successfully verified!</h2><span>Please open the CoffeePass app to continue.</span></> : <h2>There was an issue verifying your email. Please contact support.</h2>}
                            </div>
                            }
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

const styles = {
    progress: {
        display: 'flex',
        margin: 200,
        paddingBottom: 100,
        flexDirection: 'column',
        alignItems: 'center'
    },
    message: {
        display: 'flex',
        margin: 200,
        paddingBottom: 0,
        flexDirection: 'column',
        alignItems: 'center'
    }
}


const mapStateToProps = state => {
    return {
    }
}
const actionCreators = {
    verifyEmailAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(withRouter(Verify));
