import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g strokeWidth="1.333" transform="translate(-44 -328)">
          <g transform="translate(0 -22)">
            <g transform="translate(20 263)">
              <g transform="translate(15 79)">
                <g transform="translate(10 9)">
                  <path d="M12 6.66666667L12 13.3333333 1.33333333 13.3333333 1.33333333 6.66666667"></path>
                  <path d="M0 3.333H13.333V6.666H0z"></path>
                  <path d="M6.667 13.333L6.667 3.333"></path>
                  <path d="M6.667 3.333h-3a1.667 1.667 0 110-3.333c2.333 0 3 3.333 3 3.333zM6.667 3.333h3a1.667 1.667 0 000-3.333c-2.334 0-3 3.333-3 3.333z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
