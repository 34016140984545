import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import { NotificationManager, NotificationContainer } from 'react-notifications';
import Button from "@material-ui/core/Button";
import IntlMessages from "../util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from '../assets/coffeepass_web_logo_interior.png';
import {
    hideMessage,
    showAuthLoader,
    userSignIn,
    shopLoginAction
} from "../actions";
import { MixpanelTrack, MixpanelEmail, MixpanelIdentify } from "../util/MixpanelHelper";

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        MixpanelTrack("Visited SignIn Page")
    }

    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }
        if (this.props.shop != null && this.props.shop.shopId !== null && this.props.shop.shopId != "") {
            this.props.history.push('/app/overview');
        }
    }

    render() {
        const {
            email,
            password
        } = this.state;
        const {showMessage, loader, alertMessage} = this.props;
        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img src={logo} className="appLogo" alt="logo" />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1><IntlMessages id="appModule.email"/></h1>
                        </div>

                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        label={<IntlMessages id="appModule.email"/>}
                                        fullWidth
                                        onChange={(event) => this.setState({email: event.target.value})}
                                        defaultValue={email}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />
                                    <TextField
                                        type="password"
                                        label={<IntlMessages id="appModule.password"/>}
                                        fullWidth
                                        onChange={(event) => this.setState({password: event.target.value})}
                                        defaultValue={password}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />

                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button onClick={() => {
                                            this.props.showAuthLoader();
                                            // this.props.userSignIn({email, password});
                                            this.props.shopLoginAction({
                                                email: email,
                                                password: password
                                            }, (resp) => {
                                                console.log("onsuccess " + resp)
                                                if(resp.val == 'True') {
                                                    MixpanelIdentify(email)
                                                    MixpanelEmail(email)
                                                    MixpanelTrack('Successfully Logged in')
                                                } else {
                                                    NotificationManager.error("Invalid Email or Password", "Error", 6000)
                                                }
                                            }, (error) => {
                                                NotificationManager.error("Error connecting to server. If you would, let us know the issue by emailing us at support@coffeepass.io. Thanks!", "Error", 6000)
                                            })
                                        }} variant="raised" color="primary" style={{backgroundColor: "#78A852"}}>
                                            <IntlMessages id="appModule.signIn"/>
                                        </Button>

                                        <Link className="anchor-cp" to="/signup">
                                            <IntlMessages id="signIn.signUp"/>
                                        </Link>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>
                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {showMessage && console.log(alertMessage)}
                <NotificationContainer/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {loader, alertMessage, showMessage, authUser, shop} = state.auth;
    return {loader, alertMessage, showMessage, authUser, shop}
};

export default connect(mapStateToProps, {
    userSignIn,
    hideMessage,
    showAuthLoader,
    shopLoginAction
})(SignIn);
