import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

class OrderTableCell extends React.Component {

    onOptionMenuSelect = event => {
        this.setState({ menuState: true, anchorEl: event.currentTarget });
    };
    handleRequestClose = () => {
        this.setState({ menuState: false });
    };

    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            menuState: false,
        }
    }

    render() {
        const { anchorEl, menuState } = this.state;
        const id = this.props.data['key']
        const orderId = this.props.data['orderNum']
        const name = this.props.data['name']
        const orderDate = this.props.data['date']
        const totalOrder = this.props.data['spent']
        return (
            <tr
                tabIndex={-1}
                key={id}
            >
                <td>{orderId}</td>
                <td>
                    <div className="user-profile d-flex flex-row align-items-center">
                        <div className="user-detail">
                            <h5 className="user-name">{name} </h5>
                        </div>
                    </div>
                </td>
                <td>{orderDate}</td>
                <td className="text-right">
                    <div className={`text-uppercase`}><i className="zmdi zmdi-money"></i> {totalOrder.toFixed(2)}</div>
                </td>
            </tr>

        );
    }
}

export default OrderTableCell;
