import React, { Component } from 'react';
import homeLogo from '../../assets/coffeepass_web_logo_home.png';
import iOSStore from '../../assets/appStore.png'
import googlePlay from '../../assets/googlePlay.png'
import screenShot from '../../assets/iphone_10_info.png'
import demoGif from "../../assets/gifs/coffeepass_demo.gif"
import iphone from "../../assets/home/iphone_mockup3.png"
import './App.css';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import { NavLink } from 'react-router-dom';
import windowSize from 'react-window-size';

class CustomerBlock extends Component {
  render() {
        const isMobileDevice = this.props.windowWidth < 770 ? true : false

        return (
            <div className="customerBlock">
                <div className={isMobileDevice ? 'appStoreLogoContainer-mobile' : 'appStoreLogoContainer'}>
                    {/*<h1 className="tagline">Order ahead and skip the line at your favorite coffee shops!</h1>
                    <p className="description">CoffeePass lets you support independent coffee without having to wait. Just place your order, arrive at the shop, and walk straight to the pickup counter! </p>
                    <a href="https://itunes.apple.com/us/app/mojo-order-ahead-coffee/id1383623831" onClick={() => MixpanelTrack('Clicked iOS badge')}>
                        <img src={iOSStore} className="appStoreLogo" title="iOS" alt="App Store Link" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.coffeeapp" onClick={() => MixpanelTrack('Clicked android badge')}>
                        <img src={googlePlay} className="appStoreLogo" title="Google Play" alt="Google Play Link" />
                    </a>*/}

                    <NavLink to='/' style={{ textDecoration: 'none' }}>
                        <img className={isMobileDevice ? 'logo-mobile' : 'logo'} src={homeLogo} />
                    </NavLink>
                    <h1 className={isMobileDevice ? 'heading-mobile' : 'heading'}><span><b>Order ahead</b></span> & skip the line<br />at your favorite coffee shops!</h1>
                    <div className="divider"></div>
                    <p className="tag">CoffeePass lets you support independent coffee without<br />
                        having to wait. Just place your order, arrive at the shop, and<br />
                        walk straight to the pickup counter!</p>
                    <div className="mt30">
                        <a href="https://itunes.apple.com/us/app/mojo-order-ahead-coffee/id1596107600" onClick={() => MixpanelTrack('Clicked iOS badge')}>
                            <img src={iOSStore} width={isMobileDevice ? '115' : '150'} title="iOS" alt="App Store Link" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.coffeeapp" onClick={() => MixpanelTrack('Clicked android badge')}>
                            <img src={googlePlay} width={isMobileDevice ? '115' : '150'} title="Google Play" alt="Google Play Link" />
                        </a>
                    </div>
                </div>
                <div className='imagesContainer'>
                    <img src={demoGif} className="screenShot" title="Screen Shot" alt="App Screen Shot" />
                    <img src={iphone}  className="iphoneMockup" title="iPhone Mockup" alt="iPhone mockup" />
                </div>
            </div>
        );
    }
}

export default windowSize(CustomerBlock);
