import React, {Component} from 'react';
import SectionHeader from './SectionHeader';
import WorkBlock from './WorkBlock';
import karenProfile from '../../assets/shopLanding/karen_profile.png'
import cympProfile from '../../assets/shopLanding/cymp_profile.png'

class OwnerTestimonial extends Component {
    render() {
        const references = [{
            className: 'genericBlockReverse workBlock row',
            ownerName: "-Karen Bassett",
            ownerImage: karenProfile,
            ownerShop: 'Wayfarer Coffee Roasters',
            ownerWords: '"One of our busiest Saturdays ever today ... I literally saw customers (mostly a couple regulars) walk in the door, see the line, get out their phones and place an order on CoffeePass. So funny, but awesome because our register is our bottleneck on busy days, so we can handle so many more drinks."'
        }, 
        {
            className: 'genericBlockReverse ownerBlock workBlock row',
            ownerName: "-Cynthia Stemple",
            ownerImage: cympProfile,
            ownerShop: 'Coffee Hub',
            ownerWords: '"I love the CoffeePass app. As a coffee shop owner... It\'s so simple to use. The customers love it (Especially that they don\'t have to wait) and the designers are so easy to work with. We absolutely love CoffeePass!"'
        }]

        const referencesView = references.map((references, index) => {
            return <WorkBlock
                        key={index}
                        title=""
                        body={references.ownerWords}
                        author={references.ownerName}
                        source={references.ownerShop}
                        class={references.className}
                        imageURL={references.ownerImage} 
                        textClass='stepGenericText ownerTestimonText col-md-5 col-9'
                        imageClass='shopGenericImage workImage col-md-4 col-12'/>                            
        });
        return(
            <div className="blockWrapper offWhiteBackground">
                <SectionHeader title="What Shop Owners are Saying" />
                {referencesView}
            </div>
        );
    }
}

export default OwnerTestimonial;