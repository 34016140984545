import queryString from 'query-string'
import { SHOP_LOGIN, SIGNOUT_USER } from "../constants/ActionTypes"
import jwtManager from "./jwtManager"

const apiJsonMiddleware = store => next => action => {
    if (!action.meta || action.meta.type !== 'apijson') {
        return next(action)
    }
    // This is an api request

    // Find the request URL and compose request options from meta
    const {url} = action.meta;
    var fetchOptions = Object.assign({}, action.meta.options)

    if(!url.includes("s3.amazonaws")) {
        var access_token = store.getState().auth.access_token || localStorage.getItem("access_token");

        fetchOptions = {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                Authorization:
                    'Bearer ' + access_token,

            },
        };
    }

    const finalUrl = url +'?' + queryString.stringify(action.meta.params)

    // Make the request
    fetch(finalUrl, fetchOptions)
    .then(resp => {
        if (resp.status >= 200 && resp.status < 300) {
            return resp
        } else {
            if (typeof action.meta.onFailure === 'function') {
                action.meta.onFailure(resp)
                throw "Error on backend"
            }
        }
    })
    .then(resp => resp.json())
    .then(json => {
        if (typeof action.meta.onSuccess === 'function') {
            if(action.type === SHOP_LOGIN && json.access_token) {
                jwtManager.setToken(json.access_token);
            }
            if(action.type === SIGNOUT_USER) {
                jwtManager.clearToken();
            }
            action.meta.onSuccess(json)
        }
        return json  // For the next promise in the chain
    })
    .then(json => {
        // respond back to the user
        // by dispatching the original action without
        // the meta object
        let newAction = Object.assign({}, action, {
            payload: json
        });
        delete newAction.meta
        store.dispatch(newAction)
    })
    .catch((error) => {
        //caught an error here
        console.log('found an error: ', error)
    })
}

export default apiJsonMiddleware
