import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PropTypes } from 'prop-types';

class ExtraMenu extends Component {
    state = {
        anchorEl: undefined,
        open: false,
    }

    handleClick = event => {
        this.setState({open: true, anchorEl: event.currentTarget})
    }

    handleRequestClose = () => {
        this.setState({open: false})
    }

    onSelectOption = (option) => {
        this.setState({open: false})
        this.props.onSelectOption(option)
    }

    render() {
        return (
            <div>
                <IconButton
                    aria-label="More"
                    aria-owns={this.state.open ? 'long-SidenavContent.js' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleRequestClose}

                    MenuListProps={{
                        style: {
                            width: 200,
                        },
                    }}
                >
                    {this.props.options.map(option =>
                        <MenuItem key={option} onClick={() => this.onSelectOption(option)}>
                            {option}
                        </MenuItem>,
                    )}
                </Menu>
            </div>
        );
    }
}

ExtraMenu.propTypes = {
    options: PropTypes.array.isRequired,
    onSelectOption: PropTypes.func.isRequired
}

export default ExtraMenu;