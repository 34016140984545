import React from 'react';
import { NavLink } from 'react-router-dom';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import ReactPixel from 'react-facebook-pixel';

const MoneyModule = ({styleName, headerStyle, itemStyle, footerStyle}) => {
        return (
            <div className={`${styleName}`}>
                <div className={`${headerStyle}`}>
                    <span className="price">8%</span>
                    <h4 className="letter-spacing-base text-uppercase mb-0">per transaction</h4>
                </div>

                <ul className={`package-items ${itemStyle}`}>
                     <li>
                        <i className="zmdi zmdi-card zmdi-hc-fw"/>
                        <span>Credit card fees included</span>
                    </li>
                    <li>
                        <i className="zmdi zmdi-block zmdi-hc-fw"/>
                        <span>No upfront or subscription fees</span>
                    </li>
                    <li>
                        <i className="zmdi zmdi-money-box zmdi-hc-fw"/>
                        <span>We only make money when you do</span>
                    </li>
                    <li>
                        <i className="zmdi zmdi-refresh zmdi-hc-fw"/>
                        <span>Shops are paid weekly</span>
                    </li>
                </ul>
                <div className="package-footer">
                    <NavLink className={`${footerStyle}`} to="/signup" onClick={() => {
                            MixpanelTrack('Clicked Get Started')
                            ReactPixel.track("Clicked Get Started", 'no data')
                        }}>Get Started</NavLink>
                </div>
            </div>
        )
    };

export default MoneyModule;

