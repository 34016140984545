import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import CardBox from '../../component/dashboard/analytics/util/CardBox';
import logo from '../../assets/coffeepass_web_logo_interior.png';
import { NavLink } from 'react-router-dom';
import AddressFields from '../../component/dashboard/ShopInfo/AddressFields';
import Hours from '../../component/dashboard/ShopInfo/Hours';
import ShopImageUpload from '../../component/dashboard/ShopInfo/ShopImageUpload';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { doesUrlExistAction, updateShopOnboardedAction, getShopInfoAction, saveStripeTokenAction } from '../../actions/ShopInfo';
import { TermsConditions } from './TermsConditions';
import SetupPayments from './SetupPayments';
import * as qs from 'query-string';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import selectors from '../../reducers/selectors';
import {awsBuckets} from "../../constants/awsBucket";

class ShopOnboarding extends React.Component {

    constructor(props) {
        super(props)

        this.steps = ['Shop Information', 'Shop Hours', 'Shop Branding', 'Setup Payments', 'Confirm and Finish']

        var codeVal = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code
        this.state = {
            activeStep: codeVal != undefined? 4: 0,
            checkboxClicked: false
        }

        if(codeVal != undefined) {
            MixpanelTrack('back from stripe connect')
            this.props.saveStripeTokenAction({
                    shopId: this.props.shopId,
                    authorizationCode: codeVal
                },
                (resp) => {
                    console.log('resp', resp)
                    if(resp.error != undefined) {
                        NotificationManager.error("Failed to save payment Info. Contact support@coffeepass.io if this continues.", "Failed", 5000)
                    }
                }, (error) => {
                    console.log('stripe error', error)
                    NotificationManager.error("Failed to save payment Info. Contact support@coffeepass.io if this continues.", "Failed", 5000)
                })
        } else {
            //redirect to signup
            if (this.props.shopId == null || this.props.shopId == "") {
                this.props.history.push('/signup');
            }
            this.props.getShopInfoAction(this.props.shopId,
                (resp) => {
                    if(resp.val != false) {
                        //redirect if the user has already onboarded
                        if (resp.isOnboarded) {
                            this.props.history.replace('/app/analytics')
                        }
                    }
                },
                (error) => {
                    console.log("getshop info error", error)
                })
        }

    }

    componentDidMount() {
        MixpanelTrack("Visited Onboarding Page")
    }

    clickedCheckBox = () => {
        this.setState({checkboxClicked: !this.state.checkboxClicked })
    }

    handleNext = () => {
        const {activeStep} = this.state;
        if((activeStep + 1) === this.steps.length) {
            if(this.state.checkboxClicked) {
                this.props.updateShopOnboardedAction({
                    shopId: this.props.shopId,
                    isOnboarded: true
                },
                (resp) => {
                    this.props.history.replace('/app/menu')
                    MixpanelTrack('Finished Onboarding')
                })
            } else {
                NotificationManager.error("You must agree to the Terms and Conditions.", "Must Agree", 5000)
            }
        } else {
            MixpanelTrack('to step ' + this.steps[activeStep + 1])
            this.setState({
                activeStep: activeStep + 1,
            })
        }
    }

    getImagesExist = () => {
        const logoUrl = `https://s3.amazonaws.com/${awsBuckets.shop_info_bucket}/shop_logo/` + this.props.shopId + "_logo.png"
        const bannerUrl = `https://s3.amazonaws.com/${awsBuckets.shop_info_bucket}/shop_logo/` + this.props.shopId + "_banner.png"
        this.props.doesUrlExistAction(logoUrl,
            (resp) => {
                this.props.doesUrlExistAction(bannerUrl,
                    (resp) => {
                        this.handleNext()
                    },
                    (error) => {
                        NotificationManager.error("You must add both a logo and a banner to continue.", "Error Saving Image!", 5000)
                    })
            },
            (error) => {
                NotificationManager.error("You must add both a logo and a banner to continue.", "Error Saving Image!", 5000)
            })
    }

    handleBack = () => {
        const {activeStep} = this.state;
        this.setState({
            activeStep: activeStep - 1,
        })
    }

    handleReset = () => {
        this.setState({
            activeStep: 0
        })
    }

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <AddressFields activeStep={this.state.activeStep}
                                        handleNext={this.handleNext}
                                        handleBack={this.handleBack}
                                        steps={this.steps} />
            case 1:
                return <Hours activeStep={this.state.activeStep}
                                handleNext={this.handleNext}
                                handleBack={this.handleBack}
                                steps={this.steps} />
            case 2:
                return (<div className='row'>
                            <div className="col-md-3 col-12">
                                <ShopImageUpload
                                    imgNameEnding='_logo.png'
                                    imgName='Shop Logo'
                                    imgRatio={1/1}
                                    text=""
                                    imgUrl=''
                                    activeStep={this.state.activeStep}/>
                            </div>
                            <div className="col-md-9 col-12">
                                <ShopImageUpload
                                    imgNameEnding='_banner.png'
                                    imgName='Shop Banner'
                                    imgRatio={8/5}
                                    text=""
                                    imgUrl=''
                                    activeStep={this.state.activeStep}
                                    handleNext={this.getImagesExist}
                                    handleBack={this.handleBack}
                                    steps={this.steps}/>
                            </div>
                        </div>)
            case 3:
                return <SetupPayments activeStep={this.state.activeStep}
                                        handleNext={this.handleNext}
                                        handleBack={this.handleBack}
                                        steps={this.steps} />
            case 4:
                return <TermsConditions
                            clickedCheckbox={this.clickedCheckBox}
                            activeStep={this.state.activeStep}
                            handleNext={this.handleNext}
                            handleBack={this.handleBack}
                            steps={this.steps} />

            default:
                return 'Uknown stepIndex';
        }
    }

    render() {
        const {activeStep} = this.state;

        return (
            <div className="app">
                <div className="stripes">
                    <span className="topStripe"></span>
                </div>
                <div className="animated slideInUpTiny animation-duration-3">
                    <div className="row introBlock" style={styles.logo}>
                        <div className="col-md-2" />
                        <NavLink to="/">
                            <img src={logo} className="appLogo" alt="logo" />
                        </NavLink>
                    </div>
                    <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8 col-12">
                            <CardBox styleName="col-12" childrenStyle=" justify-content-center">
                                <div className="w-100">
                                    <Stepper activeStep={activeStep} alternativeLabel className="horizontal-stepper-linear">
                                        {this.steps.map((label, index) => {
                                            return (
                                                <Step key={label} className={`horizontal-stepper ${index === activeStep ? 'active' : ''}`}>
                                                    <StepLabel className="stepperlabel">{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                    <div className="row">
                                        <div className="col-md-2" />
                                        <div className="col-md-8 col-12">
                                            {this.getStepContent(activeStep)}
                                        </div>
                                    </div>
                                </div>
                            </CardBox>
                        </div>
                    </div>
                </div>
                <NotificationContainer />
            </div>
        )
    }
}

const styles = {
    logo: {
        marginTop: 10,
        marginBottom: 10
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    doesUrlExistAction,
    updateShopOnboardedAction,
    getShopInfoAction,
    saveStripeTokenAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(ShopOnboarding);
