
export default class CoffeeIcons {
    constructor() {
        this.images = [
            { key: 'espresso.png', defaultName: 'Espresso' },
            { key: 'americano.png', defaultName: 'Americano'  },
            { key: 'traditional-macchiato.png', defaultName: 'Macchiato (sm)' },
            { key: 'cappuaccino.png', defaultName: 'Cappuccino' },
            { key: 'latte.png', defaultName: 'Latte' },
            { key: 'mocha.png', defaultName: 'Mocha' },
            { key: 'drip.png', defaultName: 'Drip' },
            { key: 'pourover.png', defaultName: 'Pourover' },
            { key: 'frenchpress_for_2.png', defaultName: 'Frenchpress for 2' },
            { key: 'chemex.png', defaultName: 'Chemex' },
            { key: 'chemex_for_2.png', defaultName: 'Chemex for 2' },
            { key: 'hot-chocolate.png', defaultName: 'Hot Chocolate'},
            { key: 'steamer.png', defaultName: 'Steamer' },
            { key: 'tea.png', defaultName: 'Tea'},
            { key: 'macchiato.png', defaultName: 'Macchiato' },
            { key: 'cortado.png', defaultName: 'Cortado' },
            { key: 'smoothie.png', defaultName: 'Smoothie'},
            { key: 'cold-tea.png', defaultName: 'Iced Tea'},
            { key: 'cold-mocha.png', defaultName: 'Iced Mocha' },
            { key: 'cold-americano.png', defaultName: 'Iced Americano' },
            { key: 'cold-latte.png', defaultName: 'Iced Latte' },
            { key: 'cold-steamer.png', defaultName: 'Iced Steamer' },
            { key: 'cold-macchiato.png', defaultName: 'Iced Macchiato' },
            { key: 'cold-brewed-iced-coffee.png', defaultName: 'Cold Brew'},
            { key: 'blue-majik.png', defaultName: 'Blue Majik Latte' },
            { key: 'coffee-beans.png', defaultName: 'Coffee Beans'},
            { key: 'bottle-blue.png', defaultName: 'Bottled Drink'},
            { key: 'cold-blue-majik.png', defaultName: 'Iced Blue Majik'},
            { key: 'tea-berry.png', defaultName: 'Berry Tea'},
            { key: 'cold-tea-berry.png', defaultName: 'Iced Berry Tea'},
            { key: 'tea-ginger.png', defaultName: 'Ginger Tea'},
            { key: 'cold-tea-ginger.png', defaultName: 'Iced Ginger Tea'},
            { key: 'smoothie_blue.png', defaultName: 'Blue Smoothie'},
            { key: 'smoothie_ginger.png', defaultName: 'Ginger Smoothie'},
            //drink item images for Robinson
            { key: 'trr-drink-coffee-americano.png', defaultName: 'TRR - Americano' },
            { key: 'trr-drink-coffee-freshbrewed.png', defaultName: 'TRR - Fresh Brew' },
            { key: 'trr-drink-coffee-latte.png', defaultName: 'TRR - Latte' },
            { key: 'trr-drink-coffee-icedcoffee.png', defaultName: 'TRR - Iced Coffee' },
            { key: 'trr-drink-coffee-coldbrew.png', defaultName: 'TRR - Cold Brew' },
            { key: 'trr-drink-coffee-espresso.png', defaultName: 'TRR - Espresso' },
            { key: 'trr-drink-specialty-cerealmilk.png', defaultName: 'TRR - Cereal Milk Latte' },
            { key: 'trr-drink-specialty-electric.png', defaultName: 'TRR - Electric' },
            { key: 'trr-drink-specialty-matchablossom.png', defaultName: 'TRR - Matcha Blossom' },
            { key: 'trr-drink-specialty-robinsonlatte.png', defaultName: 'TRR - Robinson Latte' },
            { key: 'trr-drink-specialty-rosegold.png', defaultName: 'TRR - Rose Gold' },
            { key: 'trr-drink-specialty-saltedcream.png', defaultName: 'TRR - Salted Cream Coffee' },
            { key: 'trr-drink-specialty-taro.png', defaultName: 'TRR - Taro Latte' },
            { key: 'trr-drink-coffeecocktail-carajillo.png', defaultName: 'TRR - Carajillo' },
            { key: 'trr-drink-coffeecocktail-elevatedcoffeetonic.png', defaultName: 'TRR - Elevated Coffee Tonic' },
            { key: 'trr-drink-coffeecocktail-espressomartini.png', defaultName: 'TRR - Espresso Martini' },
            { key: 'trr-drink-coffeecocktail-oldfashioned.png', defaultName: 'TRR - Old Fashioned' },
            { key: 'trr-drink-juicecocktail-blueberrybasil.png', defaultName: 'TRR - Blueberry Basil' },
            { key: 'trr-drink-juicecocktail-bourbonlemonadee.png', defaultName: 'TRR - Bourbon Lemonadee' },
            { key: 'trr-drink-juicecocktail-orangehorizon.png', defaultName: 'TRR - Orange Horizon' },
            { key: 'trr-drink-juicecocktail-watermelonfalls.png', defaultName: 'TRR - Watermelon Falls' },
            { key: 'trr-drink-juicecocktail-watermelonrose.png', defaultName: 'TRR - Watermelon Rose' },
            { key: 'trr-drink-wine-rose.png', defaultName: 'TRR - Rosé' },
            { key: 'trr-drink-wine-sparkling.png', defaultName: 'TRR - Sparkling Wine' },
            { key: 'trr-drink-wine-red.png', defaultName: 'TRR - Red Wine' },
            { key: 'trr-drink-wine-white.png', defaultName: 'TRR - White Wine' },
            { key: 'trr-drink-bottle-crookedstaveipa.png', defaultName: 'TRR - Crooked Stave IPA' },
            { key: 'trr-drink-bottle-crookedstavevon.png', defaultName: 'TRR - Crooked Stave Von' },
            { key: 'trr-drink-bottle-redstripe.png', defaultName: 'TRR - Red Stripe' },
            { key: 'trr-drink-beer-bluemoon.png', defaultName: 'TRR - Blue Moon' },
            { key: 'trr-drink-bottle-stella.png', defaultName: 'TRR - Stella' },
            { key: 'trr-drink-default.png', defaultName: 'TRR - Drink - Default' },
            { key: 'trr-drink-none.png', defaultName: 'TRR - Drink - None' },
            { key: 'trr-drink-tea-assorted.png', defaultName: 'TRR - Tea' },
            { key: 'trr-drink-specialty-rosegoldlatte.png', defaultName: 'TRR - Rose Gold Latte' },
            { key: 'trr-drink-specialty-recharge.png', defaultName: 'TRR - Recharge' },
            { key: 'trr-drink-specialty-immunity.png', defaultName: 'TRR - Immunity' },
            { key: 'trr-drink-specialty-healthshot.png', defaultName: 'TRR - Health Shot' },
            { key: 'trr-drink-specialty-detox.png', defaultName: 'TRR - Detox' },
            { key: 'trr-drink-juicecocktail-strawbantini.png', defaultName: 'TRR - Straw Ban Tini' },
            { key: 'trr-drink-juicecocktail-matchahaven.png', defaultName: 'TRR - Match Haven' },
            { key: 'trr-drink-juicecocktail-berrycherry.png', defaultName: 'TRR - Berry Cherry' },
            { key: 'trr-drink-coffeecocktail-coffeetonic.png', defaultName: 'TRR - Coffee Tonic' },
            { key: 'trr-drink-cocktail-javaslide.png', defaultName: 'TRR - Java Slide' },
            { key: 'trr-drink-cocktail-millennialmilieu.png', defaultName: 'TRR - Millennial Milieu' },
            { key: 'trr-drink-cocktail-springdream.png', defaultName: 'TRR - Spring Dream' },
            { key: 'trr-drink-coffee-oatdraftlatte.png', defaultName: 'TRR - Oat Draft Latte' },
            { key: 'trr-drink-coffee-machiato.png', defaultName: 'TRR - Machiato' },
            { key: 'trr-drink-coffee-cortado.png', defaultName: 'TRR - Cortado' },
            { key: 'trr-drink-specialtycoffee-electriclemonade.png', defaultName: 'TRR - Electric Lemonade' },
            { key: 'trr-drink-specialtycoffee-strawberryupsidedownlatte.png', defaultName: 'TRR - Strawberry Upside Down' },
            { key: 'trr-drink-tea-chailatte.png', defaultName: 'TRR - Chai Latte' },
            { key: 'trr-drink-juice-hydration.png', defaultName: 'TRR - Hydration' },
            { key: 'trr-drink-coffee-cappuccino.png', defaultName: 'TRR - Cappuccino' },
            { key: 'trr-drink-coffee-batchcoffee.png', defaultName: 'TRR - Bath Coffee' },

            //food item images for Robinson
            { key: 'trr-food-smallplate-bangbang.png', defaultName: 'TRR - Bang Bang'},
            { key: 'trr-food-smallplate-brussellsprouts.png', defaultName: 'TRR - Brussell Sprouts'},
            { key: 'trr-food-smallplate-fries.png', defaultName: 'TRR - Truffle Fries'},
            { key: 'trr-food-smallplate-chickpea.png', defaultName: 'TRR - Chickpea Rolls'},
            { key: 'trr-food-smallplate-parfait.png', defaultName: 'TRR - Parfait'},
            { key: 'trr-food-smallplate-pudding.png', defaultName: 'TRR - Pudding'},
            { key: 'trr-food-toast-avocado.png', defaultName: 'TRR - Avocado Toast'},
            { key: 'trr-food-toast-supernova.png', defaultName: 'TRR - Supernova Toast'},
            { key: 'trr-food-worb_chicken.png', defaultName: 'TRR - Chicken Bowl'},
            { key: 'trr-food-worb_mongolian.png', defaultName: 'TRR - Mongolian Bowl'},
            { key: 'trr-food-worb_southwest.png', defaultName: 'TRR - Southwest Bowl'},
            { key: 'trr-food-worb_tunasaladbowl.png', defaultName: 'TRR - Tuna Salad Bowlå'},
            { key: 'trr-food-handheld-blackbean.png', defaultName: 'TRR - Blackbean Burger'},
            { key: 'trr-food-handheld-chickenbreakfast.png', defaultName: 'TRR - Chicken Breakfast Wrap'},
            { key: 'trr-food-handheld-chickencaesarwrap.png', defaultName: 'TRR - Chicken Caesar Wrap'},
            { key: 'trr-food-handheld-roastedchickenwrap.png', defaultName: 'TRR - Chicken Chicken Wrap'},
            { key: 'trr-food-handheld-southwestveggiewrap.png', defaultName: 'TRR - Southwest Veggie Wrap'},
            { key: 'trr-food-handheld-tunasaladwrap.png', defaultName: 'TRR - Tuna Salad Wrap'},
            { key: 'trr-food-handheld-classic.png', defaultName: 'TRR - Classic Sandwich'},
            { key: 'trr-food-handheld-crispychicken.png', defaultName: 'TRR - Crispy Chicken Sandwich'},
            { key: 'trr-food-handheld-spicycrispychicken.png', defaultName: 'TRR - Spicy Crispy Chicken Sandwich'},
            { key: 'trr-food-handheld-eggandcheese.png', defaultName: 'TRR - Egg and Cheese Sandwich'},
            { key: 'trr-food-handheld-tunasandwich.png', defaultName: 'TRR - Tuna Sandwich'},
            { key: 'trr-food-handheld-tunacroissant.png', defaultName: 'TRR - Tuna Croissant'},
            { key: 'trr-food-handheld-turkeyavocado.png', defaultName: 'TRR - Turkey Avocado Sandwich'},
            { key: 'trr-food-salad-arugula.png', defaultName: 'TRR - Arugula Salad'},
            { key: 'trr-food-salad-kale.png', defaultName: 'TRR - Kale Salad'},
            { key: 'trr-food-salad-chickencaesarsalad.png', defaultName: 'TRR - Chicken Caesar Salad'},
            { key: 'trr-food-smallplate-waffle.png', defaultName: 'TRR - Waffle'},
            { key: 'trr-food-pastry-1.png', defaultName: 'TRR - Pastry 1'},
            { key: 'trr-food-pastry-2.png', defaultName: 'TRR - Pastry 2'},
            { key: 'trr-food-pastry-3.png', defaultName: 'TRR - Pastry 3'},
            { key: 'trr-food-pastry-4.png', defaultName: 'TRR - Pastry 4'},
            { key: 'trr-food-pastry-5.png', defaultName: 'TRR - Pastry 5'},
            { key: 'trr-food-pastry-6.png', defaultName: 'TRR - Pastry 6'},
            { key: 'trr-food-pastry-7.png', defaultName: 'TRR - Pastry 7'},
            { key: 'trr-food-pastry-8.png', defaultName: 'TRR - Pastry 8'},
            { key: 'trr-food-pastry-9.png', defaultName: 'TRR - Pastry 9'},
            { key: 'trr-food-pastry-10.png', defaultName: 'TRR - Pastry 10'},
            { key: 'trr-food-pastry-11.png', defaultName: 'TRR - Pastry 11'},
            { key: 'trr-food-pastry-12.png', defaultName: 'TRR - Pastry 12'},
            { key: 'trr-food-pastry-13.png', defaultName: 'TRR - Pastry 13'},
            { key: 'trr-food-pastry-14.png', defaultName: 'TRR - Pastry 14'},
            { key: 'trr-food-pastry-15.png', defaultName: 'TRR - Pastry 15'},


            { key: 'trr-event-default.png', defaultName: 'TRR - Event'},
            { key: 'trr-event-brunch.png', defaultName: 'TRR - Brunch'},
            { key: 'trr-event-mixology.png', defaultName: 'TRR - Mixology'},
            { key: 'trr-event-coffee.png', defaultName: 'TRR - Coffee Tasting'},
            { key: 'trr-event-dance.png', defaultName: 'TRR - Dance'},
            { key: 'trr-event-education.png', defaultName: 'TRR - Education'},
            { key: 'trr-event-happyhour.png', defaultName: 'TRR - Happy Hour'},
            { key: 'trr-event-music-dj.png', defaultName: 'TRR - DJ'},
            { key: 'trr-event-music-live.png', defaultName: 'TRR - Live Music'},
            { key: 'trr-event-music-mic.png', defaultName: 'TRR - Open Mic'},
            { key: 'trr-event-bodypainting.png', defaultName: 'TRR - Body Painting'},
            { key: 'trr-event-paint.png', defaultName: 'TRR - Paint Night'},
            { key: 'trr-event-party.png', defaultName: 'TRR - Party'},
            { key: 'trr-event-trivia.png', defaultName: 'TRR - Trivia'},
            { key: 'trr-event-wine.png', defaultName: 'TRR - Wine Tasting'},
            { key: 'trr-event-yoga.png', defaultName: 'TRR - Yoga'}
        ]
    }

    // getImages() {
    //     //alphabetical order
    //     return this.images.sort((a, b) => a.defaultName.localeCompare(b.defaultName))
    // }

    getImages() {
            //alphabetical order
            return this.images.sort((a, b) => a.defaultName.localeCompare(b.defaultName));
    }

    iconForCurrentValue(value) {
        var containedIcon = {key: -1, defaultName: ""}
        for(var index in this.images) {
            var image = this.images[index]
            if(value.toLowerCase().includes(image.defaultName.toLowerCase())) {
                if(image.defaultName.length > containedIcon.defaultName.length) {
                    containedIcon = Object.assign({}, image)
                }
            }
        }
        return containedIcon.key
    }

}
