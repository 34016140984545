const jwtManager = () => {
    //let jwt = null;

    const getToken = () => localStorage.getItem('token');;

    const setToken = (token) => {
        localStorage.setItem('token', token);
    };

    const clearToken = () => {
        localStorage.removeItem('token');
    };

    return {
        getToken,
        setToken,
        clearToken
    }
}

export default jwtManager();
