import React, {Component} from 'react';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux'
import CoffeeImage from './CoffeeImage';
import ExtraMenu from './ExtraMenu';
import { updateCurrentItemAction, updateShowItemDescriptionAction, updateShowItemDiscountAction } from '../../../../actions/MenuBuilder';
import {
    changeItemNameAction,
    changePickupTimeAction,
    deleteItemAction,
    copyItemAction,
    pasteItemAction,
    changeItemAvailabilityAction,
    moveItemAction,
    changeItemDescriptionAction,
    changeItemDiscountValueAction,
    changeItemDiscountUnitAction,
    changeComplimentaryStatusAction,
    changeUpSellStatusAction,
    changeQuantityAction
} from '../../../../actions/Menu';
import TextFieldValidator from '../../util/TextFieldValidator'
import ErrorIcon from '@material-ui/icons/Error';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MixpanelTrack } from '../../../../util/MixpanelHelper';
import TextField from '@material-ui/core/TextField';
import selectors from '../../../../reducers/selectors';
import { pickupTimes, FIVE_TO_TEN } from './PickupTimes';
import { discountUnitTypes, DISCOUNT_UNIT_SELECT } from './DiscountUnitTypes';
import SelectValidator from '../../util/SelectValidator';
import MItem from '@material-ui/core/MenuItem';
import MenuItemDiscountUnits from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import GiftIcon from './iconMembershipGold.png';
import { Icon } from "@material-ui/core";
import {withRouter} from "react-router";

// This can be any component you want
const DragHandle = SortableHandle(() =>
    <i className="zmdi zmdi-menu draggable-icon d-none d-sm-flex" style={{fontSize: 25}}/>);


class EventItem extends Component {

    onSelectOption(option) {
        if(option === 'Delete') {
            this.props.deleteItemAction(this.props.i)
        } else if(option === 'Copy') {
            this.props.copyItemAction(this.props.i)
        } else if(option === 'Paste') {
            this.props.pasteItemAction(this.props.i)
        } else if(option.includes('Description')) {
            this.props.updateShowItemDescriptionAction(!this.props.menuUI.isShowItemDescription)
        } else if (option.includes('Complimentary')) {
            this.props.changeComplimentaryStatusAction(this.props.i, !this.props.item.isComplimentary)
        } else if (option.includes('Up-sell')) {
            this.props.changeUpSellStatusAction(this.props.i, !this.props.item.isUpsell)
        } else if(option.includes('Discount')) {
            this.props.updateShowItemDiscountAction(!this.props.menuUI.isShowItemDiscount)
        }
    }

    render() {
        const {i, item, onClickImage, menuUI, clipboard, hasComplimentary} = this.props
        var membershipEnabled = true

        var extraOptions = ['Delete', 'Show Description', 'Show Discount']//, 'Copy']
        if(Object.keys(clipboard.item).length !== 0) {
            extraOptions.push('Paste')
        }
        if(menuUI.isShowItemDescription) {
            extraOptions[2] = 'Hide Description'
        }
        if(menuUI.isShowItemDiscount) {
            extraOptions[3] = 'Hide Discount'
        }
        if (membershipEnabled) {//if complementary
            if (item.isComplimentary === undefined || !item.isComplimentary) {
              extraOptions.push('Set as Complimentary')
            } else {
              extraOptions.push('Cancel Complimentary')
            }
        }
        if (hasComplimentary && !item.isComplimentary) { //note: itemlist is still undefined
            if (item.isUpsell === undefined || !item.isUpsell) {
              extraOptions.push('Set as Up-sell')
            } else {
              extraOptions.push('Cancel Up-sell')
            }
        }

        return (
            <div className="module-list-item">
                <div className="row">
                    <div className="module-list-icon col-1">
                        <span className="bar-icon">
                            <DragHandle/>
                        </span>
                    </div>
                    <div className="col-2">
                        <TextFieldValidator
                            required
                            label="Event Name"
                            value={item.name}
                            onChange={event => this.props.changeItemNameAction(i, event.target.value)}
                            defaultValue=""
                            margin="normal"
                            fullWidth
                            errorMessage={item.errorMessage}
                        />
                    </div>
                    <div className="col-1" style={styles.button}>
                        <Button variant="contained" style={styles.imageButton} color="#c5c5c5" onClick={() => onClickImage(i, item.image)}>
                            {(item.image === "" || item.image == null)?
                                <span style={styles.buttonFont}>Select Icon</span>
                                :
                                <CoffeeImage name={item.image} style={styles.image}/>
                            }
                        </Button>
                        { (item.isImageValid === undefined || item.isImageValid)? null : <ErrorIcon style={styles.error}/>}
                    </div>

                    <div className="col-1">
                        <TextFieldValidator
                            required
                            label="Remaining Inventory"
                            inputProps={{style:{paddingTop:10}}}
                            value={item.inventory.quantity}
                            onChange={event => this.props.changeQuantityAction(i, event.target.value)}
                            defaultValue=""
                            margin="normal"
                            fullWidth
                            errorMessage={item.errorMessageQuantity}
                        />
                    </div>
                    <div className="col-1">
                        <TextFieldValidator
                            disabled
                            label="Tickets Sold"
                            inputProps={{style:{paddingTop:10}}}
                            value={item.inventory.quantitySold}
                            margin="normal"
                            fullWidth
                            errorMessage={item.errorMessageQuantity}
                        />
                    </div>
                    <div className="col-1">
                        <TextFieldValidator
                            disabled
                            label="Total Tickets"
                            inputProps={{style:{paddingTop:10}}}
                            value={(Number.isInteger(parseInt(item.inventory.quantity)) ? parseInt(item.inventory.quantity) : 0) + parseInt(item.inventory.quantitySold)}
                            margin="normal"
                            fullWidth
                            errorMessage={item.errorMessageQuantity}
                        />
                    </div>

                    <div className="col-2" style={styles.button}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!item.isAvailable}
                                    onChange={(event) => this.props.changeItemAvailabilityAction(i, !event.target.checked)}
                                    value={!item.isAvailable}
                                    color="primary" />
                            }
                            label="Marked Sold Out" />
                    </div>
                    <div className="col-2" style={styles.button}>
                        <Button variant="contained" color="#c5c5c5" onClick={() => {
                                this.props.updateCurrentItemAction(i)
                                MixpanelTrack('Clicked Modifiers')
                            }}>
                            <span style={styles.buttonFont}>Modifiers</span>
                        </Button>
                        <Button style={{marginLeft:10}} variant="contained" color="#c5c5c5" onClick={() => {
                            this.props.history.push(`/app/events/${item.menuItemId}?eventName=${item.name}`)
                            MixpanelTrack('Clicked Modifiers')
                        }}>
                            <span style={styles.buttonFont}>Guest List</span>
                        </Button>
                        { (item.isOptionsValid == undefined || item.isOptionsValid)? null : <ErrorIcon style={styles.error}/>}
                    </div>

                    <div className="col-1" style={styles.button}>
                    {item.isComplimentary &&//check if complementary
                      //show icon, add tooltip
                        <Tooltip title="Complimentary for Members">
                              <img src={GiftIcon} alt="GiftIcon"/>
                        </Tooltip>
                    }
                    {item.isUpsell &&
                        <Tooltip title="Up-sell">
                          <ThumbUpIcon color="primary"/>
                        </Tooltip>
                    }
                        <ExtraMenu options={extraOptions} onSelectOption={option => this.onSelectOption(option)} />
                    </div>
                </div>
                {menuUI.isShowItemDescription?
                    <div className="row">
                        <div className='col-1' />
                        <div className='col-10'>
                            <TextField
                                id="description"
                                label="Description"
                                value={item.description}
                                onChange={(event) => this.props.changeItemDescriptionAction(i, event.target.value)}
                                placeholder='Describe the item here'
                                multiline={true}
                                margin="normal"
                                inputProps={{ maxLength: 300 }}
                                fullWidth />
                            </div>
                    </div>
                    :
                    null
                }

                {menuUI.isShowItemDiscount?
                    <div className="row">
                        <div className='col-1' />
                        <div className='col-md-2'>
                            <TextField
                                label="Discount"
                                value={item.discountValue == null? '' : item.discountValue}
                                onChange={(event) => this.props.changeItemDiscountValueAction(i, event.target.value)}
                                placeholder="0.00"
                                margin="normal"
                                fullWidth
                                type="number"
                                errorMessage=""
                            />
                        </div>
                        <div className='col-2'>
                            <SelectValidator
                                label=""
                                // value={item.discountUnit}
                                value={item.discountUnit == null? DISCOUNT_UNIT_SELECT : item.discountUnit}
                                onChange={event => {
                                                    this.props.changeItemDiscountUnitAction(i, 'discountUnit', event.target.value, this.props.builderType)
                                                    }}
                                errorMessage={item.errorMessageDiscountUnit}
                                required
                                inputProps={{
                                    name: 'type'
                                }}>
                                {discountUnitTypes.map(obj => {
                                    return (<MenuItemDiscountUnits key={obj.value}
                                                value={obj.value}
                                                >
                                                {obj.text}
                                            </MenuItemDiscountUnits>)
                                })}
                            </SelectValidator>
                        </div>
                    </div>
                    :
                    null
                }

            </div>
        )
    }
}

const styles = {
    image: {
        width: 30,
        height: 30,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
    },
    imageButton: {
        padding: 0
    },
    buttonFont: {
        fontSize: 11
    },
    error: {
        position: 'absoliute',
        color: '#ff0000',
        marginLeft: -10,
        marginTop: -35,
        zIndex: 10
    }
}

const mapStateToProps = state => {
    return {
        menuUI: selectors(state).menuUI,
        clipboard: selectors(state).clipboard
    }
}
const actionCreators = {
    updateCurrentItemAction,
    changeItemNameAction,
    changePickupTimeAction,
    deleteItemAction,
    copyItemAction,
    pasteItemAction,
    changeItemAvailabilityAction,
    moveItemAction,
    updateShowItemDescriptionAction,
    changeItemDescriptionAction,
    changeComplimentaryStatusAction,
    changeUpSellStatusAction,
    updateShowItemDiscountAction,
    changeItemDiscountValueAction,
    changeItemDiscountUnitAction,
    changeQuantityAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(withRouter(EventItem));
