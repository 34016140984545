

import { 
        UPDATE_ITEM_INDEX,
        DRINK_OR_FOOD,
        FIRST_MENU_LOAD,
        UPDATE_DEFAULT_MODIFIER_INDEX,
        types
    } from '../constants/ActionTypes';

const INIT_STATE = {
    currentItemIndex: null,
    drinkOrFood: 'drinks',
    isFirst: true,
    selectedDefaultIndex: 0,
    isFirstDefaultModifiers: true,
    isShowItemDescription: false,
    isShowItemDiscount: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_ITEM_INDEX: {
            return {
                ...state,
                currentItemIndex: action.payload
            }
        }
        case DRINK_OR_FOOD: {
            return {
                ...state,
                drinkOrFood: action.payload,
                currentItemIndex: null
            }
        }
        case FIRST_MENU_LOAD: {
            return {
                ...state,
                isFirst: false
            }
        }
        case types.FIRST_DEFAULT_MODIFIERS: {
            return {
                ...state,
                isFirstDefaultModifiers: false
            }
        }
        case UPDATE_DEFAULT_MODIFIER_INDEX: {
            return {
                ...state,
                selectedDefaultIndex: action.payload
            }
        }
        case types.SHOW_ITEM_DESCRIPTION: {
            return {
                ...state,
                isShowItemDescription: action.payload
            }
        }
        case types.SHOW_ITEM_DISCOUNT: {
            return {
                ...state,
                isShowItemDiscount: action.payload
            }
        }
        default:
            return state
    }
}