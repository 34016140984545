import React, { Component } from 'react';
import ContainerHeader from '../../util/ContainerHeader';
import CardBox from '../util/CardBox';
import DatePicker from './components/DatePicker';
import TopFiveGraph from './../util/TopFiveGraph';
import DataTable from '../util/DataTable';
import { connect } from 'react-redux';
import DatabaseValidator from './../../util/DatabaseValidator';
import { Divider } from '@material-ui/core';
import '../util/styles/dateANDGraph.css';
import NoDataCard from './../util/NoDataCard';
import GraphFormatter from './../util/GraphFormatter';

class ItemSales extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
        }
    }

    formatItemSalesData(data) {
        let resultArray = [
            { value: data.name, numeric: false },
            { value: (data.count.toString()), numeric: true },
            { value: ("$" + data.gross.toFixed(2).toString()), numeric: true }
        ];
        return resultArray;
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    }
    render() {
        const columnData = [
            { id: 'name', numeric: false, disablePadding: true, label: 'Item' },
            { id: 'count', numeric: true, disablePadding: false, label: 'Number of Orders' },
            { id: 'gross', numeric: true, disablePadding: false, label: 'Gross Sales' },
        ];
        let gf = new GraphFormatter();
        let dv = new DatabaseValidator();
        let dataVis = '';
        if (!dv.isEmpty(this.props.itemSales)) {
            dataVis = <DataTable className="tableCard" title='Item Sales' columnData={columnData} dataFormatter={gf.formatSalesData} data={this.props.itemSales}
                onChangePage={this.handleChangePage}
                page={this.state.page} />
        } else {
            dataVis = <NoDataCard type="item sale" />
        }
        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <ContainerHeader match={this.props.match} title="Item Sales" />

                <div className="row col-12 datePicker"><DatePicker /></div>
                <Divider />
                <CardBox styleName="col-12 graphCard" cardStyle="mb-0 p-0">
                    <div className="row col-12 "><TopFiveGraph data={this.props.topFiveItemsData} isMoney={true} /></div>
                </CardBox>
                {dataVis}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        nothing: "",
        itemSales: state.analytics.itemSales,
        topFiveItemsData: state.analytics.topFiveItems
    }
}

export default connect(
    mapStateToProps
)(ItemSales);