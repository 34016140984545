import { baseUrl } from '../constants/baseUrl';
import { 
    NOT_IN_STORE
} from '../constants/ActionTypes';

export const addPromoCodeAction = (paramsObj, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: paramsObj,
    meta: {
        type: 'api',
        url: baseUrl + '/promo_code',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {},
        options: {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(paramsObj)
        }
    }
})

export const deactivatePromoCodeAction = (promoCodeId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: promoCodeId,
    meta: {
        type: 'api',
        url: baseUrl + '/deactivate_promo_code',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            promoCodeId: promoCodeId
        },
        options: {
            method: 'POST'
        }
    }
})
