
import React, {Component} from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';

export default class OpenClosedSwitch extends Component {

    render(props) {
        return(
            <FormControlLabel
                control={
                    <Switch color="primary"
                        classes={{
                            checked: 'text-primary',
                            bar: 'bg-primary',
                        }}
                        checked={this.props.switch}
                        onChange={(event, checked) => this.props.onChange(checked)}
                    />
                }
                label={this.props.switch? "Open" : "Closed"} />)
    }
}