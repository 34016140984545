import React, { Component } from 'react';
import { DateRangePicker, focusedInput, onDatesChange, isInclusivelyBeforeDay } from 'react-dates';
import { connect } from 'react-redux';
import { getGrossSalesRangeAction } from './../../../../../../../actions/GrossSalesRange';
import { getGrossOrdersAction } from './../../../../../../../actions/GrossOrders';
import { getGrossSalesHoursAction } from './../../../../../../../actions/GrossSalesHours';
import { getTableDRDataAction } from './../../../../../../../actions/GetTableDRData';
import '../../../../util/styles/dateANDGraph.css';
import selectors from '../../../../../../../reducers/selectors';

class DatePicker extends Component {

    onDatesChange(startDate, endDate) {
        if (!startDate || !endDate) {
            return;
        } else {
            if (startDate.isValid() && endDate.isValid()) {
                let paramObj = {
                    shopId: this.props.shopId,
                    dateFirst: startDate.unix(),
                    dateSecond: endDate.unix(),
                };
                this.props.getGrossSalesRangeAction(Object.assign(paramObj, { timeBlock: 'DAY' }));
                this.props.getGrossOrdersAction(Object.assign(paramObj, { timeBlock: 'DAY' }));
                this.props.getGrossSalesHoursAction(Object.assign(paramObj, { timeBlock: 'HOUR' }));
                this.props.getTableDRDataAction(paramObj);
            }
        }
    }

    constructor(props) {
        super(props);
        var moment = require('moment');
        moment().format();
        this.state = {
            startDate: moment().day(-7),
            endDate: moment().day(0),
            focusedInput: null
        };
        //Set default dates to the last complete week
        this.onDatesChange(this.state.startDate, this.state.endDate);
    }



    render() {
        var moment = require('moment');
        moment().format()
        return (
            <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate }, this.setState(this.onDatesChange(startDate, endDate)))} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                numberOfMonths={2}
                initialVisibleMonth={() => moment().subtract(1, 'month')}
            />
        );
    }
}
const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}

const actionCreators = {
    getGrossSalesRangeAction,
    getGrossOrdersAction,
    getGrossSalesHoursAction,
    getTableDRDataAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(DatePicker);