import React, { Component } from 'react';
import MoneyModule from './MoneyModule';
import { NavLink } from 'react-router-dom';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import windowSize from 'react-window-size';
import { MixpanelTrack } from '../../util/MixpanelHelper';

class MoneyStats extends Component {
    onCaseStudyClicked() {
        NotificationManager.error("The Case Study is not ready yet. Try again later!", "Case Study Coming Soon", 5000)
        MixpanelTrack('Clicked on Case Study')
    }

    render() {
        return (
            <div className='blockWrapper moneyStats'>
                <div className='row'>
                    <div className='col-md-2 col-2' />
                    <div className='col-6'>
                        <h1 style={{ fontSize: 40, color: '#333333', marginBottom: '5%' }}>Pricing</h1>
                    </div>
                </div>
                <div className="row">
                    <span className="col" />
                    <div className="col-md-4 px-lg-4">
                        <div className="price-tables row pt-default d-flex justify-content-around">
                            <MoneyModule
                                styleName="card package bg-white shadow"
                                headerStyle="package-header bg-primary text-white"
                                itemStyle="package-items text-grey text-darken-3"
                                footerStyle="btn btn-default bg-primary text-white" />
                        </div>
                    </div>
                    <a href={'http://blog.coffeepass.io/coffeepass/wayfarer/data/2018/12/15/case-study.html'} style={{ textDecoration: 'none' }} className="caseStudyWrapper col-md-4">
                        <div className="caseStudy">
                            <div className="caseStudyText caseStudyText">Check out the Case Study!</div>
                        </div>
                    </a>
                    <span className="col"></span>
                </div>
                <NotificationContainer />
            </div>
        )
    }
}

export default windowSize(MoneyStats);