
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default class OverrideSureDialog extends Component {

    render(props) {
        return(
            <Dialog
                open={this.props.isDialogOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to overwrite the shop's {this.props.dialogText} at location: {this.props.location}? This will update the shop's {this.props.dialogText} to be the same as the chain template and can be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onNoClicked} color="primary">
                        No
                    </Button>
                    <Button onClick={() => this.props.onYesClicked(this.props.dialogText.includes('info'))} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            )
    }
}

OverrideSureDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onYesClicked: PropTypes.func.isRequired,
    onNoClicked: PropTypes.func.isRequired,
    dialogText: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired
}