import React, { Component } from 'react';
import ChartCard from './ChartCard';
import { connect } from 'react-redux';
import { getGrossSalesToday } from './../../../../../actions/GrossSalesToday';
import DatabaseValidator from './../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangeHelper from '../../../util/DateRangeHelper';
import selectors from '../../../../../reducers/selectors';

class GrossSalesToday extends Component {

    constructor(props) {
        super(props);

        const dateRangeHelper = new DateRangeHelper()
        const range = dateRangeHelper.getDay()
        const paramObj = {
            shopId: this.props.shopId,
            timeBlock: 'MONTH',
            dateFirst: range.dateFirst,
            dateSecond: range.dateSecond
        }
        this.props.getGrossSalesToday(paramObj);
    }

    render() {
        console.log('sales today', this.props.grossSalesToday)
        const dbv = new DatabaseValidator();
        const grossSalesResult = dbv.isEmpty(this.props.grossSalesToday)?  0 : parseInt(this.props.grossSalesToday[0]['amt'])
        const dataVis = dbv.isEmpty(this.props.grossSalesToday) ? <div className="manage-margin"><CircularProgress color="secondary" /> </div> : <h2><i className="zmdi zmdi-money"></i> {grossSalesResult.toFixed(2)}</h2>
        return (
            <div className="col-lg-3 col-sm-6 col-12">
                <ChartCard styleName="bg-primary text-white">
                    <div className="chart-title">
                        {dataVis}
                        <p>Gross Sales Today</p>
                    </div>

                    <div style={{height: 25}} />
                    {/* <ResponsiveContainer width="100%" height={110}>
                        <AreaChart data={this.props.data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                            <Area type="monotone" dataKey="pv" stroke="rgba(255,255,255,0.5)" activeDot={{ r: 8 }}
                                fillOpacity={.5}
                                fill="white" />
                        </AreaChart>
                    </ResponsiveContainer> */}
                </ChartCard>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        grossSalesToday: state.analytics.grossSalesToday
    }
}

const actionCreators = {
    getGrossSalesToday
}

export default connect(
    mapStateToProps,
    actionCreators
)(GrossSalesToday);