import React, {Component} from 'react';
import { Switch, FormControlLabel, TextField } from '@material-ui/core';
import SaveSureDialog from './SaveSureDialog';
import Button from '@material-ui/core/Button';
import PopoverItem from './PopoverItem'
import { getShopRewardsAction, switchShopRewardsAction } from '../../../actions/ShopInfo';
import { connect } from 'react-redux';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import selectors from '../../../reducers/selectors';

class ShopRewards extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isEnabled: true,
            isDialogOpen: false
        }

        this.stateCopy = Object.assign(this.state, {})

        this.props.getShopRewardsAction(this.props.shopId,
            (resp) => {
                if(resp.val != 'False') {
                    this.stateCopy = Object.assign(resp, {}, {isDialogOpen: false})
                    this.setState(resp)
                }
            },
            (error) => {
                console.log("getShopRewardsAction error", error)
            })

        this.onSaveClicked = this.onSaveClicked.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.onDialogYesClicked = this.onDialogYesClicked.bind(this)
        this.isStateDifferent = this.isStateDifferent.bind(this)
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    onSwitchChange = (checked) => {
        this.setState({isEnabled: checked})
    }

    onSaveClicked() {
        this.setState({
            isDialogOpen: true
        })
    }

    handleDialogClose() {
        this.setState({isDialogOpen: false})
    }

    onDialogYesClicked() {
        this.handleDialogClose()
        const paramsObj = Object.assign({}, this.state, {
            shopId: this.props.shopId,
            isEnabled: this.state.isEnabled
        })

        this.props.switchShopRewardsAction(paramsObj,
            (resp) => {
                if(resp.val != 'False') {
                    NotificationManager.success("The new settings are now saved in CoffeePass!", "Success")
                    this.stateCopy = Object.assign(this.state, {})
                    this.setState(this.state)
                } else {
                    NotificationManager.error("There was an Error saving the shop rewards. If the problem persists contact support@coffeepass.io.", "Error Saving shop rewards!", 9000)
                }
            },
            (error) => {
                NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error saving Settings!", 9000)
            })
    }

    isStateDifferent() {
        for(var key in this.state) {
            var val = this.state[key]
            var copyVal = this.stateCopy[key]
            if(val != copyVal) {
                return true
            }
        }
        return false
    }

    render(props) {
        return(
            <form className="row" autoComplete="off">
                <div className="col-md-12 col-12">
                    <FormControlLabel
                        control={
                            <Switch color="primary"
                                classes={{
                                    checked: 'text-primary',
                                    bar: 'bg-primary',
                                }}
                                checked={this.state.isEnabled}
                                onChange={(event, checked) => this.onSwitchChange(checked)}
                            />
                        }
                        label={this.state.isEnabled? "Rewards currently ON" : "Rewards currently OFF"} />
                    <PopoverItem 
                        placement={'right'} 
                        id={'rewardsinfoId'}
                        title="What is This?"
                        text="You can switch ON/OFF shop rewards at anytime." />
                </div>
                <div className="col-md-12 col-12" style={styles.buttonContainer}>
                    <Button
                        variant="raised"
                        color="primary"
                        className="jr-btn"
                        disabled={!this.isStateDifferent()}
                        onClick={this.onSaveClicked}>
                        Save
                    </Button>
                </div>
                <SaveSureDialog
                    isDialogOpen= {this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    onYesClicked={this.onDialogYesClicked}
                    onNoClicked={this.handleDialogClose}
                    dialogDataToSave="shop rewards"
                    />
                <NotificationContainer />
            </form>
            )
    }
}

const styles = {
    buttonContainer: {
        display: 'flex', 
        justifyContent: 'flex-end'
    }
}
const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    getShopRewardsAction,
    switchShopRewardsAction
}
export default connect(
    mapStateToProps,
    actionCreators
)(ShopRewards);