import React from 'react';
import { PropTypes } from 'prop-types';
import InfoCard from './InfoCard';
import money from '../../../assets/analytics/money.png'
import happy from '../../../assets/analytics/happy.png'
import flag from '../../../assets/analytics/flag.png'

export default class MainDataPoints extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var data = [
            { title: '$' + this.props.subtotal, subTitle: 'Subtotal', img: money, styleName: 'bg-cyan darken-2' },
            { title: '$' + this.props.tax, subTitle: 'Tax', img: flag, styleName: 'bg-secondary' },
            { title: '$' + this.props.tip, subTitle: 'Tip', img: happy, styleName: 'bg-primary' },
            { title: '$' + this.props.discounts, subTitle: 'Discount', img: money, styleName: 'bg-red lighten-1' },
            { title: '$' + this.props.total, subTitle: 'Total', img: money, styleName: 'bg-teal lighten-1' },
            { title: '$' + this.props.fee, subTitle: 'Fees', img: money, styleName: 'bg-blue darken-2' },
            { title: '$' + this.props.net, subTitle: 'Net', img: money, styleName: 'bg-green darken-2' }
        ]

        return (
            <div className="col-xl-9 col-lg-10 col-9">
                <div style={{display: "flex"}}>
                    {data.map(d => {
                        return (<div key={d.subTitle} className="col-2">
                            <InfoCard data={d} styleName={d.styleName} />
                        </div>)
                    })}
                </div>
            </div>
        );
    }
}

MainDataPoints.propTypes = {
    subtotal: PropTypes.string.isRequired,
    tax: PropTypes.string.isRequired,
    tip: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
}
