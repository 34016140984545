import React, { Component } from 'react';

class TeamMember extends Component {
    render() {
        return (
            <div className="m-1 teamCard">
                <div className="jr-card px-0 pt-sm-5 text-center">
                    <img className="size-120 avatar-shadow rounded-circle mx-auto mb-2" src={this.props.image} alt="Team-member"/>
                    <div className="card-body bg-transparent">
                        <h3 className="card-title">{this.props.name}</h3>
                        <span className="post-designation">{this.props.destination}</span>
                        <p className="card-text">{this.props.description}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamMember;
