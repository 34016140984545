import React, {Component} from 'react';
import { Switch, FormControlLabel, TextField } from '@material-ui/core';
import SaveSureDialog from './SaveSureDialog';
import Button from '@material-ui/core/Button';
import PopoverItem from './PopoverItem'
import {getShopRewardsAction, getShopUserAction, switchShopRewardsAction} from '../../../actions/ShopInfo';
import { connect } from 'react-redux';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import selectors from '../../../reducers/selectors';
import PickupButtonDialogue from "./PickupButtonDialogue";
import {updateCheckoutButtons} from "../../../actions/UpdateCheckoutButtons";

class PickupOptions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isDialogOpen: false,
            selectedOption: null,
            selectedIndex: null,
            saving: false,
            error: null,
            membershipEnabled: false
        }

        this.stateCopy = Object.assign(this.state, {})

        this.onAddClicked = this.onAddClicked.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.onEditOption = this.onEditOption.bind(this)
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.getShopUserAction(this.props.shopId,
            (respShop) => {
                if(respShop.val !== false) {
                    this.setState({membershipEnabled: respShop.membershipEnabled})
                }
            },
            (error) => {
                console.log("get shop user error", error)
            })
    }


    onSave(newOption, doDelete) {
       let options = [...this.props.pickupButtons];

       if(this.state.selectedIndex === null && !doDelete) {
           options.push(newOption)
       } else if(!doDelete) {
           options[this.state.selectedIndex] = newOption;
       } else {
           options.splice(this.state.selectedIndex);
       }
       this.setState({saving: true});
       this.props.updateCheckoutButtons(options, this.props.shopId, () => this.onSuccess(), () => this.onFailure())
    }

    onSuccess() {
        this.handleDialogClose();
        this.props.onSave();
    }

    onFailure() {
        NotificationManager.error("There was an issue saving the pickup options.", "Error Saving Info!", 5000);
        this.setState({isDialogOpen: false, selectedOption: null, selectedIndex: null, saving: false});
    }

    onAddClicked() {
        this.setState({
            isDialogOpen: true
        })
    }

    handleDialogClose() {
        this.setState({isDialogOpen: false, selectedOption: null, selectedIndex: null, saving: false});
    }

    onEditOption(index) {
        this.setState({selectedOption: this.props.pickupButtons[index], isDialogOpen: true, selectedIndex: index});
    }



    onDialogYesClicked() {
        this.handleDialogClose()
        const paramsObj = Object.assign({}, this.state, {
            shopId: this.props.shopId,
            isEnabled: this.state.isEnabled
        })

        this.props.switchShopRewardsAction(paramsObj,
            (resp) => {
                if(resp.val != 'False') {
                    NotificationManager.success("The new settings are now saved in CoffeePass!", "Success")
                    this.stateCopy = Object.assign(this.state, {})
                    this.setState(this.state)
                } else {
                    NotificationManager.error("There was an Error saving the shop rewards. If the problem persists contact support@coffeepass.io.", "Error Saving shop rewards!", 9000)
                }
            },
            (error) => {
                NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error saving Settings!", 9000)
            })
    }


    getButtons() {
        return this.props.pickupButtons.map((button, index) =>
                <li key={index}><div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>{`${button.label} ${index === 0 ? '(default)' : ''}`}
                    <Button
                    variant="raised"
                    size={"small"}
                    className="jr-btn"
                    onClick={() => this.onEditOption(index)}>
                        Edit</Button></div></li>
        )
    }

    render(props) {
        return(
            <form className="row" autoComplete="off">
                <div className="col-md-12 col-12">
                    <h4>Add a pick-up or dine-in option</h4>
                    <ol style={{padding: 12}}>
                    {this.getButtons()}
                    </ol>
                    <Button
                        variant="raised"
                        color="primary"
                        className="jr-btn"
                        onClick={this.onAddClicked}>
                        + Add
                    </Button>
                </div>
                <PickupButtonDialogue
                    isDialogOpen= {this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    onSaveClicked={this.onSave}
                    dialogDataToSave="pickup options"
                    selectedOption={this.state.selectedOption}
                    saving={this.state.saving}
                    selectedIndex={this.state.selectedIndex}
                    membershipEnabled={this.state.membershipEnabled}
                    />
                <NotificationContainer />
            </form>
            )
    }
}

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}
const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    updateCheckoutButtons,
    getShopUserAction
}
export default connect(
    mapStateToProps,
    actionCreators
)(PickupOptions);
