import React, { Component } from 'react'
import ReportBox from './ReportBox';
import { Area, XAxis, YAxis, AreaChart, CartesianGrid, Tooltip } from 'recharts';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatabaseValidator from './../../../../../util/DatabaseValidator';
import NoDataCard from '../../../../util/NoDataCard';
import GraphFormatter from './../../../../util/GraphFormatter';

class TimeOfDay extends Component {

    constructor(props) {
        super(props);
    }

    compareHours(a, b) {
        let hourA = parseInt(a.date);
        let hourB = parseInt(b.date);
        return (hourA - hourB);
    }


    setupData(data, checkForHourAndInsert, parent) {
        //GOAL: to insert data that is 0 for all hours not retrieved by database UP TO the present hour
        //Find number of present hour in 24-hour scheme
        let presentHour = new Date().getHours();
        //loop from opening time to present hour num
        for (var hour = this.props.shopOpensAt; hour <= presentHour; hour++) {
            checkForHourAndInsert.apply(parent, [data, hour]);
        }

        //Sort the hours
        data.sort(this.compareHours)
        //Turns 24 hour integers to 12-hour strings
        var moment = require('moment')
        for (let obj in data) {
            let hourString = data[obj].date + ':00';
            data[obj]['date'] = moment.utc(hourString, "h:mm").local().format("h:mmA")
        }
        return (data);
    }

    checkForHourAndInsert(data, hour) {
        //check for if data exists for that hour, if not, insert values.
        var insertBool = true;
        for (let index in data) {
            if (hour == data[index].date) {
                insertBool = false;
                break;
            }
        }
        if (insertBool == true) {
            data.push({
                amt: 0.00,
                date: hour.toString()
            });
        }
    }

    render() {
        let graphFormatter = new GraphFormatter();
        let dv = new DatabaseValidator();
        let dataVis = ''
        if (!dv.isEmpty(this.props.grossSalesHours)) {
            if (this.props.grossSalesHours.date == 0) {
                dataVis = <NoDataCard type='hourly sales' />
            } else {
                dataVis = <AreaChart data={this.setupData(this.props.grossSalesHours, this.checkForHourAndInsert, this)}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#FFA441" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#FFA441" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis tickFormatter={graphFormatter.moneyFormatter} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip formatter={graphFormatter.moneyFormatter} />
                    <Area type="monotone" dataKey="amt" stroke="#FFA441" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
            }
        } else {
            dataVis = <CircularProgress size={100} color="secondary" />
        }
        return (
            <div className="col-9">
                <ReportBox heading="Time of Day">
                    {dataVis}
                </ReportBox>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        nothing: "",
        shopId: state.auth.shop.shopId,
        grossSalesHours: state.analytics.grossSalesHours,
        shopOpensAt: 5
    }
}

export default connect(
    mapStateToProps,
)(TimeOfDay);