
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import ArrowIcon from '@material-ui/icons/ArrowForward';
import stripeImage from '../../assets/onboarding/powered_by_stripe.png'
import UniqueIdGenerator from '../../util/UniqueIdGenerator';
import { connect } from 'react-redux';
import { getShopInfoAction, getShopStripeIdAction } from '../../actions/ShopInfo';
import SaveStepButton from './SaveStepButton';
import { MixpanelTrack } from '../../util/MixpanelHelper';
import selectors from '../../reducers/selectors';

class SetupPayments extends Component {
    constructor(props) {
        super(props)

        this.stateValue = new UniqueIdGenerator().getId()
        
        this.clientId = 'ca_DkY2X2RRxcL4IWMcQNJ3uKFJHEUHpg8z' //'ca_DkY2LQiEDeS2Lpo7ImeYYM6cAtf6irsC'
        const url = 'https://connect.stripe.com/express/oauth/authorize?' + 
                    // 'redirect_uri=localhost:3000/#/onboarding' +
                    '&client_id=' + this.clientId +
                    '&state=' + this.stateValue +
                    '&business_type=company'

        this.state = {
            isSetup: false,
            url: url
        }

    }

    componentDidMount() {
        this.props.getShopInfoAction(this.props.shopId,
            (resp) => {
                // const shop = this.props.shop
                if(resp.ownerEmail != undefined) {
                    const url = 'https://connect.stripe.com/express/oauth/authorize?' + 
                                // 'redirect_uri=localhost:3000/#/onboarding' +
                                '&client_id=' + this.clientId +
                                '&state=' + this.stateValue +
                                '&business_type=company' +
                                '&business_name=' + resp.shopName +
                                '&first_name=' + resp.ownerName +
                                '&email=' + resp.ownerEmail +
                                '&phone_number' + resp.phoneNumber
                    this.setState({url: url})
                }
            }, (error) => {
                console.log('error', error)
            })

        this.props.getShopStripeIdAction(this.props.shopId,
            (resp) => {
                console.log('resp payments', resp)
                if(resp != '') {
                    this.setState({isSetup: true})
                }
            },
            (error) => {
                console.log('error', error)
            })
    }

    render() {
        const {activeStep} = this.props
        
        return <div className="tab-pane" id="tab2-4">
                    <div className="d-flex row justify-content-center">
                        <h3 className="title text-primary">Connect your CoffeePass account to Stripe</h3>
                        <p className="title">We use Stripe to make sure you get paid on time and keep your personal and bank details secure.</p>
                        {(this.state.isSetup)?
                            <h3 className="title text-primary">You're all set with your Stripe Account!</h3>
                            :
                            <a href={this.state.url} onClick={() => MixpanelTrack('Clicked Setup Payments on Stripe')}>
                                <Button color="primary" variant="raised" style={{marginTop: 20, marginBottom: 20}}>
                                    Setup Payments on Stripe
                                    <ArrowIcon />
                                </Button>
                            </a>
                        }
                    </div>
                    <img src={stripeImage} />
                    <div className="col-md-12 col-12" style={styles.buttonContainer}>
                        <SaveStepButton {...this.props}
                            activeStep={activeStep}
                            isStateDifferent={() => {}} 
                            onSaveClicked={() => {}}
                            isLoadingSave={false}
                            onDialogYesClicked={(handleNext)=> handleNext()} />
                    </div>
                </div>
    }
}

const styles = {
    buttonContainer: {
        display: 'flex', 
        justifyContent: 'flex-end'
    }
}

const mapStateToProps = state => {
    return {
        shop: selectors(state).shop,
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    getShopInfoAction,
    getShopStripeIdAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(SetupPayments)