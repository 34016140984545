
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class SaveStepButton extends Component {

    render(props) {
        const {activeStep, steps, isLoadingSave, isStateDifferent, onSaveClicked, onDialogYesClicked, handleNext, handleBack} = this.props
        
        return(
            <div>
                {(activeStep == undefined)?
                    <div className="col-md-12 col-12" style={styles.buttonContainer}>
                        <Button
                            variant="raised"
                            color="primary"
                            className="jr-btn"
                            disabled={!isStateDifferent()}
                            onClick={onSaveClicked}>
                            {isLoadingSave? 
                                <CircularProgress size={16} style={styles.progress} />
                                :
                                "Save"
                            }
                        </Button>
                    </div>
                    :
                    <div className="col-md-12 col-12" style={styles.buttonContainer}>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className="mr-2">
                            Back
                        </Button>
                        <Button variant="raised" color="primary" onClick={() => onDialogYesClicked(handleNext)}>
                            {isLoadingSave? 
                                <CircularProgress size={16} style={styles.progress} />
                                :
                                activeStep === steps.length - 1 ? 'Finish' : 'Next'
                            }
                        </Button>
                    </div>
                }
            </div>)
    }
}

const styles = {
    buttonContainer: {
        display: 'flex', 
        justifyContent: 'flex-end'
    },
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    }
}

SaveStepButton.propTypes = {
    activeStep: PropTypes.number,
    steps:  PropTypes.array,
    isLoadingSave: PropTypes.bool,
    isStateDifferent: PropTypes.func.isRequired,
    onSaveClicked: PropTypes.func.isRequired,
    onDialogYesClicked: PropTypes.func.isRequired,
    handleNext: PropTypes.func,
    handleBack: PropTypes.func
}