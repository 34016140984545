import React from 'react';

import ContainerHeader from '../util/ContainerHeader';
import CardBox from '../analytics/util/CardBox';

import { Component } from 'react';
import Hours from './Hours';
import AddressFields from './AddressFields';
import ShopImageUpload from './ShopImageUpload';
import MiscFields from './MiscFields';
import ShopUser from './ShopUser';
import ShopRewards from './ShopRewards';
import Membership from './Membership';
import { MixpanelTrack } from '../../../util/MixpanelHelper';

import { connect } from 'react-redux';
import selectors from '../../../reducers/selectors';
import { getShopInfoAction } from '../../../actions/ShopInfo';
import PickupOptions from "./PickupOptions";

class ShopInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            src: null,
            crop: {
                x: 10,
                y: 10,
                width: 80,
                height: 80,
            },
            shopInfoFetched: false,
            logoUrl: '',
            bannerUrl: '',
            pickupUrl: '',
            pickupButtons: null
        }

        this.refreshInfo = this.refreshInfo.bind(this);

        this.refreshInfo();

    }

    refreshInfo() {
        this.props.getShopInfoAction(this.props.shopId,
            (resp) => {
                if(resp.val != false) {
                    this.setState({shopInfoFetched: true, logoUrl: resp.logoUrl, bannerUrl: resp.bannerUrl, pickupUrl: resp.pickupUrl, pickupButtons: JSON.parse(resp.checkoutButtons)})
                }
            },
            (error) => {
                console.log("getshop info error", error)
            })
    }

    componentDidMount() {
        MixpanelTrack("Visited ShopInfo Page");
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    render() {
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={"Shop Info"} match={this.props.match}/>
                <div style={{margin: 20}}>
                    <div className="row">
                        {this.state.shopInfoFetched?
                            <CardBox styleName="col-lg-12" heading={"Shop Images"}>
                                <div className='row'>
                                    <div className="col-md-3 col-12">
                                        <ShopImageUpload
                                            imgNameEnding='_logo.png'
                                            imgName='Shop Logo'
                                            imgRatio={1/1}
                                            imgUrl = {this.state.logoUrl}
                                            text="Upload your shop logo so your users can see your familar brand. This will show up in CoffeePass."/>
                                    </div>
                                    <div className="col-md-9 col-12">
                                        <ShopImageUpload
                                            imgNameEnding='_banner.png'
                                            imgName='Shop Banner'
                                            imgRatio={8/5}
                                            imgUrl = {this.state.bannerUrl}
                                            text="Upload a shop banner so your users can see your familar brand. This will show up in CoffeePass."/>
                                    </div>
                                    <div className="col-md-6 col-12" style={{marginTop: 40}}>
                                        <ShopImageUpload
                                            imgNameEnding='_pickup.png'
                                            imgName='Pickup Station'
                                            imgRatio={8/5}
                                            isPickupImage={true}
                                            imgUrl = {this.state.pickupUrl}
                                            text="Upload an image of where customers will come to pick up their drink in the shop. This will show up in CoffeePass."/>
                                    </div>
                                </div>
                            </CardBox>
                            :
                            <div/>
                        }
                        <CardBox styleName="col-lg-6" heading={"Info"}>
                            <AddressFields />
                        </CardBox>
                        <CardBox styleName="col-lg-6" heading={"Hours"}>
                            <Hours />
                        </CardBox>
                        <CardBox styleName="col-lg-6" heading={"Miscellaneous"}>
                            <MiscFields />
                        </CardBox>
                        <div class="col-lg-6">
                            <CardBox styleName="" heading={"Shop Rewards"}>
                                <ShopRewards />
                            </CardBox>
                            {this.state.pickupButtons !== null &&
                            <CardBox styleName="" heading={"Pick-up & Dine-in options"}>
                                <PickupOptions onSave={this.refreshInfo} pickupButtons={this.state.pickupButtons}/>
                            </CardBox>}
                            <CardBox styleName="" heading={"Shop Access Info"}>
                                <ShopUser />
                            </CardBox>
                        </div>
                        <Membership shopInfo={this.state.shopInfo}/>
                    </div>
                </div>
            </div>

        );
    }
};

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    getShopInfoAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(ShopInfo);
