import React, { Component } from 'react';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableHead from '@material-ui/core/TableHead'; import TableRow from '@material-ui/core/TableRow';
import '../../salesStyles.css';
import DatabaseValidator from '../../../../../util/DatabaseValidator';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoDataCard from '../../../../util/NoDataCard';


function createData(data, id) {
    console.log(id)
    let name = ''
    let amount = ''
    for (let key in data) {
        if ('name' in data && 'amount' in data) {
            name = data['name']
            amount = data['amount']
        } else {
            name = key;
            amount = data[key]
        }
    }
    let styleType = 'normalRow'
    if (id % 2 == 1) {
        styleType = 'highlightRow';
    }
    id += 1;
    return { id, styleType, name, amount };
}



class BasicTable extends Component {

    constructor(props) {
        super(props)
    }



    render() {
        let dataVis = ''
        let dv = new DatabaseValidator()
        if (!dv.isEmpty(this.props.tableDRD)) {
            let data = this.props.tableDRD;
            let rowCount = 0;
            for (let row in data) {
                data[row] = createData(data[row], rowCount)
                rowCount += 1;
            }

            dataVis = <Table>
                <TableHead>
                    <TableRow className="greyGrad">
                        <TableCell align="left">Category</TableCell>
                        <TableCell align='right'>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {}
                    {data.map(n => {
                        let icon = '';
                        if (n.name != "Number of Orders") {
                            icon = '$'
                        }
                        return (
                            <TableRow key={n.id} >
                                <TableCell className={n.styleType} align="left">{n.name}</TableCell>
                                <TableCell className={n.styleType} align="right">{icon}{n.amount}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>;
        } else {
            dataVis = <Table>
                <TableHead>
                    <TableRow className="greyGrad">
                        <TableCell align="left">Category</TableCell>
                        <TableCell align='right'>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="left">{/*<CircularProgress color="secondary" />*/}<NoDataCard type="category" /></TableCell>
                        <TableCell align="right">{/*<CircularProgress />*/}<NoDataCard type="amount" /></TableCell>
                    </TableRow>
                </TableBody>
            </Table>;
        }
        return (
            <div className="table-responsive-material">
                {dataVis}
            </div>
        );


    }

}

const mapStateToProps = state => {
    return {
        nothing: "",
        shopId: state.auth.shop.shopId,
        grossTips: state.analytics.grossTips,
        grossTax: state.analytics.grossTax,
        grossSales: state.analytics.grossSales,
        tableDRD: state.analytics.tableDateRangeReportData
    }
}

export default connect(
    mapStateToProps
)(BasicTable);