import React, {Component} from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

const data = [
    {
        question: 'How will I integrate CoffeePass into my daily flow?',
        answer: 'We understand that the flow of your coffee shop is so important, and have worked to make CoffeePass easy to use for your baristas. Orders are received on an always-on tablet. When a new order comes in, it is added to the CoffeePass queue, and a notification sound alerts the baristas. Orders are marked as “In Progress” and “Complete” on the tablet, and change color accordingly. This makes it simple to see the status of an order, even from across the coffee bar.',
    },
    {
        question: 'What experience do you have in the restaurant industry?',
        answer: 'We’ve worked closely with Karen Bassett, owner of Wayfarer Coffee Roasters, a widely successful and well-reviewed shop, to ensure that CoffeePass works seamlessly for shop owners, baristas, and customers.'
    },
    {
        question: 'What need are you fulfilling with this app?',
        answer: 'We are providing your customers with the same convenience that they can  find in the big coffee chains. This will help keep your customers\' loyalty and give them a great experience!'
    },
    {
        question: 'How did you come up with this idea?',
        answer: 'Coffee shop owner Karen Bassett (as mentioned above) came to us wanting an app like Starbucks for her shop. We knew this would be really expensive for just one shop and a lot of work! So we came up with the idea to make a universal app that could serve as a single platform for all coffee shops, making it much more affordable.'
    },
    {
        question: 'How difficult is it to make changes to my menu and pricing through the app?',
        answer: 'Our owner dashboard makes it easy to change your menu from any web browser. You can also change shop information, update hours, and turn CoffeePass on/off as needed.'
    },
    {
        question: 'How will you encourage customers to add food to their transactions as an upsell?',
        answer: 'This is not a feature that we have currently, but we do intend to add ways to upsell customers on your special menu items in the future.'
    },
    {
        question: 'What features do you plan to add in future versions of the app?',
        answer: 'We have big plans to continue improving CoffeePass going forward. We want to let customers earn shop rewards, set favorite drinks for easy ordering, and have the ability to send others CoffeePass credit. We have lots of other ideas, so stay tuned!  We would love your ideas and input as well, by emailing support@coffeepass.io!'
    },
    {
        question: 'What incentives will you offer to me as an owner to try the app?',
        answer: 'Shops can try CoffeePass free for 1 month to ensure it\'s a good fit! Ask us how to get three months free.'
    },
    {
        question: 'Will I be able to track analytics to help me grow my business?',
        answer: 'Yes! You can track orders and summaries over any date range through the owner dashboard. We’ll constantly be improving the analytics we offer to ensure that you have access to the information you care most about. If we’re missing your favorite stat, let us know by emailing support@coffeepass.io!'
    },
    {
        question: 'How will you incorporate our shop\'s branding into your app?',
        answer: 'We understand that your shop\'s brand is very important, so we include your logo and a personalized banner in the app! We also add your logo to receipt emails so customer experiences still feel shop-specific.'
    }
]


export default class Faq extends Component {
    state = {
        expanded: null,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    }

    render() {
        const {expanded} = this.state

        const views = data.map((d, index) => {
            return (
                <ExpansionPanel key={index} expanded={expanded === index} onChange={this.handleChange(index)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        {d.question}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <p>{d.answer}</p>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )
        })
        return (
            <div style={{paddingBottom: '5%', paddingTop: '3%'}}>
                <div className='row'>
                    {/*<div className='col-md-2 col-sm-2 col-2'/>*/}
                    <div className="col-xs-0 col-sm-3 col-md-2"/>
                    <div className='col-6'>
                        <h1 style={{fontSize: 50, color: '#333333'}}>FAQ</h1>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-sm-7 col-12">
                        {views}
                    </div>
                </div>
            </div>
        )
    }

}