import React from 'react';
import BasicTable from './BasicTable';
import CardBox from '../../../../util/CardBox';

const BasicTables = () => {
    return (
        <div className="d-flex justify-content-between col-12">
            <div className="row mb-md-4 col-12">
                <CardBox styleName="col-12" cardStyle="p-0"
                    headerOutside>
                    <BasicTable />
                </CardBox>
            </div>
        </div>
    );
};

export default BasicTables;

