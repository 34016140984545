import React, { Component } from 'react';
import CardBox from '../util/CardBox';
import DataTable from './components/MembershipTable';
import { connect } from 'react-redux';
import DatabaseValidator from './../../util/DatabaseValidator';
import LoadingTable from './components/LoadingTable';
import selectors from '../../../../reducers/selectors';
import moment from "moment";
import {getMembershipStats} from "../../../../actions/MembershipStatsTable";
import AppBar from "@material-ui/core/AppBar";
import {Tab, Tabs} from "@material-ui/core";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class MembershipStats extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            tab: 0,
            cancelledMembers: [],
            failedMembers: [],
            approvedMembers: [],
            summaryStats: []
        }

    }

    async componentDidMount() {
        let paramObj = {
            shopId: this.props.shopId
        }
        await this.props.getMembershipStats(paramObj);
    }

    async componentDidUpdate(pp, ps) {
        if(pp.membershipStats !== this.props.membershipStats) {
            const cancelledMembers = this.props.membershipStats.filter(member => member.active === 0 && member.cancelledDate !== null && member.status.includes('cancelled'));
            const approvedMembers = this.props.membershipStats.filter(member => member.active === 1 && member.approvedDate !== null)
            const failedMembers = this.props.membershipStats.filter(member => member.active === 0 && member.failedRenewal !== null && member.status === 'renewal failed')
            const unique = [...new Set(this.props.membershipStats.map(stat => stat.status))];
            let summaryStats = []
            unique.forEach(status => {
                let stat = {
                    status: status,
                    number: this.props.membershipStats.filter(s => s.status === status).length
                }
                summaryStats.push(stat);
            })
            this.setState({summaryStats, cancelledMembers, failedMembers, approvedMembers});
        }
    }

    //Summary
    formatSummaryMembershipData(data) {
        let resultArray = [
            { value: data.status, numeric: false },
            { value: data.number, numeric: true }

        ];
        return resultArray;
    }

    getSummaryDataVis() {
        const columnData = [
            { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
            { id: 'number', numeric: false, disablePadding: false, label: '#' },
        ];
        let dv = new DatabaseValidator();
        let dataVis;

        if (dv.isEmpty(this.props.membershipStats)) {
            dataVis = <LoadingTable />
        } else {
            const unique = [...new Set(this.props.membershipStats.map(stat => stat.status))];
            let summary = []
            unique.forEach(status => {
                let stat = {
                    status: status,
                    number: this.props.membershipStats.filter(s => s.status === status).length
                }
                summary.push(stat);
            })
            dataVis = <DataTable title="Payout History" columnData={columnData} dataFormatter={this.formatSummaryMembershipData} data={summary}
                                 onChangePage={this.handleChangePage}
                                 page={this.state.page} />
        }
        return dataVis
    }

    showDownloadButton() {
        return this.props.membershipStats !== undefined;
    }

    //Approved
    formatApprovedMembershipData(data) {
        let resultArray = [
            { value: `${(moment(new Date(data.approvedDate).toLocaleDateString("en-US", {timeZone: 'UTC'}))).format("MM/DD/yy")}`, numeric: false },
            { value: data.name, numeric: false },
            { value: data.email, numeric: false },
            { value: data.phone, numeric: false },
            { value: ("$" + (data.price).toFixed(2).toString()), numeric: true },

        ];
        return resultArray;
    }

    getApprovedDataVis() {
        const columnData = [
            { id: 'approvedDate', numeric: false, disablePadding: false, label: 'Approved Date' },
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
            { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
            { id: 'price', numeric: false, disablePadding: false, label: 'Price' },
        ];
        let dv = new DatabaseValidator();
        let dataVis;

        if (dv.isEmpty(this.props.membershipStats)) {
            dataVis = <LoadingTable />
        } else {
            const approvedMembers = this.props.membershipStats.filter(member => member.active === 1 && member.approvedDate !== null)
            dataVis = <DataTable title="Payout History" columnData={columnData} dataFormatter={this.formatApprovedMembershipData} data={approvedMembers}
                                 onChangePage={this.handleChangePage}
                                 page={this.state.page} />
        }
        return dataVis
    }

    //Failed
    formatFailedMembershipData(data) {
        let resultArray = [
            { value: `${(moment(new Date(data.failedRenewal).toLocaleDateString("en-US", {timeZone: 'UTC'}))).format("MM/DD/yy")}`, numeric: false },
            { value: data.status, numeric: false },
            { value: data.name, numeric: false },
            { value: data.email, numeric: false },
            { value: data.phone, numeric: false }
        ];
        return resultArray;
    }

    getFailedDataVis() {
        const columnData = [
            { id: 'failedDate', numeric: false, disablePadding: false, label: 'Failed Date' },
            { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
            { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },

        ];
        let dv = new DatabaseValidator();
        let dataVis;

        if (dv.isEmpty(this.props.membershipStats)) {
            dataVis = <LoadingTable />
        } else {
            const failedMembers = this.props.membershipStats.filter(member => member.active === 0 && member.failedRenewal !== null && member.status === 'renewal failed')
            dataVis = <DataTable title="Payout History" columnData={columnData} dataFormatter={this.formatFailedMembershipData} data={failedMembers}
                                 onChangePage={this.handleChangePage}
                                 page={this.state.page} />
        }
        return dataVis
    }

    //Cancelled
    formatCancelledMembershipData(data) {
        let resultArray = [
            { value: `${(moment(new Date(data.cancelledDate).toLocaleDateString("en-US", {timeZone: 'UTC'}))).format("MM/DD/yy")}`, numeric: false },
            { value: data.status, numeric: false },
            { value: data.name, numeric: false },
            { value: data.email, numeric: false },
            { value: data.phone, numeric: false }
        ];
        return resultArray;
    }

    getCancelledDataVis() {
        const columnData = [
            { id: 'cancelledDate', numeric: false, disablePadding: true, label: 'Cancelled Date' },
            { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
            { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },

        ];
        let dv = new DatabaseValidator();
        let dataVis;

        if (dv.isEmpty(this.props.membershipStats)) {
            dataVis = <LoadingTable />
        } else {
            const cancelledMembers = this.props.membershipStats.filter(member => member.active === 0 && member.status.includes('cancelled'))
            dataVis = <DataTable title="Payout History" columnData={columnData} dataFormatter={this.formatCancelledMembershipData} data={cancelledMembers}
                                 onChangePage={this.handleChangePage}
                                 page={this.state.page} />
        }
        return dataVis
    }




    handleChangePage = (event, page) => {
        this.setState({ page });
    }
    handleTabChange = (event, tab) => {
        this.setState({ page: 0, tab: tab});
    }
    render() {
        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <div>
                    <AppBar position="static">
                        <Tabs value={this.state.tab} onChange={this.handleTabChange}>
                            <Tab label="Summary" />
                            <Tab label="Active Members" />
                            <Tab label="Canceled Members" />
                            <Tab label="Failed Renewals"/>
                        </Tabs>
                    </AppBar>
                    {this.state.tab === 0 &&
                     <div>
                        <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                            {this.getSummaryDataVis()}
                        </CardBox>
                     </div>}
                    {this.state.tab === 1 &&
                    <div>
                        <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                            {this.getApprovedDataVis()}
                        </CardBox>
                    </div>}
                    {this.state.tab === 2 &&
                    <div>
                        <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                            {this.getCancelledDataVis()}
                        </CardBox>
                    </div>}
                    {this.state.tab === 3 &&
                    <div>
                        <CardBox styleName="col-12" cardStyle="mb-0 p-0">
                            {this.getFailedDataVis()}
                        </CardBox>
                    </div>}
                </div>
                { this.showDownloadButton() && <div className="p-3">
                    <ExcelFile element={<button className="btn btn-primary">Download Membership Stats</button>} filename="MembershipStats">
                        <ExcelSheet data={this.state.summaryStats} name="Summary">
                            <ExcelColumn label="Status" value="status"/>
                            <ExcelColumn label="#" value="number"/>
                        </ExcelSheet>
                        <ExcelSheet data={this.state.approvedMembers} name="Active Members">
                            <ExcelColumn label="Name" value="name"/>
                            <ExcelColumn label="Email" value="email"/>
                            <ExcelColumn label="Phone" value="phone"/>
                            <ExcelColumn label="Approved Date" value="approvedDate"/>
                            <ExcelColumn label="Price" value="price"/>
                            <ExcelColumn label="Signup Date" value="signupDate"/>
                            <ExcelColumn label="Status" value="status"/>
                        </ExcelSheet>
                        <ExcelSheet data={this.state.cancelledMembers} name="Cancelled Members">
                            <ExcelColumn label="Name" value="name"/>
                            <ExcelColumn label="Email" value="email"/>
                            <ExcelColumn label="Phone" value="phone"/>
                            <ExcelColumn label="Cancelled Date" value="cancelledDate"/>
                            <ExcelColumn label="Price" value="price"/>
                            <ExcelColumn label="Signup Date" value="signupDate"/>
                            <ExcelColumn label="Status" value="status"/>
                        </ExcelSheet>
                        <ExcelSheet data={this.state.failedMembers} name="Failed Renewals">
                            <ExcelColumn label="Name" value="name"/>
                            <ExcelColumn label="Email" value="email"/>
                            <ExcelColumn label="Phone" value="phone"/>
                            <ExcelColumn label="Failed Date" value="failedRenewal"/>
                            <ExcelColumn label="Price" value="price"/>
                            <ExcelColumn label="Signup Date" value="signupDate"/>
                            <ExcelColumn label="Status" value="status"/>
                        </ExcelSheet>
                    </ExcelFile>
                </div> }
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        membershipStats: state.analytics.membershipStats
    }
}

const actionCreators = {
    getMembershipStats

}

export default connect(
    mapStateToProps,
    actionCreators
)(MembershipStats);
