import React, {Component} from 'react';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import OpenClosedSwitch from './OpenClosedSwitch';
import Button from '@material-ui/core/Button';
import SaveSureDialog from './SaveSureDialog';
import { connect } from 'react-redux';
import { updateShopHoursAction, getShopHoursAction } from '../../../actions/ShopInfo';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import SaveStepButton from '../../../containers/ShopOnboarding/SaveStepButton';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import selectors from '../../../reducers/selectors';

function AmMask(props) {
    const { mask, inputRef, ...other } = props;
  
    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/\d+/, /\d/, ':', /[0-5]/, /\d/, ' ','a', 'm']}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

function PmMask(props) {
    const { mask, inputRef, ...other } = props;
  
    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/\d/, /\d/, ':', /[0-5]/, /\d/, ' ','p', 'm']}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

class Hours extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hours: [
                {
                    day: "Sun",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Mon",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Tues",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Wed",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Thurs",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Fri",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                },
                {
                    day: "Sat",
                    openTime: '0700',
                    closeTime: '0900',
                    isOpen: true
                }
            ],
            isDialogOpen: false
        }

        this.stateCopy = JSON.parse(JSON.stringify(this.state))

        this.props.getShopHoursAction(this.props.shopId,
            (resp) => {
                if(resp.val != false) {
                    this.stateCopy = JSON.parse(JSON.stringify({hours: resp}))
                    this.setState({hours: resp})
                }
            },
            (error) => {
                console.log("getshop info error", error)
            })

        this.onSaveClicked = this.onSaveClicked.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.onDialogYesClicked = this.onDialogYesClicked.bind(this)
        this.isStateDifferent = this.isStateDifferent.bind(this)
    }

    handleChange = (index, key) => event => {
        var copy = Object.assign(this.state.hours, [])
        copy[index][key] = event.target.value
        this.setState({
            hours: copy
        })
    }

    handleOpenCloseChange = (index, value) => {
        var copy = Object.assign(this.state.hours, [])
        copy[index]['isOpen'] = value
        this.setState({
            hours: copy
        })
        MixpanelTrack('Set day open to ' + value)
    }

    onSaveClicked() {
        this.setState({
            isDialogOpen: true
        })
        MixpanelTrack('Save Shop Hours')
    }

    handleDialogClose() {
        this.setState({isDialogOpen: false})
    }

    onDialogYesClicked(handleNextStep) {
        this.handleDialogClose()
        MixpanelTrack('Save Shop Hours Confirmed')
        const json = Object.assign({}, this.state, {
            shopId: this.props.shopId
        })
        this.props.updateShopHoursAction(json,
            (resp) => {
                if(resp == "True") {
                    if(typeof handleNextStep === 'function') {
                        handleNextStep()
                    } else {
                        NotificationManager.success("The new shop hours are now in CoffeePass!", "Success")
                    }
                } else {
                    NotificationManager.error("Make sure the hours are correct! If the problem persists contact support@coffeepass.io.", "Error Saving Hours!", 9000)
                }
            },
            (error) => {
                NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Saving Hours!", 9000)
            })
    }

    isStateDifferent() {
        for(var k = 0; k < this.state.hours.length; k++) {
            const day = this.state.hours[k]
            for(var key in day) {
                var val = day[key]
                if(typeof val === 'string') {
                    val = val.replace(' pm', '').replace(' am', '')
                }
                const copyVal = this.stateCopy.hours[k][key]
                if(val != copyVal) {
                    return true
                }
            }
        }
            
        return false
    }

    render() {

        const hourWidgets = this.state.hours.map((day, index) => {
            return (
                <FormControl style={styles.form} key={index}>
                    <medium style={styles.dayText}> {day.day}:</medium>
                    <Input
                        style={styles.input}
                        value={day.openTime}
                        onChange={this.handleChange(index, 'openTime')}
                        inputComponent={ AmMask }
                        disabled={!day.isOpen}
                    />
                    <medium style={styles.toText}>to</medium>
                    <Input
                        style={styles.input}
                        value={day.closeTime}
                        onChange={this.handleChange(index, 'closeTime')}
                        inputComponent={ PmMask }
                        disabled={!day.isOpen}
                    />
                    <OpenClosedSwitch switch={day.isOpen} onChange={(checked) => this.handleOpenCloseChange(index, checked)} />
                    <NotificationContainer />
                </FormControl>
            )
        })

        return (
            <div>
                <div className="col-md-6 col-6">
                    {hourWidgets}
                </div>
                <div className="col-md-12 col-12" style={styles.buttonContainer}>
                    <SaveStepButton {...this.props}
                        isStateDifferent={this.isStateDifferent} 
                        onSaveClicked={this.onSaveClicked}
                        isLoadingSave={this.state.isLoadingSave}
                        onDialogYesClicked={(handleNext)=> this.onDialogYesClicked(handleNext)} />
                </div>
                <SaveSureDialog
                    isDialogOpen= {this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    onYesClicked={this.onDialogYesClicked}
                    onNoClicked={this.handleDialogClose}
                    dialogDataToSave="shop info hours"
                    />
            </div>)
    }
}

const styles = {
    dayText: {
        alignSelf: 'center', 
        width: 40
    },
    toText: {
        alignSelf: 'center'
    },
    form: {
        flexDirection: 'row',
        marginBottom: 10
    },
    input: {
        marginLeft: 20, 
        marginRight: 20, 
        width: 75
    },
    buttonContainer: {
        display: 'flex', 
        justifyContent: 'flex-end'
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    updateShopHoursAction,
    getShopHoursAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(Hours);