import React, { Component } from 'react';
import wayfarer from '../../assets/shopLogos/wayfarer.png';
import redVelvet from '../../assets/shopLogos/redVelvet.png';
import xeniaHub from '../../assets/shopLogos/xenia.png';
import ghostlight from '../../assets/shopLogos/ghostlight.png';
import cafeAhroma from '../../assets/shopLogos/cafeAhroma.png'
import telemetry from '../../assets/shopLogos/telemetry.png'
import courthouse from '../../assets/shopLogos/courthouse.png'
import thirdPerk from '../../assets/shopLogos/3rdperk.jpg'
import gemcity from '../../assets/shopLogos/gemcity.png'
import sanjulian from '../../assets/shopLogos/sanjulian.png'
import newgeneral from '../../assets/shopLogos/newgeneral.png'
import cfs from '../../assets/shopLogos/cfs.png'

import './App.css';

class PartnersBlock extends Component {
  render() {
    var logos = [
        {src: wayfarer, title: 'Wayfarer'},
        {src: ghostlight, title: 'Ghostlight'},
        {src: gemcity, title: 'Gem City Catfe'},
        {src: xeniaHub, title: 'Coffee Hub'},
        {src: thirdPerk, title: 'Third Perk'},
        {src: courthouse, title: 'Courthouse'},
        {src: sanjulian, title: "San Julian's Coffee"},
        {src: newgeneral, title: 'New General'},
        {src: cfs, title: 'CFS Coffee'}
    ]

    var view = logos.map(logo =>
            <img src={logo.src} className="partnerLogo" title={logo.title} alt={logo.title} key={logo.title} />
        )

    return (
      <div className="partnersBlock">
        <div className="partnersLabel">
          <h1> CoffeePass Partners: </h1>
        </div>
        <div className="logoContainer">
            { view }
        </div>
      </div>
    );
  }
}

export default PartnersBlock;