import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton'
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { widgetTypes, SELECT_MAX } from './WidgetTypes';
import ExtraMenu from './ExtraMenu';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import TextFieldValidator from '../../util/TextFieldValidator'
import SelectValidator from '../../util/SelectValidator';
import ErrorIcon from '@material-ui/icons/Error';
import { changeOptionValueAction, changeOptionTitleAction, addMenuValueAction, deleteOptionAction, deleteValueAction, copyOptionAction, pasteOptionAction, pasteValueAction, copyValueAction, makeOptionNonDefaultAction, changeOptionOnTabletAction } from '../../../../actions/Menu';
import { MixpanelTrack } from '../../../../util/MixpanelHelper';
import { updateDefaultModifierIndexAction } from '../../../../actions/MenuBuilder';
import { types } from '../../../../constants/ActionTypes';
import DefaultModifierDialog from './DefaultModifierDialog';
import DeleteDefaultModifierDialog from './DeleteDefaultModifierDialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import selectors from '../../../../reducers/selectors';

// This can be any component you want
const DragHandle = SortableHandle(() =>
    <i className="zmdi zmdi-menu draggable-icon d-none d-sm-flex" style={{fontSize: 25}}/>);

const StyledChip = withStyles({
    label: {
        fontSize: 9,
    },
    root: {
        height: 23
    }
})(Chip)

const GreenChip = withStyles({
    label: {
        fontSize: 9,
    },
    root: {
        height: 23,
        backgroundColor: '#66bb6a',
        marginRight: 30
    }
})(Chip)

class Option extends Component {

    constructor(props) {
        super(props)
        this.myInput = React.createRef()
        this.state = { 
            selectWidth: 100,
            infoDescription: '',
            infoGif: null,
            isDefaultDialogOpen: false,
            isDeleteDefaultDialogOpen: false
        }
    }

    componentDidMount() {
        this.setState({width: this.myInput.current.offsetWidth})
    }

    onSelectOption = (option) => {
        if(option == 'Delete') {
            if(this.props.option.isDefault) {
                this.props.deleteOptionAction(this.props.i, types.DELETE_DEFAULT_OPTION)
            } else {
                //they are trying to delete a default modifier, we warn in a dialog that this will remove other options
                if(this.props.builderType == types.DEFAULT_MODIFIERS_BUILDER) {
                    this.showDialog('isDeleteDefaultDialogOpen')
                } else {
                    this.props.deleteOptionAction(this.props.i, this.props.builderType)
                }
            }
        } else if(option == 'Copy') {
            this.props.copyOptionAction(this.props.i)
        } else if(option == 'Paste') {
            this.props.pasteOptionAction(this.props.i)
        } else {
            this.props.changeOptionOnTabletAction(this.props.i, this.props.builderType)
        }
    }

    onSelectOptionValue(option, valueIndex) {
        if(option == 'Delete') {
            if(this.props.option.isDefault) {
                this.showDialog('isDefaultDialogOpen')
            } else {
                this.props.deleteValueAction(this.props.i, valueIndex, this.props.builderType)
            }
        } else if(option == 'Copy') {
            this.props.copyValueAction(this.props.i, valueIndex)
        } else if(option == 'Paste') {
            this.props.pasteValueAction(this.props.i, valueIndex)
        }
    }

    onDeleteDialogYesClicked = () => {
        this.props.deleteOptionAction(this.props.i, this.props.builderType)
        this.setState({isDeleteDefaultDialogOpen: false})

        if(this.props.onShowValues == null && this.props.selectedIndex >= this.props.defaultModifiers.length - 1) {
            this.props.updateDefaultModifierIndexAction(Math.max(this.props.selectedIndex - 1, 0))
        }
    }

    showTypeInfo = (description, gif) => {
        this.setState({infoDescription: description, infoGif: gif})
    }

    handleSaveDialogClose = (key) => {
        this.setState({[key]: false})
    }

    showDialog = (key) => {
        this.setState({[key]: true})
    }

    onDialogYesClicked = () => {
        this.props.makeOptionNonDefaultAction(this.props.i)
        this.setState({isDefaultDialogOpen: false})
    }
    
    render() {
        const {i, option, showValues, onShowValues, clipboard} = this.props

        var infoCardStyle = {
            position: 'absolute',
            right: this.state.width,
            zIndex: 100,
            width: this.state.width * 1.7,
            overflow: 'visible'
        }

        var extraOptions = ['Delete', 'Copy']
        if(Object.keys(clipboard.option).length !== 0) {
            extraOptions.push('Paste')
        }
        if(!option.isDefault) {
            if(option.isOnTablet) {
                extraOptions.push('Hide on Tablet')
            } else {
                extraOptions.push('Show on Tablet')
            }
        }
        

        var extraOptionsValue = ['Delete', 'Copy']
        if(Object.keys(clipboard.value).length !== 0) {
            extraOptionsValue.push('Paste')
        }
        return (
            <div style={styles.listItem}>
                {option.isDefault != undefined && option.isDefault?
                    <StyledChip
                        color='primary'
                        label="Default Modifier" />
                        :
                        null
                }
                {option.isOnTablet != undefined && option.isOnTablet && !option.isDefault?
                        <div style={styles.onTablet}>
                            <GreenChip
                                label="On Tablet" />
                        </div>
                        :
                        null
                }
                <div className="row">
                    <div className="module-list-icon col-1">
                        {this.props.onShowValues != null?
                            <span className="bar-icon">
                                <DragHandle/>
                            </span>
                            :
                            null
                        }
                    </div>
                    <div className={(option.widgetType == SELECT_MAX)? "col-3" : "col-4"}>
                        <TextFieldValidator
                            required
                            label="Modifier Title"
                            value={option.title}
                            onChange={event => {
                                if(option.isDefault) {
                                    this.showDialog('isDefaultDialogOpen')
                                } else {
                                    this.props.changeOptionTitleAction(i, 'title', event.target.value, this.props.builderType)
                                }
                            }}
                            defaultValue=""
                            margin="normal"
                            fullWidth
                            errorMessage={option.errorMessageTitle}
                        />
                    </div>
                    <div className={(option.widgetType == SELECT_MAX)? "col-2" : "col-3"} ref={this.myInput}>
                        {this.state.infoDescription != ''?
                            <Card style={infoCardStyle}>
                                <CardContent>
                                    <Typography component="p">
                                        {this.state.infoDescription}
                                    </Typography>
                                    <img src={this.state.infoGif} style={{width: this.state.width * 1.4, height: 'auto', marginTop: 10}} />
                                </CardContent>
                            </Card>
                            :
                            null
                        }
                        <SelectValidator
                            label="Button Type"
                            style={{width: (option.widgetType == SELECT_MAX)? this.state.width - 70: this.state.width }}
                            value={option.widgetType}
                            onChange={event => {
                                if(option.isDefault) {
                                    this.showDialog('isDefaultDialogOpen')
                                } else {
                                    this.props.changeOptionTitleAction(i, 'widgetType', event.target.value, this.props.builderType)
                                }
                                //this timeout is so the mouse can enter again and repoen the tutorial
                                setTimeout(() => {
                                    this.setState({width: this.myInput.current.offsetWidth, infoDescription: ''})
                                }, 400)
                            }}
                            required
                            errorMessage={option.errorMessageWidget}
                            inputProps={{
                                name: 'type'
                            }}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {widgetTypes.map(obj => {
                                return (<MenuItem key={obj.value}
                                            value={obj.value}
                                            onMouseLeave={() => this.setState({infoDescription: ''})}
                                            onMouseEnter={() => this.showTypeInfo(obj.description, obj.gif)}>
                                                {obj.text}
                                        </MenuItem>)
                            })}
                        </SelectValidator>
                    </div>
                    {(option.widgetType == SELECT_MAX)?
                        <div className="col-2">
                            <TextFieldValidator
                                required
                                label="Max #"
                                value={option.maxNum}
                                onChange={event => {
                                    if(option.isDefault) {
                                        this.showDialog('isDefaultDialogOpen')
                                    } else {
                                        this.props.changeOptionTitleAction(i, 'maxNum', event.target.value)
                                    }
                                }}
                                defaultValue=""
                                margin="normal"
                                placeholder="12"
                                fullWidth
                                type="number"
                                errorMessage={option.errorMessageMax}/>
                        </div>
                        :
                        null
                    }
                    {this.props.onShowValues != null?
                        <div className="col-3" style={styles.button}>
                            <Button variant="contained" style={styles.buttonPad} color="#c5c5c5" onClick={() => {
                                MixpanelTrack('Clicked ' + (option.showValues)? 'Hide Options' : 'Show Options')
                                onShowValues(option.menuOptionId)
                            }}>
                                <span style={styles.buttonFont}>{(option.showValues)? 'Hide Options' : 'Show Options' }</span>
                            </Button>
                            { (option.isValuesValid == undefined || option.isValuesValid)? null : <ErrorIcon style={styles.error}/>}
                        </div>
                        :
                        null
                    }
                    <div className="col-1" style={styles.button}>
                        <ExtraMenu options={extraOptions} onSelectOption={option => this.onSelectOption(option)} />
                    </div>
                </div>

                {(showValues)?
                    <div>
                        {option.values.map((value, index) => {
                            return (<div className="row" key={index}>
                                        <div className="col-2"/>
                                        <div className='col-md-4'>
                                            <TextFieldValidator
                                                required
                                                label="Option"
                                                onChange={event => {
                                                    if(option.isDefault) {
                                                        this.showDialog('isDefaultDialogOpen')
                                                    } else {
                                                        this.props.changeOptionValueAction(i, index, 'value', event.target.value, this.props.builderType)
                                                    }
                                                }}
                                                value={value.value}
                                                defaultValue=""
                                                margin="normal"
                                                fullWidth
                                                errorMessage={value.errorMessageValue}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <TextFieldValidator
                                                required
                                                label="Price"
                                                onChange={event => {
                                                    if(option.isDefault) {
                                                        this.showDialog('isDefaultDialogOpen')
                                                    } else {
                                                        this.props.changeOptionValueAction(i, index, 'price', event.target.value, this.props.builderType)
                                                    }
                                                }}
                                                value={value.price == null? '' : value.price}
                                                defaultValue=""
                                                margin="normal"
                                                placeholder="$0.00"
                                                fullWidth
                                                type="number"
                                                errorMessage={value.errorMessagePrice}
                                            />
                                        </div>
                                        <div className="col-md-2" style={styles.button}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!value.isAvailable}
                                                        onChange={(event) => {
                                                            if(option.isDefault) {
                                                                this.showDialog('isDefaultDialogOpen')
                                                            } else {
                                                                this.props.changeOptionValueAction(i, index, 'isAvailable', !value.isAvailable, this.props.builderType)
                                                            }
                                                        }}
                                                        value={!value.isAvailable}
                                                        color="primary" />
                                                }
                                                label="Sold Out" />
                                        </div>
                                        <div className="col-md-1 col-1" style={styles.button}>
                                            <ExtraMenu options={extraOptionsValue} onSelectOption={option => this.onSelectOptionValue(option, index)} />
                                        </div>
                                        <div className="col-2"/>
                                    </div>)
                        })}
                        <div className="row">
                            <div className="col-2"/>
                            <Button color="primary" onClick={() => {
                                    if(option.isDefault) {
                                        this.showDialog('isDefaultDialogOpen')
                                    } else {
                                        this.props.addMenuValueAction(i, this.props.builderType)
                                    }
                                }}
                                style={styles.addButton}>
                                    Add Option
                            </Button>
                        </div>
                    </div>
                    :
                    null
                }
                <DefaultModifierDialog
                    isDialogOpen= {this.state.isDefaultDialogOpen}
                    handleClose={() => this.handleSaveDialogClose('isDefaultDialogOpen')}
                    onYesClicked={this.onDialogYesClicked}
                    onNoClicked={() => this.handleSaveDialogClose('isDefaultDialogOpen')} />
                <DeleteDefaultModifierDialog
                    isDialogOpen= {this.state.isDeleteDefaultDialogOpen}
                    handleClose={() => this.handleSaveDialogClose('isDeleteDefaultDialogOpen')}
                    onYesClicked={this.onDeleteDialogYesClicked}
                    onNoClicked={() => this.handleSaveDialogClose('isDeleteDefaultDialogOpen')} />
            </div>

        )
    }
}

const styles = {
    image: {
        width: 30,
        height: 30,
    },
    checkBox: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        alignItems: 'center'
    },
    addButton: {
        marginTop: 10,
        marginBottom: 10
    },
    buttonPad: {
        padding: 0
    },
    buttonFont: {
        fontSize: 10
    },
    error: {
        color: '#ff0000',
        marginLeft: -10,
        marginTop: -35,
        zIndex: 10
    },
    listItem: {
        alignItems: 'center',
        padding: '15px 28px',
        borderBottom: 'solid 1px #e9ecef',
        position: 'relative',
        cursor: 'pointer'
    },
    onTablet: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}
Option.propTypes = {
    i:  PropTypes.number.isRequired,
    option: PropTypes.object.isRequired,
    showValues: PropTypes.bool.isRequired,
    onShowValues: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        clipboard: selectors(state).clipboard,
        selectedIndex: state.menuBuilder.selectedDefaultIndex,
        defaultModifiers: state.menu.defaultModifiers
    }
}
const actionCreators = {
    changeOptionValueAction,
    changeOptionTitleAction,
    addMenuValueAction,
    deleteOptionAction,
    deleteValueAction,
    copyOptionAction,
    pasteOptionAction,
    copyValueAction,
    pasteValueAction,
    updateDefaultModifierIndexAction,
    makeOptionNonDefaultAction,
    changeOptionOnTabletAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(Option)