export const UNIT_PERCENT = 'percent'

export const discountUnitTypes = [
    {
        id: 0,
        value: UNIT_PERCENT,
        text: '%'
    },
    {
        id: 1,
        value: 'dollar',
        text: '$'
    }
]