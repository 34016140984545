import React, { Component } from 'react';
import { DateRangePicker, focusedInput, onDatesChange, isInclusivelyBeforeDay } from 'react-dates';
import { connect } from 'react-redux';
import { getTopFiveItemsData } from './../../../../../actions/TopFiveItemGraph';
import DatabaseValidator from './../../../util/DatabaseValidator';
import { getItemSalesData } from './../../../../../actions/ItemSales';
import selectors from '../../../../../reducers/selectors';


class DatePicker extends Component {

    onDatesChange(startDate, endDate) {
        if (!startDate || !endDate) {
            return;
        } else {
            if (startDate.isValid() && endDate.isValid()) {
                let paramObj = {
                    shopId: this.props.shopId,
                    dateFirst: startDate.unix(),
                    dateSecond: endDate.unix(),
                };
                let paramObjTopFive = {
                    shopId: this.props.shopId,
                    dateFirst: startDate.unix(),
                    dateSecond: endDate.unix(),
                    type: 'ITEM'
                }
                this.props.getTopFiveItemsData(paramObjTopFive)
                this.props.getItemSalesData(paramObj)
            }
        }
    }

    constructor(props) {
        super(props);
        var moment = require('moment');
        moment().format();
        this.state = {
            startDate: moment().day(-7),
            endDate: moment().day(0),
            focusedInput: null
        };
        //Set default dates to the last complete week
        this.onDatesChange(this.state.startDate, this.state.endDate);
    }



    render() {
        var moment = require('moment');
        moment().format()
        let dv = new DatabaseValidator();
        return (
            <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate }, this.setState(this.onDatesChange(startDate, endDate)))} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                numberOfMonths={2}
                initialVisibleMonth={() => moment().subtract(1, 'month')}
            />
        );
    }
}
const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        topFiveItemsData: selectors(state).topFiveItemsData,
        itemSales: selectors(state).itemSales
    }
}

const actionCreators = {
    getTopFiveItemsData,
    getItemSalesData
}

export default connect(
    mapStateToProps,
    actionCreators
)(DatePicker);