import React, { Component } from 'react'
import ReportBox from './ReportBox';
import { Area, XAxis, YAxis, AreaChart, CartesianGrid, Tooltip } from 'recharts';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatabaseValidator from '../../../../../util/DatabaseValidator';
import NoDataCard from '../../../../util/NoDataCard';
import GraphFormatter from './../../../../util/GraphFormatter';

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DaySales extends Component {

    constructor(props) {
        super(props)
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: null
        }
    }

    render() {
        let graphFormatter = new GraphFormatter();
        let dv = new DatabaseValidator();
        var moment = require('moment');
        moment().format()
        var heading = 'Daily Sales Data ';
        let dataVis = '';
        let showDownloadButton = true;

        if(this.props.grossSalesRange.length === 1){
            if(this.props.grossSalesRange[0].date === 0){
                showDownloadButton = false
            }
        }
                        
        if (!dv.isEmpty(this.props.grossSalesRange)) {
            if (this.props.grossSalesRange.date == 0) {
                dataVis = <NoDataCard type="sales" />
            } else {
                heading = heading + 'for ' + this.props.grossSalesRange[0].date + ' - ' + this.props.grossSalesRange[this.props.grossSalesRange.length - 1].date;
                dataVis = <AreaChart data={this.props.grossSalesRange}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#3087A8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#3087A8" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis tickFormatter={graphFormatter.moneyFormatter} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip formatter={graphFormatter.moneyFormatter} />
                    <Area type="monotone" dataKey="amt" stroke="#3087A8" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>;
            }
        } else if (this.state.startDate == null || this.state.endDate == null) {
            dataVis = <div className="col-12 noDataDisplay"><h1 className="text-muted">Please Select a Date Range.</h1></div>;

        } else {
            dataVis = <CircularProgress size={70} />
        }
        return (
            <div className="col-12">
                <div className="text-center">
                    <ReportBox heading={heading}>
                        {dataVis}
                    </ReportBox>
                </div>
                {showDownloadButton && <div className="mb-3">
                        <ExcelFile element={<button className="btn btn-primary">Download Daily Sales Data</button>} filename="DailySalesData">
                            <ExcelSheet data={this.props.grossSalesRange} name="DailySales">
                                <ExcelColumn label="Date" value="date"/>
                                <ExcelColumn label="Amount" value="amt"/>
                            </ExcelSheet>
                        </ExcelFile>
                    </div>}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        nothing: "",
        grossSalesRange: state.analytics.grossSalesRange
    }
}

export default connect(
    mapStateToProps
)(DaySales);