import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header";
import Sidebar from "../containers/SideNav/index";
import Footer from "./Footer";
import Analytics from "../component/dashboard/analytics/Analytics";
import ShopInfo from '../component/dashboard/ShopInfo/ShopInfo';
import MembersList from '../component/dashboard/membership/MembersList';
import MemberDetails from '../component/dashboard/membership/MemberDetails';
import Overview from '../component/dashboard/analytics/overview/Overview';
import SalesOverview from '../component/dashboard/analytics/sales/Overview/SalesOverview';
import Payouts from '../component/dashboard/analytics/payouts/Payouts';
import ModifierSales from '../component/dashboard/analytics/modifierSales/ModifierSales';
import ItemSales from '../component/dashboard/analytics/itemSales/ItemSales';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION
} from "../constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import TopNav from "./TopNav";
import MenuBuilder from "../component/dashboard/menu/MenuBuilder/MenuBuilder";
import DefaultModifiers from "../component/dashboard/menu/defaultmodifiers/DefaultModifiers";
import ManageShops from "../component/dashboard/chain/ManageShops";
import PromoCode from '../component/dashboard/promocode/PromoCode';
import PromoCodeList from '../component/dashboard/promocode/PromoCodeList';
import PromoCodeUsage from '../component/dashboard/promocode/PromoCodeUsage';
import NewAnnouncement from "../component/dashboard/announcements/NewAnnouncement";
import AnnouncementHistory from "../component/dashboard/announcements/AnnouncementHistory";
import MembershipStats from "../component/dashboard/analytics/membership/MembershipStats";
import GuestList from "../component/dashboard/events/GuestList";
import Verify from "../component/verify";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileNavOpen: false
        }

        this.toggleMobileNav = this.toggleMobileNav.bind(this);
    }

    toggleMobileNav() {
        this.setState({mobileNavOpen: !this.state.mobileNavOpen})
    }

    render() {

        const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        return (
            <div className={`app-container ${drawerStyle}`}>
                <Sidebar onToggleCollapsedNav={this.toggleMobileNav} mobileNavOpen={this.state.mobileNavOpen}/>
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                            <TopNav styleName="app-top-header" />}
                        <Header toggleCollapsedNav={this.toggleMobileNav}/>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                            <TopNav />}
                    </div>

                    <main className="app-main-content-wrapper">
                        <div className="app-main-content">
                            <Switch>
                                <Route path={`${match.url}/menu`} component={MenuBuilder} />
                                <Route path={`${match.url}/events/:menuItemId`} component={GuestList} />
                                <Route path={`${match.url}/overview`} component={Overview} />
                                <Route path={`${match.url}/sales_overview`} component={SalesOverview} />
                                <Route path={`${match.url}/modifier_sales`} component={ModifierSales} />
                                <Route path={`${match.url}/membership_stats`} component={MembershipStats} />
                                <Route path={`${match.url}/payouts`} component={Payouts} />
                                <Route path={`${match.url}/item_sales`} component={ItemSales} />
                                <Route path={`${match.url}/default-modifiers`} component={DefaultModifiers} />
                                <Route path={`${match.url}/shop_info`} component={ShopInfo} />
                                <Route path={`${match.url}/members`} component={MembersList} />
                                <Route path={`${match.url}/member_details`} component={MemberDetails} />
                                <Route path={`${match.url}/manage_shops`} component={ManageShops} />
                                <Route path={`${match.url}/analytics`} component={Analytics} />
                                <Route path={`${match.url}/promo-code`} component={PromoCode} />
                                <Route path={`${match.url}/promo-code-list`} component={PromoCodeList} />
                                <Route path={`${match.url}/promo-code-usage`} component={PromoCodeUsage} />
                                <Route path={`${match.url}/promo-code-usage`} component={PromoCodeUsage} />
                                <Route path={`${match.url}/announcements`} component={NewAnnouncement} />
                                <Route path={`${match.url}/announcementHistory`} component={AnnouncementHistory} />
                            </Switch>
                        </div>
                        <Footer />
                    </main>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ settings }) => {
    const { drawerType, navigationStyle, horizontalNavPosition } = settings;
    return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps)(App));
