import React, { Component } from 'react';
import OrderTableCell from './OrderTableCell';
import { getRecentOrdersAction } from './../../../../../../actions/RecentOrders';
import { connect } from 'react-redux';
import NoDataCard from '../NoDataCard';
import DatabaseValidator from './../../../../util/DatabaseValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import selectors from '../../../../../../reducers/selectors';


let counter = 0;

class OrderTable extends Component {
    constructor(props) {
        super(props);
        let paramObj = {
            shopId: this.props.shopId,
            numOrders: 4
        }
        this.props.getRecentOrdersAction(paramObj)
    }

    isEmpty(obj) {
        for (var props in obj) {
            if (obj.hasOwnProperty(props)) {
                return false;

            }
        }
        return true;
    }

    render() {
        var dataVis = '';
        const dbv = new DatabaseValidator();
        if (!dbv.isEmpty(this.props.recentOrders)) {
            if (JSON.parse(this.props.recentOrders['val']) == 'No Data') {
                dataVis = <NoDataCard type="Order" />;
            } else {
                dataVis = <tbody>{JSON.parse(this.props.recentOrders['val']).map(data => { return (<OrderTableCell key={data.id} data={data} />); })}</tbody>;
            }
        } else {
            dataVis = <div className="text-center" style={{ width: '500%', marginTop: '10px' }}><CircularProgress size={60} color="secondary" /></div>;
        }

        return (
            <div className="table-responsive-material">
                <table className="default-table table-unbordered table table-sm table-hover">
                    <thead className="th-border-b">
                        <tr>
                            <th>OrderId</th>
                            <th>Customer</th>
                            <th>Order Date</th>
                            <th className="text-right">Total Order</th>
                            <th />
                        </tr>
                    </thead>
                    {dataVis}
                </table>
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId,
        recentOrders: selectors(state).recentOrders
    }
}

const actionCreators = {
    getRecentOrdersAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(OrderTable);