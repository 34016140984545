import React, { Component } from 'react';

class Product extends Component {
    render() {
        return (
            <div className="m-1 serviceCard">
                <div className="jr-card">
                    <div className="row align-items-center">
                        <div className="col-md-5 order-md-7">
                            <div className="grid-thumb-equal">
                                <div className="grid-thumb-cover">
                                    <img className="img-fluid" src={this.props.image} alt="Imac" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7 order-md-5">
                            <div className="pt-3 p-lg-5 text-center">
                                <h2><strong>{this.props.title}</strong></h2>
                                <p>{this.props.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


export default Product;
