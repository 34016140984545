import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { Badge } from 'reactstrap';
import { Component } from 'react';

class UserDetailCell extends Component {

    render() {
        return (
            <tr
                tabIndex={-1}
                key={this.props.key}>
                <td>
                    <div className="user-profile d-flex flex-row align-items-center">
                        <div className="user-detail">
                            <h4 className="user-name text-capitalize">{this.props.data} </h4>
                            <p className="user-description">A New Customer through CoffeePass</p>
                        </div>
                    </div>
                </td>
                {/*            <td className="text-right">
                <Badge className="d-block text-uppercase" href="javascript:void(0)" color={color}>{detail}</Badge>
    </td>*/}
            </tr>

        );
    }

}

export default UserDetailCell;
