import Signature from "./Signature";
import Policy from "./Policy";
import { xAmzDate, dateYMD } from "./Date";
import {DeleteObjectCommand, ListObjectsV2Command, S3} from "@aws-sdk/client-s3";
import selectors from "../../../../reducers/selectors";
import {doesUrlExistAction, setUploadShopImageAction} from "../../../../actions/ShopInfo";
import {store} from '../../../../MainApp';
import {deleteFromS3, getS3Images, uploadToS3} from "../../../../actions/S3";

class S3FileUpload {
    static async uploadImage(file, config) {
        const key = `${config.dirName ? config.dirName + "/" : ""}${file.name}`;
        const form = new FormData();
        form.append('file', file);
        const params = {
            bucket: config.bucketName,
            key,
            form
        }
        return new Promise((resolve, reject) => store.dispatch(uploadToS3(params, (response) => {
            resolve(response);
        },
        (error) => {
            reject(error);
        })))
    }


    static async deleteUpload(config, fileName) {
        const key = `${config.dirName ? config.dirName + "/" : ""}${fileName}`;
        const params = {
            bucket: config.bucketName,
            key
        };
        return new Promise((resolve, reject) => store.dispatch(deleteFromS3(params, (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            })))
    }

    static async getFiles(config, shopId) {
        const params = {
            bucket: config.bucketName,
            shopId
        };
        return new Promise((resolve, reject) => store.dispatch(getS3Images(params, (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            })))
    }

}
const { uploadFile, deleteFile } = S3FileUpload;
export { uploadFile, deleteFile };
export default S3FileUpload;
