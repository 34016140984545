import {NOT_IN_STORE} from "../constants/ActionTypes";
import {baseUrl} from "../constants/baseUrl";

export const refundItem = (orderItemId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    meta: {
        type: 'apijson',
        url: baseUrl + `/refund_item`,
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            order_item_id: orderItemId
        },
        options: {
            method: 'PUT'
        }
    }
})
