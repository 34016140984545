import React, {Component} from 'react';
import Card from '@material-ui/core/Card';

class WorkBlock extends Component {
    render() {
        return(
            <div className={this.props.class}>
                <span className='col-md-1 col'></span>
                <div className={this.props.textClass}>
                    <h1 className="genericTitle">{this.props.title}</h1>
                    <div className="stepGenericBody"><p>{this.props.body}</p></div>
                    <span className="genericAuthor">{this.props.author}</span>
                    <span className="genericSource">{ this.props.source}</span>
                </div>
                <span className='col-md-1 col'></span>
                <div className={this.props.imageClass}>
                    <img src={this.props.imageURL} className="workIcon" alt="image" />
                </div>
                <span className='col-md-2 col-1'></span>
            </div>
        );
    }
}

export default WorkBlock;