import React from 'react';
import MenuItem from './MenuItem';
import CustomScrollbars from '../../../../util/CustomScrollbars';
import Button from '@material-ui/core/Button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { addMenuItemAction } from '../../../../actions/Menu';
import selectors from '../../../../reducers/selectors';
import EventItem from "./EventItem";

const MenuItemList =(({items, onClickImage, width, addMenuItemAction, menuUI, hasComplimentary, onItemDragEnd, categories}) => {
    return (
        <DragDropContext onDragEnd={onItemDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} className="module-list">
                        <CustomScrollbars className="module-list-scroll scrollbar"
                                        style={{height: width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                            {items && items.map((item, index) =>
                                <Draggable key={index} draggableId={index} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}>
                                            {item.isEvent ?
                                                <EventItem key={index}
                                                          i={index}
                                                          item={item}
                                                          onClickImage={onClickImage}
                                                          hasComplimentary={hasComplimentary}/>
                                                :
                                                <MenuItem key={index}
                                                i={index}
                                                item={item}
                                                onClickImage={onClickImage}
                                                hasComplimentary={hasComplimentary}/>}
                                        </div>
                                    )}
                                </Draggable>
                            )}
                            <Button color="primary" onClick={() => addMenuItemAction(categories[menuUI.drinkOrFood].events)} style={{marginTop: 10, marginBottom: 10}}>
                                Add Item
                            </Button>
                        </CustomScrollbars>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
})

const mapStateToProps = state => {
    return {
        menuUI: selectors(state).menuUI
    }
}
const actionCreators = {
    addMenuItemAction,
}

export default connect(
    mapStateToProps,
    actionCreators
)(MenuItemList)
