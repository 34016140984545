import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';


class CustomerRecencyTableCell extends Component {

    render() {
        const { name, customers, percentageCurr, percentagePast } = this.props.data;
        const id = this.props.id;
        const iconName = percentageCurr > percentagePast ? "up" : percentageCurr == percentagePast ? "same" : "down";
        const statusStyle = percentageCurr >= percentagePast ? " text-success" : "text-danger";

        if (this.props.data == 'No customers in this time period') {
            const timePeriod = (id == 1) ? 'Day' : (id == 2) ? 'Week' : (id == 3) ? 'Month' : (id == 4) ? 'Year' : 'time';
            return (
                <tr
                    tabIndex={- 1}
                    key={id}
                >
                    <td>
                        <div className="user-profile py-1 d-flex flex-row align-items-center">

                            <div className="user-detail">
                                <h4 className="user-name">
                                    <i className="zmdi zmdi-circle recencyBullet"></i>
                                    <span className="listIndent">No data from this {timePeriod}.</span>
                                </h4>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        } else {
            return (
                <tr
                    tabIndex={- 1}
                    key={id}
                >
                    <td>
                        <div className="user-profile py-1 d-flex flex-row align-items-center">

                            <div className="user-detail">
                                <h4 className="user-name">
                                    <i className="zmdi zmdi-circle recencyBullet"></i>
                                    <span className="listIndent">Last full {name}</span>
                                </h4>
                            </div>
                        </div>
                    </td>
                    <td>
                        <h5 className="mb-0 dataSizeMd">{customers}</h5>
                        <span className="text-muted">Customers</span>
                    </td>
                    <td className="text-right">
                        <div className={`${statusStyle}`}>
                            <i className={`zmdi zmdi-trending-${iconName}`} />
                            {percentageCurr}%
                        </div>
                    </td>
                </tr >

            );
        }
    }
}

export default CustomerRecencyTableCell;