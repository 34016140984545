
export default class MemberBenefitIcons {
    constructor() {
        this.images = [
            { key: 'benefit-drink.png', defaultName: 'Benefit Drink' },
            { key: 'benefit-deal.png', defaultName: 'Benefit Deal'  },
            { key: 'benefit-event.png', defaultName: 'Benefit Event' },
            { key: 'benefit-exclusive.png', defaultName: 'Benefit Exclusive' },
            { key: 'benefit-cancel.png', defaultName: 'Benefit Cancel' }
        ]
    }

    getImages() {
        //alphabetical order
        return this.images.sort((a, b) => a.defaultName.localeCompare(b.defaultName))
    }

    iconForCurrentValue(value) {
        var containedIcon = {key: -1, defaultName: ""}
        for(var index in this.images) {
            var image = this.images[index]
            if(value.toLowerCase().includes(image.defaultName.toLowerCase())) {
                if(image.defaultName.length > containedIcon.defaultName.length) {
                    containedIcon = Object.assign({}, image)
                }
            }
        }
        return containedIcon.key
    }
}