import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import StepBlock from './StepBlock.js';
import howTo1 from '../../../src/assets/home/how_to_1.png'
import howTo2 from '../../../src/assets/home/how_to_2.png'
import howTo3 from '../../../src/assets/home/how_to_3.png'
import howTo4 from '../../../src/assets/home/how_to_4.png'

class HowItWorks extends Component {
    render() {
        const steps = [{
            body: "Choose your favorite coffee shop near you",
            className: "genericBlock stepBlock row",
            imageURL: howTo1,
            title: "One"
        },
        {
            body: "Choose a drink or item and customize it to your liking",
            className: "genericBlockReverse stepBlock row",
            imageURL: howTo2,
            title: "Two"
        },
        {
            body: "Checkout! You will receive a notification once a barista has started working on your order",
            className: "genericBlockReverse stepBlock step3Block row",
            imageURL: howTo3,
            title: "Three"
        },
        {
            body: "Pick up your order at the coffee shop counter!",
            className: "genericBlock stepBlock row",
            imageURL: howTo4,
            title: "Four"
        }];

        const stepsView = steps.map((steps, index) => {
            return <StepBlock key={steps.title}
                className={steps.className}
                title={steps.title}
                body={steps.body}
                index={index + 1}
                imageURL={steps.imageURL} />
        })
        return (
            <div className="HowItWorksWrapper">
                <div className="headerTextWrapper">
                    <h1 className="headerText">How CoffeePass Works</h1>
                </div>
                {stepsView}
            </div>
        );
    }
}

export default HowItWorks;
