
import React, {Component} from 'react';
import { Switch, FormControlLabel, TextField } from '@material-ui/core';
import SaveSureDialog from './SaveSureDialog';
import Button from '@material-ui/core/Button';
import PopoverItem from './PopoverItem'
import MaskedInput from 'react-text-mask';
import { getShopInfoAction, updateShopTaxPausedAction } from '../../../actions/ShopInfo';
import { connect } from 'react-redux';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import selectors from '../../../reducers/selectors';

export const TaxMask = (props) => {
    const { mask, inputRef, ...other } = props;
  
    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={(rawValue)=> {
                console.log("rawValue", rawValue)
                if(rawValue.includes('.') && rawValue.indexOf('.') == 1) {
                    return [/\d/, /\./, /\d/, /\d/, /\d/, '%']
                } else {
                    return [/\d/, /\d/, /\./, /\d/, /\d/, /\d/, '%']
                }
            }}
            placeholderChar={'\u2000'}
            guide={true}
            showMask
        />
    )
}

class MiscFields extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tax: '0%',
            isPaused: false,
            facebookUrl: '',
            instagramUrl: '',
            isDialogOpen: false
        }

        this.stateCopy = Object.assign(this.state, {})

        this.props.getShopInfoAction(this.props.shopId,
            (resp) => {
                if(resp.val != false) {
                    this.stateCopy = Object.assign(resp, {}, {isDialogOpen: false})
                    this.setState(resp)
                }
            },
            (error) => {
                console.log("getshop info error", error)
            })

        this.onSaveClicked = this.onSaveClicked.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.onDialogYesClicked = this.onDialogYesClicked.bind(this)
        this.isStateDifferent = this.isStateDifferent.bind(this)
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
        MixpanelTrack('set tax to ' + event.target.value)
    }

    onSwitchChange = (checked) => {
        this.setState({isPaused: !checked})
        MixpanelTrack('set isShopPaused to ' + !checked)
    }

    onSaveClicked() {
        this.setState({
            isDialogOpen: true
        })
        MixpanelTrack('Save Shop Misc')
    }

    handleDialogClose() {
        this.setState({isDialogOpen: false})
    }

    onDialogYesClicked() {
        this.handleDialogClose()
        MixpanelTrack('Save Shop Misc Comfirmed')
        const paramsObj = Object.assign({}, this.state, {
            shopId: this.props.shopId,
            tax: (typeof this.state.tax === 'string')? parseFloat(this.state.tax.replace('%', '')) : this.state.tax
        })
        this.props.updateShopTaxPausedAction(paramsObj,
            (resp) => {
                if(resp == "True") {
                    NotificationManager.success("The new settings are now saved in CoffeePass!", "Success")
                    this.stateCopy = Object.assign(this.state, {})
                    this.setState(this.state)
                } else {
                    NotificationManager.error("There was an Error saving the Shop's Settings. If the problem persists contact support@coffeepass.io.", "Error Saving Settings!", 9000)
                }
            },
            (error) => {
                NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error saving Settings!", 9000)
            })
    }

    isStateDifferent() {
        for(var key in this.state) {
            var val = this.state[key]
            var copyVal = this.stateCopy[key]
            if(key == 'tax') {
                val = (typeof this.state.tax === 'string')? parseFloat(this.state.tax.replace('%', '')) : this.state.tax
                copyVal = (typeof this.stateCopy.tax === 'string')? parseFloat(this.stateCopy.tax.replace('%', '')) : this.stateCopy.tax
            }
            if(val != copyVal) {
                return true
            }
        }
        return false
    }

    render(props) {
        return(
            <form className="row" autoComplete="off">
                <div className="col-md-6 col-12">
                    <TextField
                        required
                        label="Tax"
                        value={this.state.tax}
                        onChange={this.handleChange('tax')}
                        margin="normal"
                        InputProps={{
                            inputComponent: TaxMask,
                        }}
                    />
                    <PopoverItem 
                        placement={'right'} 
                        id={'myinfoId1'}
                        title="What is This?"
                        text="Some states require tax for every drink/food transaction. Put the percentage of the transaction that is taxed here. If your state doesn't require tax, leave this as 0%." />
                </div>
                <div className="col-md-12 col-12">
                    <FormControlLabel
                        control={
                            <Switch color="primary"
                                classes={{
                                    checked: 'text-primary',
                                    bar: 'bg-primary',
                                }}
                                checked={!this.state.isPaused}
                                onChange={(event, checked) => this.onSwitchChange(checked)}
                            />
                        }
                        label={this.state.isPaused? "Shop is currently Paused." : "Shop is currently Live!"} />
                    <PopoverItem 
                        placement={'right'} 
                        id={'myinfoId'}
                        title="What is This?"
                        text="You can pause your shop at anytime. Your shop will still show up in the CoffeePass app but customers won't be able to order from it. If this says your shop is live then customers will be able to make orders from CoffeePass now." />
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        id="facebook_url"
                        label="Facebook Page Link"
                        value={this.state.facebookUrl}
                        onChange={this.handleChange('facebookUrl')}
                        margin="normal"
                        fullWidth
                    />
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        id="instagram_url"
                        label="Instagram Page Link"
                        value={this.state.instagramUrl}
                        onChange={this.handleChange('instagramUrl')}
                        margin="normal"
                        fullWidth
                    />
                </div>
                <div className="col-md-12 col-12" style={styles.buttonContainer}>
                    <Button
                        variant="raised"
                        color="primary"
                        className="jr-btn"
                        disabled={!this.isStateDifferent()}
                        onClick={this.onSaveClicked}>
                        Save
                    </Button>
                </div>
                <SaveSureDialog
                    isDialogOpen= {this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    onYesClicked={this.onDialogYesClicked}
                    onNoClicked={this.handleDialogClose}
                    dialogDataToSave="shop info"
                    />
                <NotificationContainer />
            </form>
            )
    }
}

const styles = {
    buttonContainer: {
        display: 'flex', 
        justifyContent: 'flex-end'
    }
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    updateShopTaxPausedAction,
    getShopInfoAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(MiscFields);