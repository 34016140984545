
import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import SaveStepButton from './SaveStepButton';
import { NavLink } from 'react-router-dom';


export class TermsConditions extends Component {
    
    render() {
        const {clickedCheckbox, activeStep} = this.props
        
        return <div className="tab-pane" id="tab2-4">
            <h3 className="title text-primary">Terms and Conditions</h3>
                
            <div className="tos-onboarding d-flex align-items-left">
                <NavLink to={'/terms'} target="_blank" style={{ textDecoration: 'none' }}>
                    <div className="onboardingTOSLink">
                        Click here to see the Terms of Service
                    </div>
                </NavLink>
                <NavLink to={'/privacy'} target="_blank" style={{ textDecoration: 'none' }}>
                    <div className="onboardingTOSLink">
                        Click here to see the Privacy Policy
                    </div>
                </NavLink>
                
            </div>
            <div className="tos-onboarding d-flex align-items-center">
                <div>
                    <Checkbox color="primary" onClick={clickedCheckbox}/> 
                    <span>I agree with the Terms and Conditions.</span>
                </div>
            </div>
            {activeStep != undefined?
                <div className="col-md-12 col-12" style={styles.buttonContainer}>
                    <SaveStepButton {...this.props}
                        activeStep={activeStep}
                        isStateDifferent={() => {}} 
                        onSaveClicked={() => {}}
                        isLoadingSave={false}
                        onDialogYesClicked={(handleNext)=> handleNext()} />
                </div>
                :
                null
            }
        </div>
    }
}

const styles = {
    buttonContainer: {
        display: 'flex', 
        justifyContent: 'flex-end'
    }
}