import {analyticsConstants, NOT_IN_STORE} from "../constants/ActionTypes";
import { baseUrl } from "../constants/baseUrl";

export const updateCheckoutButtons = (options, shopId, onSuccess, onFailure) => ({
    type: NOT_IN_STORE,
    payload: options,
    meta: {
        type: 'apijson',
        url: baseUrl + '/update_checkout_buttons',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shopId: shopId
        },
        options: {
            method: 'PUT',
            body: JSON.stringify(options),
        }
    }
})
