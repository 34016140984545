import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION
} from '../constants/ActionTypes';
import logo from '../assets/coffeepass_web_logo_interior.png'
import { isShopChainAction } from '../actions/ManageShops';

class Header extends React.Component {

    onAppNotificationSelect = () => {
        this.setState({
            appNotification: !this.state.appNotification
        })
    };
    onMailNotificationSelect = () => {
        this.setState({
            mailNotification: !this.state.mailNotification
        })
    };
    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
        })
    };
    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    };
    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };
    handleRequestClose = () => {
        this.setState({
            langSwitcher: false,
            userInfo: false,
            mailNotification: false,
            appNotification: false,
            searchBox: false
        });
    };


    constructor(props) {
        super(props);
        this.state = {
            anchorEl: undefined,
            searchBox: false,
            searchText: '',
            mailNotification: false,
            userInfo: false,
            langSwitcher: false,
            appNotification: false,
            isChain: false
        }

        props.dispatchIsShopChain(this.props.shopId,
            (resp) => {
                if(resp == "True") {
                    this.setState({'isChain': true})
                }
            })
    }

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    updateSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
        });
    }

    render() {
        const {drawerType, locale, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

        return (
            <AppBar
                className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
                <Toolbar className="app-toolbar" disableGutters={false}>
                    {navigationStyle === HORIZONTAL_NAVIGATION ?
                        <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                            <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
                        </div>
                        :
                        <IconButton color={"primary"} className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                                    onClick={this.onToggleCollapsedNav}>
                            <span style={{backgroundColor: "#c25551"}}className="menu-icon"/>
                        </IconButton>
                    }

                    <Link className="app-logo" to="/">
                        <img src={logo} alt="CoffeePass Logo" title="CoffeePass Logo"/>
                    </Link>
                    {this.state.isChain?
                        <div style={{fontSize: 20}}>
                            Chain Dashboard
                        </div>
                        :
                        <div />
                    }
                </Toolbar>
            </AppBar>
        );
    }

}

const mapStateToProps = (state) => {
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = state.settings;
    return {drawerType, locale, navigationStyle, horizontalNavPosition, shopId: state.auth.shop.shopId}
}

const mapDispatchToProps = dispatch => ({
    dispatchIsShopChain: (shopId, onSuccess, onFailure) => dispatch(isShopChainAction(shopId, onSuccess, onFailure))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
