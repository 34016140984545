import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import { NotificationManager, NotificationContainer } from 'react-notifications';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from '../assets/coffeepass_web_logo_interior.png';
import { MixpanelTrack } from "../util/MixpanelHelper";
import { resetPasswordAction, isTokenExpired } from "../actions/ResetPassword";
import * as qs from 'query-string';

class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            password: '',
            confirmPassword: '',
            isValidToken: true,
            isPasswordsEqual: true
        }
    }
    
    componentDidMount() {
        MixpanelTrack("Visited Reset Password Page")
        this.token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id

        this.props.isTokenExpired(this.token,
            resp => {
                this.setState({isValidToken: resp == "False"})
            }, error => {
                this.setState({isValidToken: false})
            })
        
        if(this.token == undefined) {
            this.setState({isValidToken: false})
        }
    }

    render() {

        const {showMessage, loader, alertMessage} = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img src={logo} className="appLogo" alt="logo" />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>Reset Password</h1>
                        </div>
                        {!this.state.isValidToken &&
                            <div className="app-login-header mb-4">
                                <h1 style={{color: 'red'}}>Your token has expired. You will have to request to reset your password again.</h1>
                            </div>
                        }
                        {!this.state.isPasswordsEqual &&
                            <div className="app-login-header mb-4">
                                <h1 style={{color: 'red'}}>Passwords are not the same.</h1>
                            </div>
                        }
                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        type="password"
                                        label={'New Password'}
                                        fullWidth
                                        onChange={(event) => this.setState({password: event.target.value})}
                                        defaultValue={''}
                                        margin="normal"
                                        className="mt-1 my-sm-3" />
                                    <TextField
                                        type="password"
                                        label={'Confirm Password'}
                                        fullWidth
                                        onChange={(event) => this.setState({confirmPassword: event.target.value})}
                                        defaultValue={''}
                                        margin="normal"
                                        className="mt-1 my-sm-3" />
                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button 
                                            disabled={!this.state.isValidToken}
                                            onClick={() => {
                                                if(this.state.password == this.state.confirmPassword) {
                                                    this.props.resetPasswordAction({
                                                        password: this.state.password,
                                                        token: this.token
                                                    }, (resp) => {
                                                        if(resp == 'True') {
                                                            MixpanelTrack('Successfully reset password')
                                                            NotificationManager.success("You Successfully changed your password", "Success", 6000)
                                                            setTimeout(() => {
                                                                this.props.history.push('/')
                                                            }, 6000);
                                                        } else {
                                                            NotificationManager.error("Password Reset must be expired", "Error", 6000)
                                                        }
                                                    }, (error) => {
                                                        NotificationManager.error("Error connecting to server. If you would, let us know the issue by emailing us at feedback@coffeepass.io. Thanks!", "Error", 6000)
                                                    })
                                                    this.setState({isPasswordsEqual: true})
                                                } else {
                                                    this.setState({isPasswordsEqual: false})
                                                }
                                                
                                        }} variant="raised" color="primary">
                                            Set Password
                                        </Button>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {showMessage && console.log(alertMessage)}
                <NotificationContainer/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {loader, alertMessage, showMessage, authUser, shop} = state.auth;
    return {loader, alertMessage, showMessage, authUser, shop}
};

export default connect(mapStateToProps, {
    resetPasswordAction,
    isTokenExpired
})(ResetPassword);
