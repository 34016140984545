import React from 'react';
import {Popover, PopoverBody, PopoverHeader} from 'reactstrap';
import Button from '@material-ui/core/Button';
import { PropTypes } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

export default class PopoverItem extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        return (
            <span className="d-inline-block">
                <IconButton id={'Popover-' + this.props.id} color="primary" aria-label="Info" variant="raised" onClick={this.toggle} >
                    <InfoIcon />
                </IconButton>
                <Popover placement={this.props.placement} isOpen={this.state.popoverOpen}
                        target={'Popover-' + this.props.id} toggle={this.toggle}>
                <PopoverHeader>{this.props.title}</PopoverHeader>
                <PopoverBody>{this.props.text}</PopoverBody>
                </Popover>
            </span>
        );
    }
}

PopoverItem.propTypes = {
    placement: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}
