import { analyticsConstants } from "../constants/ActionTypes";
import { baseUrl } from '../constants/baseUrl';

export const getRecentOrdersAction = (paramObj, onSuccess, onFailure) => ({
    type: analyticsConstants.GET_RECENT_ORDERS,
    payload: paramObj,
    meta: {
        type: 'api',
        url: baseUrl + '/recent_orders',
        onSuccess: onSuccess,
        onFailure: onFailure,
        params: {
            shop_id: paramObj.shopId,
            num_orders: paramObj.numOrders
        },
        options: {
            method: 'GET'
        }
    }
})