//import action type
import {
    analyticsConstants
} from "../constants/ActionTypes";

//set INIT_STATE
const INIT_STATE = {
    numOfOrders: '',
    bestSellers: {},
    grossOrders: {},
    grossSalesToday: {},
    recentOrders: {},
    uniqueCustomers: '',
    averageTransaction: '',
    newCustomers: {},
    customerRecencyDay: {},
    customerRecencyWeek: {},
    customerRecencyMonth: {},
    customerRecencyYear: {},
    customerFreqFive: {},
    customerFreqFour: {},
    customerFreqThree: {},
    customerFreqTwo: {},
    customerFreqOne: {},
    grossSalesRange: {},
    grossSalesHours: {},
    grossTips: {},
    grossTax: {},
    grossSales: {},
    tableDateRangeReportData: {},
    payoutTableData: {},
    topFiveItems: {},
    itemSales: {},
    topFiveAddons: {},
    addonSales: {},
    membershipStats: {}
}

//export the actions
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case analyticsConstants.GET_ADDON_SALES_DATA: {
            return {
                ...state,
                addonSales: action.payload
            }
        }
        case analyticsConstants.GET_TOP_FIVE_ADDON_SALES: {
            return {
                ...state,
                topFiveAddons: action.payload
            }
        }
        case analyticsConstants.GET_ITEM_SALES_DATA: {
            return {
                ...state,
                itemSales: action.payload
            }
        }
        case analyticsConstants.GET_TOP_FIVE_ITEM_SALES: {
            return {
                ...state,
                topFiveItems: action.payload
            }
        }
        case analyticsConstants.GET_PAYOUT_TABLE_DATA: {
            return {
                ...state,
                payoutTableData: action.payload
            }
        }
        case analyticsConstants.GET_TABLE_DATE_RANGE_REPORT: {
            return {
                ...state,
                tableDateRangeReportData: action.payload
            }
        }
        case analyticsConstants.GET_GROSS_SALES: {
            return {
                ...state,
                grossSales: action.payload
            }
        }
        case analyticsConstants.GET_GROSS_TIPS: {
            return {
                ...state,
                grossTips: action.payload
            }
        }
        case analyticsConstants.GET_GROSS_TAX: {
            return {
                ...state,
                grossTax: action.payload
            }
        }
        case analyticsConstants.GET_GROSS_SALES_HOURS: {
            return {
                ...state,
                grossSalesHours: action.payload
            }
        }
        case analyticsConstants.GET_GROSS_SALES_RANGE: {
            return {
                ...state,
                grossSalesRange: action.payload
            }
        }
        case analyticsConstants.GET_CUSTOMER_FREQ_FIVE: {
            return {
                ...state,
                customerFreqFive: action.payload,
            }
        }
        case analyticsConstants.GET_CUSTOMER_FREQ_FOUR: {
            return {
                ...state,
                customerFreqFour: action.payload
            }
        }
        case analyticsConstants.GET_CUSTOMER_FREQ_THREE: {
            return {
                ...state,
                customerFreqThree: action.payload
            }
        }
        case analyticsConstants.GET_CUSTOMER_FREQ_TWO: {
            return {
                ...state,
                customerFreqTwo: action.payload
            }
        }
        case analyticsConstants.GET_CUSTOMER_FREQ_ONE: {
            return {
                ...state,
                customerFreqOne: action.payload
            }
        }
        case analyticsConstants.GET_CUSTOMER_RECENCY_DAY: {
            return {
                ...state,
                customerRecencyDay: action.payload
            }
        }
        case analyticsConstants.GET_CUSTOMER_RECENCY_WEEK: {
            return {
                ...state,
                customerRecencyWeek: action.payload
            }
        }
        case analyticsConstants.GET_CUSTOMER_RECENCY_MONTH: {
            return {
                ...state,
                customerRecencyMonth: action.payload
            }
        }
        case analyticsConstants.GET_CUSTOMER_RECENCY_YEAR: {
            return {
                ...state,
                customerRecencyYear: action.payload
            }
        }
        case analyticsConstants.GET_BEST_SELLERS: {
            return {
                ...state,
                bestSellers: action.payload
            }
        }
        case analyticsConstants.GET_GROSS_ORDERS: {
            return {
                ...state,
                grossOrders: action.payload
            }
        }
        case analyticsConstants.GET_GROSS_SALES_TODAY: {
            return {
                ...state,
                grossSalesToday: action.payload
            }
        }
        case analyticsConstants.GET_UNIQUE_CUSTOMERS: {
            return {
                ...state,
                uniqueCustomers: action.payload
            }
        }
        case analyticsConstants.GET_NUM_ORDERS: {
            return {
                ...state,
                numOfOrders: action.payload
            }
        }
        case analyticsConstants.GET_RECENT_ORDERS: {
            return {
                ...state,
                recentOrders: action.payload
            }
        }
        case analyticsConstants.GET_AVERAGE_TRANSACTION: {
            return {
                ...state,
                averageTransaction: action.payload
            }
        }
        case analyticsConstants.GET_NEW_CUSTOMERS: {
            return {
                ...state,
                newCustomers: action.payload
            }
        }
        case analyticsConstants.GET_MEMBERSHIP_STATS: {
            return {
                ...state,
                membershipStats: action.payload
            }
        }
        default:
            return state
    }
}
