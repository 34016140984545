import React, {Component} from 'react';
import ExtraMenu from '../menu/MenuBuilder/ExtraMenu';
import TextFieldValidator from '../util/TextFieldValidator'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Question extends Component {

    onSelectOption(option) {
        if(option == 'Delete') {
            this.props.onDeleteQuestion(this.props.i)
        }
    }

    render() {
        const {i, question} = this.props
        var extraOptions = ['Delete']

        return (
            <div>
                <div className="row">
                    <div className="col-md-1 col-1 mt-3">
                        <span>{i+1}.</span>
                    </div>
                    <div className="col-md-10 col-10">
                        <div>
                            <TextFieldValidator
                                required
                                label="Question"
                                value={question.question}
                                onChange={event => this.props.onChangeQuestion(i, event.target.value)}
                                defaultValue=""
                                margin="normal"
                                fullWidth
                            />
                        </div>
                        <div style={styles.button}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={question.required}
                                        onChange={event => this.props.onQuestionRequiredChange(i, event.target.checked)}
                                        color="primary" />
                                }
                                label="Required" />
                        </div>
                    </div>
                    <div className="col-md-1 col-1 mt-2">
                        <ExtraMenu options={extraOptions} onSelectOption={option => this.onSelectOption(option)} />
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    button: {
        display: 'flex',
        alignItems: 'center',
    },
    error: {
        position: 'absoliute',
        color: '#ff0000',
        marginLeft: -10,
        marginTop: -35,
        zIndex: 10
    }
}

export default Question;