import React, {Component} from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

class SelectValidator extends Component {

    render() {
        const { errorMessage, label, style, ...rest } = this.props;
        const isError = errorMessage != undefined && errorMessage != ""
        
        return (
            <div>
                <FormControl margin="normal" style={style}>
                    <InputLabel error={isError}>{label}</InputLabel>
                    <Select
                        {...rest}
                        error={isError}>
                        { this.props.children }
                    </Select>
                </FormControl>
                {(isError)?
                    <div style={styles.errorText}>
                        {errorMessage}
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

const styles = {
    errorText: {
        left: 13,
        fontSize: '12px',
        color: '#ff0000',
        position: 'absolute',
        marginTop: '-8px',
    }
}

export default SelectValidator;