import React from 'react';
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
import { copyChainMenuAction, copyChainInfoAction } from '../../../actions/ManageShops';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import OverrideSureDialog from './OverrideSureDialog';
import { NotificationManager, NotificationContainer } from 'react-notifications';

class ShopItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isCopyShopInfoLoading: false,
            isCopyMenuLoading: false,
            isDialogOpen: false,
            dialogText: 'info'
        }

    }

    onCopyInfoClicked = () => {
        this.setState({isDialogOpen: true, dialogText: 'info'})
    }

    onCopyMenuClicked = () => {
        this.setState({isDialogOpen: true, dialogText: 'menu'})
    }

    onDialogYesClicked = (isShopInfo) => {
        this.onDialogNoClicked()
        if(isShopInfo) {
            this.setState({isCopyShopInfoLoading: true})
            this.props.copyChainInfoAction(this.props.shopId,
                (resp) => {
                    if(resp == "True") {
                        NotificationManager.success("The chain shop info has been copied over to the shops location!", "Success")
                    } else {
                        NotificationManager.error("Error: overriding info. If the problem persists contact support@coffeepass.io.", "Error Overriding Info!", 9000)
                    }
                    this.setState({isCopyShopInfoLoading: false})
                },
                (error) => {
                    this.setState({isCopyShopInfoLoading: false})
                    NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Overriding Info!", 9000)
                })
        } else {
            this.setState({isCopyMenuLoading: true})
            this.props.copyChainMenuAction(this.props.shopId,
                (resp) => {
                    if(resp == "True") {
                        NotificationManager.success("The chain shop menu has been copied over to the shops location!", "Success")
                    } else {
                        NotificationManager.error("Error: overriding menu. If the problem persists contact support@coffeepass.io.", "Error Overriding Menu!", 9000)
                    }
                    this.setState({isCopyMenuLoading: false})
                },
                (error) => {
                    this.setState({isCopyMenuLoading: false})
                    NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error Overriding Menu!", 9000)
                })
        }

    }

    onDialogNoClicked = () => {
        this.setState({isDialogOpen: false})
    }

    render() {
        return (
            <div style={styles.container}>
                <div className="row" style={{alignItems: 'center'}}>
                    <div className='col-3'>
                        {this.props.shopName}
                    </div>
                    <div className='col-3'>
                        {this.props.address}
                    </div>
                    <div className='col-2' />
                    <div className='col-2'>
                        <Button
                            variant="raised"
                            color="primary"
                            className="jr-btn"
                            onClick={this.onCopyInfoClicked}>
                            {this.state.isCopyShopInfoLoading? 
                                <CircularProgress size={16} style={styles.progress} />
                                :
                                "Overwrite Shop Info"
                            }
                        </Button>
                    </div>
                    <div className='col-2'>
                        <Button
                            variant="raised"
                            color="primary"
                            className="jr-btn"
                            onClick={this.onCopyMenuClicked}>
                            {this.state.isCopyMenuLoading? 
                                <CircularProgress size={16} style={styles.progress} />
                                :
                                "Overwrite Shop Menu"
                            }
                        </Button>
                    </div>
                </div>
                <OverrideSureDialog
                    isDialogOpen= {this.state.isDialogOpen}
                    handleClose={this.onDialogNoClicked}
                    onYesClicked={this.onDialogYesClicked}
                    onNoClicked={this.onDialogNoClicked}
                    dialogText={this.state.dialogText}
                    location={this.props.address} />
                <NotificationContainer />
            </div>
        )
    }
}

const styles = {
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    },
    container: {
        alignItems: 'center',
        padding: '15px 32px',
        borderBottom: 'solid 1px #e9ecef',
        position: 'relative'
    }
}

const mapStateToProps = (state) => {
    return {
        
    }
}

const actionCreators = {
    copyChainInfoAction,
    copyChainMenuAction
}

export default withRouter(connect(mapStateToProps, actionCreators)(ShopItem))