
import React, {Component} from 'react';
import {Cropper} from 'react-image-cropper'
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import S3FileUpload from './S3Upload/ReactS3';
import { doesUrlExistAction, setUploadShopImageAction } from '../../../actions/ShopInfo';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import SaveStepButton from '../../../containers/ShopOnboarding/SaveStepButton';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import selectors from '../../../reducers/selectors';
import {awsBuckets as awsBucket} from "../../../constants/awsBucket";

class ShopImageUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            accepted: [],
            rejected: [],
            src: null,
            imageExist: false,
            isUpdating: false,
            isLoadingSave: false
        }

        this.s3Config = {
            bucketName: awsBucket.shop_info_bucket,
            dirName: 'shop_logo',
            region: 'us-east-1'
        }

        if(this.props.imgUrl === '' || this.props.imgUrl === null || this.props.imgUrl === 'undefined'){
            this.url = `https://s3.amazonaws.com/${awsBucket.shop_info_bucket}/shop_logo/` + this.props.shopId + this.props.imgNameEnding
        }
        else{
            this.url = this.props.imgUrl
        }

        this.getImageExist(this.url)

        this.onCancelClicked = this.onCancelClicked.bind(this)
        this.onSaveClicked = this.onSaveClicked.bind(this)
    }

    onSelectFile = (accepted, rejected) => {
        this.setState({accepted, rejected});
        if (accepted && accepted.length > 0) {
            const reader = new FileReader()
            reader.addEventListener(
                'load',
                () =>
                    this.setState({
                        src: reader.result,
                    }),
                false
            )
            reader.readAsDataURL(accepted[0])
        }
        MixpanelTrack('selected image file')
    }

    onCancelClicked() {
        this.setState({src: null})
        MixpanelTrack('Clicked Cancel image')
    }

    onCancelUpdateClicked = () => {
        this.setState({isUpdating: false})
        MixpanelTrack('Clicked Cancel update image')
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    onSaveClicked() {
        MixpanelTrack('Saved image')
        var file = this.dataURLtoFile(this.croppedImage.crop(), this.props.shopId + this.props.imgNameEnding)
        this.setState({isLoadingSave: true})
        S3FileUpload
            .uploadImage(file, this.s3Config)
            .then(data => {
                this.getImageExist(this.url)
                this.props.setUploadShopImageAction({
                    shopId: this.props.shopId,
                    isPickupImage: this.props.isPickupImage
                })
                if(this.props.activeStep == undefined) {
                    NotificationManager.success("The new" + this.props.imgName + " is now saved in CoffeePass!", "Success")
                }
            })
            .catch(err => {
                console.error(err)
                this.setState({isLoadingSave: true})
                NotificationManager.error("Error: " + err + ". If the problem persists contact support@coffeepass.io.", "Error Saving Image!", 9000)
            })
    }

    onUpdateClicked = () => {
        this.setState({isUpdating: true})
    }

    getImageExist = (url) => {
        this.props.doesUrlExistAction(url,
            (resp) => {
                this.setState({imageExist: true, isUpdating: false, src: null, isLoadingSave: false})
            },
            (error) => {
                this.setState({imageExist: false, isUpdating: false, isLoadingSave: false})
            })
    }

    render() {
        return(
            <div>
                <span style={styles.text}>{this.props.text}</span>
                {this.state.imageExist && !this.state.isUpdating?
                    <div>
                        <div style={styles.imageContainer}>
                            <img style={styles.logo} src={this.url + '?t=' + new Date()}/>
                        </div>
                        <div style={styles.buttonContainer}>
                            <QuickButton
                                onClick={this.onUpdateClicked}
                                text='Update' />
                        </div>
                    </div>
                    :
                    <div>
                        {(this.state.src)?
                            <div>
                                <Cropper
                                    src={this.state.src}
                                    ref={ ref => { this.croppedImage = ref }}
                                    ratio={this.props.imgRatio}
                                    allowNewSelection={false}
                                />
                                <div style={styles.buttonContainer}>
                                    <QuickButton
                                            onClick={this.onCancelClicked}
                                            text='Cancel' />
                                    <Button
                                        variant="raised"
                                        color="primary"
                                        className="jr-btn"
                                        onClick={this.onSaveClicked}>
                                        {this.state.isLoadingSave?
                                            <CircularProgress size={16} style={styles.progress} />
                                            :
                                            "Save"
                                        }
                                    </Button>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="dropzone">
                                    <Dropzone
                                        accept="image/jpeg, image/png"
                                        onDrop={(accepted, rejected) => { this.onSelectFile(accepted, rejected)}} >
                                        <div style={styles.buttonContainer}>
                                            <Button
                                                variant="raised"
                                                color="primary"
                                                className="jr-btn">
                                                Upload {this.props.imgName}
                                            </Button>
                                        </div>
                                    </Dropzone>
                                </div>
                                {this.state.imageExist && this.state.isUpdating?
                                    <div style={styles.buttonContainer}>
                                        <QuickButton
                                            onClick={this.onCancelUpdateClicked}
                                            text='Cancel' />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        }
                    </div>
                }
                {(this.props.imgName == 'Shop Banner' && this.props.activeStep != undefined)?
                    <div className="col-12" style={styles.buttonContainer}>
                        <SaveStepButton {...this.props}
                            isStateDifferent={() => {}}
                            onSaveClicked={() => {}}
                            isLoadingSave={this.state.isLoadingNext}
                            onDialogYesClicked={(handleNext)=> handleNext()} />
                    </div>
                    :
                    null
                }
                <NotificationContainer />
            </div>
        )
    }
}

const QuickButton = ({onClick, text}) => (
    <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={onClick}>
        {text}
    </Button>
)

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'center',
        marginTop: 10
    },
    logo: {
        width: 'auto',
        height: 200
    },
    imageContainer: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    progress: {
        color: 'white',
        marginLeft: 7,
        marginRight: 7
    },
    text: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10
    }
}

ShopImageUpload.propTypes = {
    imgNameEnding: PropTypes.string.isRequired,
    imgName: PropTypes.string.isRequired,
    isPickupImage: PropTypes.bool.isRequired,
    imgRatio: PropTypes.number.isRequired,
    imgUrl: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        shopId: selectors(state).shopId
    }
}
const actionCreators = {
    doesUrlExistAction,
    setUploadShopImageAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(ShopImageUpload);
