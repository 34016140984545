import React, { Component } from 'react';
import ContainerHeader from '../../../util/ContainerHeader';
import BasicTableContainer from './components/Table/BasicTableContainer';
import WeekGraphReport from './components/Graphs/WeekGraphReport';
import TimeOfDay from './components/Graphs/TimeOfDay';
import DaySales from './components/Graphs/DaySales';
import DatePicker from './components/DatePicker/DatePicker';
import { Divider } from '@material-ui/core';

class SalesAnalytics extends Component {
    render() {
        return (
            <div className="dashboard animated slideInUpTiny animation-duration-3">
                <ContainerHeader match={this.props.match} title="Sales Overview" />
                <div className="row col-12 datePicker">
                    <DatePicker className="datePicker" />
                </div>
                <div className="row col-12 graphCard"> {/*Row for line graph */}
                    <DaySales />
                </div>
                <div className="row text-center col-12"> {/*Row for day of the week bar graph and time of day dot graph */}
                    <WeekGraphReport />
                    <TimeOfDay />

                </div>
                <div className="row text-center col-12"> {/*Row for table with data*/}
                    <BasicTableContainer />
                </div>
            </div>
        );
    }
}

export default SalesAnalytics;