import React from 'react';
import CustomScrollbars from '../../../../util/CustomScrollbars';
import { Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { connect } from 'react-redux';
import { addDefaultOptionAction } from '../../../../actions/Menu';
import colors from '../../../colors';
import { updateDefaultModifierIndexAction } from '../../../../actions/MenuBuilder';
import selectors from '../../../../reducers/selectors';

const DefaultList =(({defaultModifiers, width, selectedIndex, addDefaultOptionAction, updateDefaultModifierIndexAction}) => {
    return (
        <span style={styles.container}>
            <CustomScrollbars className="module-list-scroll scrollbar"
                            style={{height: width >= 1200 ? 'calc(100vh - 265px)' : 'calc(100vh - 245px)'}}>
                {defaultModifiers.map((modifier, index) =>
                    <span>
                        <Button key={index}
                            variant={selectedIndex == index? 'contained' : 'outlined'}
                                size="large" 
                                color="primary" 
                                style={styles.button}
                                onClick={() => updateDefaultModifierIndexAction(index)}
                                className='col-12'>
                            <span style={styles.left}>{modifier.title == ""? 'Empty' : modifier.title}</span>
                        </Button>
                        { (modifier.isValid == undefined || modifier.isValid)? null : <ErrorIcon style={styles.error}/>}
                    </span>
                )}
                <Button color="primary" onClick={() => {
                        addDefaultOptionAction()
                        updateDefaultModifierIndexAction(defaultModifiers.length)
                    }} style={{marginTop: 10, marginBottom: 10}}>
                    Add Default Modifier
                </Button>
            </CustomScrollbars>
        </span>
    )
})

const styles = {
    container: {
        margin: 10
    },
    button: {
        marginBottom: 5
    },
    left: {
        position: 'absolute',
        top: 9,
        left: 10
    },
    error: {
        position: 'absoliute',
        color: '#ff0000',
        marginLeft: -24,
        marginTop: -10,
        zIndex: 10
    }
}

const mapStateToProps = state => {
    return {
        selectedIndex: state.menuBuilder.selectedDefaultIndex,
        defaultModifiers: selectors(state).defaultModifiers
    }
}
const actionCreators = {
    addDefaultOptionAction,
    updateDefaultModifierIndexAction
}

export default connect(
    mapStateToProps,
    actionCreators
)(DefaultList)