import React, { Component } from 'react';
import HeaderBlock from '../home/HeaderBlock';
import Footer from '../home/Footer';
import TeamMember from './TeamMember';
import Product from './Product';
import NoahProfile from '../../assets/images/noah_profile_pic.png'
import NathanProfile from '../../assets/images/nathan_profile_pic.png'
import AnyProfile from '../../assets/images/any_profile_pic.png'
import DrewProfile from '../../assets/images/drew_profile_pic.png'
import KarenProfile from '../../assets/images/karen_profile_pic.png'
import MojoSystem from '../../assets/images/mojo_system.png'
import { MixpanelTrack } from '../../util/MixpanelHelper';

class AboutUs extends Component {
    componentDidMount() {
        MixpanelTrack("Visited About Us Page")
    }

    render() {
        const services = [
            {
                id: 'order-ahead',
                title: 'CoffeePass\'s Story',
                description: 'CoffeePass started with a problem: big coffee chains and the restaurant industry as a whole were finding wild success with mobile ordering, but there wasn’t a good way for local coffee shops to offer their customers the same convenience. The existing solutions were focused on restaurants and delivery, neither of which lent themselves to independent coffee. Noah and Drew, best friends and former college roommates, set out to put their skills to use and solve this problem. First, they emailed over 100 coffee shops  to make sure this was a real issue, and to understand it better. After receiving validation from numerous shop owners, they went to work, building a simple app and crude order-processing system to test the idea at Wayfarer Coffee Roasters in Laconia, NH. After a lot of help from Karen, coffee shop owner-extraordinaire, and many iterations, CoffeePass is now ready to help increase revenue and improve customer experience for shops everywhere!',
                image: MojoSystem
            }
        ];
        const teams = [
            {
                name: 'Drew Bidlen',
                destination: 'Co-founder',
                description: 'Drew has been an entrepreneur all of his life. While other kids were selling lemonade, he was going door to door offering his services as a detective. During engineering school, he built and sold a digital night vision company. Now, he lives by the beaches of Charleston, South Carolina with his wife and golden retriever. If he\'s not working on CoffeePass, Drew is probably at the beach playing doubles volleyball.',
                image: DrewProfile
            },
            {
                name: 'Noah Bragg',
                destination: 'Co-founder',
                description: 'Noah and his beautiful wife Melissa just welcomed their first child, Lydia, to their family! In his free time Noah enjoys reading, listening to podcasts, and playing with his little baby girl! He has developed six apps in the App Store and three in the Google Play Store. He loves creating and building startups that benefit others.',
                image: NoahProfile
            },
            {
                name: 'Karen Bassett',
                destination: 'Advisor',
                description: 'Karen Bassett is the owner of Wayfarer Coffee Roasters in Laconia, NH. Her almost 4 year old coffee shop has been featured in the nationally published Barista Magazine and has been awarded Best Coffee in NH by Food and Wine Magazine. She is passionate about organization, efficiency, and systems that help small business owners, and loves opportunities to be involved in the community. When she is not at the coffee shop, she is spending time outside at her farm with her family and most likely baking sourdough.',
                image: KarenProfile
            },
            {
                name: 'Nathan Harris',
                destination: 'Web Dev Intern',
                description: 'Nathan was raised in the snowy tundra of northern Wisconsin, and perhaps that is why he holds a deep passion for steaming tea and chunky sweaters. Last summer he had the privilege of working on developing an insider-threat ranking algorithm for U.S. Bank. In the precious spare hours between finishing up his Bachelor\'s of Computer Science, Nathan loves to hit the gym, dance studio, and piano keys.',
                image: NathanProfile
            },
            {
                name: 'This could be you!',
                destination: 'Intern',
                description: 'Email us at support@coffeepass.io if you are interested in being a software developer intern or sales intern for CoffeePass!',
                image: AnyProfile
            }
        ];
        const serviceView = services.map((services) => {
            return <Product key={services.id}
                id={services.id}
                title={services.title}
                description={services.description}
                image={services.image} />
        })
        const teamsView = teams.map((teams) => {
            return <TeamMember key={teams.name}
                name={teams.name}
                destination={teams.destination}
                description={teams.description}
                image={teams.image} />
        })
        return (
            <div className='app'>
                <div className='aboutUsWrapper'>
                    <div className="topBlock">
                        <div className="navBarBackground">
                            <HeaderBlock isMainPage={false} />
                        </div>
                    </div>
                    <div className='serviceView'>
                        {serviceView}
                    </div>
                    <div className='teamsView'>
                        {teamsView}
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}

export default AboutUs;