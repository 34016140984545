
import React, {Component} from 'react';
import CardBox from '../analytics/util/CardBox';
import { Switch, FormControlLabel, TextField } from '@material-ui/core';
import SaveSureDialog from './SaveSureDialog';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import Checkbox from '@material-ui/core/Checkbox';
import { getShopUserAction } from '../../../actions/ShopInfo';
import { updateMembershipAction, getMembershipAction } from '../../../actions/Membership';
import { connect } from 'react-redux';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { MixpanelTrack } from '../../../util/MixpanelHelper';
import selectors from '../../../reducers/selectors';
import BenefitList from './BenefitList';
import QuestionList from './QuestionList';
import BenefitIconSelectDialog from './BenefitIconSelectDialog'
import {isShopChainAction} from "../../../actions/ManageShops";

export const RedemptionMask = (props) => {
    const { mask, inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={(rawValue)=> {
                // console.log("rawValue", rawValue)
                return [/\d/, /\d/, ' hour']
            }}
            placeholderChar={'\u2000'}
            guide={true}
            showMask
        />
    )
}

export const DiscountMask = (props) => {
    const { mask, inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={(rawValue)=> {
                console.log("rawValue", rawValue)
                if(rawValue.includes('.') && rawValue.indexOf('.') == 1) {
                    return [/\d/, /\./, /\d/, /\d/, '%']
                } else {
                    return [/\d/, /\d/, /\./, /\d/, /\d/, '%']
                }
            }}
            placeholderChar={'\u2000'}
            guide={true}
            showMask
        />
    )
}

class Membership extends Component {
    constructor(props) {
        super(props)

        this.state = {
            membershipId: null,
            isMembershipEnabled: false,
            callToAction: '',
            description: '',
            redemptionFrequency: 4,
            termsUrl: '',
            policyUrl: '',
            memberBenefits: [],
            questions: [],
            manualApproval: false,
            globalDiscountEnabled: true,
            globalDiscount: 0.0,
            startedDate: '',
            lastUpdatedDate: '',
            selectedImageIndex: 0,
            selectedImage: null,
            isDialogOpen: false,
            isSaveDialogOpen: false,
            isChain: false,
            isGlobalMembership: false,
            limitComplimentaryItems: false
        }

        this.stateCopy = Object.assign(this.state, {})

        this.props.getShopUserAction(this.props.shopId,
            (respShop) => {
                if(respShop.val !== false) {
                    this.setState({isMembershipEnabled: respShop.membershipEnabled})
                    if(respShop.membershipId){
                        this.props.getMembershipAction(respShop.membershipId,
                            (resp) => {
                                // console.log('Response', resp)
                                if(resp.val !== false) {
                                    this.stateCopy = Object.assign(resp, {}, {isMembershipEnabled:respShop.membershipEnabled, isSaveDialogOpen: false})
                                    this.setState(resp)
                                }
                            },
                            (error) => {
                                console.log("get shop membership error", error)
                            })
                    }
                }
            },
            (error) => {
                console.log("get shop user error", error)
            })

        this.onSaveClicked = this.onSaveClicked.bind(this)
        this.handleSaveDialogClose = this.handleSaveDialogClose.bind(this)
        this.onDialogYesClicked = this.onDialogYesClicked.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.getMembershipFields = this.getMembershipFields.bind(this);
    }

    componentDidMount() {
        this.props.isShopChainAction(this.props.shopId,
            (resp) => {
                if (resp === "True") {
                    this.setState({ isChain: true });
                }
            })
        MixpanelTrack("Visited Membership Page")
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
        // console.log('set ' + name + ' to ' + event.target.value)
        MixpanelTrack('set ' + name + ' to ' + event.target.value)
    }

    onSwitchChange = (event, checked) => {
        if(event.target.value === 'membership-switch'){
            this.setState({isMembershipEnabled: checked})
            MixpanelTrack('set isMembershipEnabled to ' + checked)
        }
        else if(event.target.value === 'discount-switch'){
            this.setState({globalDiscountEnabled: checked})
            MixpanelTrack('set globalDiscountEnabled to ' + checked)
        }
        else if(event.target.value === 'global-membership-switch'){
            this.setState({isGlobalMembership: checked})
            MixpanelTrack('set globalMembership to ' + checked)
        }
        else if(event.target.value === 'limit-complimentary-items-switch'){
            this.setState({limitComplimentaryItems: checked})
            MixpanelTrack('set limitComplimentaryItems to ' + checked)
        }
    }

    onApprovalTypeChange = (checked) => {
        this.setState({manualApproval: checked})
        MixpanelTrack('set manualApproval to ' + checked)
    }

    onSaveClicked() {
        if(this.isValidMembership()){
            this.setState({
                isSaveDialogOpen: true
            })
            MixpanelTrack('Save Shop Membership')
        }
    }

    handleSaveDialogClose() {
        this.setState({isSaveDialogOpen: false})
    }

    onDialogYesClicked() {
        var discount = (typeof this.state.globalDiscount === 'string')? parseFloat(this.state.globalDiscount.replace('%', '')) : this.state.globalDiscount
        if(discount === undefined || isNaN(discount)){
            discount = 0.0
        }
        this.handleSaveDialogClose()
        MixpanelTrack('Save Shop Membership Comfirmed')
        const paramsObj = Object.assign({}, this.state, {
            shopId: this.props.shopId,
            isMembershipEnabled: this.state.isMembershipEnabled,
            callToAction: this.state.callToAction,
            description: this.state.description,
            redemptionFrequency: (typeof this.state.redemptionFrequency === 'string')? parseInt(this.state.redemptionFrequency.replace('hour', '')) : this.state.redemptionFrequency,
            termsUrl: this.state.termsUrl,
            policyUrl: this.state.policyUrl,
            memberBenefits: this.state.memberBenefits,
            questions: this.state.questions,
            manualApproval: this.state.manualApproval,
            globalDiscountEnabled: this.state.globalDiscountEnabled,
            globalDiscount: discount,
            isGlobalMembership: this.state.isGlobalMembership,
            limitComplimentaryItems: this.state.limitComplimentaryItems
        })
        this.props.updateMembershipAction(paramsObj,
            (resp) => {
                if(resp && JSON.parse(resp)[0].membershipId > 0) {
                    NotificationManager.success("The new membership settings are now saved in CoffeePass!", "Success")
                    this.stateCopy = Object.assign(this.state, {})
                    this.setState(this.state)
                } else {
                    NotificationManager.error("There was an error in saving membership settings. If the problem persists contact support@coffeepass.io.", "Error saving membership!", 9000)
                }
            },
            (error) => {
                NotificationManager.error("Error: " + error + ". If the problem persists contact support@coffeepass.io.", "Error saving membership settings!", 9000)
            })
    }

    isValidMembership() {
        var errorMessage = ''
        var redemptionFrequency = (typeof this.state.redemptionFrequency === 'string')? parseInt(this.state.redemptionFrequency.replace('hour', '')) : this.state.redemptionFrequency
        // console.log("validate redemptionFrequency", redemptionFrequency)

        var globalDiscount = (typeof this.state.globalDiscount === 'string')? parseFloat(this.state.globalDiscount.replace('%', '')) : this.state.globalDiscount
        if(this.state.globalDiscountEnabled === true && (globalDiscount === undefined || isNaN(globalDiscount) || globalDiscount <= 0)){
            errorMessage = 'Invalid discount'
        }else if(this.state.callToAction === ''){
            errorMessage = 'Call to action is blank'
        }else if(this.state.callToAction.length > 250){
            errorMessage='Call to action is more than 250 characters'
        }else if(this.state.description === ''){
            errorMessage = 'Description is blank'
        }else if(this.state.description.length > 250){
            errorMessage = 'Description is more than 250 characters'
        }else if(redemptionFrequency === undefined || isNaN(redemptionFrequency) || redemptionFrequency <= 0){
            errorMessage = 'Invalid redemption frequency'
        }

        this.state.memberBenefits.map(objBenefit => (
            (() => {
                if(objBenefit.title === ''){
                    errorMessage = 'One of membership benefit title is blank'
                }else if(objBenefit.title.length > 100){
                    errorMessage = 'One of membership benefit title is more than 100 characters'
                }else if(objBenefit.icon === ''){
                    errorMessage = 'One of membership benefit icon is not set'
                }
             })()
        ))
        this.state.questions.map(objQuestion => (
            (() => {
                if(objQuestion.question === ''){
                    errorMessage = 'One of membership question is blank'
                }else if(objQuestion.question.length > 250){
                    errorMessage = 'One of membership question is more than 250 characters'
                }
             })()
        ))

        var isValid = false
        if(errorMessage !== ''){
            NotificationManager.error(errorMessage, "Error saving membership!", 5000)
        }else{
            isValid = true
        }
        return isValid
    }

    addBenefit = () => {
        var newBenefit = {
            title: '',
            description: '',
            icon: ''
        }
        var objects = this.state.memberBenefits
        objects.push(newBenefit)
        this.setState({memberBenefits: objects})
    }

    onDeleteBenefit = (idx) => {
        var objects = this.state.memberBenefits
        objects.splice(idx, 1)
        this.setState({memberBenefits: objects})
    }

    onChangeBenefit = (idx, value) => {
        var objects = this.state.memberBenefits
        objects[idx].title = value
        this.setState({memberBenefits: objects})
    }

    onClickImage = (idx, icon) => {
        this.setState({
            selectedImageIndex: idx,
            selectedImage: icon,
            isDialogOpen: true
        })
    }

    closeDialog(icon) {
        var objects = this.state.memberBenefits
        objects[this.state.selectedImageIndex].icon = icon
        this.setState({
            memberBenefits: objects,
            isDialogOpen: false
        })
    }

    addQuestion = () => {
        var newQuestion = {
            question: '',
            required: false
        }

        var objects = this.state.questions
        objects.push(newQuestion)
        this.setState({questions: objects})
    }

    onDeleteQuestion = (index) => {
        var objects = this.state.questions
        objects.splice(index, 1)
        this.setState({questions: objects})
    }

    onChangeQuestion = (index, value) => {
        var objects = this.state.questions
        objects[index].question = value
        this.setState({questions: objects})
    }

    onQuestionRequiredChange = (index, value) => {
        var objects = this.state.questions
        objects[index].required = value
        this.setState({questions: objects})
    }

    getFieldContent() {
        return (
            <CardBox styleName="col-lg-6" heading={"Membership"}>
                <form className="row" autoComplete="off">
                    <div className="col-md-12 col-12">
                        <FormControlLabel
                            control={
                                <Switch color="primary"
                                        classes={{
                                            checked: 'text-primary',
                                            bar: 'bg-primary',
                                        }}
                                        checked={this.state.isMembershipEnabled}
                                        value='membership-switch'
                                        onChange={(event, checked) => this.onSwitchChange(event, checked)}
                                />
                            }
                            label={this.state.isMembershipEnabled ? "Membership available at this shop" : "Membership not available at this shop"}/>
                    </div>
                    <div className="col-md-12 col-12">
                    </div>
                    <div className="col-md-12 col-12 mt-3">
                        <FormControlLabel
                            control={
                                <Switch color="primary"
                                        classes={{
                                            checked: 'text-primary',
                                            bar: 'bg-primary',
                                        }}
                                        checked={this.state.globalDiscountEnabled}
                                        value='discount-switch'
                                        onChange={(event, checked) => this.onSwitchChange(event, checked)}
                                />
                            }
                            label={this.state.globalDiscountEnabled ? "Discount available at this shop" : "Discount not available at this shop"}/>
                    </div>
                    <div className="col-md-12 col-12 mt-3">
                        <FormControlLabel
                            control={
                                <Switch color="primary"
                                        classes={{
                                            checked: 'text-primary',
                                            bar: 'bg-primary',
                                        }}
                                        checked={this.state.limitComplimentaryItems}
                                        value='limit-complimentary-items-switch'
                                        onChange={(event, checked) => this.onSwitchChange(event, checked)}
                                />
                            }
                            label={this.state.limitComplimentaryItems ?  "Members can redeem only one complimentary item" : "Members can redeem one of each complimentary item"}/>
                    </div>
                    <div className="col-md-12 col-12">
                        {this.state.globalDiscountEnabled ?
                            <TextField
                                required
                                label="Discount"
                                value={this.state.globalDiscount}
                                onChange={this.handleChange('globalDiscount')}
                                margin="normal"
                                InputProps={{
                                    inputComponent: DiscountMask,
                                }}
                            />
                            :
                            <div/>
                        }
                    </div>
                    <div className="col-md-12 col-12 mt-3">
                        <TextField
                            required
                            id="call_to_action"
                            label="Call-to-Action"
                            value={this.state.callToAction}
                            onChange={this.handleChange('callToAction')}
                            margin="normal"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            required
                            id="description"
                            label="Description"
                            value={this.state.description}
                            onChange={this.handleChange('description')}
                            margin="normal"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            required
                            id="redemption_frequency"
                            label="Redemption Frequency for Complimentary Items at Every"
                            value={this.state.redemptionFrequency}
                            onChange={this.handleChange('redemptionFrequency')}
                            margin="normal"
                            InputProps={{
                                inputComponent: RedemptionMask,
                            }}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            id="terms_url"
                            label="Terms & Conditions Link"
                            value={this.state.termsUrl}
                            onChange={this.handleChange('termsUrl')}
                            margin="normal"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            id="policy_url"
                            label="Privacy Policy Link"
                            value={this.state.policyUrl}
                            onChange={this.handleChange('policyUrl')}
                            margin="normal"
                            fullWidth
                        />
                    </div>
                    <div className="col-md-12 col-12 mt-4">
                        <BenefitList
                            memberBenefits={this.state.memberBenefits}
                            width={this.props.width}
                            addBenefit={this.addBenefit}
                            onDeleteBenefit={this.onDeleteBenefit}
                            onChangeBenefit={this.onChangeBenefit}
                            onClickImage={this.onClickImage.bind(this)}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <QuestionList
                            questions={this.state.questions}
                            width={this.props.width}
                            addQuestion={this.addQuestion}
                            onDeleteQuestion={this.onDeleteQuestion}
                            onChangeQuestion={this.onChangeQuestion}
                            onQuestionRequiredChange={this.onQuestionRequiredChange}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-md-9 col-9">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.manualApproval}
                                            onChange={(event, checked) => this.onApprovalTypeChange(checked)}
                                            color="primary"/>
                                    }
                                    label="Review & approve manually"/>
                            </div>
                            <div className="col-md-3 col-3" style={styles.buttonContainer}>
                                <Button
                                    variant="raised"
                                    color="primary"
                                    className="jr-btn"
                                    onClick={this.onSaveClicked}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                    <BenefitIconSelectDialog
                        isDialogOpen={this.state.isDialogOpen}
                        handleClose={() => {
                            this.setState({isDialogOpen: false})
                        }}
                        selectedImage={this.state.selectedImage}
                        onClickImage={this.closeDialog}
                    />
                    <SaveSureDialog
                        isDialogOpen={this.state.isSaveDialogOpen}
                        handleClose={this.handleSaveDialogClose}
                        onYesClicked={this.onDialogYesClicked}
                        onNoClicked={this.handleSaveDialogClose}
                        dialogDataToSave="shop membership info"
                    />
                    <NotificationContainer/>
                </form>
            </CardBox>)
    }

    getMembershipFields() {
        if(this.state.isMembershipEnabled || this.state.membershipId > 0) {
            if (this.props.membership.payload.isGlobalMembership && !this.state.isChain) {
                return <div/>
            } else if (this.state.isChain && !this.props.membership.payload.isGlobalMembership) {
                return (
                    <CardBox styleName="col-lg-6" heading={"Membership"}>
                        <span>Memberships for your shops must be managed at the individual shop level.</span>
                    </CardBox>
                    )
            } else return (this.getFieldContent())
        } else return (<div/>)
    }

    render(props) {
        return(this.getMembershipFields())
    }
}

const styles = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}

const mapStateToProps = state => {
    return {
        width: selectors(state).width,
        shopId: selectors(state).shopId,
        shop: selectors(state).shop,
        membership: state.membership
    }
}
const actionCreators = {
    getShopUserAction,
    getMembershipAction,
    updateMembershipAction,
    isShopChainAction,
}

export default connect(
    mapStateToProps,
    actionCreators
)(Membership);
