import React from "react";
import {ConnectedRouter} from "react-router-redux";
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";
import configureStore, {history} from "./store";
import "./helper/firebase";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import App from "./containers/App";
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: "https://afee19a5e290422a94e9964427cdb06c@sentry.io/1358661"
})

export const store = configureStore();

const MainApp = () =>
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={App}/>
            </Switch>
        </ConnectedRouter>
    </Provider>;

export default MainApp;